import {getItemByKey} from "./indexedDB";
import {
    sendEventReturn,
    sendEventReturnToAndroid,
    sendEventReturnToIOS,
    sendEventToAndroid,
    sendEventToIOS
} from "../api/nativeBridge";
import paymentApi from "../api/gatewayApi/payment";
import {ApiParams, ApiResponse} from "../api/types";

export const getCurrentYYYYMM = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
    return `${year}${month}`;
}

export const callCheckPassword = async (page?: string) => {
    const telcoCode = await getItemByKey("telcoCode")
    const isCarrier = telcoCode === "L";
    //bio여부와 상관없이 무조건 패스워드를 체크해야 하는 경우에는 param에 reqAuthType을 00으로 호출해야합니다.
    sendEventReturn("CHECK_PASSWORD", {reqAuthType: "01", isCarrier: isCarrier, page: page ?? ''})
}

export const getAgeCode = async () => {
    const birthday = await getItemByKey('birthday');
    if (!birthday) {
        return ''; // 빈값일경우 전체
    }
    // 생년월일을 YYYYMMDD 형식에서 연, 월, 일로 분리
    const birthYear = parseInt(birthday.substring(0, 4), 10);
    const birthMonth = parseInt(birthday.substring(4, 6), 10);
    const birthDay = parseInt(birthday.substring(6, 8), 10);

    // 현재 날짜 가져오기
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1
    const currentDay = today.getDate();

    // 나이 계산
    let age = currentYear - birthYear;

    // 생일이 아직 지나지 않았다면 나이를 1살 줄임
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    // 나이에 따른 코드 반환
    if (age < 10) {
        return '119001';
    } else if (age >= 10 && age < 20) {
        return '119002';
    } else if (age >= 20 && age < 30) {
        return '119003';
    } else if (age >= 30 && age < 40) {
        return '119004';
    } else if (age >= 40 && age < 50) {
        return '119005';
    } else if (age >= 50) {
        return '119006';
    } else {
        return '';
    }
}
export const handleSendEvent = (platform: string, key: string, param: any) => {
    console.log(platform, key, param)
    if (platform === 'Android') {
        const message = sendEventToAndroid(key, param);
        console.log('Android handleSendEvent', message);
    } else if (platform === 'IOS') {
        const message = sendEventToIOS(key, param);
        console.log('iOS handleSendEvent', message);
    } else {
        console.log('Platform not supported');
    }
};

export const handleSendEventReturn = (platform: string, key: string, param: any) => {
    if (platform === 'Android') {
        const message = sendEventReturnToAndroid(key, param);
        console.log('Android handleSendEvent', message);
    } else if (platform === 'IOS') {
        const message = sendEventReturnToIOS(key, param);
        console.log('IOS handleSendEvent', message);
    } else {
        console.log('Platform not supported');
    }
};

export const handleBackClick = (navigate: (to: number) => void) => {
    navigate(-1); // Navigate back
};

export const getMemberStatus = (memberId: string | undefined, memberStatus: string | undefined, cardLevel: string | undefined) => {
    if (!memberId || !memberStatus) return '0';
    if (!cardLevel) return '5';

    if (['41', '42', '43', '44', '49'].includes(memberStatus) && ['6', '8'].includes(cardLevel)) return '1'
    if (['41', '42', '43', '44', '49'].includes(memberStatus) && cardLevel === '7') return '2';
    if (memberStatus === '48' && ['6', '8'].includes(cardLevel)) return '3';
    if (memberStatus === '48' && cardLevel === '7') return '4';

    return '0';
};

export const formatAndMaskPhoneNumber = (phoneNumber: string) => {
    // 전화번호를 하이픈으로 구분된 배열로 나눕니다.
    const phonePattern = /(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/;
    const match = phoneNumber.match(phonePattern);

    if (match) {
        const [, part1, part2, part3, part4, part5] = match;

        const maskedPart3 = part3.replace(/\d/g, '*');
        const maskedPart5 = part5.replace(/\d/g, '*');

        return `${part1}-${part2}${maskedPart3}-${part4}${maskedPart5}`;
    }

    return phoneNumber;
}

export const formatTimeAgo = (itemDate: string): string => {
    const itemTime = new Date(itemDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - itemTime.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return "방금";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}분 전`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours}시간 전`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        return `${diffInDays}일 전`;
    }

    return itemDate;  // 7일 이상인 경우에는 원래 날짜를 반환합니다.
};

export const generateLast12Months = (year: number, month: number): { id: string, label: string }[] => {
    const months = [];
    let currentYear = year;
    let currentMonth = month;

    for (let i = 0; i < 12; i++) {
        const month = `${currentYear}년 ${currentMonth}월`;
        months.push({id: i.toString(), label: month});
        currentMonth--;
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear--;
        }
    }

    return months;
};

export const getBenefitTagByUserLevel = (userLevel: string) => {
    const benefitMap: { [key: string]: string } = {
        "6": "127001", // VVIP
        "7": "127003", // 우수
        "8": "127002", // VIP
    };

    return benefitMap[userLevel];
}

export const getTagInfoByBenefitCode = (benefitCode: string) => {
    const tagInfoMap: { [key: string]: { label: string; colorToken: string } } = {
        "127001": {label: 'VVIP', colorToken: 'primary_low'},
        "127002": {label: 'VIP', colorToken: 'purple_low'},
        "127003": {label: '우수', colorToken: 'blue_low'},
        "127004": {label: '일반', colorToken: 'default'},
        "127005": {label: '장기고객', colorToken: 'blue_low'},
        "127006": {label: '유쓰', colorToken: 'green_low'},
        "127007": {label: 'VIP콕', colorToken: 'purple_low'},
    };

    return tagInfoMap[benefitCode] || {label: '기본', size: 'xSmall', style: 'solid', colorToken: 'gray_low'};
}

export const extractMonth = (dateString: string) => {
    // MM 부분을 추출 (index 4, 5)
    const month = dateString.substring(4, 6);

    // 숫자로 변환하여 앞의 0을 제거하고 반환
    return parseInt(month, 10).toString();
}

export const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

export const goAppStore = async () => {
    const platform = await getItemByKey("platform")
    const url = platform === "IOS" ? "https://apps.apple.com/app/id958811075" : "https://play.google.com/store/apps/details?id=uplus.membership"
    sendEventReturn("EXTERNAL_CALL", {
        type: '1',
        url: url
    })
}

export const formatPhoneNumber = (phoneNumber: string) => {
    // 전화번호가 11자리이고 '010'으로 시작하는지 확인
    if (phoneNumber.length === 11 && phoneNumber.startsWith('010')) {
        return phoneNumber.slice(0, 3) + '0' + phoneNumber.slice(3);
    }
    // 조건에 맞지 않으면 원래 전화번호 반환
    return phoneNumber;
}

export const getColorTokenForLabel = (label: string): string => {
    switch (label) {
        case "유쓰":
            return "green_low";
        case "멤버십":
            return "blue_low";
        case "장기고객":
            return "green_low";
        default:
            return "orange_low";
    }
};
const ngEligibility: Record<'NG1' | 'NG2' | 'NG3' | '', number> = {
    '': 0,    // 2년 미만
    NG1: 2,  // 2년 이상
    NG2: 5,  // 5년 이상
    NG3: 10  // 10년 이상
};

// 혜택 코드 기준: 14001 10년 이상, 14002 5년 이상, 14003 2년 이상, 14004 2년 미만
const benefitEligibility: Record<'14001' | '14002' | '14003' | '14004', number> = {
    '14001': 10, // 10년 이상
    '14002': 5,  // 5년 이상
    '14003': 2,  // 2년 이상
    '14004': 0   // 2년 미만
};
export const isEligibleForBenefit = (ngCode: 'NG1' | 'NG2' | 'NG3' | '', benefitCodes: string): boolean => {
    // 고객 구분 기준: NG1 2년 이상, NG2 5년 이상, NG3 10년 이상

    // 혜택 코드들을 쉼표로 구분하여 배열로 변환
    const benefitCodeArray = benefitCodes.split(',');

    // 고객의 NG 코드에 따른 연수 가져오기
    const customerYears = ngEligibility[ngCode];

    // 혜택 코드들 중 하나라도 조건을 만족하는지 확인
    for (const benefitCode of benefitCodeArray) {
        // 혜택 코드에서 요구되는 연수를 가져옴
        const requiredYears = benefitEligibility[benefitCode.trim() as keyof typeof benefitEligibility];

        // 혜택 대상 여부 판단 (고객의 연수가 요구되는 연수 이상일 경우 true)
        if (customerYears >= requiredYears) {
            return true;
        }
    }

    // 조건을 만족하는 혜택 코드가 없으면 false 반환
    return false;
}

// callbackFunction body 값을 return 해준다
export const preparePaymentApi = async (certType: string, passwordsha: string, params: any, callbackFunction: (params: ApiParams | Record<string, any>, version: string) => Promise<any>): Promise<ApiResponse> => {
    try {
        const otpRes = await paymentApi.postPayOtp({certType: certType, uicc: ''}, 'v1');
        if (otpRes.header.resultCode !== '0000') {
            return otpRes;
        }
        let otpId = otpRes.body.otpId;

        const seqRes = await paymentApi.getPaySeq('v1');
        if (seqRes.header.resultCode !== '0000') {
            return seqRes;
        }
        const encSeq = seqRes.body.encSeq;

        const confirmRes = await paymentApi.postPayConfirm({
            otpId: otpId,
            encSeq: encSeq,
            passwordSha: passwordsha,
            appResult: '00',
            authType: '01'
        }, 'v1');
        if (confirmRes.header.resultCode !== '0000') {
            return confirmRes;
        }

        const req = {
            encSeq: encSeq,
            otpId: otpId,
            passwordSha: passwordsha,
            ...params
        };

        return await callbackFunction(req, 'v1');
    } catch (e) {
        console.log(e)
        return {header: {resultCode: '9999', message: '잠시후 다시 시도해주세요', timeStamp: ''}, body: {}};
    }
}

export const getBadgeTagId = (badgeName: string): string => {
    switch (badgeName) {
        case '나들이킹':
            return '0';
        case '쇼핑킹':
            return '1';
        case '푸드킹':
            return '2';
        case '콘텐츠킹':
            return '3';
        case '스터디킹':
            return '4';
        case '일상킹':
            return '5';
        case '뷰티킹':
            return '6';
        default:
            return '';
    }
};
