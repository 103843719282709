// ****************************************************
// [배포] 4.마이 - 휴대폰결제 - 결제 케이스 [결제완료] (UmembershipPaymentCompleted)
// [배포] 4.마이 - 휴대폰결제 - 결제 케이스 [결제 취소] (UmembershipPaymentCompleted_3)
// ****************************************************
import React from 'react'
import {Box, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonDividerThin from "../Common/CommonDividerThin";
import {PaymentInfo} from "../../pages/MobilePayment/MobilePaymentResult";
import {formatCurrency} from "../../utils/currencyUtils";

interface SectionInfoProps {
    paymentInfo: PaymentInfo;
}

const SectionInfo: React.FC<SectionInfoProps> = ({paymentInfo}) => {

    const renderContentRow = (key: string, value: string) => {
        return (<div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "4px 0"
        }}>
            <Text
                style={{flex: "1"}}
                typo="font_body_1M"
                color="color_text_neutral_4">
                {key}
            </Text>
            <Text typo="font_heading_5Sb" color="color_text_neutral_6">
                {formatCurrency(value)}원
            </Text>
        </div>)
    }
    return (
        <>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '휴대폰결제 내역'
                    }}
                    weight="bolder"
                />
            </Box>
            <div style={{
                padding: "12px 20px"
            }}>
                {renderContentRow('상품금액', String(Number(paymentInfo.payPrice) + Number(paymentInfo.discountPrice1) + Number(paymentInfo.discountPrice2)))}
                {renderContentRow('GS25 POP 할인', paymentInfo.discountPrice2)}
                {renderContentRow('U+멤버십 할인', paymentInfo.discountPrice1)}
            </div>


            <div style={{padding: "0 20px"}}>
                <CommonDividerThin/>
            </div>

            <Box
                type="4_trbl"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        padding: "4px 0"
                    }}
                >
                    <Text
                        style={{flex: "1"}}
                        typo="font_body_1M"
                        color="color_text_neutral_4">
                        최종 결제 금액
                    </Text>
                    <Text typo="font_heading_5Sb" color="color_text_primary">
                        {formatCurrency(paymentInfo.payPrice)}원
                    </Text>
                </div>
            </Box>
        </>
    )
}

export default SectionInfo
