import CommonPopup from "./CommonPopup";
import React from "react";

interface CommonErrorPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const CommonErrorPopup: React.FC<CommonErrorPopupProps> = ({isOpen, onClose}) => {
    return (
        <>
            <CommonPopup onSubmit={onClose} submitText='확인' description='일시적인 서버 오류에요'
                         isOpen={isOpen}/>
        </>

    )
}

export default CommonErrorPopup;