import {Box, Button, Checkbox, CTA, Image, Popup, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
// import ExImg from "../../images/default/default_Upltwopl_full.svg";
import ExxImg from "../../images/default/default_uplus_thumb.svg";

import React, {useEffect, useState} from 'react';
import {sendEventReturn} from "../../api/nativeBridge";


interface CommonPopupWithImageProps {
    onSubmit: () => void;
    onClose?: () => void;
    submitText: string;
    cancelText?: string;
    showCheckbox?: boolean;
    imageUrl: string;
    title?: string;
    description?: string;
    onImageClick?: () => void;
    onCheckbox?: () => void;
    isOpen?: boolean;
    adLogo?: string;
    logoLanding?: string;
    gtmType?: string; // ga 태깅을 위한 식별 타입
    bottomTitle?: string;
}

const CommonPopupWithImage: React.FC<CommonPopupWithImageProps> = ({
                                                                       onClose,
                                                                       onSubmit,
                                                                       submitText,
                                                                       cancelText,
                                                                       showCheckbox = false,
                                                                       imageUrl,
                                                                       title,
                                                                       description,
                                                                       onImageClick,
                                                                       onCheckbox,
                                                                       isOpen,
                                                                       adLogo,
                                                                       gtmType,
                                                                       logoLanding,
                                                                       bottomTitle
                                                                   }) => {
    const [isChecked, setIsChecked] = useState(false)
    const hasText = title || description;
    const [isFold, setIsFold] = useState(window.innerWidth > 650);

    const handleResize = () => {
        setIsFold(window.innerWidth > 650);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmitButton = () => {
        if (isChecked) {
            if (onCheckbox) {
                onCheckbox();
            }
        }
        if (onSubmit) {
            onSubmit();
        }
    };

    const handleCheckBox = () => {
        setIsChecked((prevState) => !prevState);
    };

    const handleLogoClick = () => {
        sendEventReturn('EXTERNAL_CALL', {type: '1', url: logoLanding});
    }

    return (
        <Popup isOpen={isOpen === undefined ? true : isOpen} onClose={onClose ?? undefined} padding={false}
               width={isFold ? "calc(50%)" : undefined} style={{zIndex: '1001'}}>
            <Popup.CenterSlot>
                <Box
                    style={{
                        padding: hasText ? '20px 0 0 0' : '0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {hasText ? (
                        <>
                            <TextSet
                                subComponent="Heading_3"
                                text={{
                                    title: title,
                                }}
                                weight="bolder"
                            />
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <>
                                            <span style={{paddingTop: '12px', textAlign: 'center'}}>
                                                {description?.split('\\n').map((line, index) => (
                                                    <>
                                                        {line}
                                                        <br/>
                                                    </>
                                                ))}
                                            </span>
                                        </>
                                    ),
                                }}
                                weight="default"
                            />
                            <div style={{marginTop: '20px', position: 'relative'}}>
                                <Image
                                    height="auto"
                                    srcFallback={ExxImg}
                                    src={imageUrl}
                                    width="100%"
                                    onClick={onImageClick}
                                />
                                {adLogo && (
                                    <img
                                        src={adLogo}
                                        alt="Logo"
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            width: '20px',
                                            height: '20px',
                                        }}
                                        onClick={handleLogoClick}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain', // Ensure the image maintains aspect ratio and is fully visible
                                minWidth: '320px',
                                minHeight: '250px',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            {gtmType === 'ad' ? (
                                <Image
                                    src={imageUrl}
                                    srcFallback={ExxImg}
                                    alt="popup image"
                                    width="100%"
                                    height="auto"
                                    onClick={onImageClick}
                                    data-gtm-event-name="all_click"
                                    data-gtm-event-category="클릭"
                                    data-gtm-event-action="마케팅 팝업 - 링크 클릭"
                                    data-gtm-event-label="컨텐츠 : 이미지"
                                    data-gtm-click-url=""
                                    data-gtm-click-location="마케팅 팝업"
                                    data-gtm-click-direction="외부"
                                    data-gtm-click-text="이미지"
                                    data-gtm-mkt-event-id=""
                                    data-gtm-mkt-event-name=""
                                    data-gtm-mkt_event_period=""
                                />
                            ) : (
                                <Image
                                    src={imageUrl}
                                    srcFallback={ExxImg}
                                    alt="popup image"
                                    width="100%"
                                    ratio="30:25"
                                    onClick={onImageClick}
                                />
                            )}
                            {adLogo && (
                                <img
                                    src={adLogo}
                                    alt="Logo"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '30px',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    onClick={handleLogoClick}
                                />
                            )}
                        </div>
                    )}
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                {bottomTitle &&
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px 0 0 0"
                    }}>
                        <Text typo="font_label_1Sb" color="color_text_neutral_6">
                            U+멤버십을 정말 종료하시겠어요?
                        </Text>
                    </Box>}
                {showCheckbox && (
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 0 0 0'
                    }}>
                        <Checkbox
                            label="다시보지 않기"
                            size="medium"
                            width="auto"
                            onChange={handleCheckBox}
                            data-gtm-event-name={gtmType === 'ad' ? 'all_click' : undefined}
                            data-gtm-event-category={gtmType === 'ad' ? '클릭' : undefined}
                            data-gtm-event-action={gtmType === 'ad' ? '마케팅 팝업 - 링크 클릭' : undefined}
                            data-gtm-event-label={gtmType === 'ad' ? `컨텐츠 : 다시보지 않기` : undefined}
                            data-gtm-click-url={gtmType === 'ad' ? '' : undefined}
                            data-gtm-click-location={gtmType === 'ad' ? '마케팅 팝업' : undefined}
                            data-gtm-click-direction={gtmType === 'ad' ? '내부' : undefined}
                            data-gtm-click-text={gtmType === 'ad' ? `다시보지 않기` : undefined}
                            data-gtm-mkt-event-id={gtmType === 'ad' ? '' : undefined}
                            data-gtm-mkt-event-name={gtmType === 'ad' ? '' : undefined}
                            data-gtm-mkt_event_period={gtmType === 'ad' ? '' : undefined}
                        />
                    </Box>
                )}
                <CTA gradation layout="row">
                    {cancelText && (
                        <Button color="neutral" size="large" onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                    <Button color="primary" size="large" onClick={handleSubmitButton}>
                        {submitText}
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default CommonPopupWithImage;
