import React from "react";
import {Image, Text} from "@lguuxe/2024_designsystem_uni";

import moneyme_s from "../../images/main/benefit/moneyme_s.svg";
import arrow from "../../images/etc/chevron_right_small_24_line.svg";

interface BannerInfoProps {
    onClick: () => void;
}

const BannerInfo: React.FC<BannerInfoProps> = ({onClick}) => {
    return (
        <div style={{padding: "0"}}>
            <div
                onClick={onClick}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px 16px",
                    border: "1px solid #FBDAED",
                    borderRadius: "12px",
                    gap: "8px",
                    alignItems: "center",
                    background: "#FDEDF6",
                    cursor: "pointer", // 클릭 가능하게 표시
                }}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={'머니Me 블록 - 링크 클릭'}
                data-gtm-event-label={'컨텐츠 : 머니Me 이동하기'}
                data-gtm-click-url={''}
                data-gtm-click-location={'머니Me 블록'}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={'머니Me 이동하기'}
            >
                <Image src={moneyme_s} alt="placeholder" width="24" ratio="1:1"/>
                <Text
                    typo="font_body_1Sb"
                    color="color_text_neutral_5"
                    style={{
                        display: "inline",
                        width: "100%"
                    }}
                >
                    멤버십 할인 놓쳤다면? <span style={{display: "inline-block", color: "#E6007E"}}>머니Me</span>
                </Text>
                <Image src={arrow} alt="placeholder" width="24" ratio="1:1"/>
            </div>
        </div>
    );
};

export default BannerInfo;
