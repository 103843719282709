import React from "react";
import { Box, TextSet, Image, Text, IconButton, List, Button } from "@lguuxe/2024_designsystem_uni";
import { useNavigate } from 'react-router-dom';
import image_default_nemo2 from "../../images/img/empty/empty_login.svg";

const HeadingCard: React.FC = () => {
    const navigate = useNavigate();
    const handleSearchClick = () => {
        navigate('/identity-verification');
    };
    return (
        <div style={{ padding: "0" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "24px 50px",
                    border: "1px solid #E7EBEE",
                    borderRadius: "16px",
                    gap: "20px",
                    alignItems: "center",
                    background: "#fff"
                }}
            >
                <Image src={image_default_nemo2} alt="placeholder" width="120" ratio="1:1" />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1"
                    }}
                >
                    <Text
                        typo="font_body_1M"
                        color="color_text_neutral_3"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "2px",
                            alignSelf: "stretch",
                            width: "100%"
                        }}
                    >
                        본인인증 하면 취향에 맞는 <br/>혜택을 추천해드려요
                    </Text>
                </div>
                <Button color="neutral" size="medium" onClick={handleSearchClick}>
                    본인인증 하기
                </Button>
            </div>
        </div>
    );
};

export default HeadingCard;
