import React from 'react';
import {Image, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import defaultThumbnail from "../../images/default/default thumbnail.svg";

interface StoreItemProps {
    itemList: StoreItem[];
    threshold?: number;
    onCheckLogin: () => boolean;
}

interface StoreItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
}

const StoreItemMore: React.FC<StoreItemProps> = ({itemList, threshold, onCheckLogin}) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const navigate = useNavigate();

    const handleClickStore = (store: StoreItem) => () => {
        if (onCheckLogin()) {
            navigate(`/partners/${store.itemId}`);
        }
    };

    return (
        <div style={{padding: "10px 15px", overflowY: 'auto'}}>
            <div
                style={{
                    display: 'grid',
                    gap: '16px 6px',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(104px, 1fr))',
                }}
            >
                {itemsToRender.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '0 6px',
                            minWidth: '104px',
                        }}
                        onClick={handleClickStore(item)}
                    >
                        <Image
                            src={item.itemImgUrl}
                            alt='파트너 로고'
                            width='56'
                            ratio='1:1'
                            rounded={54.86}
                            srcFallback={defaultThumbnail}
                        />
                        <div
                            style={{
                                gap: '2px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '104px',
                            }}
                        >
                            <div style={{padding: '16px 8px 12px 8px'}}>
                                <Text
                                    typo='font_body_1B'
                                    color='color_text_neutral_6'
                                    style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {item.itemName}
                                </Text>
                            </div>
                            <div style={{padding: '0 8px 16px 8px'}}>
                                <Text
                                    typo='font_body_3M'
                                    color='#888888'
                                    style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {item.itemSummary}
                                </Text>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StoreItemMore;