import {Button, Icon, Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";

interface CouponButtonProps {
    couponState: '미발급' | '발급' | '사용완료'
    onClick: () => void;
}

const CouponButton: React.FC<CouponButtonProps> = ({couponState, onClick}) => {

    const renderInfoBox = () => {
        return (
            <div
                style={{
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    backgroundColor: "#F9FAFB",
                    borderRadius: "12px",
                    display: "flex",
                    gap: "6px",
                    padding: "16px 20px"
                }}
            >

                <Icon color="#7f8a94" name="error_circle_filled" size="24px"></Icon>
                {couponState === '발급' && <Text typo="font_body_2M">{'받은 쿠폰은 마이 > 내 쿠폰에서'}<br/>확인 할 수 있어요.</Text>}

            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    justifyContent: "center",
                    width: "100%",
                    paddingBottom: "32px"
                }}
            >
                {couponState === '발급' && renderInfoBox()}

                <Button
                    color="secondary"
                    fill="solid"
                    size="medium"
                    style={{
                        display: "flex",
                        width: "100%"
                    }}
                    onClick={onClick}
                    disabled={couponState === '발급'}
                >
                    {couponState === '미발급' && '쿠폰 받기'}
                    {couponState === '발급' && '다운 완료'}
                </Button>
            </div>

        </>
    );
}

export default CouponButton;