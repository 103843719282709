import React from "react";

export const decodeHtmlEntities = (str: string) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    const content = doc.documentElement.textContent || "";

    return content.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/> {/* 개행 처리를 위한 <br> 태그 */}
        </React.Fragment>
    ));
};

export const decodeDangerouslySetInnerHTML = (str: string) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    const content = doc.documentElement.innerHTML || "";

    return (
        <div dangerouslySetInnerHTML={{
            __html: content, // 개행 처리
        }}/>
    );
};