// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// ****************************************************
import React from 'react';
import {Box, Button, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";
import {MonthUseHistory, PointUse} from "../../pages/My/types";
import {formatCurrency} from "../../utils/currencyUtils";

interface UseHistoryProps {
    history: MonthUseHistory;
}

const UseHistory: React.FC<UseHistoryProps> = ({history}) => {
    const navigate = useNavigate();

    const handleMore = () => {
        navigate('/my/benefit');
    };

    const renderHistoryItem = (item: PointUse) => (
        <Box type='3_trbl' key={item.useSiteNm}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: '4px 0',
                }}
            >
                <Text style={{flex: '1'}} typo='font_heading_5Sb' color='color_text_neutral_6'>
                    {item.useSiteNm}
                </Text>
                <div style={{width: '12px'}}></div>
                <div style={{width: '90px', textAlign: 'right'}}>  {/* 감싸는 div에 textAlign 적용 */}
                    <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                        {formatCurrency(item.usePt?.toString()) + '원'}
                    </Text>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <Text style={{flex: '1'}} typo='font_body_2M' color='color_text_neutral_4'>
                    {item.useDt?.split(' ')[0].replace(/-/g, '.')}
                </Text>
                <Text typo='font_body_2M'
                      color={Number(item.usePt || '') >= 0 ? 'color_text_neutral_4' : 'color_status_error'}>
                    {Number(item.usePt || '') >= 0 ? '사용' : '취소'}
                </Text>
            </div>
        </Box>
    );

    const renderNoItem = () => (
        <div
            style={{
                textAlign: 'center',
                padding: '20px',
            }}
        >
            <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                사용 내역이 없습니다.
            </Text>
        </div>
    );

    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>내가 받은 혜택</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            {history.pointUseList.length > 0 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {history.pointUseList.slice(0, 2).map((item) => renderHistoryItem(item))}
                </div>
            ) : (
                renderNoItem()
            )}

            <div
                style={{
                    textAlign: 'center',
                    borderTop: '1px solid #E7EBEE',
                    marginTop: '12px',
                }}
            >
                <Button
                    color='neutral'
                    rightIcon='chevron_right_small_line'
                    size='large'
                    fill='text'
                    style={{
                        padding: '14px 0',
                        width: '100%',
                    }}
                    onClick={handleMore}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.USE_HISTORY} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.USE_HISTORY_MORE}`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`${CLICK_LOCATION.USE_HISTORY}`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`${CLICK_TEXT.USE_HISTORY_MORE}`}
                >
                    <Text typo='font_label_2Sb' color='color_text_neutral_3'>
                        더보기
                    </Text>
                </Button>
            </div>
        </>
    );
};
export default UseHistory;
