import React, {useEffect, useState} from 'react';
import {Box, Image, List, Tag, TextSet} from '@lguuxe/2024_designsystem_uni';
import vvip from '../../images/icon/1_grade/membership_24=vvip.svg';
import excellent from '../../images/icon/1_grade/membership_24=excellent.svg';
import general from '../../images/icon/1_grade/membership_24=general.svg';
import vip from '../../images/icon/1_grade/membership_24=vip.svg';
import defaultImage from '../../images/default/default thumbnail.svg';
import {BenefitSummery} from '../../pages/My/types';
import {useNavigate} from 'react-router-dom';
import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";
import {getItemByKey} from "../../utils/indexedDB";


interface MembershipGradeProps {
    summary: BenefitSummery;
}

const MembershipGrade: React.FC<MembershipGradeProps> = ({summary}) => {
    const [name, setName] = useState<string>('');
    const [cardLevel, setCardLevel] = useState<string>('0');
    const [youthYn, setYouthYn] = useState<string>('');
    const [longTermName, setLongTermName] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIndexedDB = async () => {
            setName(await getItemByKey('customerName'));
            setCardLevel(await getItemByKey('cardLevel'));
            setYouthYn(await getItemByKey('youthYn'));
            setLongTermName(await getItemByKey('longTermName'));
        }
        fetchIndexedDB();
    }, []);

    const tags: any[] = [];
    if (longTermName) {
        tags.push({
            colorToken: 'blue_low',
            label: longTermName,
            size: 'xSmall',
            style: 'solid',
        });
    }

    if (youthYn === 'Y') {
        tags.push({
            colorToken: 'green_low',
            label: '유쓰',
            size: 'xSmall',
            style: 'solid',
        });
    }
    const gradeImage = () => {
        switch (cardLevel) {
            case '0':
                return '';
            case '6':
                return vvip;
            case '8':
                return vip;
            case '7':
                return excellent;
            default:
                return general;
        }
    };

    const getCardLeverName = () => {
        switch (cardLevel) {
            case '0':
                return '';
            case '6':
                return 'VVIP';
            case '8':
                return 'VIP';
            case '7':
                return '우수';
            default:
                return '일반';
        }
    }

    const handleMore = () => {
        navigate('/my/grade');
    }

    return (
        <>
            <Box type='2_trbl'>
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: (
                            <>
                                <span style={{color: '#181A1B'}}>{name}님 등급</span>
                            </>
                        ),
                    }}
                    weight='bolder'
                />
            </Box>
            <div style={{paddingBottom: '12px'}}>
                <List
                    fill='none'
                    items={[
                        {
                            onClick: handleMore,
                            leftSlot: [
                                <Image alt='Cat 1' ratio='1:1' src={gradeImage()} width='48px'
                                       srcFallback={defaultImage}/>,
                                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                    <TextSet
                                        subComponent='Body_1'
                                        text={{
                                            title: (
                                                <>
                                                    <span style={{color: '#E6007E'}}>{getCardLeverName()}등급</span>
                                                </>
                                            ),
                                        }}
                                        weight='bolder'
                                    />
                                    {tags.length > 0 && <Tag item={tags}/>}
                                </div>,
                            ],
                            linkIconOnlyId: 'placeHolder',
                        },
                    ]}
                    type='view'
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.PROFILE} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.MY_INFO_MORE}`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`${CLICK_LOCATION.PROFILE}`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`${CLICK_TEXT.MY_INFO_MORE}`}
                />
            </div>
        </>
    );
};

export default MembershipGrade;
