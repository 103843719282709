import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

const paymentApi = {
    getServiceUseTerm: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.SERVICE_USE_TERM, {}, version),
    postServiceUseTerm: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.PAYMENT.SERVICE_USE_TERM, body, version),
    getStatus: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.STATUS, {}, version),
    getMembershipDiscount: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.MEMBERSHIP_DISCOUNT, params, version),
    putMembershipDiscount: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.PAYMENT.MEMBERSHIP_DISCOUNT, body, version),
    getCardBarcode: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.CARD_BARCODE, params, version),
    getSetting: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.SETTING, {}, version),
    putSetting: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.PAYMENT.SETTING, body, version),
    getLimit: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.LIMIT, params, version),
    putLimit: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.PAYMENT.LIMIT, body, version),
    getUseHistory: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.USE_HISTORY, params, version),
    postPassword: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.PAYMENT.PASSWORD, body, version),
    getPayRequest: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.PAY_REQUEST, params, version),
    postPayConfirm: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.PAYMENT.PAY_CONFIRM, body, version),
    postPayOtp: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.PAYMENT.PAY_OTP, body, version),
    getPaySeq: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PAYMENT.PAY_SEQ, {}, version),
    getPayResult: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.NOTI.PAY_RESULT, {}, version),
    setLockDisable: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.PAYMENT.LOCK_DISABLE, body, version),
};

export default paymentApi;
