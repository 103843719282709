// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내_일반등급) (UMembershipReceivedBenefit2)
// ****************************************************
import React from 'react';
import {Box, Button, CTA, Image, Text} from '@lguuxe/2024_designsystem_uni';
import vipImage from '../../images/icon/1_grade/membership_24=vip.svg';
import vvipImage from '../../images/icon/1_grade/membership_24=vvip.svg';
import excellentImage from '../../images/icon/1_grade/membership_24=excellent.svg';
import generalImage from '../../images/icon/1_grade/membership_24=general.svg';
import {useNavigate} from 'react-router-dom';
import {getGradeName, GradeInfo} from "../../pages/My/types";
import CurrentYearGrade from "./CurrentYearGrade";


interface UserGradeInfoProps {
    grade: GradeInfo;
}

const UserGradeInfo: React.FC<UserGradeInfoProps> = ({grade}) => {
    const navigate = useNavigate();

    const getGradeIcon = (cardLevel: string) => {
        switch (cardLevel) {
            case '6':
                return vvipImage;
            case '7':
                return excellentImage;
            case '8':
                return vipImage;
            default:
                return generalImage;
        }
    };

    const handleButtonClick = () => {
        navigate('/benefit-guide');
    };

    return (
        <Box
            style={{
                padding: '24px 0 4px',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 20px 32px',
                }}
            >
                <Box style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                    <Text typo='font_heading_3B'>{grade.name}님은 올해</Text>
                    <Text typo='font_heading_3B'>
                        <Text typo='font_heading_3B' color='color_text_primary'>
                            {getGradeName(grade.cardLevel)}
                        </Text>
                        &nbsp; 혜택을 받을 수 있어요
                    </Text>
                </Box>
                <Image customClass='customClass' ratio='1:1' src={getGradeIcon(grade.cardLevel)} width='72'/>
            </Box>

            <CurrentYearGrade grade={grade}/>
            {/*<div*/}
            {/*    style={{*/}
            {/*        padding: '0 20px 8px',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <CommonDividerThin/>*/}
            {/*</div>*/}

            <CTA layout='column'>
                <Button color='neutral' size='medium' onClick={handleButtonClick}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'마이|내 등급 - 링크 클릭'}
                        data-gtm-event-label={`컨텐츠 : 등급 및 혜택 안내`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'마이|내 등급'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`등급 및 혜택 안내`}
                >
                    등급 및 혜택 안내
                </Button>
            </CTA>
        </Box>
    );
};

export default UserGradeInfo;
