// ****************************************************
// [배포] 1.혜택 - 검색 - 진입 (UMembershipSearchEntry)
// [배포] 1.혜택 - 검색 - 진입(최근 검색어 이력없을 때) (UMembershipSearchEntry2)
// ****************************************************
import React, {useState} from 'react'
import {Image, List, TextSet} from "@lguuxe/2024_designsystem_uni";
import chatbotIcon from "../../images/icon/5_full menu/chatbot_s.svg"
import {getItemByKey} from "../../utils/indexedDB";
import {chatbot} from "../../api/externalApi";
import CommonLoginPopup from "../Common/CommonLoginPopup";
import useDebounce from "../../hooks/useDebounce";


const ChatbotItem = () => {
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const handleClickChatbot = useDebounce(async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            setShowLoginPopup(true);
            return;
        }
        chatbot();
    });
    return (
        <>
            <List
                items={[
                    {
                        onClick: () => handleClickChatbot(),
                        leftSlot: [
                            <>
                                <Image
                                    customClass="customClass"
                                    ratio="1:1"
                                    src={chatbotIcon}
                                    width="48"
                                />
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: '챗봇'
                                    }}
                                    weight="default"
                                />
                            </>
                        ],
                        linkIconOnlyId: 'empty',
                    }
                ]}
                type="view"
                fill="none"
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={'검색|검색 실행 - 링크 클릭'}
                data-gtm-event-label={`컨텐츠 : 챗봇`}
                data-gtm-click-url={''}
                data-gtm-click-location={'검색|검색 실행'}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`챗봇`}
            />
            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
        </>
    )
}
export default ChatbotItem