import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
    const {pathname} = useLocation(); // 현재 경로 확인

    useEffect(() => {
        window.scrollTo(0, 0); // 페이지 이동 시 스크롤을 최상단으로 이동
    }, [pathname]);

    return null; // 렌더링할 내용이 없기 때문에 null을 반환
};

export default ScrollToTop;