import React from 'react';

interface DotIndicatorProps {
    total: number;
    currentIndex: number;
}

const DotIndicator: React.FC<DotIndicatorProps> = ({total, currentIndex}) => {
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                padding: '20px 0',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    padding: '4px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                }}
            >
                {Array.from({length: total}).map((_, index) => (
                    <span
                        key={index}
                        style={{
                            backgroundColor: '#66707A',
                            width: index === currentIndex ? '18px' : '6px',
                            height: '6px',
                            borderRadius: index === currentIndex ? '24px' : '50%',
                            opacity: index === currentIndex ? '1' : '0.4',
                            cursor: 'pointer',
                            transition: 'width 0.3s ease', // 강조 전환 애니메이션
                        }}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default DotIndicator;