import {Button, Checkbox, Icon, Text} from "@lguuxe/2024_designsystem_uni";
import React, {useEffect, useState} from "react";
import {SortItem} from "../../pages/Partner/types";
import BS_List from "../Common/BS_List";
import CommonDividerVertical from "../Common/CommonDividerVertical";

interface SortFilterProps {
    totalCount: string;
    sortList: SortItem[];
    onSortChange: (sortTypeId: string, isUsable: boolean) => void;
    isUsableCheckBox?: boolean;
    isNumber?: boolean;
}

const SortFilter: React.FC<SortFilterProps> = ({
                                                   totalCount,
                                                   sortList,
                                                   onSortChange,
                                                   isUsableCheckBox = true,
                                                   isNumber = true
                                               }) => {
    const [selectedSort, setSelectedSort] = useState<SortItem>({id: '0', label: ''}); // '인기순'으로 초기화
    const [isUsable, setIsUsable] = useState<boolean>(false);
    const [showSortBS, setShowSortBS] = useState(false); // BS 리스트의 표시 여부

    useEffect(() => {
        console.log('SortFilter useEffect:', sortList);
        if (sortList.length > 0) setSelectedSort(sortList[0]);
    }, []);

    const handleSortChange = (selectedId: string) => {
        const selectedSortOption = sortList.find(item => item.id === selectedId);
        console.log('SortFilter handleSortChange:', selectedSortOption);
        if (selectedSortOption) {
            setSelectedSort(selectedSortOption); // 선택한 정렬 옵션으로 상태 업데이트
            onSortChange(selectedSortOption.id, isUsable);
        }
        setShowSortBS(false); // BS 리스트를 닫음
    };

    const handleUsableChange = () => {
        console.log('SortFilter handleUsableChange:', isUsable);
        setIsUsable(!isUsable);
        onSortChange(selectedSort.id, !isUsable);
    }
    return (
        <>
            <div style={{
                paddingTop: '12px',
                paddingLeft: '20px',
                paddingRight: '20px',
                display: 'flex',
                // justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Text typo='font_heading_5B' color='color_text_neutral_6'>
                    {isNumber ? `총 ${totalCount}개` : ''}
                </Text>

                <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center',}}>
                    {isUsableCheckBox && (
                        <>
                            <div style={{paddingTop: '5px',}}>
                                <Checkbox
                                    falseValue='falseValue'
                                    trueValue='trueValue'
                                    label='내가 쓸 수 있는'
                                    onChange={handleUsableChange}
                                    size='medium'
                                    value='value'
                                    weight='default'
                                    width='auto'
                                />
                            </div>
                            <CommonDividerVertical margin={'12'}/>
                        </>
                    )}


                    <div style={{width: 'auto'}}>
                        <Button fill="ghost" size="small" color="secondary" onClick={() => setShowSortBS(!showSortBS)}
                                style={{paddingLeft: '0'}}>
                            <Text as="span" textAlign="center">
                                {selectedSort.label}
                            </Text>
                            <Icon
                                name='chevron_down_small_line'
                                color='#7f8a94'
                            />
                        </Button>
                    </div>
                </div>
            </div>
            {/*{showSortBS && (*/}
            {/*    <div style={{*/}
            {/*        position: 'fixed',*/}
            {/*        top: 0,*/}
            {/*        left: 0,*/}
            {/*        width: '100%',*/}
            {/*        height: '100%',*/}
            {/*        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 검은색 배경, 투명도 조절 가능*/}
            {/*        zIndex: 999, // BottomSheet보다 낮은 zIndex를 설정*/}
            {/*    }}*/}
            {/*         onClick={() => setShowSortBS(false)} // 배경을 클릭하면 BottomSheet 닫기*/}
            {/*    />*/}
            {/*)}*/}
            <BS_List
                title={'정렬'}
                dataList={sortList}
                onClose={() => setShowSortBS(false)}
                onSelected={handleSortChange}
                checkedItem={selectedSort.id}
                isOpen={showSortBS}
            />
        </>
    );
}

export default SortFilter;