import React from "react";
import {Text} from "@lguuxe/2024_designsystem_uni";

interface CommonTextBlockProps {
    textList: string[];
}

const TextBlock: React.FC<CommonTextBlockProps> = ({textList}) => {
    return (
        <div style={{paddingRight: "0.75rem"}}>
            <ul>
                {textList.map((text, index) => (
                    <li key={index} style={{color: "#66707A"}}>
                        <Text typo="font_body_2M" color="color_text_neutral_3">
                            {text}
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TextBlock;