import {getItemByKey} from "../utils/indexedDB";
import {getGradeName} from "../pages/My/types";

const trackingEvent = async (jsonObject: any) => {
    const stringifiedObject = JSON.stringify(jsonObject);
    const os = await getItemByKey('platform')
    if (os === "IOS") {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BrazeBridgeTrackingEvent) {
            window.webkit.messageHandlers.BrazeBridgeTrackingEvent.postMessage(stringifiedObject);
        }
    } else {
        if (window.BrazeBridge && window.BrazeBridge.trackingEvent) {
            window.BrazeBridge.trackingEvent(stringifiedObject);
        }
    }
};

export const trackingAttr = async (jsonObject: any) => {
    const stringifiedObject = JSON.stringify(jsonObject);
    const os = await getItemByKey('platform')
    if (os === "IOS") {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BrazeBridgeTrackingAttr) {
            window.webkit.messageHandlers.BrazeBridgeTrackingAttr.postMessage(stringifiedObject);
        }
    } else {
        if (window.BrazeBridge && window.BrazeBridge.trackingAttr) {
            window.BrazeBridge.trackingAttr(stringifiedObject);
        }
    }
};

export const brazeAttributeApi = {
    firstCollection: async () => {
        const moneyMe = await getItemByKey('moneyMeUse');
        const telcoCode = await getItemByKey('telcoCode');
        trackingAttr({own_third_type: telcoCode === 'L'});
        trackingAttr({member_type: getGradeName(await getItemByKey('cardLevel'))});
        trackingAttr({mydata_join: moneyMe === 'Y'});
        const vipkok = await getItemByKey('vipkok')
        trackingAttr({
            user_mykok_type: !vipkok ? null : {
                mykok_use_month: vipkok.isUsed ? vipkok.isUsed : 'N',
                mykok_name: vipkok.ptnName ?? null,
                mykok_cd: vipkok.ptnId ?? null
            }
        });
        trackingAttr({user_id: await getItemByKey('memberId')});
        trackingAttr({gender: await getItemByKey('gender')});
        const birthday = await getItemByKey('birthday');
        trackingAttr({birthdate: birthday.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')});
        trackingAttr({marketing_agreement_status: await getItemByKey('maketingInfoYn') === 'Y'});
        trackingAttr({ad_agreement_app: await getItemByKey('adAgreeYn') === 'Y'});
        const longTermName = await getItemByKey('longTermName');
        const memberLoyalty = longTermName === 'NG1' ? '2년 이상' : 'NG2' ? '5년 이상' : 'NG3' ? '10년 이상' : '';
        trackingAttr({member_loyalty: memberLoyalty});
        trackingAttr({member_uth: await getItemByKey('youthYn') === 'Y'});
    }
}

const timeFormat = (date: Date): string => {
    const currentTime = date.toISOString();
    const offset = new Date().getTimezoneOffset() * -1; // Timezone offset in minutes (KST is UTC+9)
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;

    const formattedOffset = `${offsetHours >= 0 ? "+" : "-"}${String(Math.abs(offsetHours)).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

    return `${currentTime.split(".")[0]}${formattedOffset}`;
}

const getBrazeInfo = async () => {
    const memberId = await getItemByKey('memberId');
    const telcoCode = await getItemByKey('telcoCode');
    const cardLevel = await getItemByKey('cardLevel');
    const vipkok = await getItemByKey('vipkok');
    const maketingInfoYn = await getItemByKey('maketingInfoYn');

    const now = new Date();
    return {
        custom_event_time: timeFormat(new Date()),
        login_status: memberId ? true : false,
        signup_date: timeFormat(now),
        own_third_type: telcoCode === 'L' ? '자사' : '타사',
        mykok_use_month: vipkok ? vipkok.isUsed : 'N',
        // mykok_name: vipkok ? vipkok.ptnName : null,
        // mykok_cd: vipkok ? vipkok.ptnId : null,
        member_type: getGradeName(cardLevel),
        ad_agreement_app: await getItemByKey('adAgreeYn') === 'Y',
        marketing_agreement_status: maketingInfoYn === 'Y',
    };
}

export const brazeEventApi = {
    tutorial_close: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'tutorial_close',
            custom_event_name: 'tutorial_close',
            event_occur_platform: 'app',
            own_third_type: '자사',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    mission_tab_enter: async (mileage: string) => {
        // 미션 탭 진입 시
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'mission_tab_enter',
            custom_event_name: 'mission_tab_enter',
            platform: 'app',
            availableMileage: mileage,
            click_location: '탭 영역',
            entry_type: '',
        };
        trackingEvent(params);
    },
    mission_tab_exit: async (mileage: string) => {
        // 미션탭 진입 > 타 페이지로 이동 시
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'mission_tab_enter',
            custom_event_name: 'mission_tab_enter',
            platform: 'app',
            availableMileage: mileage,
            click_location: '탭 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    logIn_complete: async () => {
        // 로그인 완료 시 (자동/수동)
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'logIn_complete',
            custom_event_name: 'logIn_complete',
            event_occur_platform: 'app',
            login_type: '간편로그인',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },

    attendance_check_complete: async (isSuccess: boolean, attendanceCnt: number) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'attendance_check_complete',
            custom_event_name: 'attendance_check_complete',
            attendance_success: isSuccess,
            attendace_cnt: attendanceCnt,
            click_location: '탭 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    my_tab_enter: async (useMoneyMe: boolean, moneyMePoint: number) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'my_tab_enter',
            custom_event_name: 'my_tab_enter',
            mydata_join: useMoneyMe,
            mydata_money: moneyMePoint,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    my_tab_exit: async (useMoneyMe: boolean, moneyMePoint: number) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'my_tab_exit',
            custom_event_name: 'my_tab_exit',
            mydata_join: useMoneyMe,
            mydata_money: moneyMePoint,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    benefitplus_page_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'benefitplus_page_enter',
            custom_event_name: 'benefitplus_page_enter',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    event_participation_complete: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'event_participation_complete',
            custom_event_name: 'event_participation_complete',
            mkt_event_name: '7월 출석체크 이벤트',
            mkt_event_id: 'E8982402',
            is_push_subscribed_event: false,
            is_attendance_event: true,
            user_id: await getItemByKey('memberId'),
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },

    homemain_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'homemain_enter',
            custom_event_name: 'homemain_enter',
            mobilePlanExpDate: '', // TODO: 모바일 약정 만료일 예시 이거 어캐 암?
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    event_list_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'event_list_enter',
            custom_event_name: 'event_list_enter',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    event_detail_page_enter: async (eventName: string, eventId: string) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'event_detail_page_enter',
            custom_event_name: 'event_detail_page_enter',
            mkt_event_name: eventName,
            mkt_event_id: eventId,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    affiliateInfo_detail_page_enter: async (partnerName: string, partnerId: string, patnerCategory: string) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'affiliateInfo_detail_page_enter',
            custom_event_name: 'affiliateInfo_detail_page_enter',
            affiliate_name: partnerName,
            affiliate_id: partnerId,
            affiliate_sector: patnerCategory,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    alltap_menu_click: async (tabName: string) => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'alltap_menu_click',
            custom_event_name: 'alltap_menu_click',
            tap_menu: tabName,
            click_location: '탭 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    home_barcode_floating_close: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'home_barcode_floating_close',
            custom_event_name: 'home_barcode_floating_close',
            click_location: '탭 영역', // 예시 값: '컨텐츠 영역', '탭 영역'
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
    vipKok_info_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            ...info,
            EventName: 'vipKok_info_enter',
            custom_event_name: 'vipKok_info_enter',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
        };
        trackingEvent(params);
    },
};