import React, {forwardRef, useImperativeHandle} from 'react'
import {Box, Button, CTA, Image, TextSet} from '@lguuxe/2024_designsystem_uni'
import updateImage from "../../images/img/popup/update.svg"
import defaultImage from "../../images/default/default_thumbnail_full.svg"
import {sendEventReturn, sendEventToAndroid, sendEventToIOS} from "../../api/nativeBridge";
import {removeItemByKey} from "../../utils/indexedDB";

export interface UpdateHandles {
    handleRemove: () => void;
}

const Update = forwardRef<UpdateHandles>((props, ref) => {

    useImperativeHandle(ref, () => ({
        handleRemove: async () => {
            console.log('handleRemove');
            await removeItemByKey('memberId');
            await removeItemByKey('memberStatus');
            await removeItemByKey('customerName');
            sendEventToAndroid("APP_FINISH", {});
            sendEventToIOS("APP_FINISH", {});
        }
    }))

    const handleButtonClick = () => {
        sendEventReturn('RESET_LOGIN_INFO', {});
    }

    return (
        <>
            <Box
                type="2_trbl"
                style={{
                    marginTop: "56px"
                }}
            >
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        title:
                            <span>
                                아래 버튼을 눌러
                                <br/>
                                새로운 멤버십 앱을 경험해 보세요
                            </span>,
                        description:
                            <span style={{paddingTop: "6px"}}>
                                더 나은 서비스를 위해 멤버십 앱을 업데이트 했어요.
                                <br/>
                                서비스 최적화를 위해 아래 버튼을 눌러 재실행해 주세요.
                            </span>
                    }}
                    weight="bolder"
                />
            </Box>
            <Box>
                <Image
                    customClass="customClass"
                    ratio="1.778:1"
                    src={updateImage}
                    srcFallback={defaultImage}
                />
            </Box>

            <Box style={{position: "fixed", bottom: "0", width: "100%", padding: "0",}}>
                <CTA gradation layout="column" style={{paddingBottom: '32px'}}>
                    <Button color="primary" size="large" onClick={handleButtonClick}>
                        앱 재실행하기
                    </Button>
                </CTA>
            </Box>
        </>
    )
});

export default Update
