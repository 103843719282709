// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내)
// ****************************************************
import React, {useEffect, useState} from 'react';
import {Button, CTA} from '@lguuxe/2024_designsystem_uni';

import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import UserGradeInfo from '../../components/My/UserGradeInfo';
import LevelUpTips from '../../components/My/LevelUpTips';
import {getItemByKey} from "../../utils/indexedDB";
import {sendEventReturn} from "../../api/nativeBridge";
import {GradeInfo, initGradeInfo} from "./types";
import CommonDivider from "../../components/Common/CommonDivider";

const MyGrade: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [gradeInfo, setGradeInfo] = useState<GradeInfo>(initGradeInfo);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            const GradeInfo = {
                name: await getItemByKey('customerName'),
                memberStatus: await getItemByKey('memberStatus'),
                cardLevel: await getItemByKey('cardLevel'),
                // cardLevel: '0',
                isVIP: await getItemByKey('isVIP'),
                longTermName: await getItemByKey('longTermName'),
                youthYn: await getItemByKey('youthYn'),
            };
            setGradeInfo(GradeInfo);
        }
        // const fetchData = async () => {
        //     try {
        //         const memberId = await getItemByKey('memberId');
        //         membershipsServiceApi.getRankInfo({memberId: memberId}, 'v1').then((res) => {
        //             if (res.header.resultCode === '0000') {
        //                 setGrade(res.body);
        //             }
        //         });
        //     } catch (error) {
        //         console.error('Failed to fetch :', error);
        //     } finally {
        //         setIsLoading(false);
        //     }
        // };

        fetchIndexedDB();
        // fetchData();
    }, []);
    const handleClick = () => {
        sendEventReturn('EXTERNAL_CALL', {
            type: 1,
            url: 'https://cv.uplus.kr/17.html',
        })
    };
    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div style={{paddingBottom: '114px'}}>

                <CommonLeftIconHeader title='내 U+멤버십'/>

                <UserGradeInfo grade={gradeInfo}/>


                {/*<NextYearGrade grade={gradeInfo}/>*/}

                <div style={{marginBottom: '8px'}}>
                    <CommonDivider/>
                </div>

                <LevelUpTips/>
            </div>

            {!['6', '7', '8'].includes(gradeInfo.cardLevel) ? null : (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        width: '100%',
                    }}
                >
                    <CTA layout='column'>
                        <Button color='secondary' onClick={handleClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'마이|내 등급 - 링크 클릭'}
                                data-gtm-event-label={`컨텐츠 : 모바일요금제 변경하러 가기`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'마이|내 등급'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`모바일요금제 변경하러 가기`}>
                            모바일요금제 변경하러 가기
                        </Button>
                    </CTA>
                </div>
            )}
        </>
    );
};

export default MyGrade;
