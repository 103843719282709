// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// ****************************************************
import React, {useEffect} from 'react';
import {List, Tag, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {GradeInfo} from '../../pages/My/types';
import {getGradeTag, getLongTermTag, getYouthTag} from '../../utils/tagUtils';
import {sendEventReturn} from "../../api/nativeBridge";
import {getItemByKey} from "../../utils/indexedDB";

interface CurrentYearGradeProps {
    grade: GradeInfo
}

const CurrentYearGrade: React.FC<CurrentYearGradeProps> = ({grade}) => {
    const [memberStatus, setMemberStatus] = React.useState<string>('');
    const currentYear = new Date().getFullYear();
    const tags: any[] = [];

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const memberStatus = await getItemByKey('memberStatus');
            setMemberStatus(memberStatus);
        }
        fetchIndexedDb();
    }, []);

    if (grade.cardLevel) {
        tags.push(getGradeTag(grade.cardLevel));
    }

    if (grade.longTermName) {
        tags.push(getLongTermTag(grade.longTermName));
    }

    if (grade.youthYn === 'Y') {
        tags.push(getYouthTag());
    }

    const handleBenefitClick = (event: any) => {
        if (event.target.id === 'id') {
            sendEventReturn('EXTERNAL_CALL', {
                type: 1,
                url: 'https://cv.uplus.kr/396.html',
            })
        }
    }

    return (
        <>
            <List
                fill='none'
                items={[
                    {
                        leftSlot: [
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TextSet
                                        subComponent='Body_1'
                                        text={{
                                            title: `${currentYear} 혜택`,
                                        }}
                                        weight='bolder'
                                    />
                                    <div style={{marginTop: '8px'}}>
                                        <Tag item={tags}/>
                                    </div>
                                </div>
                            </>,
                        ],
                        linkIconOnlyId: memberStatus === '5' ? '' : 'id',
                        onClick: handleBenefitClick,
                    },
                ]}
                type='view'
            />
            {!(['6', '7', '8'].includes(grade.cardLevel)) && (
                <div
                    style={{
                        //     alignItems: 'flex-start',
                        //     alignSelf: 'stretch',
                        //     backgroundColor: '#F3F5F6',
                        //     borderRadius: '12px',
                        //     display: 'flex',
                        //     gap: '6px',
                        padding: '8px 20px 20px 20px',
                        //     border: '1px solid #E7EBEE',
                        //     boxSizing: 'border-box',
                        //     margin: '0 20px',
                    }}
                >
                    {/*<Icon color='#7f8a94' name='error_circle_filled' size='24px'/>*/}
                    <Text typo='font_body_1M' color='color_text_neutral_4'>U+모바일 고객이 아닌 분은 일반 혜택을 이용할 수 있어요.</Text>
                </div>
            )}
        </>
    );
};

export default CurrentYearGrade;
