import React, {useEffect, useState} from "react";
import Lottie from "lottie-react";
import {Image} from "@lguuxe/2024_designsystem_uni";

import chevron_right_small_24_line from "../../images/main/benefit/chevron_right_line.svg";
import earlyMonthIcon from "../../images/lottie/json/Icon/Icon_New.json";
import midMonthIcon from "../../images/lottie/json/Icon/Icon_Bell.json";
import endMonthIcon from "../../images/lottie/json/Icon/Icon_Time.json";
import notificationIcon from "../../images/lottie/json/Icon/Icon_Notification.json";
import guestIcon from "../../images/lottie/json/Icon/Icon_Gift.json";
import MidMonth from "./UserMessageCard/MidMonth";
import EndMonth from "./UserMessageCard/EndMonth";
import EarlyMonth from "./UserMessageCard/EarlyMonth";
import NotLogin from "./UserMessageCard/NotLogin";
import Notification from "./UserMessageCard/Notification";

import {Msg} from "../../pages/Home/types";
import {useNavigate} from "react-router-dom";
import useLandingPage from "../../hooks/useLandingPage";

interface UserMessageProps {
    prsMsg: Msg[];
    type?: string;
    useVipYn: boolean;
    memberStatus: string;
}

const style = {
    width: 48
};

const initialMsg = {
    msgId: "",
    msgTypeCode: "",
    msgTitle: "",
    msgDetail: "",
    linkType: "",
    linkUrl: ""
};

const UserMessage: React.FC<UserMessageProps> = ({prsMsg, useVipYn, memberStatus}) => {
    const [currentPeriod, setCurrentPeriod] = useState<"early" | "mid" | "end">("early");
    const [messageType, setMessageType] = useState<string>("Notification");
    const [notificationMessage, setNotificationMessage] = useState<Msg>(initialMsg);
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    const now = new Date();

    useEffect(() => {
        const day = now.getDate();

        if (day >= 1 && day <= 5) {
            setCurrentPeriod("early");
        } else if (day >= 6 && day <= 19) {
            setCurrentPeriod("mid");
        } else {
            setCurrentPeriod("end");
        }

        if (notificationMessage == initialMsg && prsMsg.length > 0) {
            setNotificationMessage(prsMsg[Math.floor(Math.random() * prsMsg.length)]);
        }

        const fetchMemberStatus = async () => {
            if ((memberStatus === "1" || memberStatus === "3") && useVipYn) {
                setMessageType("Notification");
            } else if ((memberStatus === "1" || memberStatus === "3") && !useVipYn) {
                setMessageType("VIP");
            } else if (memberStatus === "2" || memberStatus === "4" || memberStatus === "5") {
                setMessageType("Notification");
            } else {
                setMessageType("NotLogin");
            }
        }
        fetchMemberStatus();

    }, [useVipYn, prsMsg, memberStatus]);

    const getAnimationData = () => {
        switch (messageType) {
            case "VIP":
                switch (currentPeriod) {
                    case "early":
                        return earlyMonthIcon;
                    case "mid":
                        return midMonthIcon;
                    case "end":
                        return endMonthIcon;
                    default:
                        return earlyMonthIcon;
                }
            case "NotLogin":
                return guestIcon;
            case "Notification":
                return notificationIcon;
            default:
                return notificationIcon;
        }
    };

    const onClick = () => {
        if (messageType === "NotLogin") {
            navigate("/identity-verification");
        } else if (messageType === "Notification") {
            if (prsMsg[0].linkUrl && prsMsg[0].linkType) {
                landingPage(notificationMessage.linkType, notificationMessage.linkUrl, navigate, notificationMessage.msgTitle)
            } else {
                console.log("편성 에러 linkType과 linkUrl이 Null입니다.", prsMsg[0])
            }
        } else {
            navigate("/vip-kok")
        }
    };

    const getText = () => {
        switch (messageType) {
            case "VIP":
                switch (currentPeriod) {
                    case "early":
                        return <EarlyMonth/>;
                    case "mid":
                        return <MidMonth/>;
                    case "end":
                        return <EndMonth/>;
                    default:
                        return <EarlyMonth/>;
                }
            case "NotLogin":
                return <NotLogin/>;
            case "Notification":
                return <Notification msgData={notificationMessage}/>;
            default:
                return <Notification msgData={null}/>;
        }
    };

    return (
        <div style={{padding: "0 20px"}} onClick={onClick}
             data-gtm-event-name={'all_click'}
             data-gtm-event-category={'클릭'}
             data-gtm-event-action={'프로필 블록 - 링크 클릭'}
             data-gtm-event-label={'컨텐츠 : ***님을 위한 맞춤 메시지'}
             data-gtm-click-url={''}
             data-gtm-click-location={'프로필 블록'}
             data-gtm-click-direction={'내부'}
             data-gtm-click-text={'***님을 위한 맞춤 메시지'}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "8px 24px 8px 16px",
                    border: "1px solid #E7EBEE",
                    borderRadius: "16px",
                    gap: "8px",
                    alignItems: "center",
                    background: "#fff"
                }}
            >
                <Lottie animationData={getAnimationData()} style={style}/>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1"
                    }}
                >
                    {getText()}
                </div>
                <Image src={chevron_right_small_24_line} alt="placeholder" width="12" ratio="1:2"/>
            </div>
        </div>
    );
};

export default UserMessage;
