import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

const commonApi = {
    join: (params: ApiParams, version: string, mcpTrKey: string): Promise<ApiResponse> => api.post(ApiEndpoints.JOIN, params, version, mcpTrKey),
    getSession: (params: ApiParams, version: string, mcpTrKey: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.SESSION, params, version, mcpTrKey),
    getServiceNoti: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.SERVICE_NOTI, params, version),
    getMarketingNoti: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.MARKETING_NOTI, params, version),
    getTutorialInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.TUTORIAL_INFO, params, version),
    getBlockInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.BLOCK_INFO, params, version),
    getSlashInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.SLASH_INFO, params, version),
    terms: {
        getAgreements: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.TERMS.AGREEMENTS, params, version),
        getAgreementsById: (termId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.TERMS.AGREEMENTS_BY_ID(termId), params, version),
        postAgreementsConfirm: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.COMMON.TERMS.AGREEMENTS_CONFIRM, body, version),
    },
    withdrawal: (params: ApiParams, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.USER.WITHDRAWAL, params, version),
    getToday: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COMMON.TODAY, {}, version),
};

export default commonApi;
