import {Box, Button, CTA, Image, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {BenefitThisMonth} from '../../pages/My/types';
import {useNavigate} from 'react-router-dom';
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";

interface VipkokBenefitProps {
    vipkok: BenefitThisMonth;
}

const VipkokBenefit: React.FC<VipkokBenefitProps> = ({vipkok}) => {
    const navigate = useNavigate();

    const handleVipkokClick = (e: any) => {
        navigate('/vip-kok');
    };

    return (
        <>
            <Box type='2_trbl'>
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: (
                            <>
                                <span style={{color: '#181A1B'}}>VIP콕</span>
                            </>
                        ),
                    }}
                    weight='bolder'
                />
            </Box>

            {vipkok.isVIP === 'Y' && vipkok.useVipkok.length > 0 && vipkok.useVipkok[0].ptnId && (
                <div style={{paddingBottom: '12px'}}>
                    <List
                        fill='none'
                        items={[
                            {
                                onClick: () => {
                                    navigate(`/vip-kok-detail/${vipkok.useVipkok[0].ptnId}?idType=vipkok`)
                                },
                                leftSlot: [
                                    <Image
                                        srcFallback={defaultThumbnail}
                                        ratio='1:1'
                                        rounded='50%'
                                        src={vipkok.useVipkok[0].ptnLogoImgUrl}
                                        width='48px'
                                    />,
                                    <TextSet
                                        subComponent='Body_1'
                                        text={{
                                            title: vipkok.useVipkok[0].ptnName,
                                            eyebrow: '', //TODO: 링크 내려오면 박아주기
                                        }}
                                        weight='bolder'
                                    />,
                                ],
                                linkIconOnlyId: 'placeHolder',
                            },
                        ]}
                        type='view'
                    />
                </div>
            )}

            {vipkok.isVIP === 'Y' && (vipkok.useVipkok.length === 0 || !vipkok.useVipkok[0].ptnId) && (
                <div style={{padding: '24px 0 4px'}}>
                    <CTA layout='column'>
                        <Button color='secondary' onClick={handleVipkokClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={`${CLICK_LOCATION.VIPKOK_BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                                data-gtm-event-label={`컨텐츠 : 이번 달 VIP콕 사용하기`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={`${CLICK_LOCATION.VIPKOK_BENEFIT}`}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`이번 달 VIP콕 사용하기`}
                        >
                            이번 달 VIP콕 사용하기
                        </Button>
                    </CTA>
                </div>
            )}

            {(vipkok.isVIP === 'N' || !vipkok.isVIP) && ( // 우수이거나 타사인경우
                <div style={{padding: '24px 0 4px'}}>
                    <CTA layout='column'>
                        <Button color='secondary' onClick={handleVipkokClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={`${CLICK_LOCATION.VIPKOK_BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                                data-gtm-event-label={`컨텐츠 : VIP콕 보기`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={`${CLICK_LOCATION.VIPKOK_BENEFIT}`}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`VIP콕 보기`}
                        >
                            VIP콕 보기
                        </Button>
                    </CTA>
                </div>
            )}
        </>
    );
};

export default VipkokBenefit;