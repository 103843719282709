import React, {useEffect} from 'react';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import EventItem from '../../components/ListItem/EventItem';
import NoticeItem from '../../components/ListItem/NoticeItem';
import QnAItem from '../../components/ListItem/QnAItem';
import UPTPItem from '../../components/ListItem/UPTPItem';
import {useLocation} from "react-router-dom";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import StoreItemMore from "../../components/ListItem/StoreItemMore";
import {gtmAffiliateCouponDownloadComp} from "../../api/gtm";
import {formatDate} from "../../utils/dateUtiles";

const ResultMore: React.FC = () => {
    const location = useLocation();
    const state = location.state || {title: "", items: []};
    const [itemList, setItemList] = React.useState<any[]>([]);
    const [memberStatus, setMemberStatus] = React.useState<string>('');
    const [showLoginPopup, setShowLoginPopup] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('');
    useEffect(() => {
        const fetchData = async () => {
            let stateData = location.state;
            if (!stateData) {
                // state가 없을 때 IndexedDB에서 searchDetail로 저장된 값 가져오기
                const storedDetail = await getItemByKey('searchDetail');
                if (storedDetail) {
                    stateData = storedDetail;
                } else {
                    console.error('검색 결과를 찾을 수 없습니다.');
                    return;
                }
            } else {
                // state가 있으면 IndexedDB에 저장
                await addItem('searchDetail', {title: stateData.title, items: stateData.items});
            }

            setTitle(stateData.title);
            setItemList(stateData.items);
        };

        const fetchMemberStatus = async () => {
            const status = await getItemByKey('memberStatus');
            setMemberStatus(status);
        };

        fetchData();
        fetchMemberStatus();
    }, [location.state]);

    const handleLoginCheck = (): boolean => {
        if (!memberStatus || memberStatus === '0') {
            setShowLoginPopup(true);
            return false;
        }
        return true;
    }

    const handleCouponIssue = (cpnId: string) => {
        const newItemList = itemList.map((item) => {
            if (item.itemId == cpnId) {
                console.log('item', item);
                gtmAffiliateCouponDownloadComp(item.itemName, '', item.ptnName, '', `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`);
                return {...item, isIssuedCoupon: 'Y'};
            }
            return item;
        });
        setItemList(newItemList);

    }

    const renderItems = (title: string, items: any[]) => {
        switch (title) {
            // case '쿠폰':
            //     return <CouponItem itemList={itemList} onCheckLogin={handleLoginCheck}
            //                        onCouponIssue={handleCouponIssue}/>;
            case '이벤트':
                return <EventItem itemList={items} onCheckLogin={handleLoginCheck}/>;
            case '공지사항':
                return <NoticeItem itemList={items}/>;
            case '자주하는 질문':
                return <QnAItem itemList={items}/>;
            case '제휴사':
                return <StoreItemMore itemList={items} onCheckLogin={handleLoginCheck}/>;
            case '유플투쁠':
                return <UPTPItem itemList={items} onCheckLogin={handleLoginCheck}/>;
            default:
                return null;
        }
    }

    return (
        <div style={{width: '100%'}}>
            <CommonLeftIconHeader title={`${title}(${itemList.length})`}/>
            {renderItems(title, itemList)}
        </div>
    );
};

export default ResultMore;
