import React from 'react';
import {Tab, TextSet} from "@lguuxe/2024_designsystem_uni";

interface CommonHeadingTabProps {
    selectedTab: string;
    setSelectedTab: (tabId: string) => void;
}

const CommonHeadingTab: React.FC<CommonHeadingTabProps> = ({selectedTab, setSelectedTab}) => {
    const tabItems = [
        {
            id: '1',
            label: '기본 혜택',
            dataAttributes: {
                'data-gtm-event-name': 'all_click',
                'data-gtm-event-category': '클릭',
                'data-gtm-event-action': `전체혜택|카테고리 - 링크 클릭`,
                'data-gtm-event-label': '컨텐츠 : 기본 혜택',
                'data-gtm-click-url': '',
                'data-gtm-click-location': `전체혜택|카테고리`,
                'data-gtm-click-direction': '내부',
                'data-gtm-click-text': '기본 혜택',
            }
        },
        {
            id: '2',
            label: 'VIP콕',
            dataAttributes: {
                'data-gtm-event-name': 'all_click',
                'data-gtm-event-category': '클릭',
                'data-gtm-event-action': `전체혜택|카테고리 - 링크 클릭`,
                'data-gtm-event-label': '컨텐츠 : VIP콕',
                'data-gtm-click-url': '',
                'data-gtm-click-location': `전체혜택|카테고리`,
                'data-gtm-click-direction': '내부',
                'data-gtm-click-text': 'VIP콕',
            }
        },
        {
            id: '3',
            label: '장기고객 혜택',
            dataAttributes: {
                'data-gtm-event-name': 'all_click',
                'data-gtm-event-category': '클릭',
                'data-gtm-event-action': `전체혜택|카테고리 - 링크 클릭`,
                'data-gtm-event-label': '컨텐츠 : 장기고객 혜택',
                'data-gtm-click-url': '',
                'data-gtm-click-location': `전체혜택|카테고리`,
                'data-gtm-click-direction': '내부',
                'data-gtm-click-text': '장기고객 혜택',
            }
        }
    ];

    return (
        <>
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: "혜택 추천",
                }}
                blockSpacing={{top: "24px", bottom: "8px"}}
                isIndent={true}
            />
            <Tab
                fill="underline"
                items={tabItems}
                layout="fitContent"
                selectedItemId={selectedTab}
                size="small"
                onItemSelected={(id: string) => setSelectedTab(id)}
            />
        </>
    );
}

export default CommonHeadingTab;
