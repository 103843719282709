import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, CTA, Text, TextField} from '@lguuxe/2024_designsystem_uni';
import {authApi} from '../../api/gatewayApi/auth';

import BS_Terms from './BS_Terms';
import ExceedPopup from './ExceedPopup';
import AuthPopup from './AuthPopup';
import CommonDivider from '../Common/CommonDivider';
import VerificationInfo from './VerificationInfo';
import {
    getAuthRequestParam,
    getBirth8Digit,
    getGender,
    getParentCheckParam,
    getRequestParam,
    User
} from '../../pages/IdentityVerification/types';
import {checkUnder14, validateUserInfo} from '../../pages/IdentityVerification/validators';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import CommonPopup from "../Common/CommonPopup";
import {getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn} from "../../api/nativeBridge";
import useDebounce from "../../hooks/useDebounce";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {encrypt, generateMCP_TR_KEY} from "../../api/authFunctions";
import {BASE_URL} from "../../api/api";

interface PhoneNumberInputProps {
    user: User;
    onChange: (user: User) => void;
    onReset: () => void;
}

const MAX_PHONE_ATTEMPTS = 3;
const MAX_AUTH_ATTEMPTS = 3;

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({user, onChange, onReset}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [supportText, setSupportText] = useState<any[]>([]); // remove
    const [authSupportText, setAuthSupportText] = useState<any[]>([]);
    const [phoneNumberButtonActive, setPhoneNumberButtonActive] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false); // 안드로이드 앱에서만 사용
    const [isAuthButtonActive, setisAuthButtonActive] = useState(false);
    const [showAuthNumberField, setShowAuthNumberField] = useState(false);

    const [exceedPopupOpen, setExceedPopupOpen] = useState(false);
    const [authPopupOpen, setAuthPopupOpen] = useState(false);
    const [TermsBsOpen, setTermsBsOpen] = useState(false);
    const [showInfoMismatchPopup, setShowInfoMismatchPopup] = useState(false);
    const [showAuthRetryLimitPopup, setShowAuthRetryLimitPopup] = useState(false);
    const [representativeNoMatchPopup, setRepresentativeNoMatchPopup] = useState(false);
    // const [showKmcPopup, setShowKmcPopup] = useState(false);
    const {showMessagePopup} = useMessagePopup();

    const authCodeRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [representativeState, setRepresentativeState] = useState<{
        userInfo: any,
        childCI: string
    }>();

    let isAgreeTerms = useRef(false);
    let phoneNumberCount = useRef(0);
    let authCount = useRef(0);

    const [searchParams] = useSearchParams();
    const target = searchParams.get('target');

    useEffect(() => {
        if (location.state) {
            setRepresentativeState(location.state);
        }

        const fetchIndexedDb = async () => {
            const ctn = await getItemByKey('ctn');

            if (target !== 'representative' && ctn && ctn !== '000000000000' && ctn !== '00000000000') { // 이게 안드로이드임
                const fixedNumber = ctn.length === 12 ? ctn.slice(0, 3) + ctn.slice(4) : ctn;
                setPhoneNumber(fixedNumber);
                setIsAndroid(true);
            }
        }
        fetchIndexedDb();
        console.log('target : ', target);
    }, []);

    useEffect(() => {
        user = {...user, phoneNumber: phoneNumber, authCode: authCode};
        onChange(user);
        if (authCode.length === 6) {
            setisAuthButtonActive(true);
        } else {
            setisAuthButtonActive(false);
        }

        // TODO: 테스트용 코드.
        if (authCode === '999999' && user.birth === '961118') {
            setIsAndroid(false);
        }

    }, [phoneNumber, authCode]);

    useEffect(() => {
        validateUserInfo(user) ? setPhoneNumberButtonActive(true) : setPhoneNumberButtonActive(false);
    }, [user]);


    const handlePhoneNumberChange = (event: any) => {
        setPhoneNumber(event.value);
    };

    const handleAuthCodeChange = (event: any) => {
        setAuthCode(event.value);
        setAuthSupportText([]);
    };

    const handleCertificationPopup = () => {
        setExceedPopupOpen(!exceedPopupOpen);
    };

    const fetchPhoneVerification = async () => {
        const mcpTrKey = generateMCP_TR_KEY();
        const request = await getRequestParam(user, mcpTrKey);
        try {
            const response = await authApi.postPhoneConfirm(request, 'v1', mcpTrKey);
            switch (response.header.resultCode) {
                case '0000':
                    onChange({...user, kmcInfo: response.body.kmcInfo});
                    setSupportText([
                        {
                            icon: 'error_circle_filled',
                            status: 'default',
                            text: `인증 번호를 발송했어요. (${phoneNumberCount.current + 1}/${MAX_PHONE_ATTEMPTS})`,
                        },
                    ]);

                    phoneNumberCount.current++;
                    if (authCodeRef.current) {
                        authCodeRef.current.focus();
                    }
                    setShowAuthNumberField(true);
                    break;
                case '1001': // 입력한 정보 확인 불가
                    console.log(response.header.message);
                    setShowInfoMismatchPopup(true);
                    break;
                case '1002': // 입력한 정보 확인 불가
                    console.log(response.header.message);
                    setShowInfoMismatchPopup(true);
                    break;
                case '1004': // 연속 인증 요청 10건 초과
                    handleCertificationPopup();
                    setPhoneNumberButtonActive(false);
                    setTimeout(() => {
                        setPhoneNumberButtonActive(true);
                    }, 1);
                    break;
                case '1003':
                case '1005': // 당일 본인인증 오류 횟수 초과
                    setShowAuthRetryLimitPopup(true);
                    break;

                case '6299':
                default:
                    showMessagePopup(response.header.message);
                    break;
            }
        } catch (error) {
            console.log('fetchSms error : ', error);
        } finally {
            setPhoneNumberButtonActive(true);
        }
    };

    const fetchAuth = async () => {
        const mcpTrKey = generateMCP_TR_KEY();
        const request = await getAuthRequestParam(user, mcpTrKey);
        const isCarrier = await getItemByKey("telcoCode") === "L"
        try {
            const response = await authApi.postAuthConfirm(request, 'v1', mcpTrKey);
            switch (response.header.resultCode) {
                case '0000':
                    if (target === 'app') {
                        const param = {
                            isCarrier: isCarrier,
                            step: 2
                        }
                        sendEventReturn('CHANGE_PASSWORD', param);
                    }
                    if (target === 'web') {
                        const info = {
                            name: user.name,
                            birthday: getBirth8Digit(user.birth, user.gender),
                            gender: getGender(user.gender),
                            nation: user.nationality == '내국인' ? '0' : '1',
                            telcoCode: user.carrier,
                            phoneNo: user.phoneNumber,
                            certNum: response.body.kmcCheckInfo.certNum,
                            userCI: response.body.kmcCheckInfo.userCI,
                        }
                        console.log('info : -----------------------', info);
                        if (checkUnder14(getBirth8Digit(user.birth, user.gender))) {
                            navigate('/representative', {
                                state: {
                                    userInfo: info,
                                    childCI: response.body.kmcCheckInfo.userCI
                                }
                            });
                        } else {
                            navigate('/agreements/join', {state: info});
                        }
                    }
                    if (target === 'representative') {
                        fetchRepresentativeCheck(response.body.kmcCheckInfo.userCI);
                    }

                    break;
                case '1007': // 인증번호 입력 5회 초과
                    setAuthPopupOpen(true);
                    onReset();
                    const ctn = await getItemByKey('ctn');
                    if (target !== 'representative' && ctn && ctn !== '000000000000' && ctn !== '00000000000') { // 이게 안드로이드임
                        const fixedNumber = ctn.length === 12 ? ctn.slice(0, 3) + ctn.slice(4) : ctn;
                        setPhoneNumber(fixedNumber);
                        setIsAndroid(true);
                    } else {
                        setPhoneNumber('');
                    }
                    setAuthCode('');
                    setPhoneNumberButtonActive(false);
                    setisAuthButtonActive(false);
                    authCount.current = 0;
                    setAuthSupportText([]);
                    setSupportText([]);
                    break;
                case '1008': // 인증번호 실패
                    setAuthSupportText([
                        {
                            icon: 'error_circle_filled',
                            status: 'error',
                            text: '인증번호를 다시 입력해 주세요.',
                        },
                    ]);
                    authCount.current++;
                    break;
                default:
                    showMessagePopup(response.header.message);
                    break;
            }
        } catch (error) {
            console.error('fetchAuth error : ', error);
        } finally {
            setisAuthButtonActive(true);
        }
    };

    const fetchRepresentativeCheck = async (parentCI: string) => {
        const mcpTrKey = generateMCP_TR_KEY();
        const childCI = representativeState!.childCI;
        const request = await getParentCheckParam(childCI, parentCI, mcpTrKey);
        try {
            const response = await authApi.postParentCheck(request, 'v1', mcpTrKey);
            switch (response.header.resultCode) {
                case '0000':
                    const userInfo = representativeState!.userInfo;
                    navigate('/agreements/join', {state: userInfo});
                    break;
                default:
                    setRepresentativeNoMatchPopup(true);
                    console.log('fetchRepresentativeCheck response : ', response);
                    break;
            }
        } catch (error) {
            console.log('fetchRepresentativeCheck error : ', error);
        }
    }

    const handlePhoneButtonClick = useDebounce(() => {
        if (isAgreeTerms.current === false) {
            setTermsBsOpen(true);
            return;
        }

        if (phoneNumberCount.current + 1 > MAX_PHONE_ATTEMPTS) {
            handleCertificationPopup();
            setPhoneNumberButtonActive(false);
            setTimeout(() => {
                setPhoneNumberButtonActive(true);
                phoneNumberCount.current = 0;
            }, 1);
            return;
        }

        setPhoneNumberButtonActive(false);
        fetchPhoneVerification();
    });

    const handleTermsAgree = () => {
        isAgreeTerms.current = true;
        handlePhoneButtonClick();
        if (authCodeRef.current) {
            authCodeRef.current.focus();
        }
    };

    const handleAuthButtonClick = useDebounce(() => {
        setisAuthButtonActive(false);
        fetchAuth();
    });

    const handleToIpin = useDebounce(async () => {
        const mcpTrKey = generateMCP_TR_KEY();
        const param = {
            url: BASE_URL + '/api-service/v1/ipin/redirect',
            isIPIN: true,
            MCP_TR_KEY: mcpTrKey,
            phoneNo: await encrypt(mcpTrKey, phoneNumber),
            showTitlebar: true,
            title: '아이핀 인증',
        }
        sendEvent('CALL_WEBVIEW', param);
    })

    return (
        <>
            <Box type='2_trbl'>
                <TextField
                    fill='single'
                    inputType='number'
                    isAutoTab
                    label='휴대폰 번호'
                    maxLength={11}
                    placeholder='010 1234 5678'
                    status={isAndroid ? 'readOnly' : 'default'}
                    value={phoneNumber}
                    supportText={supportText}
                    // disabled={isAndroid}
                    onChange={handlePhoneNumberChange}
                    rightAddons={[
                        <Button
                            state='default'
                            size='small'
                            color='primary'
                            fill='solid'
                            disabled={!phoneNumberButtonActive}
                            key='certify-button'
                            onClick={handlePhoneButtonClick}
                        >
                            {phoneNumberCount.current == 0 ? '인증번호 받기' : '다시 받기'}
                        </Button>,
                    ]}
                />
                {phoneNumberCount.current == 0 && (
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <div style={{display: 'flex'}}>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2' style={{margin: '0 4px 0 2px'}}>
                                •
                            </Text>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2'>
                                본인 명의 휴대폰 번호로만 인증할 수 있어요.
                            </Text>
                        </div>
                        {/*<div style={{display: 'flex', alignItems: 'flex-start'}}>*/}
                        {/*    <Text typo='font_detail_3Sb' color='color_text_neutral_2' style={{margin: '0 4px 0 2px'}}>*/}
                        {/*        •*/}
                        {/*    </Text>*/}
                        {/*    <Text typo='font_detail_3Sb' color='color_text_neutral_2'>*/}
                        {/*        법인고객은 ‘입력 오류’ 팝업이 나타나면 ‘아이핀 인증’을 눌러 인증해 주시기 바랍니다.*/}
                        {/*    </Text>*/}
                        {/*</div>*/}
                    </div>
                )}
            </Box>

            {showAuthNumberField && <>
                <Box type='2_trbl'>
                    <TextField
                        ref={authCodeRef}
                        fill='single'
                        inputType='number'
                        isAutoTab
                        label='인증번호'
                        maxLength={6}
                        placeholder='6자리 숫자'
                        status='default'
                        value={authCode}
                        onChange={handleAuthCodeChange}
                        supportText={authSupportText}
                    ></TextField>
                </Box>

                <Box style={{padding: '20px 0 8px'}}>
                    <CommonDivider/>
                </Box>
            </>}

            <VerificationInfo/>

            <Box
                style={{
                    width: '100%',
                    padding: '0',
                    marginTop: '26px',
                    paddingBottom: '20px',
                }}
            >
                <CTA layout='column'>
                    <Button color='primary' onClick={handleAuthButtonClick} disabled={!isAuthButtonActive}>
                        확인
                    </Button>
                </CTA>
            </Box>

            <ExceedPopup isOpen={exceedPopupOpen} onClose={handleCertificationPopup}/>
            <AuthPopup isOpen={authPopupOpen} onClose={() => {
                setAuthPopupOpen(!authPopupOpen)
            }}/>

            <BS_Terms onCloseClick={() => {
                setTermsBsOpen(false)
            }} onAgreeClick={handleTermsAgree} carrier={user.carrier} isOpen={TermsBsOpen}/>
            <CommonPopup isOpen={showInfoMismatchPopup}
                         description={'입력하신 정보를 확인할 수 없어요.\n인증 정보를 다시 입력해 주세요.\n※법인 고객은 아이핀 인증을 눌러 주세요.'}
                         submitText={'다시 입력하기'} onSubmit={() => {
                setShowInfoMismatchPopup(false)
            }} cancelText={'아이핀 인증'} onClose={handleToIpin}/>
            <CommonPopup isOpen={showAuthRetryLimitPopup}
                         description={'인증번호 오류 횟수가 초과 되었어요.\n본인확인 차단을 해제거나\n내일 다시 시도해 주세요.'}
                         submitText={'확인'} onSubmit={() => {
                setShowAuthRetryLimitPopup(false);
            }}/>
            <CommonPopup isOpen={representativeNoMatchPopup}
                         title={'법정 대리인 불일치'}
                         description={'휴대폰을 개통할 때 등록한 법정대리인 정보를 입력해 주세요. \n\n법정대리인을 변경하려면 필요 서류를 챙겨 가까운 LG유플러스 매장에 방문해 주세요.'}
                         submitText={'확인'} onSubmit={() => {
                setRepresentativeNoMatchPopup(false)
            }}/>

            {/*<CommonPopup isOpen={showKmcPopup} description={'잠시만 기다려주세요. 본인인증 차단해제중입니다.'} submitText={'확인'}*/}
            {/*             onSubmit={() => {*/}
            {/*                 setShowKmcPopup(false)*/}
            {/*             }}/>*/}
        </>
    );
};

export default PhoneNumberInput;
