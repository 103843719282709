import React from 'react';
import {BottomSheet, Header, Icon, IconButton, Text} from '@lguuxe/2024_designsystem_uni';

import '../../css/style.css';
import {MainRegion, Region} from "../../pages/Partner/types";

interface BS_SubRegionBSProps {
    mainRegion: MainRegion;
    selectedRegion: Region;
    isOpen: boolean;
    onClose: () => void;
    onSelect: (region: Region) => void;
    bottomSpace?: string;
    onBack: () => void;
}

const BS_SubRegionBS: React.FC<BS_SubRegionBSProps> = ({
                                                           mainRegion,
                                                           selectedRegion,
                                                           isOpen,
                                                           onClose,
                                                           onBack,
                                                           onSelect,
                                                           bottomSpace
                                                       }) => {
    const handleSelect = (region: Region) => {
        onSelect(region);
        onClose();
    }

    // 홀수 인덱스 필터링
    const oddItems = mainRegion.subRegionList.filter((_, index) => index % 2 === 0);
    // 짝수 인덱스 필터링
    const evenItems = mainRegion.subRegionList.filter((_, index) => index % 2 !== 0);
    
    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}
                         bottomSpace={bottomSpace === undefined ? '2rem' : bottomSpace}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading'>
                        <Header.LeftSlot>
                            <IconButton color='neutral' fill='icon' icon='chevron_left_line' noStates onClick={onBack}/>
                        </Header.LeftSlot>
                        {mainRegion.categoryName} 지역 선택
                        <Header.RightSlot>
                            <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>
                <BottomSheet.CenterSlot>
                    <div
                        style={{
                            padding: "0 20px 20px",
                            height: "auto",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "12px"
                        }}
                    >
                        <div className="tbl clear">
                            <table>
                                <caption>table</caption>
                                <colgroup>
                                    <col width="50%"/>
                                    <col/>
                                </colgroup>
                                <tbody>
                                {oddItems.map((oddItem, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between"
                                            }} onClick={() => handleSelect(oddItem)}>
                                                <Text typo="font_body_1B" color="color_text_neutral_5">
                                                    {oddItem.categoryName}
                                                </Text>
                                                {selectedRegion.categoryId === oddItem.categoryId &&
                                                    <Icon name='check_small_line' color='#e6007e'/>}
                                            </div>
                                        </td>
                                        <td>
                                            {evenItems[index] && ( // 짝수 항목이 존재하는 경우에만 td 생성
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between"
                                                }} onClick={() => handleSelect(evenItems[index])}>
                                                    <Text typo="font_body_1B" color="color_text_neutral_5">
                                                        {evenItems[index].categoryName}
                                                    </Text>
                                                    {selectedRegion.categoryId === evenItems[index].categoryId &&
                                                        <Icon name='check_small_line' color='#e6007e'/>}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </BottomSheet.CenterSlot>
            </BottomSheet>
        </>
    );
};

export default BS_SubRegionBS;
