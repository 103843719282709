import React from 'react'
import {Box, Text} from '@lguuxe/2024_designsystem_uni'
import {decodeHtmlEntities} from "../../utils/textUtils";

interface SectionBodyTextProps {
    text: string;
}

const SectionBodyText: React.FC<SectionBodyTextProps> = ({text}) => {
    return (
        <Box>
            <div style={{
                overflowX: 'auto',  // 가로 스크롤 활성화
                paddingBottom: '70px'
            }}>
                <Text typo='font_body_1M' color='color_text_neutral_4'>
                    {decodeHtmlEntities(text)}
                </Text>
            </div>
        </Box>
    )
}

export default SectionBodyText
