import React, {useEffect, useState} from 'react'
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import SectionInfoBox from "../../components/Setting/SectionInfoBox";
import {Box, Button, CTA, Menu, TextField} from '@lguuxe/2024_designsystem_uni';
import {settingApi} from "../../api/gatewayApi/settingAndTerms";
import {initialUserInfo, UserInfo} from "./types";
import {getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from "react-router-dom";
import AddressSection from "../Entire/pop/daum";
import CommonPopup from "../../components/Common/CommonPopup";
import {useSnackbar} from '../../provider/SnackbarProvider';
import {getStringBeforeAt} from "../Entire/pop/types";

const UMembershipSettingMyinfo = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);
    const [activeSaveButton, setActiveSaveButton] = useState<boolean>(false);
    const [supportText, setSupportText] = useState<any>(undefined);
    const [userName, setUserName] = useState<string>("")
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const {showSnackbar} = useSnackbar();
    const [showEmailDomain, setShowEmailDomain] = useState<boolean>(false);
    const navigate = useNavigate()

    const [showAddressSection, setShowAddressSection] = useState<boolean>(false);
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await settingApi.getMyInfo({}, 'v1');
                const userInfo = {
                    email: response.body.email ?? "",
                    zipCode: response.body.zipCode ?? "",
                    address1: response.body.address1 ?? "",
                    address2: response.body.address2 ?? ""
                }
                setUserInfo(userInfo);
            } catch (error) {
                console.error("Error fetchAlarm detail:", error);
            }
        };
        const getUserName = async () => {
            const name = await getItemByKey("customerName")
            setUserName(name)
        }
        getUserName()
        fetchUserInfo();
    }, []);

    useEffect(() => {
        if (validateAddress(userInfo) && validateEmail(userInfo.email)) {
            setActiveSaveButton(true);
        } else {
            setActiveSaveButton(false);
        }
    }, [userInfo]);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validateAddress = (userInfo: UserInfo): boolean => {
        return !(
            (userInfo.address1 == null || userInfo.address1.trim() === "") ||
            (userInfo.zipCode == null || userInfo.zipCode.trim() === "")
        );

    }
    const handleAddressDetailChange = (e: any) => {
        const address2 = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            address2: address2
        }));
    }

    const handleEmailChange = (e: any) => {
        const email = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            email: email
        }));
        if (validateEmail(email) || email.trim() === "" || email.includes('@')) {
            setSupportText(undefined);
        } else {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '이메일 주소를 정확히 입력해 주세요.'
                }
            ]);
        }

        if (email.includes('@')) {
            setShowEmailDomain(true);
        } else {
            setShowEmailDomain(false);
        }
    }

    const handleSave = async () => {
        try {
            const params = {
                email: userInfo.email,
                zipCode: userInfo.zipCode,
                address1: userInfo.address1,
                address2: userInfo.address2
            };
            const response = await settingApi.putMyInfo(params, 'v1');
            if (response.header.resultCode === "0000") {
                navigate(-1);
                showSnackbar('내 정보를 수정했어요')
            }
        } catch (error) {
            console.error("정보 저장 중 오류가 발생했습니다:", error);
        }
    }

    const handleEmailDomainSelect = (id: string) => {
        setUserInfo(prevState => ({
            ...prevState,
            email: `${getStringBeforeAt(userInfo.email)}@${id}`
        }));
        setShowEmailDomain(false);
    }

    const handleCloseAddressSection = () => {
        setShowAddressSection(false);
    }

    return (
        <div style={{paddingBottom: '70px'}}>
            <div onClick={handleCloseAddressSection}>
                <CommonLeftIconHeader title={"내 정보 수정"}/>
                <Box type="2_trbl">
                    <TextField
                        fill="single"
                        inputType="text"
                        isAutoTab
                        label="이름"
                        maxLength={10}
                        disabled={true}
                        placeholder="홍길동"
                        status="default"
                        value={userName}
                    >
                        버튼
                    </TextField>
                </Box>
            </div>

            <Box type="2_trbl">
                {!showAddressSection &&
                    <div style={{display: "flex", alignItems: "center"}}>
                        <TextField
                            fill="single"
                            inputType="text"
                            isAutoTab
                            label="주소"
                            placeholder="주소를 검색해 주세요"
                            status="readOnly"
                            value={userInfo.address1}
                            disabled
                            // rightAddons={[
                            //     <Button
                            //         size='small'
                            //         color="primary"
                            //         onClick={() => setShowAddressSection(true)}
                            //     >
                            //         주소 검색
                            //     </Button>
                            // ]}
                        />
                        <Box type="2_tb">
                            <Button
                                size='small'
                                color="primary"
                                style={{marginLeft: "8px"}} // 버튼과 텍스트 필드 사이에 여백 추가
                                onClick={() => setShowAddressSection(true)}
                            >
                                주소 검색
                            </Button>
                        </Box>

                    </div>}

                {showAddressSection &&
                    <AddressSection isOpen={showAddressSection} onClose={() => setShowAddressSection(false)}
                                    setAddress={setUserInfo}/>}

                <div style={{paddingTop: "12px"}} onClick={handleCloseAddressSection}>
                    <TextField
                        fill="single"
                        inputType="text"
                        isAutoTab
                        placeholder="상세주소"
                        value={userInfo.address2}
                        disabled={userInfo.address1 == null || userInfo.address1.trim() === ""}
                        status="default"
                        onChange={handleAddressDetailChange}
                    />
                </div>
            </Box>

            <div onClick={handleCloseAddressSection}>
                <div
                    style={{
                        padding: "24px 20px 4px",
                    }}
                >
                    <TextField
                        fill="single"
                        inputType="email"
                        isAutoTab
                        label="이메일 주소"
                        placeholder="example@lguplus.co.kr"
                        status="default"
                        value={userInfo.email}
                        supportText={supportText}
                        rightAddons={undefined}  // false는 의미가 없으므로 제거
                        onChange={handleEmailChange}
                    >
                        버튼
                    </TextField>
                    {showEmailDomain &&
                        <Box style={{padding: "0"}}>
                            <Menu
                                items={[
                                    {
                                        id: 'naver.com',
                                        label: `${getStringBeforeAt(userInfo.email)}@naver.com`
                                    },
                                    {
                                        id: 'gmail.com',
                                        label: `${getStringBeforeAt(userInfo.email)}@gmail.com`
                                    },
                                    {
                                        id: 'daum.com',
                                        label: `${getStringBeforeAt(userInfo.email)}@daum.com`
                                    },
                                    {
                                        id: 'nate.com',
                                        label: `${getStringBeforeAt(userInfo.email)}@nate.com`
                                    },
                                    {
                                        id: 'hanmail.net',
                                        label: `${getStringBeforeAt(userInfo.email)}@hanmail.net`
                                    }

                                ]}
                                size="medium"
                                weight="default"
                                onItemSelected={handleEmailDomainSelect}
                            />
                        </Box>}
                </div>

                {/* ------ 안내문구 ---------*/}
                <SectionInfoBox/>
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        width: "100%"
                    }}
                    onClick={handleCloseAddressSection}
                >
                    <CTA
                        layout="row"
                    >
                        <Button color="neutral" onClick={() => setShowPopup(true)}>
                            취소
                        </Button>
                        <Button color="primary" disabled={!activeSaveButton} onClick={handleSave}>
                            저장
                        </Button>
                    </CTA>
                    {showPopup && <CommonPopup description={"입력한 내용이 저장되지 않아요.\n수정하지 않고 나가시겠어요?"}
                                               submitText={"계속 수정"}
                                               onSubmit={() => setShowPopup(false)}
                                               cancelText={"나가기"}
                                               onClose={() => navigate(-1)}
                    />}
                </div>
            </div>
        </div>
    )
}

export default UMembershipSettingMyinfo
