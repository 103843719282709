import {Box, TextSet} from '@lguuxe/2024_designsystem_uni';
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import DomesticForeignerButton from '../../components/IdentityVerification/DomesticForeignerButton';
import MobileDropdown from '../../components/IdentityVerification/MobileDropdown';
import NameInput from '../../components/IdentityVerification/NameInput';
import PhoneNumberInput from '../../components/IdentityVerification/PhoneNumberInput';
import ResidentNumberInput from '../../components/IdentityVerification/ResidentNumberInput';
import {Nationality, User} from './types';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {gtmPageLoad} from "../../api/gtm";

const userInfo: User = {
    name: '',
    birth: '',
    gender: '',
    nationality: '내국인',
    carrier: 'L',
    phoneNumber: '',
    authCode: '',
    kmcInfo: {
        certNum: '',
        check1: '',
        check2: '',
        check3: '',
    },
};

export interface PhoneVerificationHandles {
    handleIpinVerification: (obj: any) => void;
}

const PhoneVerification = forwardRef<PhoneVerificationHandles>((props, ref) => {
    const [user, setUser] = useState<User>(userInfo);
    const [resetSignal, setResetSignal] = useState<number>(0);
    const [searchParams] = useSearchParams();
    const target = searchParams.get('target');
    const navigate = useNavigate();

    useEffect(() => {
        gtmPageLoad({
            url: '/phone-certification',
            contentGroup: '기타|본인 인증|휴대폰 인증',
            moduleName: '',
            pageTitle: '본인인증 정보를 입력해 주세요'
        });
    }, []);

    useImperativeHandle(ref, () => ({
        handleIpinVerification: (obj: any) => {
            const resultObj = {
                ...obj,
                phoneNo: user.phoneNumber,
                telcoCode: user.carrier,
            }
            navigate('/agreements/join', {state: resultObj});
        }
    }));

    const handleNameChange = (value: string) => {
        setUser({...user, name: value});
    };

    const handleResidentNumberChange = (birth: string, genderCode: string) => {
        setUser({...user, birth: birth, gender: genderCode});
    };

    const handleNationalityChange = (value: Nationality) => {
        setUser({...user, nationality: value});
    };

    const handleMobileChange = (value: string) => {
        setUser({...user, carrier: value});
    };

    const handleUserChange = (user: User) => {
        setUser(user);
    };

    const handleReset = () => {
        console.log('reset');
        setResetSignal(resetSignal + 1);
    };

    return (
        <>
            <CommonLeftIconHeader title={''}/>

            <Box type='2_trbl'>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: `${target === 'representative' ? '법정 대리인' : '본인인증'} 정보를 입력해 주세요`,
                    }}
                    weight='bolder'
                />
            </Box>

            <NameInput onChange={handleNameChange} resetSignal={resetSignal}/>

            <ResidentNumberInput onChange={handleResidentNumberChange} resetSignal={resetSignal}/>
            {/* <ResidentNumberInput /> */}

            <DomesticForeignerButton onChange={handleNationalityChange} resetSignal={resetSignal}/>

            <MobileDropdown onChange={handleMobileChange} resetSignal={resetSignal}/>

            <PhoneNumberInput user={user} onChange={handleUserChange} onReset={handleReset}/>
        </>
    );
});

export default PhoneVerification;
