// ****************************************************
// [배포] 2.미션 > 메인 (UMembershipMission)
// ****************************************************
import React, {useState} from 'react';
import {Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import box1Img from '../../images/icon/4_my/point.svg';
import box2Img from '../../images/icon/4_my/attendance_this.svg';
import {useNavigate} from 'react-router-dom';
import CommonPopup from '../Common/CommonPopup';
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import useDebounce from "../../hooks/useDebounce";
import useLandingPage from "../../hooks/useLandingPage";

interface EventBlockProps {
    winnerEventLink: string;
    infoEventLink: string;
}

const EventBlock: React.FC<EventBlockProps> = ({winnerEventLink, infoEventLink}) => {
    const navigate = useNavigate();
    const [isNotYetPopupOpen, setIsNotYetPopupOpen] = useState(false);
    const [isPreparingPopupOpen, setIsPreparingPopupOpen] = useState(false);
    const {landingPage} = useLandingPage();

    const handleBlock1Click = useDebounce(() => {
        if (!winnerEventLink) {
            setIsNotYetPopupOpen(true);
            return;
        }
        landingPage('117003', 'event|' + winnerEventLink, navigate, '당첨 결과');
    });

    const handleBlock2Click = useDebounce(async () => {
        if (!infoEventLink) {
            setIsPreparingPopupOpen(true);
            return;
        }
        landingPage('117003', 'event|' + infoEventLink, navigate, '출석체크 미션');
    });
    return (
        <>
            <div style={{paddingBottom: '0'}}>
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: (
                            <>
                                {/*<span style={{color: '#181A1B'}}>출석체크 이벤트 </span>*/}
                            </>
                        ),
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px',
                        padding: '0 20px 24px',
                    }}
                >
                    <div
                        onClick={handleBlock1Click}
                        style={{
                            backgroundColor: '#BEE4F1',
                            borderRadius: '12px',
                            width: 'calc(50% - 4px)',
                            boxSizing: 'border-box',
                            height: '168px',
                            position: 'relative',
                        }}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.MIDDLE_BANNER} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : 지난달|출석체크 결과 확인`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.MIDDLE_BANNER}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`지난달|출석체크 결과 확인`}>
                        <div style={{padding: '20px'}}>
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    eyebrow: '지난달',
                                    title: (
                                        <>
                                            <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                당첨 결과를
                                                <br/>
                                                확인해 보세요
                                            </Text>
                                        </>
                                    ),
                                }}
                                weight='bolder'
                            />
                            <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                <Image customClass='customClass' ratio='1:1' src={box1Img} width='64'/>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={handleBlock2Click}
                        style={{
                            backgroundColor: '#FBDCD3',
                            borderRadius: '12px',
                            width: 'calc(50% - 4px)',
                            boxSizing: 'border-box',
                            height: '168px',
                            position: 'relative',
                        }}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.MIDDLE_BANNER} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : 이번달|출석체크 결과 확인`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.MIDDLE_BANNER}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`이번달|출석체크 결과 확인`}
                    >
                        <div style={{padding: '20px'}}>
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    eyebrow: '이번달',
                                    title: (
                                        <>
                                            <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                출석체크 미션을
                                                <br/>
                                                알려드려요!
                                            </Text>
                                        </>
                                    ),
                                }}
                                weight='bolder'
                            />
                            <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                <Image customClass='customClass' ratio='1:1' src={box2Img} width='64'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isNotYetPopupOpen && (
                <CommonPopup
                    description='아직 지난달 당첨자 발표가 안되었어요.'
                    submitText='확인'
                    onSubmit={() => {
                        setIsNotYetPopupOpen(false);
                    }}
                />
            )}
            {isPreparingPopupOpen && (
                <CommonPopup
                    description='이번 달 출석체크 미션을 준비하고 있어요.'
                    submitText='확인'
                    onSubmit={() => {
                        setIsPreparingPopupOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default EventBlock;
