import React from "react";
import {Box, Image, Text} from "@lguuxe/2024_designsystem_uni";

interface BenefitItemProps {
    title: string;
    target: string;
    description: string;
    imageSrc: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({title, target, description, imageSrc}) => {
    // description을 \n 기준으로 분리
    const descriptionItems = description.split('\\n'); // '\\n'을 기준으로 분리
    console.log(descriptionItems);

    return (
        <Box style={{display: "flex", flexDirection: "column", gap: "2px", padding: "12px 20px 0"}}>
            <Text typo="font_body_1B" color="color_text_neutral_5" style={{fontSize: "16px", fontWeight: "600"}}>
                {title}
            </Text>
            <Text typo="font_detail_2M" color="color_text_neutral_3" style={{paddingTop: "2px"}}>
                대상 : {target}
            </Text>
            <ul style={{padding: "6px 0 0 25px", margin: "0", fontSize: "14px", color: "#66707A"}}>
                {/* description을 \n 기준으로 분리하여 각각 <li>로 출력 */}
                {descriptionItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
            <div style={{display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px"}}>
                <Image src={imageSrc} alt="placeholder" width="100%" ratio="4:3"/>
            </div>
        </Box>
    );
};

export default BenefitItem;