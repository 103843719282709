import React from 'react';
import {BottomSheet, Header, IconButton, List, Text} from '@lguuxe/2024_designsystem_uni';

import '../../css/style.css';
import CommonBsBackground from "./CommonBsBackground";

interface BS_ListProps {
    title: string;
    dataList: { id: string, label: string }[];
    onClose: () => void;
    onSelected: (id: string) => void;
    checkedItem?: string;
    isOpen: boolean;
}

const BS_List: React.FC<BS_ListProps> = ({title, dataList, onClose, onSelected, checkedItem, isOpen}) => {
    const handleClose = () => {
        onClose();
    };

    const handleListClick = (event: any) => {
        onSelected(event.id);
        onClose();
    };

    const listItems = dataList.map((item) => ({
        id: item.id,
        checked: item.id === checkedItem,
        leftSlot: [
            <div
                key={item.id}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Text as='span' typo='font_body_1M' color='color_text_neutral_5'>
                    {item.label}
                </Text>
            </div>,
        ],
    }));

    return (
        <>
            <CommonBsBackground isOpen={isOpen} onClose={handleClose}/>

            <BottomSheet isOpen={isOpen === undefined ? true : isOpen} onClose={handleClose}>
                <BottomSheet.TopSlot>
                    <div style={{padding: '0 1.25rem'}}>
                        <Header align='left' variant='heading' divider={false}>
                            {title}
                            <Header.RightSlot>
                                <IconButton color='neutral' fill='icon' icon='close_line' noStates
                                            onClick={handleClose}/>
                            </Header.RightSlot>
                        </Header>
                    </div>
                </BottomSheet.TopSlot>

                <BottomSheet.CenterSlot>
                    <div style={{
                        paddingTop: '0px', height: 'auto', maxHeight: 'calc(100vh - 9rem)',
                        overflowY: 'auto',  // 내용이 넘치면 스크롤 가능하게 설정
                    }}>
                        <List id={title} onChange={handleListClick} type='select' fill='none'
                              items={listItems}/>
                    </div>
                </BottomSheet.CenterSlot>
            </BottomSheet>
        </>
    );
};

export default BS_List;
