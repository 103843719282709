import React, {FC, useEffect, useState} from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonFooter from "../../components/Common/CommonFooter";
import {Button, Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import ExImg from "../../images/img/uplus_members_logo.svg";
import {getItemByKey} from "../../utils/indexedDB";
import {settingApi} from "../../api/gatewayApi/settingAndTerms";
import {goAppStore} from "../../utils/helper";

const AppVersion: FC = () => {
    const [currentVersion, setCurrentVersion] = useState("")
    const [LatestVersion, setLatestVersion] = useState("")
    useEffect(() => {
        const checkFirst = async () => {
            const currentVersion = await getItemByKey('appVersion')
            if (currentVersion) {
                setCurrentVersion(currentVersion); // "status"를 실제 status 값으로 설정
            }
        };
        const fetchVersion = async () => {
            try {
                const platform = await getItemByKey('platform');
                const response = await settingApi.getVersion({osType: platform}, 'v1');
                setLatestVersion(response.body.version);
            } catch (error) {
                console.error("Error fetchAlarm detail:", error);
            } finally {
                // setIsLoading(false);
            }
        };
        fetchVersion()
        checkFirst();
    }, []); // useEffect에 종속성 배열 추가

    const isLatestVersion = (): boolean => {
        // .을 제거하고 정수로 변환
        const currentVersionNum = parseInt(currentVersion.replace(/\./g, ''), 10);
        const LatestVersionNum = parseInt(LatestVersion.replace(/\./g, ''), 10);

        // 정수로 비교
        if (currentVersionNum >= LatestVersionNum) {
            return true;
        }
        return false;
    }

    return (
        <>
            <CommonLeftIconHeader title={"앱 버전"}/>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "144px",
                }}
            >
                <Image
                    customClass="customClass"
                    ratio="1:1"
                    rounded="16"
                    width="120"
                    src={ExImg}
                />
                <TextSet
                    subComponent="Body_1"
                    text={{
                        title: (
                            <>
                                <Text color='color_text_neutral_3'>설치 버전: {currentVersion}<br/>최신 버전: {LatestVersion}
                                </Text>
                            </>
                        ),
                    }}
                    weight="default"
                />
                <div
                    style={{
                        marginTop: "4px",
                    }}
                >
                    {isLatestVersion() ? (
                            <Button
                                as="button"
                                color="neutral"
                                fill="outline"
                                size="medium"
                                state="default"
                            >
                                최신 버전이에요.
                            </Button>
                        )
                        : (
                            <Button
                                as="button"
                                color="primary"
                                fill="solid"
                                size="medium"
                                state="default"
                                onClick={async () => goAppStore()}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'설절|업데이트 및 프로그램 정보 - 링크 클릭'}
                                data-gtm-event-label={'컨텐츠 : 업데이트'}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'설절|업데이트 및 프로그램 정보'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={'업데이트'}
                            >
                                업데이트
                            </Button>
                        )}
                </div>
            </div>

            <CommonFooter/>
        </>
    );
};

export default AppVersion;
