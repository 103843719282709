import React from "react";
import CommonDivider from "../../Common/CommonDivider";
import BenefitGuideSection from "../BenefitGuideSection";
import {useNavigate} from "react-router-dom";
import vipkok from "../../../images/guide/intro/vipkok.png"
import uptp from "../../../images/guide/intro/uptp.png"
import membership from "../../../images/guide/intro/membership.png"

const MembershipBenefitTab: React.FC = () => {
    const navigate = useNavigate()
    const goVIPkok = () => {
        navigate('/vip-kok')
    }
    const goUPTP = () => {
        navigate('/uptp')
    }
    const goPartners = () => {
        navigate('/partners')
    }
    return (
        <>
            <BenefitGuideSection
                title="VIP콕"
                target="VVIP/VIP"
                benefits={[
                    "VIP콕 혜택 중 매달 내가 원하는 1개를 골라 사용할 수 있어요.",
                    "VIP/VVIP가 된 날부터 바로 VIP콕을 사용할 수 있어요."
                ]}
                imageSrc={vipkok}
                buttonText={"VIP콕 혜택 보기"}
                onClick={goVIPkok}
                gtmLocation={'U+멤버십 소개|U+멤버십 혜택'}
            />
            <CommonDivider/>

            <BenefitGuideSection
                title="유플투쁠"
                target="VVIP/VIP/우수"
                benefits={[
                    "매달, 매일 달라지는 무료 또는 할인 혜택이 찾아와요",
                    "원하는 혜택을 놓치지 않도록 '유플투쁠 캘린더'를 확인 하세요"
                ]}
                imageSrc={uptp}
                buttonText={"유플투쁠 혜택 보기"}
                onClick={goUPTP}
                gtmLocation={'U+멤버십 소개|U+멤버십 혜택'}
            />
            <CommonDivider/>

            <BenefitGuideSection
                title="제휴사 혜택"
                target="모든 멤버십 고객"
                benefits={[
                    "U+모바일 고객은 물론 U+멤버십 앱에 가입한 모든 고객이 누릴수 있는 혜택이에요.",
                    "카페, 편의점, 레스토랑, 영화 등 다양한 제휴사에서 등금에 따라 혜택을 받을 수 있어요"
                ]}
                imageSrc={membership}
                buttonText={"제휴사 보기"}
                onClick={goPartners}
                gtmLocation={'U+멤버십 소개|U+멤버십 혜택'}
            />
        </>
    );
};

export default MembershipBenefitTab;
