import React, {useEffect, useState} from 'react';
import {Box, Button, Icon} from '@lguuxe/2024_designsystem_uni';
import {Carrier} from '../../pages/IdentityVerification/types';
import BS_List from "../Common/BS_List";

const MOBILE_CARRIERS: Carrier[] = [
    {
        id: '1',
        label: 'LG U+',
        value: 'L',
    },
    {
        id: '2',
        label: 'SKT',
        value: 'S',
    },
    {
        id: '3',
        label: 'KT',
        value: 'K',
    },
    {
        id: '4',
        label: 'LG U+ 알뜰폰',
        value: 'LM',
    },
    {
        id: '5',
        label: 'SKT 알뜰폰',
        value: 'SM',
    },
    {
        id: '6',
        label: 'KT 알뜰폰',
        value: 'KM',
    },
];

interface MobileDropdownProps {
    onChange: (value: string) => void;
    resetSignal: number;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({onChange, resetSignal}) => {
    const [showBsList, setShowBsList] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState<string>('1');
    // 1. 안드로이드는 통신사를 알아내서 자동 설정해줌
    // 2. iOS는 LGU+가 기본값으로 설정되어 있음

    useEffect(() => {
        setSelectedCarrier('1');
    }, [resetSignal]);

    const handleSelected = (id: string) => {
        setSelectedCarrier(id);
        onChange(MOBILE_CARRIERS[parseInt(id) - 1].value);
    }

    return (
        <>
            <Box type='2_trbl'>
                {/*<Dropdown*/}
                {/*    fill='underline'*/}
                {/*    items={MOBILE_CARRIERS.map((carrier) => ({*/}
                {/*        id: carrier.id,*/}
                {/*        label: carrier.label,*/}
                {/*    }))}*/}
                {/*    label='통신사'*/}
                {/*    size='medium'*/}
                {/*    onChange={handleChange}*/}
                {/*    value={'LG U+'}*/}
                {/*/>*/}

                <div style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    <span style={{
                        display: 'flex',
                        color: '#66707a'
                    }}>
                        <span style={{
                            fontSize: '0.75rem',
                        }}>
                            통신사
                        </span>
                    </span>
                </div>
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0.5rem 0',
                    justifyContent: 'space-between',
                    borderRadius: 'none',
                    margin: '0.25rem 0 0.5rem 0',
                }}>
                    <span style={{lineHeight: '150%'}}>
                        {MOBILE_CARRIERS.find((carrier) => carrier.id === selectedCarrier)?.label}
                    </span>
                    <Button style={{
                        border: 'none',
                        padding: 0
                    }} fill='ghost' size='small' onClick={() => setShowBsList(true)}>
                        <Icon name={'chevron_down_small_line'} size='24px' color='#7f8a94'/>
                    </Button>
                    <div style={{
                        backgroundColor: '#dce0e5',
                        overflow: 'hidden',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '2px',
                        transition: 'all 0.5s cubic-bezier(0.26, 0.74, 0.35, 1)',
                        borderRadius: '1rem',
                    }}
                    >
                    </div>
                </div>
            </Box>

            <BS_List isOpen={showBsList} title={'통신사'} dataList={MOBILE_CARRIERS} onClose={() => {
                setShowBsList(false)
            }} onSelected={handleSelected} checkedItem={selectedCarrier}/>
        </>

    );
};

export default MobileDropdown;
