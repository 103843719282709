import React, {useEffect, useState} from "react";
import {Box, Button, Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import InfoBox from "../../components/Vipkok/InfoBox";
import {PartnerDetailData, PartnerType, UserGrade} from "../../pages/Partner/types";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {useNavigate} from "react-router-dom";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {sendEventReturn} from "../../api/nativeBridge";
import {couponApi} from "../../api/gatewayApi/other";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {CouponItem} from "../../pages/My/types";
import CommonPopup from "../Common/CommonPopup";
import defaultThumbnail from '../../images/default/default thumbnail.svg'
import {goMovie} from "../../api/externalApi";
import useDebounce from "../../hooks/useDebounce";


type Props = {
    partnerDetail: PartnerDetailData; // partnerDetail 타입 지정
    partnerType: PartnerType;
    useVipkokYn: string;
    userGrade: UserGrade;
    couponList: CouponItem[];
    onChangeState: (useVipkokYn: string) => void;
};

const SectionContentHeaderBenefit: React.FC<Props> = ({
                                                          partnerDetail,
                                                          partnerType,
                                                          useVipkokYn,
                                                          userGrade,
                                                          couponList,
                                                          onChangeState
                                                      }) => {
    const [state, setState] = useState<'미사용' | '다운로드' | '사용완료'>('사용완료');
    const [couponIssued, setCouponIssued] = useState<boolean>(false);
    const {showSnackbar} = useSnackbar();
    const {showMessagePopup} = useMessagePopup()
    const [name, setName] = useState<string>('');
    const [showCouponIssuePopup, setShowCouponIssuePopup] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchName = async () => {
            setName(await getItemByKey('CustomerName'));
        }
        fetchName();
    }, []);

    useEffect(() => {
        const initPage = async () => {
            const vipkok =  await getItemByKey('vipkok')
            if(couponIssued) {
                setState('다운로드');
            } else if(vipkok && partnerDetail.extPtnCode === vipkok.ptnId && partnerType === 'coupon' && vipkok.isUsed === 'Y')
                setState('다운로드');
            // if (partnerType === 'coupon' && useVipkokYn === 'Y') {
            //
            // } else if ((partnerType === 'normal' || partnerType === 'movie') && useVipkokYn === 'Y') {
            //     setState('사용완료');
            else if (vipkok.isUsed === 'Y') {
                setState('사용완료');
            } else {
                setState('미사용');
            }
        }

        initPage()
    }, [useVipkokYn, partnerType]);


    const handleButtonClick = useDebounce(async () => {
        if (userGrade === '미로그인') {
            navigate('/identity-verification');
        } else if (userGrade === '일반' || userGrade === '우수') {
            navigate('/benefit-guide');
        } else {
            if (partnerType === 'normal') {
                console.log('homepage url : ', partnerDetail.homepage);
                let url = '';
                if (partnerDetail.homepage) {
                    console.log('homepage url : ', partnerDetail.homepage);

                    switch (partnerDetail.extPtnCode) {
                        case "LI09": // 네이버 플러스 멤버십
                        case "LI75": // 윌라
                        case "LI79": // 우리동네GS
                            const mbrcard = await getItemByKey("barcode");
                            if (partnerDetail.extPtnCode === "LI09" || partnerDetail.extPtnCode === "LI75") {
                                url = `${partnerDetail.homepage}?mbrcard=${mbrcard}`;
                            } else if (partnerDetail.extPtnCode === "LI79") {
                                url = `${partnerDetail.homepage}?view%3DregisterLifeCock%26membershipCardNumber=${mbrcard}`;
                            }
                            break;
                        default:
                            url = partnerDetail.homepage; // 기본 homepage URL 사용
                    }

                    if (url) {
                        sendEventReturn('EXTERNAL_CALL', {type: '1', url});
                    }
                } else {
                    console.log('homepage가 편성되지 않았습니다');
                }
            } else if (state === '미사용' && partnerType === 'coupon') {
                fetchCouponDownload();
            } else if (state === '다운로드' && partnerType === 'coupon') {
                navigate(`/my/coupon`)
            } else if (partnerType === 'movie') {
                goMovie()
            }
        }
    });

    const fetchCouponDownload = useDebounce(async () => {
        try {
            const res = await couponApi.postCouponVipkokById(partnerDetail.ptnId, {}, 'v1');
            if (res.header.resultCode === '0000') {
                setCouponIssued(true);
                await addItem('vipkok', {
                    ptnId: partnerDetail.extPtnCode,
                    ptnName: partnerDetail.ptnName,
                    isUsed: 'Y'
                })
                onChangeState('Y');
                // llyUrl 필드에 값이 있으면 필드에 있는 URL 로 외부 웹브라우저 실행시켜 주시면 됩니다.
                // 일리쿠폰만 특이하게 페이지 이동해달라는게 사업팀 요구라서요. (기존 U+멤버십 웹에서 처리했던 부분) from 유비소프트 홍성찬팀장님
                if (res.body && res.body.illyUrl != null) {  // res.body와 illyUrl이 모두 존재할 때만 실행
                    sendEventReturn('EXTERNAL_CALL', {type: '1', url: res.body.illyUrl});
                    return;
                }
                // showSnackbar('쿠폰이 발급되었어요', "쿠폰함 바로가기", () => navigate('/my/coupon'));
                setShowCouponIssuePopup(true);
            } else {
                showMessagePopup(res.header.message);
            }
            // TODO: api 에러 처리
        } catch (e) {
            console.log('error fetchData:', e);
        }
    });

    const renderInfoBox = () => {
        if (userGrade === '미로그인') {
            return <InfoBox text="본인 인증 후 U+멤버스만의 다양한 혜택을 즐겨보세요."/>;
        } else if (userGrade === '일반' || userGrade === '우수') {
            return <InfoBox text="VVIP/VIP 전용 혜택이에요." name={name} rank={userGrade}/>;
        } else {
            if (state === '사용완료') {
                return <InfoBox text="이번 달 VIP콕을 사용했어요."/>;
            } else if (state === '미사용' && partnerType === 'coupon') {
                return null;
            } else if (state === '다운로드' && partnerType === 'coupon') {
                return <InfoBox text="마이 > 내 쿠폰에서 확인할 수 있어요."/>;
            } else if (state === '미사용' && partnerType === 'normal') {
                return <InfoBox text="이번 달 VIP콕으로 선택할 수 있어요."/>;
            }
        }
    };

    const renderBenefitContent = () => {
        let title = partnerDetail.ptnName
        let subTitle = partnerDetail.vipSummary
        let imageSrc = partnerDetail.ptnLogoImgUrl
        let buttonText = '';

        if (userGrade === '미로그인') {
            buttonText = 'U+모바일 인증 로그인';
        } else if (userGrade === '일반' || userGrade === '우수') {
            buttonText = '등급 및 혜택 안내';
        } else {
            if (partnerType === 'normal') {
                buttonText = '혜택 사용하러 가기';
            } else if (state === '미사용' && partnerType === 'coupon') {
                buttonText = '쿠폰 받기';
            } else if (state === '다운로드' && partnerType === 'coupon') {
                buttonText = '쿠폰 보기';
            } else if (partnerType === 'movie') {
                buttonText = '예매하러 가기';
            }
        }

        return (
            <>
                <List
                    type="view"
                    fill="none"
                    items={[
                        {
                            leftSlot: (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }}
                                >
                                    <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                        {title}
                                    </Text>
                                    <Text typo="font_heading_3B" color="color_text_neutral_6">
                                        {subTitle}
                                    </Text>
                                </div>
                            ),
                            rightSlot: [
                                <Image
                                    src={imageSrc}
                                    srcFallback={defaultThumbnail}
                                    ratio="1:1"
                                    width="72px"
                                    rounded={"50%"}
                                />
                            ]
                        }
                    ]}
                />
                {/*{partnerType === 'movie' && <SectionHeadingText2row1/>}*/}
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        padding: "24px 20px 0 20px",
                        alignSelf: "stretch"
                    }}
                >
                    {renderInfoBox()}
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        padding: "0 20px"
                    }}
                >
                    {state !== '사용완료' &&
                        <Button
                            color={userGrade === '미로그인' ? 'primary' : 'secondary'}
                            fill="solid"
                            style={{
                                display: "flex",
                                flex: "1"
                            }}
                            onClick={handleButtonClick}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'마이|VIP콕|VIP콕 상세 - 링크 클릭'}
                            data-gtm-event-label={`컨텐츠 : ${buttonText}`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'마이|VIP콕|VIP콕 상세'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`${buttonText}}`}>
                            {buttonText}
                        </Button>
                    }
                </div>
            </>
        );
    };

    return (
        <div>
            <Box
                style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    padding: "0 0 24px",
                    width: "100%"
                }}
            >
                {renderBenefitContent()}
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        flexDirection: "column",
                        padding: "12px 0 0",
                        width: "100%"
                    }}
                >
                </div>
            </Box>
            <CommonPopup submitText={'확인'} onSubmit={() => {
                navigate('/my/coupon')
            }} isOpen={showCouponIssuePopup} description={'쿠폰이 발급되었어요'} onClose={() => setShowCouponIssuePopup(false)}/>
        </div>

    );
};

export default SectionContentHeaderBenefit;
