import React, {useEffect, useState} from 'react';
import './Popup.css';
import {Box, Button, CTA, TextSet} from "@lguuxe/2024_designsystem_uni";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import {BarcodeStatus, initialMembershipBarcode, MembershipBarcode} from "../Barcode/types";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import MembershipBarcodeSection from "../Barcode/MembershipBarcodeSection";
import useDebounce from "../../hooks/useDebounce";
import {INFINITE_BARCODE_TIMER} from "../Barcode/BS_Barcode";

interface PopupProps {
    onClose?: () => void;
    cancelText?: string;
    submitText: string;
    onSubmit: () => void;
    description?: string
    title?: string
}

const BarcodePopup: React.FC<PopupProps> = ({description, title, cancelText, submitText, onSubmit, onClose}) => {
    const [membershipBarcode, setMembershipBarcode] = useState<MembershipBarcode>(initialMembershipBarcode);
    useEffect(() => {
        const fetchInit = async () => {
            // const barcodeTime = await getItemByKey('barcodeTime');
            // if (barcodeTime) {
            //     const now = new Date();
            //     if (barcodeTime - now.getTime() > 0) {
            //         // TODO: 바코드값 가져와서 보여주기
            //         setMembershipBarcode({
            //             barcode: await getItemByKey('barcode'),
            //             createDate: new Date(barcodeTime),
            //             status: 'ready',
            //             barcodeTime: barcodeTime,
            //         });
            //     } else { // TIME OUT
            //         fetchMembershipBarcode();
            //     }
            // } else { // 바코드가 없을 경우
            //     fetchMembershipBarcode();
            // }

            // 고정 바코드 코드
            const barcode = await getItemByKey('barcode');
            if (barcode) {
                setMembershipBarcode({
                    barcode: barcode,
                    createDate: new Date(),
                    status: 'ready',
                    barcodeTime: INFINITE_BARCODE_TIMER,
                });
            } else {
                fetchMembershipBarcode();
            }
        }
        fetchInit();
    }, []);

    const handleBarcodeStatus = useDebounce((status: BarcodeStatus) => {
        if (status === 'ready') {
            fetchMembershipBarcode();
        }
    }, 500);


    const fetchMembershipBarcode = async () => {
        try {
            // membershipsServiceApi.getCardBarcode('v1').then((response) => {
            //     if (response.header.resultCode === '0000' && response.body) {
            //         const body = response.body;
            //         const createDate = new Date(body.createDate.replace(' ', 'T'))
            //         const barcode: MembershipBarcode = {
            //             barcode: body.barcode,
            //             createDate: createDate,
            //             status: 'ready',
            //             barcodeTime: createDate.getTime() + BARCODE_TIMER,
            //         }
            //         setMembershipBarcode(barcode);
            //         addItem('barcodeTime', createDate.getTime() + BARCODE_TIMER);
            //         addItem('barcode', response.body.barcode);
            //     } else {
            //         setMembershipBarcode({
            //             ...membershipBarcode, status: 'fail'
            //         })
            //     }
            // });

            membershipsServiceApi.getCardInfo({}, 'v1').then((response) => {
                if (response.header.resultCode === '0000' && response.body) {
                    const body = response.body;
                    const barcode: MembershipBarcode = {
                        barcode: body.barcode,
                        createDate: new Date(),
                        status: 'ready',
                        barcodeTime: INFINITE_BARCODE_TIMER,
                    }
                    setMembershipBarcode(barcode);
                    addItem('barcode', response.body.barcode);
                }
            });
        } catch (error) {
            console.log('An unexpected error occurred:', error);
            setMembershipBarcode({
                ...membershipBarcode, status: 'fail'
            })
        }
    }

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <div className="barcode-container">
                    <MembershipBarcodeSection
                        popup={true}
                        barcode={membershipBarcode}
                        onChange={handleBarcodeStatus}
                    />
                </div>
                <div className="popup">
                    <Box style={{
                        padding: '1.75rem 1.25rem 1.25rem 1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <TextSet
                            subComponent='Heading_3'
                            text={{title: title}}
                            weight='bolder'
                        />
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                title: (
                                    <span style={{paddingTop: '12px', textAlign: 'center'}}>
                                        {description?.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </span>
                                ),
                            }}
                            weight='default'
                        />
                    </Box>
                    <CTA layout='row' style={{borderRadius: "20px"}}>
                        <Button color='neutral' size='large' onClick={onClose}>
                            {cancelText}
                        </Button>
                        <Button color='primary' size='large' onClick={onSubmit}>
                            {submitText}
                        </Button>
                    </CTA>
                </div>
            </div>
        </div>
    );
};

export default BarcodePopup;
