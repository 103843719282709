import {BottomSheet, Button, CTA, Header, IconButton, List, Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";
import {useFullScreen} from "../../provider/FullScreenContext";
import AgreementsDetail from "../../pages/Agreements/AgreementsDetail";
import {useMessagePopup} from "../../provider/MessagePopupProvider";

interface BS_PaymentTermProps {
    isOpen: boolean;
    onClose: () => void;
    onAgree: () => void;
}

const BS_PaymentTerm: React.FC<BS_PaymentTermProps> = ({isOpen, onClose, onAgree}) => {
    const [checkedTerm, setCheckedTerm] = React.useState(false);
    const {show} = useFullScreen();
    const {hide} = useFullScreen();
    const {showMessagePopup} = useMessagePopup();
    const termId = 'placeHolder';

    // useEffect(() => {
    //     const handleCheckPasswordEvent = (event: CustomEvent) => {
    //         const password = event.detail.password;
    //         const postPaymentTerm = async () => {
    //             const res = await preparePaymentApi(certType, password, {}, paymentApi.postServiceUseTerm);
    //             if (res && res.header.resultCode === '0000') {
    //                 onAgree(password);
    //             } else {
    //                 showMessagePopup('일시적인 오류입니다')
    //             }
    //         }
    //         postPaymentTerm();
    //
    //     };
    //
    //     window.addEventListener('bs_paymentTerm', handleCheckPasswordEvent as EventListener);
    //
    //     return () => {
    //         window.removeEventListener('password', handleCheckPasswordEvent as EventListener);
    //     };
    // }, []);

    const handleCheck = (e: any) => {
        console.log(e);
        if (e.value === '선택되었습니다') {
            setCheckedTerm(true);
        } else {
            setCheckedTerm(false);
        }
    }

    const handleDetailClick = (e: any) => {
        if (e.target.id === 'termDetail') {
            console.log(termId);
            show(<AgreementsDetail termId={termId} type='exist' selected={true} handleClose={() => {
                hide()
            }}/>)
            // show(<AdditionalAgreements/>)
        }
    }

    const handleButtonClick = async () => {
        onAgree();
        onClose();
    }

    return (
        <BottomSheet isOpen={isOpen} bottomSpace="2rem" onClose={onClose}>
            <BottomSheet.TopSlot>
                <div
                    style={{
                        marginTop: '8px',
                    }}
                >
                    <Header align='left' variant='heading' divider={false}>
                        통신과금 서비스 이용약관
                        <Header.RightSlot>
                            <IconButton
                                color='neutral'
                                fill='icon'
                                icon='close_line'
                                noStates
                                onClick={onClose}
                            />
                        </Header.RightSlot>
                    </Header>
                </div>
            </BottomSheet.TopSlot>

            <BottomSheet.CenterSlot>
                <Text align='left' variant='body'>
                    휴대폰결제를 이용하려면 통신과금 서비스 이용약관에 동의해주세요.
                </Text>
                <List
                    id='terms'
                    fill='none'
                    items={[
                        {
                            leftSlot: [
                                <div>
                                    <Text>
                                        통신과금 서비스 이용약관
                                    </Text>
                                </div>
                            ],
                            linkIconOnlyId: "termDetail",
                            checked: checkedTerm,
                        }
                    ]}
                    type='checkbox'
                    weight='default'
                    onChange={handleCheck}
                    onClick={handleDetailClick}
                />
            </BottomSheet.CenterSlot>

            <BottomSheet.BottomSlot>
                <CTA layout='row'>
                    <Button color='primary' disabled={!checkedTerm} onClick={handleButtonClick}>
                        동의하기
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    );
}

export default BS_PaymentTerm;