import React from "react";
import {Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {gtmDataLayer} from "../../api/gtm";
import {appTech, goMovie, moneymeEnter} from "../../api/externalApi";

interface QuickMenuProps {
    userStatus: string;
}

const QuickMenu: React.FC<QuickMenuProps> = ({userStatus}) => {
    const navigate = useNavigate();
    const menuConfig: { [key: string]: string[] } = {
        1: ['출석체크', 'VIP콕', '이벤트', '앱테크'],
        2: ['출석체크', '이벤트', '유플투쁠', '앱테크'],
        3: ['영화예매', 'VIP콕', '유플투쁠'],
        4: ['유플투쁠', '전체제휴사'],
        5: ['출석체크', '이벤트', '머니Me', '앱테크'],
    };
    const handleMenuClick = (menuName: string) => {
        switch (menuName) {
            case '이벤트':
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|이벤트',})
                navigate('/event'); // 이벤트
                break;
            case 'VIP콕':
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|VIP콕',})
                navigate('/vip-kok'); // VIP콕
                break;
            case '영화예매':
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|영화예메',})
                goMovie("CGV") // 영화예매
                break;
            case '출석체크':
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|출석체크',})
                navigate('/mission/attendance'); // 출석체크
                break;
            case '유플투쁠':
                navigate('/uptp'); // 유플투쁠
                break;
            case '전체제휴사':
                navigate('/partners'); // 전체 제휴처
                break;
            case '미션':
                navigate('/mission'); // 전체 제휴처
                break;
            case '머니Me':
                moneymeEnter(); // 머니 Me
                break;
            case '앱테크':
                appTech(); // 앱테크
                break;
            default:
                break;
        }
    };

    const userMenus = menuConfig[userStatus] || []; // userStatus에 따른 메뉴 목록을 가져옵니다.

    return (
        <div
            style={{
                padding: "12px 16px",
                borderRadius: "12px",
                background: "#fff",
                border: "1px solid #E7EBEE",
                display: "flex",
                justifyContent: "space-evenly",
            }}
        >
            {userMenus.map((menuName: string, index: number) => {
                return (
                    <React.Fragment key={menuName}>
                        <Text typo="font_label_2Sb" color="color_text_natural_6" style={{color: "#181A1B"}}
                              onClick={() => handleMenuClick(menuName)}
                              data-gtm-event-name={'all_click'}
                              data-gtm-event-category={'클릭'}
                              data-gtm-event-action={'바로가기 블록 - 링크 클릭'}
                              data-gtm-event-label={`컨텐츠 : ${menuName}`}
                              data-gtm-click-url={''}
                              data-gtm-click-location={'바로가기 블록'}
                              data-gtm-click-direction={'내부'}
                              data-gtm-click-text={`${menuName}`}>
                            {menuName}
                        </Text>
                        {index < userMenus.length - 1 && (
                            <span style={{padding: "0 12px", width: "1px", height: "12px", color: "#E7EBEE"}}>|</span>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default QuickMenu;
