import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Image} from "@lguuxe/2024_designsystem_uni";

import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionBannerInfo from "../../components/UPTP/SectionBannerInfo";
import SectionHeadingTabList from "../../components/UPTP/SectionHeadingTabList";
import ExImg from "../../images/uptp/Upltwopl_default.svg";
import {
    BenefitPtn,
    initialMonthEvent,
    initialMonthEventByDate,
    LongTerm,
    MonthEvent,
    MonthEventByDate,
    VipPtn
} from "./types";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import SectionCalendar from "../../components/UPTP/SectionCalendar";
import SectionHeadingListNoBtn3 from "../../components/UPTP/SectionHeadingListNoBtn3";
import CommonHeadingTab from "../../components/Common/CommonHeadingTab";
import {handlePromiseResult} from "../../api/api";
import {brazeEventApi} from "../../api/brazeBridge";
import {getItemByKey} from "../../utils/indexedDB";
import useLoading from "../../hooks/useLoading";
import {sendEvent} from "../../api/nativeBridge";
import {eventApi} from "../../api/gatewayApi/attendanceAndEvent";
import {useNavigate} from "react-router-dom";
import {getAgeCode} from "../../utils/helper";
import BarcodeLoading from "../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import {gtmPageLoad} from "../../api/gtm";

export interface UptpHandles {
    waitingPopupSuccess: (id: string) => void;
}

const UPTP = forwardRef<UptpHandles>((props, ref) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monthEvent, setMonthEvent] = useState<MonthEvent>(initialMonthEvent);
    const [monthEventByDate, setMonthEventByDate] = useState<MonthEventByDate>(initialMonthEventByDate);
    const [basicBenefit, setBasicBenefit] = useState<BenefitPtn[]>([]);
    const [vipBenefit, setVipBenefit] = useState<VipPtn[]>([]);
    const [loyalMemberBenefit, setLoyalMemberBenefit] = useState<LongTerm[]>([]);
    const [eventBanner, setEventBanner] = useState<[]>();
    const [selectedDay, setSelectedDay] = useState<string>(""); // 클릭된 아이템의 날짜를 추적
    const [currentDay, setCurrentDay] = useState<string>(""); // 초기값을 빈 문자열로 설정
    const [selectedTab, setSelectedTab] = useState<string>('1'); // 선택된 탭을 추적
    const [imgSrc, setImgSrc] = useState<string>(ExImg); // 이미지 소스 상태
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const isFirstRender = useRef(true);
    const navigate = useNavigate();
    // 로딩 훅을 최상위에서 호출
    const loadingComponent = useLoading(isLoading);

    useImperativeHandle(ref, () => ({
        waitingPopupSuccess(id) {
            navigate(`/uptp-detail/${id}`);
        }
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentDay = await getItemByKey("currentDay");
                if (currentDay) {
                    setCurrentDay(currentDay);
                    setSelectedDay(currentDay);

                    const [monthEventResult, eventBanner,] = await Promise.allSettled([
                        membershipsServiceApi.getMonthlyEvent({month: currentDay.slice(0, 6)}, 'v1'),
                        // membershipsServiceApi.getLoyalMemberBenefit('v1'),
                        eventApi.getEventBanner({
                            dpYn: 'Y',
                            status: 'Y',
                            age: await getAgeCode(),
                            os: await getItemByKey("platform"),
                            gender: (await getItemByKey('gender')) || 'A'
                        }, 'v1'),
                    ]);
                    setMonthEvent(handlePromiseResult(monthEventResult, initialMonthEvent));
                    // setBasicBenefit(handlePromiseResult(basicBenefitResult).benefitPtnList);
                    // setVipBenefit(handlePromiseResult(vipBenefitResult).vipPtnList);
                    // setLoyalMemberBenefit(handlePromiseResult(loyalMemberBenefitResult).longTermList);
                    setEventBanner(handlePromiseResult(eventBanner).bannerList);
                    if (handlePromiseResult(monthEventResult)?.thisMonthImgUrl) {
                        setImgSrc(handlePromiseResult(monthEventResult)?.thisMonthImgUrl);
                    }
                    sendEvent("KAKAO_PIXEL", {param: "ParticipationEvent", tag: "Uplusmo"})
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false); // 로딩 상태 해제
                window.scrollTo(0, 0.1);
            }
        };
        fetchData();
        gtmPageLoad({url: 'uptp', contentGroup: '혜택|유플투쁠', moduleName: '날짜|일별혜택|중간배너|전체혜택', pageTitle: '유플투쁠'});
        brazeEventApi.benefitplus_page_enter();
    }, []);

    const getCurrentTabItems = () => {
        switch (selectedTab) {
            case '1':
                return basicBenefit;
            case '2':
                return vipBenefit;
            case '3':
                return loyalMemberBenefit;
            default:
                return [];
        }
    };

    useEffect(() => {
        if (selectedDay) {
            const fetchEventByDate = async () => {
                try {
                    const monthEventByDateResult = await membershipsServiceApi.getMonthlyEventByDate(selectedDay, 'v1');
                    setMonthEventByDate(monthEventByDateResult.body);
                } catch (error) {
                    console.error('Failed to fetch event by date:', error);
                }
            };
            fetchEventByDate();
        }
    }, [selectedDay]);

    useEffect(() => {
        setHasMore(true);
        setPage(1);
        if (selectedTab === '1') {
            fetchBasicBenefit(1);
        } else if (selectedTab === '2') {
            fetchVipBenefit(1);
        } else if (selectedTab === '3') {
            fetchLoyalMemberBenefit(1);
        }
    }, [selectedTab]);

    const fetchBasicBenefit = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page
        try {
            const requestParam: any = {
                pageNo: currentPage.toString()
            }

            membershipsServiceApi.getBasicBenefit(requestParam, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setBasicBenefit(res.body.benefitPtnList);
                        setPage(2);
                    } else {
                        const newBasicBenefitList = basicBenefit.concat(res.body.benefitPtnList);
                        setBasicBenefit(newBasicBenefitList);
                        setPage(currentPage + 1);
                    }

                    if (res.body.benefitPtnList.length === 0) {
                        setHasMore(false);
                    }
                }
            });

        } catch (error) {
            console.error('Failed to fetchBasicBenefit :', error);
        } finally {
            console.log('selectedTab', selectedTab);
            console.log('hasMore:', hasMore);
            console.log('page:', page);
        }
    }

    const fetchVipBenefit = async (pageParam?: number) => {
        const currentPage = pageParam || page
        try {
            const requestParam: any = {
                pageNo: currentPage.toString()
            }

            membershipsServiceApi.getVipBenefit(requestParam, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setVipBenefit(res.body.vipPtnList);
                        setPage(2);
                    } else {
                        const newVipBenefitList = vipBenefit.concat(res.body.vipPtnList);
                        setVipBenefit(newVipBenefitList);
                        setPage(currentPage + 1);
                    }

                    if (res.body.vipPtnList.length === 0) {
                        setHasMore(false);
                    }
                }
            });

        } catch (error) {
            console.error('Failed to fetchVipBenefit :', error);
        }
    }

    const fetchLoyalMemberBenefit = async (pageParam?: number) => {
        const currentPage = pageParam || page
        try {
            const requestParam: any = {
                pageNo: currentPage.toString()
            }
            membershipsServiceApi.getLoyalMemberBenefit(requestParam, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setLoyalMemberBenefit(res.body.longTermList);
                        setPage(2);
                    } else {
                        const newLoyalMemberBenefitList = loyalMemberBenefit.concat(res.body.longTermList);
                        setLoyalMemberBenefit(newLoyalMemberBenefitList);
                        setPage(currentPage + 1);
                    }

                    if (res.body.longTermList.length === 0) {
                        setHasMore(false);
                    }
                }
            });
        } catch (error) {
            console.error('Failed to fetchLoyalMemberBenefit :', error);
        }
    }


    // 로딩 중일 때는 로딩 컴포넌트만 렌더링
    if (isLoading) {
        return loadingComponent;
    }

    return (
        <>
            <InfiniteScroll
                next={selectedTab === '1' ? fetchBasicBenefit : selectedTab === '2' ? fetchVipBenefit : fetchLoyalMemberBenefit}
                hasMore={hasMore}
                loader={<BarcodeLoading/>}
                dataLength={Number(selectedTab === '1' ? basicBenefit.length : selectedTab === '2' ? vipBenefit.length : loyalMemberBenefit.length)}
                scrollableTarget={"window"}
            >

                <CommonLeftIconHeader
                    title={`${monthEvent.today ? parseInt(monthEvent.today.slice(4, 6)) + '월의 ' : null}유플투쁠 혜택`}/>

                {/* 이미지 예시 */}
                <div style={{height: "auto", width: "100%"}}>
                    <Image
                        customClass="uptpimg"
                        src={imgSrc}
                        width={"100%"}
                        // ratio={"1:1.24"}
                        height={"auto"}
                        alt="This Month Event Image"
                        srcFallback={ExImg}
                    />
                </div>

                <SectionCalendar monthEvent={monthEvent} currentDay={currentDay} selectedDay={selectedDay}
                                 setSelectedDay={setSelectedDay}/>

                <SectionHeadingListNoBtn3 monthEventData={monthEventByDate} currentDay={currentDay}/>
                <div style={{paddingTop: "12px"}}/>

                {/* ---------- 이벤트 배너 -------- */}
                {eventBanner && eventBanner.length > 0 && (
                    <div style={{padding: "20px 20px 24px"}}>
                        <SectionBannerInfo bannerList={eventBanner}/>
                    </div>
                )}
                <div style={{marginBottom: "8px"}}>
                    <CommonDivider/>
                </div>

                {/* 혜택 추천 */}
                <CommonHeadingTab selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
                <SectionHeadingTabList items={getCurrentTabItems()} selectedTab={selectedTab}/>

            </InfiniteScroll>
        </>
    );
});

export default UPTP;
