import React from 'react';
import {Box, TextSet} from '@lguuxe/2024_designsystem_uni';

interface SectionHeadingProps {
    mainType?: string;
    subType?: string;
    reAgreeContent?: string;
    addAgreeContent?: string;
}

const formatTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));
};

const SectionHeading: React.FC<SectionHeadingProps> = ({mainType, subType, reAgreeContent, addAgreeContent}) => {
    let title = "서비스 이용 약관 및 \n개인정보 동의서에 동의해 주세요";
    let description: string | null = "";

    if (mainType === 'additional') {
        if(reAgreeContent){
            title = reAgreeContent
        } else if(addAgreeContent) {
            title = addAgreeContent
        }
        // switch (subType) {
        //     case '필수':
        //         title = "변경된 개인정보 수집/이용 약관에 동의해 주세요";
        //         break;
        //     case '필수선택':
        //         description = "위치 정보 제공에 동의하고 내 주변의 혜택을 알림받으세요";
        //         break;
        //     case '선택':
        //         description = "위치정보에 동의하고 내 주변의 혜택 알림을 받아보세요!";
        //         break;
        //     default:
        //         break;
        // }
    } else if (subType) {
        switch (subType) {
            case '자사':
                description = "U+멤버십 앱에 가입하면 U+멤버십, 휴대폰결제, 쿠폰 등을 편리하게 이용할 수 있어요.";
                break;
            case '타사':
                description = "U+멤버십 앱에 가입하면 U+멤버십, 휴대폰결제, 쿠폰 등을 편리하게 이용할 수 있어요.\n단, 알뜰/다른 통신사/법인 고객은 휴대폰결제 서비스를 이용할 수 없어요.";
                break;
            case '14세 미만':
                description = "U+멤버십 앱에 가입하면 U+멤버십, 휴대폰결제, 쿠폰 등을 편리하게 이용할 수 있어요.\n단, 만 14세 미만 고객은 휴대폰결제 서비스를 이용할 수 없어요.";
                break;
            default:
                break;
        }
    }

    const formattedTitle = formatTextWithLineBreaks(title);
    const formattedDescription = description ? formatTextWithLineBreaks(description) : null;

    return (
        <Box type="2_trbl">
            <TextSet
                subComponent="Heading_2"
                text={{
                    title: <span style={{paddingBottom: "6px", color: "#181A1B"}}>{formattedTitle}</span>,
                    description: formattedDescription ? <span>{formattedDescription}</span> : undefined
                }}
                weight="bolder"
            />
        </Box>
    );
};

export default SectionHeading;
