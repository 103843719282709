// ****************************************************
// [배포] 4.마이 > VIP > VIP콕 사용내역 > VIP콕 상세 > 영화예매형_사용 전 (UMembershipVipDetails_3)
// ****************************************************
import React from "react";
import {Text} from "@lguuxe/2024_designsystem_uni";
import {CouponInfo} from "../../../pages/My/types";
import {formatDate} from "../../../utils/dateUtiles";

interface DateSectionProps {
    couponInfo: CouponInfo;
}

const DateSection: React.FC<DateSectionProps> = ({couponInfo}) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: " 0 20px",
                    margin: "4px 0 0"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0",
                        background: "#fff",
                        gap: "8px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            padding: "4px 0"
                        }}
                    >
                        <Text style={{flex: "1"}} typo="font_heading_5Sb" color="color_text_neutral_6">
                            발급일
                        </Text>
                        <Text typo="font_body_1M" color="color_text_neutral_4">
                            {formatDate(couponInfo.regDate)}
                        </Text>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            padding: "4px 0"
                        }}
                    >
                        <Text style={{flex: "1"}} typo="font_heading_5Sb" color="color_text_neutral_6">
                            사용 기간
                        </Text>
                        <Text style={{textAlign: "right"}} typo="font_body_1M" color="color_text_neutral_4">
                            {formatDate(couponInfo.expStartDate)}<br/>
                            ~ {formatDate(couponInfo.expEndDate)}
                        </Text>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            padding: "4px 0"
                        }}
                    >
                        <Text style={{flex: "1"}} typo="font_heading_5Sb" color="color_text_neutral_6">
                            제휴사
                        </Text>
                        <Text typo="font_body_1M" color="color_text_neutral_4">
                            {couponInfo.ptnName}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DateSection;
