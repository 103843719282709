// ****************************************************
// [배포] 1.혜택 - 유플투쁠 (응모형) [응모작성]
// ****************************************************

import React, {useEffect, useState} from 'react';
import {TextArea} from "@lguuxe/2024_designsystem_uni";
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonHeading from "../../components/Common/CommonHeading";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import {useLocation, useNavigate} from "react-router-dom";
import BS_Entry from "../../components/UPTP/BS_Entry";
import CommonPopup from "../../components/Common/CommonPopup";
import membershipsService from "../../api/gatewayApi/memberships-service";
import {getItemByKey} from "../../utils/indexedDB";
import {gtmEventSubmit} from "../../api/gtm";
import useDebounce from "../../hooks/useDebounce";

const UPTPEntry: React.FC = () => {
    const [isEntryOpen, setIsEntryOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [reasons, setReasons] = useState('');
    const [cardLevel, setCardLevel] = useState('');
    const [longTermCode, setLongTermCode] = useState('');
    const [youthYn, setYouthYn] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const info = location.state;

    useEffect(() => {
        const fetchData = async () => {
            setCardLevel(await getItemByKey("cardLevel"))
            setLongTermCode(await getItemByKey("longTermCode"))
            setYouthYn(await getItemByKey("youthYn"))
        }
        fetchData()
    }, []);

    const handleClose = () => {
        navigate(-1); // 이전 페이지로 이동
    };

    const openBottomSheet = () => {
        setIsEntryOpen(true);
    };

    const handleSubmit = () => {
        navigate(-2);
    };

    const handleReasonsChange = (value: string) => {
        setReasons(value);
    };

    // 응모하기 버튼 클릭 시 API 호출 함수
    const handleApply = useDebounce(async (infoCollectYn: string, infoHandlingYn: string) => {
        try {
            const params = {
                reasons: reasons, // 응모 사유
                infoCollectYn: infoCollectYn, // 개인정보수집이용 동의 여부 ('Y' or 'N')
                infoHandlingYn: infoHandlingYn, // 개인정보취급위탁 동의 여부 ('Y' or 'N')
                cardLevel: cardLevel, // 멤버십 등급 코드 ('6', '7', '8')
                longTermCode: longTermCode, // 장기고객 코드 ('NG1', 'NG2', 'NG3')
                youthYn: youthYn, // 유쓰 여부 ('Y' or 'N')
            };

            const response = await membershipsService.getUPTPApply(params, info.proId, 'v1');
            if (response.header.resultCode === '0000') {
                const proType = info.proType === '138001' ? '쿠폰형' : info.proType === '138002' ? '응모형' : '바코드형';
                gtmEventSubmit({
                    eventName: info.proTitle,
                    eventPeriod: `${info.proStartDate.slice(0, 10)} ~ ${info.proEndDate.slice(0, 10)}`,
                    eventType: proType,
                    eventId: info.proId
                });
                setShowPopup(true);
                setIsEntryOpen(false);
            } else {
                setPopupMessage(response.header.message);
                setIsEntryOpen(false);
                setShowErrorPopup(true);
            }

        } catch (error) {
            console.error(error);
            // 에러 처리 로직 추가
        }
    });

    return (
        <>
            {isEntryOpen && (
                <BS_Entry
                    setIsEntryOpen={setIsEntryOpen}
                    info={info}
                    handleApply={handleApply} // handleApply 함수를 전달
                />
            )}
            <CommonRightIconHeader icon={"close_line"} title={"참여하기"} onClick={handleClose}/>

            <CommonHeading title={"당첨되고 싶은 이유를 적어주세요"}/>

            <div style={{padding: "24px 20px"}}>
                <TextArea
                    maxLength={200}
                    placeholder="당첨되고 싶은 이유를 최대 200자 적어주세요."
                    rows={10}
                    status="default"
                    value={reasons}
                    onChange={(e) => handleReasonsChange(e.value)}
                />
            </div>

            <div style={{position: "fixed", bottom: "0", width: "100%"}}>
                <CommonCTAColumn buttonText={"참여하기"} handleSummit={openBottomSheet}/>
            </div>
            {showPopup && (
                <CommonPopup
                    title={"참여완료"}
                    description={
                        "당첨 결과는 [알림] 또는 [이벤트] -> [당첨 확인]에서 확인하세요."
                    }
                    onSubmit={handleSubmit}
                    submitText={"확인"}
                />
            )}
            {showErrorPopup && (
                <CommonPopup
                    // title={"참여한 이벤트"}
                    description={
                        popupMessage
                    }
                    onSubmit={handleSubmit}
                    submitText={"확인"}
                />
            )}
        </>
    );
};

export default UPTPEntry;
