import React, {useEffect, useRef, useState} from 'react';
import {Header, IconButton, List, Search, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {_OnChangeEvent} from '@lguuxe/2024_designsystem_uni/dist/esm/types/components/Search/SearchTypes';
import {ListItem} from "../../pages/Search/Search";
import {addItem, getItemByKey, removeItemByKey} from "../../utils/indexedDB";
import useDebounce from "../../hooks/useDebounce";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {searchApi} from "../../api/gatewayApi/other";
import {SearchResults} from "../../pages/Search/ResultDetail";
import CommonNodata from "./CommonNodata";
import CommonDivider from "./CommonDivider";
import RecommandSearch from "../Search/RecommandSearch";
import useLoading from "../../hooks/useLoading";
import {gtmViewSearchResults} from "../../api/gtm";

interface CommonSearchProps {
    onSearch: (resut: SearchResults, search: string) => void;
    input?: string
}

const CommonSearch: React.FC<CommonSearchProps> = ({
                                                       onSearch,
                                                       input
                                                   }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [items, setItems] = useState<ListItem[]>([]);
    const [storedSearchQueries, setStoredSearchQueries] = useState<string[]>([]);
    const [showSearchEntry, setShowSearchEntry] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [nodata, setNodata] = useState<boolean>(false);
    const {showSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            const recentSearch = await getItemByKey('recentSearch');
            setStoredSearchQueries(recentSearch || []);
        }
        init()

    }, []);

    useEffect(() => {
        if (input) {
            setInputValue(input)
        }
    }, [input]);

    const handleInputChange = (event: _OnChangeEvent) => {
        console.log(event)
        const value = event.value;
        if (value !== inputValue) {
            setShowSearchEntry(true)
        }
        if (value === '') {
            setShowSearchEntry(false)
        }

        setInputValue(value);
        const filteredItems = storedSearchQueries
            .filter((query) => query.includes(value))
            .map((query) => ({
                onClick: () => handleSearch(query),
                leftSlot: [
                    <TextSet
                        text={{
                            title: (
                                <Text typo='font_body_1B' color='color_text_primary'>
                                    {query}
                                </Text>
                            ),
                        }}
                        subComponent={'Body_2'}
                    />,
                ],
                linkIconOnlyId: "empty"
            }));

        setItems(filteredItems);

    };

    const addRecent = async (term: string) => {
        let recentSearch = await getItemByKey("recentSearch");

        if (recentSearch) {
            recentSearch = [term, ...recentSearch.filter((t: string) => t !== term)];
        } else {
            recentSearch = [term];
        }

        await addItem("recentSearch", recentSearch);
        setStoredSearchQueries(recentSearch);
    };

    const handleBackButtonClick = async () => {
        await removeItemByKey('searchQuery');
        setInputValue('');
        console.log(showSearchEntry, nodata)
        if (showSearchEntry || nodata) {
            setShowSearchEntry(false)
            setNodata(false)
        } else {
            navigate(-1)
        }
    };

    const handleSearch = useDebounce(async (inputValue: string) => {
        setIsLoading(true);
        if (inputValue.trim() === '') {
            showSnackbar('검색어를 입력해 주세요')
        } else {
            setShowSearchEntry(false);
            await addRecent(inputValue);

            await addItem('searchQuery', inputValue);
            try {
                const response = await searchApi.getSearch({
                    keyword: inputValue,
                }, 'v1');
                if (response.header.resultCode === '0000') {
                    if (response.body.searchResultList.sectionCount === "0") {
                        setNodata(true);
                    } else {
                        onSearch(response.body.searchResultList, inputValue)
                        setNodata(false);
                    }

                }
            } catch (error) {
                console.error('Failed to fetch search results:', error);
            } finally {
                setIsLoading(false)
                gtmViewSearchResults(inputValue)
            }
        }
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            console.log('Enter key was pressed!', inputValue);
            handleSearch(inputValue)
        }
    };

    return (
        <>
            {useLoading(isLoading)}
            <Header style={{position: 'fixed', width: '100%', background: 'white', zIndex: 300}}>
                <Header.LeftSlot>
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='chevron_left_line'
                        onClick={handleBackButtonClick}
                        noStates
                    />
                </Header.LeftSlot>
                <Header.CenterSlot>
                    <Search
                        value={inputValue}
                        ref={inputRef}  // inputRef를 Search 컴포넌트에 전달
                        fill='none'
                        placeholder='혜택/쿠폰/제휴사 등을 검색하세요'
                        onChange={handleInputChange}
                        size='medium'
                        onKeyDown={handleKeyDown}
                    />
                </Header.CenterSlot>
                <Header.RightSlot>
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='search_line'
                        onClick={() => handleSearch(inputValue)}
                    />
                </Header.RightSlot>
            </Header>

            {showSearchEntry && (
                <div style={overlayStyle}>
                    <div style={modalStyle}>
                        {/* 검색 결과 리스트 */}
                        <List
                            fill='none'
                            highlightKeyword={inputValue}
                            highlightColor='#E6007E'
                            items={items}
                            name='list_name'
                            size='medium'
                            type='view'
                        />
                    </div>
                </div>
            )}

            {nodata &&
                <>
                    <div style={overlayStyle}>
                        <div style={modalStyle}>
                            <div
                                style={{
                                    padding: '24px 0 24px 0'
                                }}
                            >
                                <CommonNodata type={"search"} text={"검색 결과가 없어요"}/>
                            </div>
                            <CommonDivider/>
                            <RecommandSearch onSearch={handleSearch}/>
                        </div>
                    </div>
                </>
            }


            {/*{nodata &&*/}
            {/*    <div style={contentStyle}>*/}
            {/*        <div style={overlayStyle}>*/}
            {/*            <div style={modalStyle}>*/}
            {/*                <div*/}
            {/*                    style={{*/}
            {/*                        padding: '24px 0 24px 0'*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <CommonNodata type={"search"} text={"검색 결과가 없어요"}/>*/}
            {/*                </div>*/}
            {/*                <CommonDivider/>*/}
            {/*                <RecommandSearch onSearch={handleSearch}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    )
};

export default CommonSearch;

// 스타일 정의
const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: '3.5rem',  // Header 높이만큼 아래로 오프셋 설정 (헤더 아래부터 시작)
    left: 0,
    width: '100%',
    height: 'calc(100% - 3.5rem)',  // Header 높이만큼 빼서 계산된 높이
    // height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,  // 기존 화면을 덮을 수 있도록 z-index를 높게 설정
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // pointerEvents: 'auto',  // 모달 안에서는 클릭 이벤트가 발생하게 설정
    userSelect: 'none',     // 오버레이 영역 드래그 방지
    touchAction: 'none',    // 오버레이 영역 터치 드래그 방지
};

const modalStyle: React.CSSProperties = {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    pointerEvents: 'all',   // 모달 내부 요소에만 클릭 이벤트를 허용
    userSelect: 'text',     // 내부 컴포넌트 드래그 허용
    touchAction: 'auto',    // 내부 컴포넌트 터치 드래그 허용
    overflowY: 'auto',      // 내용이 넘칠 경우 스크롤 표시
};

const contentStyle: React.CSSProperties = {
    marginTop: '3.6rem',
    padding: '20px',
};