import React, {useEffect, useState} from 'react';
import {Button, Icon, Text} from "@lguuxe/2024_designsystem_uni";
import BS_List from "../Common/BS_List";
import {getItemByKey} from "../../utils/indexedDB";

interface SectionSortingProps {
    setFilter: any;
    historyCount: number;
}

const ORDER_LIST = [
    {
        id: '1',
        label: '2024.09',
    },
    {
        id: '2',
        label: '2024.10',
    },
    {
        id: '3',
        label: '2024.11',
    },
    {
        id: '4',
        label: '2024.12',
    }
]

const SectionSorting: React.FC<SectionSortingProps> = ({setFilter, historyCount}) => {
    const [showOrderBs, setShowOrderBs] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const fetchDate = async () => {
            let currentDate: string = await getItemByKey('currentDay');
            currentDate = currentDate.substring(0, 6);
            setCurrentDate(currentDate);
            setSelectedOrderId(getDropdownValue(currentDate));
        }
        fetchDate();
    }, []);

    const handleOrderChange = (id: string) => {
        let filterValue = '';
        setSelectedOrderId(id);
        switch (id) {
            case '1':
                filterValue = '202409';
                break;
            case '2':
                filterValue = '202410';
                break;
            case '3':
                filterValue = '202411';
                break;
            case '4':
                filterValue = '202412';
                break;
            default:
                filterValue = '';
                break;
        }
        setFilter(filterValue);
    };

    const getDropdownValue = (currentDate: string): string => {
        switch (currentDate) {
            case '202409':
                return '1';
            case '202410':
                return '2';
            case '202411':
                return '3';
            case '202412':
                return '4';
            default:
                return '1';
        }
    }
    return (
        <>
            <div
                style={{
                    padding: "12px 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text typo="font_heading_5B" color="color_text_neutral_6"></Text> {/* font_heading_5sb */}
                <div style={{width: 'auto'}}>
                    <Button fill="ghost" size="small" color="secondary" onClick={() => setShowOrderBs(true)}>

                        <Text as="span" textAlign="center" style={{marginRight: '0.25rem'}}>
                            {ORDER_LIST.find(item => item.id === selectedOrderId)?.label}
                        </Text>
                        <Icon
                            name='chevron_down_small_line'
                            color='#7f8a94'
                            size='large'
                        />
                    </Button>
                </div>
            </div>
            <BS_List title={"월 선택"} dataList={ORDER_LIST} onClose={() => setShowOrderBs(false)}
                     onSelected={handleOrderChange} isOpen={showOrderBs} checkedItem={selectedOrderId}/>
        </>
    )
}

export default SectionSorting;
