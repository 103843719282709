import React, {useEffect, useRef, useState} from "react";
import {Button, Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import {Event, SSPResponse} from "../../pages/Home/types";
import {useNavigate} from "react-router-dom";
import {sendEventReturn} from "../../api/nativeBridge";
import useLandingPage from "../../hooks/useLandingPage";

// EventInfo 컴포넌트의 props 정의
interface EventInfoProps {
    events: Event[];
    sspData?: SSPResponse; // SSPResponse 데이터를 받을 수 있도록 추가
}

const EventInfo: React.FC<EventInfoProps> = ({events, sspData}) => {
    const navigate = useNavigate();  // useNavigate hook
    const {landingPage} = useLandingPage();
    const sspItemsRef = useRef<HTMLDivElement>(null);
    const [hasBeenVisible, setHasBeenVisible] = useState(false); // 노출 여부를 관리하는 상태

    useEffect(() => {
        if(sspData) {
            if (sspItemsRef.current && !hasBeenVisible && sspData?.creatives?.length > 0) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach(entry => {
                            if (entry.intersectionRatio >= 0.5) {
                                console.log("sspItems 컴포넌트가 50% 이상 노출되었습니다.");
                                setHasBeenVisible(true);

                                // impression_urls 리스트의 URL을 순차적으로 호출
                                sspData.creatives.forEach(creative => {
                                    creative.impression_urls?.forEach(url => {
                                        fetch(url)
                                            .then(response => console.log(`Called ${url}: `, response))
                                            .catch(error => console.error(`Error calling ${url}: `, error));
                                    });
                                });
                            }
                        });
                    },
                    {
                        threshold: [0.5], // 50% 노출되었을 때를 기준으로 설정
                    }
                );

                observer.observe(sspItemsRef.current);

                return () => {
                    if (sspItemsRef.current) {
                        observer.unobserve(sspItemsRef.current);
                    }
                };
            }
        }
    }, [sspData, hasBeenVisible]);// sspData 또는 hasBeenVisible 상태가 변경될 때마다 실행

    const onAdClick = (clickUrls: string[], landingUrl: string) => {
        // click_urls 리스트의 URL을 순차적으로 호출
        clickUrls.forEach(url => {
            fetch(url)
                .then(response => console.log(`Clicked ${url}: `, response))
                .catch(error => console.error(`Error clicking ${url}: `, error));
        });

        // 외부 브라우저로 이동
        sendEventReturn('EXTERNAL_CALL', {type: '1', url: landingUrl});
    };

    const onEventClick = (event: Event) => () => {
        landingPage(event.evtTargetType, event.evtTargetLink, navigate, event.evtTitle);
    }

    // SSP 데이터에서 IMAGE 타입만 필터링
    const sspItems = sspData?.exist
        ? sspData.creatives.map(creative => ({
            leftSlot: [
                <Image
                    src={creative.image_url || "https://picsum.photos/seed/default/200/300"}
                    height="auto"
                    width="100%"
                />,
            ],
            linkIconOnlyId: "id",
            onClick: () => onAdClick(creative.click_urls || [], creative.landing_url || "#")
        }))
        : [];

    // 기존 이벤트 리스트와 SSP 리스트를 병합
    const items = [
        ...events.map(event => ({
            onClick: onEventClick(event),
            leftSlot: [
                <Image
                    src={event.evtThumbImgUrl || "https://picsum.photos/seed/default/200/300"}
                    ratio="1:1"
                    width="48px"
                    rounded={"50%"}
                />,
                <div
                    style={{
                        gap: "2px",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Text typo="font_body_1M" color="color_text_neutral_3" style={{fontSize: "12px"}}>
                        {event.evtSummary || "상세 설명"}
                    </Text>
                    <Text typo="font_detail_2M" color="color_text_neutral_5" style={{fontSize: "16px"}}>
                        {event.evtTitle || "타이틀"}
                    </Text>
                </div>
            ],
            linkIconOnlyId: "empty"
        })),
        ...sspItems // SSP 데이터를 병합
    ];

    return (
        <div style={{padding: "0"}} ref={sspItemsRef}>
            <div
                style={{
                    width: "100%",
                    borderRadius: "12px",
                    border: "1px solid #DCE0E5",
                    backgroundColor: "#FFFFFF"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                        padding: "12px 0 0"
                    }}
                >
                    <List
                        isSlotClickable={true}
                        type="view"
                        fill="none"
                        items={items}
                    />
                    <div style={{display: "flex", justifyContent: "center", paddingTop: "9px"}}>
                        <div
                            style={{
                                width: "calc(100% - 40px)",
                                height: "1px",
                                backgroundColor: "#E7EBEE"
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "46px"
                        }}
                    >
                        <Button fill="ghost" color="neutral" rightIcon="chevron_right_small_line"
                                onClick={() => navigate("/event")}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'이벤트 블록 - 링크 클릭'}
                                data-gtm-event-label={'컨텐츠 : 이벤트 더보기'}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'이벤트 블록'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={'이벤트 더보기'}>
                            <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                더보기
                            </Text>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventInfo;
