import React from "react";
import MembershipBenefitSection from "../MembershipBenefitSection";
import CommonDivider from "../../Common/CommonDivider";
import m1 from "../../../images/guide/usage/mission/1.webp";
import m2 from "../../../images/guide/usage/mission/2.webp";
import m3 from "../../../images/guide/usage/mission/3.webp";
import m4 from "../../../images/guide/usage/mission/4.webp";
import m5 from "../../../images/guide/usage/mission/5.webp";

const MissionTab: React.FC = () => {
    return (
        <>
            <MembershipBenefitSection
                title="U+ 멤버십 미션 참여하기"
                description="매일 출석체크하고 다양한 경품이 가득한 이벤트에 응모해 보세요."
                imageSrc={m1}
                additionalContent={[
                    {
                        text: "U+멤버십 혜택을 사용하고 킹배지를 모아보세요.U+모바일을 사용 중인 친구와 킹배지를 주고받을 수 있어요.",
                        imageSrc: m2,
                        imageAlt: "이미지 설명 1"
                    }]}
            />
            <CommonDivider/>

            <MembershipBenefitSection
                title="리워드 적립하기"
                description="다양한 미션과 재미있는 콘텐츠 참여를 통해 리워드를 쌓을 수 있어요."
                imageSrc={m3}
            />
            <CommonDivider/>

            <MembershipBenefitSection
                title="리워드 사용하기"
                description="리워드로 U+통신비 할인 쿠폰부터 커피, 치킨, 피자 쿠폰까지 다양한 상품을 원하는 만큼 구매할 수 있어요.\n
※ U+알뜰폰 요금제는 U+통신비 할인 쿠폰을 이용하실 수 없어요."
                imageSrc={m4}
            />
            <CommonDivider/>
            <MembershipBenefitSection
                title="리워드 조회하기"
                description="리워드 적립/사용내역 메뉴에서 리워드 적립 이력, U+ 통신비 할인 쿠폰 및 모바일 쿠폰 구매, 사용 현황을 확인할 수 있어요. "
                imageSrc={m5}
            />
            <CommonDivider/>
        </>
    );
};

export default MissionTab;
