import React, {useEffect, useState} from 'react';
import {Box, Checkbox, Icon, IconButton, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonDividerThin from '../Common/CommonDividerThin';
import {useNavigate} from "react-router-dom";
import {Agreement} from "../../pages/Agreements/types";

interface SectionChkListProps {
    agreementList: Agreement[];
    allCheck: boolean;
    onAgreementChange: (termId: string, isChecked: boolean) => void;
    onAllAgreementsChange: (isChecked: boolean) => void;
    onTermClick: (termId: string, disable: boolean) => void;
    subType?: string;
}

const SectionChkList: React.FC<SectionChkListProps> = ({
                                                           agreementList,
                                                           allCheck,
                                                           onAgreementChange,
                                                           onAllAgreementsChange,
                                                           onTermClick,
                                                           subType
                                                       }) => {
    const navigate = useNavigate();
    const [activeTerm, setActiveTerm] = React.useState<boolean>(false);
    const [benefitAgreement, setBenefitAgreement] = useState<Agreement>()

    useEffect(() => {
        // 125003 인 약관 따로 저장
        setBenefitAgreement(agreementList.find((agreement) => agreement.termsType === '125003' && !agreement.isRequired))

    }, [agreementList]);

    const handleCheckboxChange = (termId: string) => {
        if (termId === benefitAgreement?.termId && !activeTerm) {
            return;
        }
        const agreement = agreementList.find((term) => term.termId === termId);
        if (agreement) {
            onAgreementChange(termId, !agreement.selected);
            if (agreement.termsType === '125001' && !agreement.isRequired && !agreement.selected) { //
                setActiveTerm(true);
                if (benefitAgreement) { // 개인정보 수집 동의를 체크하면 고객 광고 수신 약관을 체크한다
                    onAgreementChange(benefitAgreement.termId, true)
                }
            }
            if (agreement.termsType === '125001' && !agreement.isRequired && agreement.selected) {
                setActiveTerm(false);
                if (benefitAgreement) { //개인정보 수집 동의를 체크해제하면 고객 광고 수신 약관을 체크해제한다
                    onAgreementChange(benefitAgreement.termId, false)
                }
            }
        }
    };

    const handleAllAgreementsChange = () => {
        onAllAgreementsChange(!allCheck);
        if (!allCheck) {
            setActiveTerm(true);
        } else {
            setActiveTerm(false);
        }
    };

    const getTermType = (isRequired: boolean): string => {
        return isRequired ? '필수' : '선택';
    };

    const handleTermClick = (agreement: Agreement) => {
        if (agreement.termsType === '125003') {
            onTermClick(agreement.termId, !activeTerm);
        } else {
            onTermClick(agreement.termId, false);
        }
    }

    return (
        <Box type="3_tb">
            <List
                fill="none"
                size="large"
                items={[
                    {
                        checked: allCheck,
                        text: {
                            title: (
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: <span style={{color: "#181A1B"}}>모든 이용약관에 동의</span>,
                                        description: subType !== "필수" ? "모든 약관에 동의하면 선택동의를 포함하여 모두 동의됩니다. 선택동의를 하지 않더라도 서비스에 가입할 수 있습니다." : ""
                                    }}
                                    weight="bolder"
                                />
                            ),
                        },
                    },
                ]}
                type="checkbox"
                onChange={handleAllAgreementsChange}
            />
            <Box style={{padding: "0 20px"}}>
                <CommonDividerThin/>
            </Box>

            <Box style={{padding: "0"}}>
                {agreementList
                    .filter((agreement) => agreement.termsType !== '125003' || agreement.isRequired)
                    .map((agreement, index) => (
                        <React.Fragment key={agreement.termId}>
                            {/* Render the current agreement */}
                            <List
                                fill="none"
                                size="medium"
                                type="checkbox"
                                items={[
                                    {
                                        checked: agreement.selected,
                                        id: agreement.termId,
                                        leftSlot: (
                                            <div style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                                <TextSet
                                                    subComponent="Body_1"
                                                    text={{
                                                        title: (
                                                            <span style={{color: "#181A1B"}}>
                                                            [{getTermType(agreement.isRequired)}] {agreement.termName}
                                                        </span>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ),
                                        rightSlot: (
                                            <IconButton
                                                color='neutral'
                                                fill='icon'
                                                size={"large"}
                                                icon='chevron_right_small_line'
                                                onClick={() => handleTermClick(agreement)}
                                                noStates
                                            />
                                        ),
                                    },
                                ]}
                                onChange={(e) => handleCheckboxChange(e.id)}
                            />

                            {/*// 125001 아래에 125003을 그린다*/}
                            {agreement.termsType === '125001' && !agreement.isRequired && benefitAgreement && (
                                <div
                                    style={{
                                        backgroundColor: "#F3F5F6",
                                        borderRadius: "12px",
                                        margin: "0 20px",
                                        padding: "12px 20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                    onClick={() => handleCheckboxChange(benefitAgreement?.termId)}
                                >
                                    <div style={{display: "flex", gap: "12px"}}>
                                        <Checkbox fill="icon" checked={benefitAgreement.selected}
                                                  disabled={!activeTerm}
                                                  preventBubble={true}
                                            // onChange={() => handleCheckboxChange(benefitAgreement?.termId)}
                                        />
                                        <TextSet
                                            subComponent="Body_1"
                                            text={{
                                                title: (
                                                    <span style={{color: "#181A1B"}}>
                                                            [{getTermType(benefitAgreement.isRequired)}] {benefitAgreement.termName}
                                                        </span>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <Icon color="#7f8a94" name="chevron_right_small_line" size="24px"
                                          onClick={() => handleTermClick(benefitAgreement)}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}

                {/* 옵션 125001 이 없을경우 옵션 125003을 표시해준다.*/}
                {!agreementList.some(
                    (agreement) => agreement.termsType === '125001' && !agreement.isRequired) && benefitAgreement && (
                    <div
                        style={{
                            backgroundColor: "#F3F5F6",
                            borderRadius: "12px",
                            margin: "0 20px",
                            padding: "12px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                        onClick={() => handleCheckboxChange(benefitAgreement?.termId)}
                    >
                        <div style={{display: "flex", gap: "12px"}}>
                            <Checkbox fill="icon"
                                      checked={benefitAgreement.selected}
                                      preventBubble={true}
                            />
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <span
                                            style={{color: "#181A1B"}}>[{getTermType(benefitAgreement.isRequired)}] {benefitAgreement.termName}
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                        <Icon color="#7f8a94" name="chevron_right_small_line" size="24px"
                              onClick={() => handleTermClick(benefitAgreement)}
                        />
                    </div>
                )}
            </Box>
        </Box>
    );
};

export default SectionChkList;