import React from 'react';
import {Button, Icon, Text} from '@lguuxe/2024_designsystem_uni';

import '../../css/style.css';

interface SnackbarProps {
    text: string;
    buttonText?: string;
    buttonLinkUrl?: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({text, buttonText, buttonLinkUrl}) => {

    return (
        <div className='snackbar' style={{margin: '0, 35px', zIndex: 1001}}>
            <Text typo='font_body_2M' color='color_text_onNeutral_low' textAlign='center'>
                {text.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br/>
                    </React.Fragment>
                ))}
            </Text>
            {buttonLinkUrl && buttonText && (
                <Button
                    color='secondary'
                    fill='ghost'
                    style={{
                        display: 'flex',
                        gap: '8px',
                    }}
                    onClick={buttonLinkUrl}
                >
                    <Text style={{padding: '0, 8px'}} typo='font_label_3Sb' color='color_text_onNeutral_low'>
                        {buttonText}
                    </Text>
                    <Icon color='#7F8A94' name='chevron_right_small_line' size='16px'/>
                </Button>
            )}
        </div>
    );
};

export default Snackbar;
