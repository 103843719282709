// types.ts
export type session = {
    memberStatus: string;  // M
    serviceNotiYn: string;  // M
    maketingInfoYn: string;  // M
    sessionKey: string;  // M
    serviceYackReqStatus?: string;  // O
    memberId?: string;  // O
    subNo?: string;  // O
    subCustNo?: string;  // O
    customerName?: string;  // O
    birthday?: string;  // O
    sex?: string;  // O
    telcoCode?: string;  // O
    payExistYn?: string;  // O
    updateStatus?: string;  // O
    lossPhone?: string;  // O
    emulUse?: string;  // O
    moneyMeUse?: string;  // O
    dataDogSessionSampleRate?: string;  // O
}

export type Msg = {
    msgId: string;
    memberId?: string;
    msgTypeCode: string;
    msgTitle: string;
    msgDetail: string;
    linkType?: string;
    linkUrl?: string;
}

export type UptpEvent = {
    evtId: string;
    mainImgUrl: string;
    thumbImgUrl?: string;
    startDate: string;
    endDate: string;
    targetLink: string;
    categoryId: string;
    categoryName: string;
    isNxtEvt: string;
    nxtEvtTitle?: string;
    nxtEvtDescription?: string;
}

export type UptpData = {
    currentMonth: string;
    uptpList: UptpEvent[]
}

export type RecentUsedBenefit = {
    ptnId: string;
    ptnLogoImgUrl: string;
    pntName: string;
}

export type Tag = {
    tagId: string;
    tagName: string;
    tagType: string;
}

export type Movie = {
    theater: string;
    movieCode: string;
    movieNm: string;
    rate: string;
    openDate?: string;
    watchGrd?: string;
    posterImgUrl?: string;
}

export type Event = {
    evtThumbImgUrl: string;
    evtTitle: string;
    evtSummary: string;
    evtStartDate: string;
    evtEndDate: string;
    evtTargetType: string;
    evtTargetLink: string;
};

export type Benefit = {
    ptnId: string;
    ptnLogoImgUrl: string;
    ptnName: string;
    benefitType: string;
    benefitSummary: string;
    ptnBranchName?: string;
    startDate?: string;
    endDate?: string;
    distance?: string;
};

export type MembershipInfo = {
    cardLevel: string; // 등급 코드 (6: VVIP, 7: 우수, 8: VIP)
    cardLevelName: string; // 등급 명 (VVIP, VIP, 우수, 일반)
    isVIP: string; // VIP 이상 등급 여부 (VIP 이상: Y)
    longTermCode: string; // 장기고객 대상 코드 (NG1: 2년 이상, NG2: 5년 이상, NG3: 10년 이상)
    longTermName: string; // 장기고객 명
    youthYn: string; // 유쓰 대상자 여부
    youthName: string; // 유쓰 명칭
    totalDiscount: string; // 당해년도 누적할인 금액
    thisMonthDiscount: string; // 당월 누적할인 금액
    barcode: string; // 고정 바코드 번호
    token: string; // 고객인증 API에서 받아온 token
    encKey: string; // 고객인증 API에서 받아온 enckey
};

export const defaultMembershipInfo: MembershipInfo = {
    cardLevel: '',
    cardLevelName: '',
    isVIP: '',
    longTermCode: '',
    longTermName: '',
    youthYn: '',
    youthName: '',
    totalDiscount: '',
    thisMonthDiscount: '',
    barcode: '',
    token: '',
    encKey: '',
};
export const initialMsg: Msg = {
    msgId: "default_id",
    msgTypeCode: "121001", // 기본형(공지) 코드
    msgTitle: "기본 메시지 제목",
    msgDetail: "이 메시지는 기본 메시지입니다.",
    linkType: "117001", // 기본 외부 링크 타입
    linkUrl: "https://default-url.com", // 기본 URL
};

export type SSPResponse = {
    exist: boolean;
    creatives: Array<{
        type: 'IMAGE' | 'ERROR';
        image_url?: string;  // IMAGE일 때만 존재
        background_color?: string;  // IMAGE일 때만 존재
        landing_url?: string;  // IMAGE일 때만 존재
        impression_urls?: string[];  // IMAGE일 때만 존재
        click_urls?: string[];  // IMAGE일 때만 존재
        logo_image_url?: string;  // IMAGE일 때만 존재
        logo_landing_url?: string;  // IMAGE일 때만 존재
        status?: string;  // ERROR일 때만 존재
        message?: string;  // ERROR일 때만 존재
    }>;
};

export type ProConditionInfo = {
    conditionsYn: string;
    conditionsMsg: string | null;
}