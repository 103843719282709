import React, {useEffect, useRef, useState} from "react";
import {settingApi} from "../../api/gatewayApi/settingAndTerms";
import {sendEventReturn} from "../../api/nativeBridge";
import BS_Location from "./BS_Location";
import CommonPopup from "../Common/CommonPopup";
import {useNavigate} from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import {gtmCommonComp} from "../../api/gtm";
import {TermsListItem} from "../../pages/Setting/types";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {addItem} from "../../utils/indexedDB";

export interface GpsPopupHandles {
    triggerGpsPopup: () => void;
}

interface LocationComponentProps {
    isOpen: boolean;
    onClose: () => void;
    ptnId?: string;
}

const CommonLocation: React.FC<LocationComponentProps> = ({isOpen, onClose, ptnId}) => {
    const isFirstRender = useRef(true);
    const [showGPSPopup, setShowGPSPopup] = useState(false);
    const [showLocationTermBS, setShowLocationTermBS] = useState(false);
    const {showSnackbar} = useSnackbar();
    const [term, setTerm] = useState<TermsListItem>({
        termName: '',
        termId: '11',
        termsType: '125003',
        termYn: '',
        dpOrder: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        const triggerGpsPopupEvent = (event: CustomEvent) => {
            setShowGPSPopup(true);
        }

        window.addEventListener('triggerGpsPopup', triggerGpsPopupEvent as EventListener);

        return () => {
            window.removeEventListener('triggerGpsPopup', triggerGpsPopupEvent as EventListener)
        }
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (isOpen) {
            fetchTermCheck();
        }
    }, [isOpen]);

    useEffect(() => {
        if (term.termYn === '') {
            return;
        }
        if (term) {
            if (term.termYn === 'Y') {
                sendEventReturn('GET_GRANT_LOCATION', {ptnId: ptnId ?? ''});
                gtmCommonComp('위치기반  서비스 동의 변경', 'Y');
                onClose();

            } else {
                setShowLocationTermBS(true)
            }
        }
    }, [term]);

    const handleTermsAgree = useDebounce(() => {
        fetchTermAgree();
    });

    const fetchTermCheck = async () => {
        try {
            const res = await settingApi.getTermsPersonalInfo({}, 'v1');
            if (res.header.resultCode !== '0000') {
                console.log('fetchLocationAgree error');
            }
            const term: TermsListItem = res.body.termsList.find((item: any) => {
                return item.termsType === '125004';
            });

            if (term) {
                console.log('term', term);
                setTerm(term);
            } else {
                showSnackbar('위치 기반 서비스 약관을 받아올 수 없어요');
                onClose();
            }
        } catch (e) {
            console.log('fetchLocationAgree error');
        }
    }

    const fetchTermAgree = async () => {
        try {
            const res = await settingApi.putTermsPersonalInfo({termId: term.termId, termYn: 'Y'}, 'v1');
            if (res.header.resultCode === '0000') {
                // sendEventReturn('CALL_LOCATION_POPUP', {});
                sendEventReturn('GET_GRANT_LOCATION', {ptnId: ptnId ?? ''})
                await addItem('geoAgreeYn', 'Y');
                (window as any).digitalData.userInfo.ad_agreement_app = 'Y';
            } else {
                showSnackbar('위치 기반 서비스 약관 동의에서 문제가 발생했어요');
                onClose();
            }
            // console.log('TODO: 에러처리 ');
            // sendEventReturn('CALL_LOCATION_POPUP', {});
        } catch (e) {
            console.log('fetchLocationAgree error');
            showSnackbar('위치 기반 서비스 약관 동의에서 문제가 발생했어요');
            onClose();
        }
    }

    return (
        <>
            <BS_Location isOpen={showLocationTermBS} onClose={() => {
                setShowLocationTermBS(false)
                onClose();
            }} onAgree={handleTermsAgree} termId={term?.termId}/>

            <CommonPopup submitText={'설정하기'} onSubmit={() => sendEventReturn('CALL_LOCATION_POPUP', {})}
                         isOpen={showGPSPopup}
                         onClose={() => {
                             setShowGPSPopup(false);
                             onClose();
                             ptnId ? navigate('/map', {state: {ptnId: ptnId}}) : navigate('/map');
                         }}
                         description={'내 주변 제휴사를 확인하려면\n "위치 서비스" 권한을 허용해 주셔야 해요.'} cancelText={'취소'}
            />

        </>
    );
}

export default CommonLocation;