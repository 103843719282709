import React from 'react';
import {Spinner, Text} from '@lguuxe/2024_designsystem_uni';

const LoadingOverlay: React.FC = () => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(24, 26, 27, 0.6)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9998,
        }}
    >
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(24, 26, 27, 0.6)',
                zIndex: 1,
            }}
        ></div>
        <div style={{zIndex: 2}}>
            <Spinner color="primary" fill="outline" size="medium"/>
        </div>
        <div style={{textAlign: 'center', zIndex: 2, marginTop: '1rem'}}>
            <Text typo='font_body_2M' color='#FFFFFF'>
                불러오는 중..
            </Text>
        </div>
    </div>
);

export default LoadingOverlay;
