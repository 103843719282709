export const ASK_TYPE_LIST = [
    {
        id: 1,
        label: "멤버십 혜택",
        code: "143001"
    }, {
        id: 2,
        label: "이벤트/쿠폰",
        code: "143016"
    }, {
        id: 3,
        label: "휴대폰결제",
        code: "143011"
    }, {
        id: 4,
        label: "앱 이용문의",
        code: "143014"
    }, {
        id: 5,
        label: "머니Me",
        code: "144015"
    }, {
        id: 6,
        label: "미션",
        code: "144030"
    }, {
        id: 7,
        label: "기타",
        code: "143006"
    }
]

export const getInquiryTypeName = (code: string): string => {
    const item = ASK_TYPE_LIST.find((askType) => askType.code === code);
    return item ? item.label : '기타'; // 찾은 경우 label을 반환, 없으면 undefined 반환
};