// PartnerList.tsx
import { Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PartnerItem, TotalPartners} from '../../pages/Partner/types';
import emptyPartnerImg from '../../images/img/empty/empty_partner.svg';
import defaultThumbnail from '../../images/default/default thumbnail.svg';

interface PartnerListProps {
    partners: TotalPartners;
}

const BadgePartnerList: React.FC<PartnerListProps> = ({partners}) => {
    const navigate = useNavigate();

    const handlePartnerClick = (partner: PartnerItem) => {
        navigate(`/partners/${partner.ptnId}`);
    };

    const renderNoPartner = () => (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '88px 0',
            }}>
                <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{
                    paddingTop: '20px',
                }}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        제휴사가 없습니다.
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderPartnerList = () => (
        <List
            type='view'
            fill='none'
            id='partnerList'
            isSlotClickable={true}
            items={partners.partnerList.map((partner) => ({
                id: `${partner.ptnId}`,
                leftSlot: [
                    <Image
                        src={partner.ptnLogoImgUrl}
                        ratio='1:1'
                        width='48px'
                        rounded={'50%'}
                        srcFallback={defaultThumbnail}
                        onClick={() => handlePartnerClick(partner)}
                    />,
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}
                         onClick={() => handlePartnerClick(partner)}>
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                eyebrow: (
                                    <>
                                        <span>{partner.ptnName}</span>
                                        {/*<span className='bar'></span>*/}
                                        {/*<span>텍스트</span>*/}
                                    </>
                                ),
                                title: partner.benefitSummary,
                            }}
                        />
                    </div>,
                ],
            }))}
        />
    );

    return (
        <>
            {partners?.totalCount === '0' ? renderNoPartner() : renderPartnerList()}
        </>
    );
};

export default BadgePartnerList;
