import React, {useState} from 'react';
import {Box, Icon, Text, Button, CTA, Checkbox} from '@lguuxe/2024_designsystem_uni';
import {addItem} from "../../utils/indexedDB";

interface TermUnitedInfoBoxCTAProps {
    onClickConfirm: (arg0?: boolean) => void;  // onClick 핸들러를 prop으로 받음
    onClickCancel?: () => void
    isButtonEnabled: boolean;  // 버튼 활성화 여부를 나타내는 prop
    subType?: string;
    showCheckbox?: boolean
    onCheckbox?: () => void
}

const TermUnitedInfoBoxCTA: React.FC<TermUnitedInfoBoxCTAProps> = ({ onClickConfirm, onClickCancel, isButtonEnabled, subType ,showCheckbox, onCheckbox}) => {
    const [isChecked, setIsChecked] = useState(false)
    const onSubmit = () => {
        if(isChecked) {
            onClickConfirm(isChecked)
        } else (
            onClickConfirm()
        )
    }
    const onCancel = async () => {
        if (onClickCancel) {
            if (isChecked && onCheckbox) {
                onCheckbox()
                onClickCancel()
            } else if(!isChecked && onCheckbox) {
                await addItem("termsHideDuration", null)
                onClickCancel()
            } else {
                onClickCancel()
            }
        }
    }
    const handleCheckBox = () => {
        setIsChecked((prevState) => !prevState);
    };

    return (
        <>
            {subType === "필수" && (
                <Box
                    style={{
                        backgroundColor: '#F9FAFB',
                        borderRadius: '12px',
                        padding: '16px 20px',
                        margin: '0 20px 12px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            gap: '6px',
                            padding: '0',
                        }}
                    >
                        <Icon color="#7f8a94" name="error_circle_filled" size="20px" />
                        <Text typo="font_body_2M" color="color_text_neutral_3">
                            약관동의에 거부할 수 있으나, 서비스 이용에 제약이 있을 수 있습니다. <br />
                        </Text>
                    </Box>
                </Box>
            )}

            <Box style={{ position: "fixed", bottom: "0", left: "0", right: "0", padding: "0", background: "#fff" }}>
                {showCheckbox && (
                    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '0 0 0 20px'}}>
                        <Checkbox
                            label="7일간 다시보지 않기"
                            size="medium"
                            width="auto"
                            onChange={handleCheckBox}
                        />
                    </Box>
                )}
                {subType ? (<CTA gradation layout="row">
                        <Button color="neutral" size="large" onClick={onCancel}>
                            닫기
                        </Button>
                        <Button color="primary" size="large" onClick={onSubmit} disabled={!isButtonEnabled}>
                            동의하기
                        </Button>
                    </CTA>) :
                    (<CTA gradation layout="column">
                        <Button color="primary" size="large" onClick={onSubmit} disabled={!isButtonEnabled}>
                            동의하고 진행하기
                        </Button>
                    </CTA>)}
            </Box>
        </>
    );
};

export default TermUnitedInfoBoxCTA;
