import React, {useState} from 'react';
import {BottomSheet, Box, Button, CTA, Header, IconButton, Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import {nftInfo} from "../../pages/Badge/types";
import dayjs from 'dayjs';
import {SwipeableProps, useSwipeable} from 'react-swipeable';
import './BS_Badge.css';
import {useNavigate} from "react-router-dom";
import {getBadgeTagId} from "../../utils/helper"; // 추가된 CSS 파일

interface BS_BadgeProps {
    nftInfoList: nftInfo[];
    imageSrc: string;
    name: string;
    count: number;
    onClose: () => void;
}

const BS_Badge: React.FC<BS_BadgeProps> = ({nftInfoList, imageSrc, name, count, onClose}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();
    const formatDate = (dateString: string) => {
        return dayjs(dateString).format('YYYY년 MM월 DD일');
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => {
            if (prevIndex < nftInfoList.length - 1) {
                return prevIndex + 1;
            }
            return prevIndex;
        });
    };

    const handlePrev = () => {
        setCurrentIndex(prevIndex => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }
            return prevIndex;
        });
    };

    interface CustomSwipeableProps extends SwipeableProps {
        preventDefaultTouchmoveEvent?: boolean;
    }

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    } as CustomSwipeableProps);

    const handleGoBrandList = () => {
        const tagId = getBadgeTagId(name);  // 배지 이름을 이용해 tagId를 가져옴
        if (tagId) {
            navigate(`/promotion?tab=2&CategoryId=${tagId}`);  // CategoryId로 이동
        } else {
            console.error('Invalid badge name provided.');
        }
    };

    return (
        <BottomSheet isOpen onClose={onClose}>
            <BottomSheet.TopSlot>
                <Header
                    align="left"
                    variant="heading"
                    divider={false}
                    style={{
                        padding: "20px 20px 8px",
                        height: "auto"
                    }}
                >
                    <Header.CenterSlot>
                        <Text typo="font_heading_3B" style={{padding: "8px 0"}}>
                            {name}&nbsp;
                            {count !== 0 && <Text typo="font_heading_3B" style={{color: "#E6007E"}}>
                                {count}개
                            </Text>}
                        </Text>
                    </Header.CenterSlot>
                    <Header.RightSlot>
                        <IconButton
                            color="neutral"
                            fill="icon"
                            icon="close_line"
                            noStates
                            onClick={onClose}
                        />
                    </Header.RightSlot>
                </Header>
            </BottomSheet.TopSlot>
            <BottomSheet.CenterSlot>
                {nftInfoList.length > 0 ? (
                    <div {...handlers} className="slider-container">
                        <div
                            className="slides-wrapper"
                            style={{
                                transform: `translateX(-${currentIndex * 100}%)`,
                                transition: 'transform 0.3s ease-in-out',
                                width: `100%`
                            }}
                        >
                            {nftInfoList.map((nft, index) => (
                                <div key={index} className="slide">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Box type="2_trbl">
                                            <Image
                                                customClass="customClass"
                                                ratio="1:1"
                                                src={imageSrc}
                                                width="160"
                                            />
                                        </Box>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginTop: "16px"
                                        }}
                                    >
                                        <Text typo="font_body_2B"
                                              style={{fontSize: "16px", weight: 600, color: "#E6007E"}}>
                                            {nft.serialNumber}
                                        </Text>
                                        <Text typo="font_body_2B"
                                              style={{fontSize: "14px", weight: 500, color: "#66707A"}}>
                                            {formatDate(nft.updateTime)}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* 점(dot) 내비게이션 */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "16px"
                            }}
                        >
                            {nftInfoList.map((_, index) => (
                                <span
                                    key={index}
                                    style={{
                                        display: "inline-block",
                                        backgroundColor: "#66707A",
                                        width: index === currentIndex ? "18px" : "6px",
                                        height: "6px",
                                        borderRadius: index === currentIndex ? "24px" : "50%",
                                        opacity: index === currentIndex ? "1" : "0.4",
                                        margin: "0 3px",
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    // nftInfoList가 비어있는 경우 기본 내용 표시
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Box type="2_trbl">
                            <Image
                                customClass="customClass"
                                ratio="1:1"
                                src={imageSrc}
                                width="160"
                            />
                        </Box>
                        <TextSet
                            blockSpacing={{
                                bottom: '4px',
                                top: '4px',
                            }}
                            subComponent="Heading_5"
                            text={{
                                title: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>해당하는 브랜드에서 혜택을</span>
                                        <span style={{color: "#E6007E"}}>3번 사용하면 발급돼요</span>
                                    </div>
                                )
                            }}
                            weight="default"
                        />
                    </div>
                )}
            </BottomSheet.CenterSlot>
            <BottomSheet.BottomSlot>
                <CTA gradation layout="column">
                    <Button
                        color="primary"
                        size="large"
                        onClick={handleGoBrandList}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.BADGE_DETAIL_POPUP} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : 브랜드 목록 보기`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.BADGE_DETAIL_POPUP}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`브랜드 목록 보기`}
                    >
                        브랜드 목록 보기
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    );
};

export default BS_Badge;
