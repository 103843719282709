// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내_일반등급) (UMembershipReceivedBenefit2)
// ****************************************************
import React from 'react';
import {Box, Text} from '@lguuxe/2024_designsystem_uni';

const LevelUpTips: React.FC = () => {
    return (
        <>
            <Box type="2_trbl">
                <Text typo="font_heading_5B" color="color_text_neutral_6">등급 업그레이드 팁</Text>
                <ul style={{
                    padding: "5px 0 0 20px",
                    margin: "0",
                    fontSize: "14px",
                    color: "#66707A",
                    listStyleType: 'disc',
                }}>
                    <li style={{lineHeight: "21px",}}>
                        이용 중인 모바일 요금제나 1년 동안 낸 통신 요금 조건을 만족하면 등급을 올릴 수 있어요.
                    </li>
                    <li style={{lineHeight: "21px",}}>
                        모바일 요금제를 변경하면 매월 1일에 등급을 결정해요.
                    </li>
                </ul>
            </Box>
            <Box type="2_trbl">
                <Text typo="font_heading_5B" color="color_text_neutral_6">TIP1. VVIP 되는 방법</Text>
                <ul style={{
                    padding: "5px 0 0 20px",
                    margin: "0",
                    fontSize: "14px",
                    color: "#66707A",
                    listStyleType: 'disc',
                }}>
                    <li style={{lineHeight: "21px",}}>
                        95,000 이상 모바일요금제 가입하기
                    </li>
                    <li style={{lineHeight: "21px",}}>
                        전년도 연간납부실적 200만원 이상 충족하기
                    </li>
                </ul>
            </Box>
            <Box type="2_trbl">
                <Text typo="font_heading_5B" color="color_text_neutral_6">TIP2. VIP 되는 방법</Text>
                <ul style={{
                    padding: "5px 0 0 20px",
                    margin: "0",
                    fontSize: "14px",
                    color: "#66707A",
                    listStyleType: 'disc',
                }}>
                    <li style={{lineHeight: "21px",}}>
                        74,800 이상 모바일요금제 가입하기
                    </li>
                    <li style={{lineHeight: "21px",}}>
                        전년도 연간납부실적 100만원 이상 충족하기
                    </li>
                </ul>
            </Box>
        </>
    );
};

export default LevelUpTips;
