import React, {useEffect} from 'react';
import {List, Tag, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import {TabType} from './types';
import {formatCurrency} from "../../utils/currencyUtils";
import {MembershipInfo} from "../../pages/Home/types";
import {getItemByKey} from "../../utils/indexedDB";
import {LimitInfo} from "../../pages/MobilePayment/types";

interface CardInfoProps {
    tabType: TabType;
    cardInfo: MembershipInfo;
    paymentLimit: LimitInfo
    initialTab: string | undefined;
}

const CardInfo: React.FC<CardInfoProps> = ({tabType, cardInfo, paymentLimit, initialTab}) => {
    const navigate = useNavigate();
    const [tagList, setTagList] = React.useState<any[]>([]);
    const [userInfo, setUserInfo] = React.useState<any>({});

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const name = await getItemByKey('customerName');
            const payExistYn = await getItemByKey('payExistYn');
            const memberStatus = await getItemByKey('memberStatus');
            setUserInfo({name, payExistYn, memberStatus});

            if (memberStatus === '5') {
                setTagList([{colorToken: 'default', label: '일반', size: 'xSmall', style: 'solid', fill: 'solid'}]);
            }
        }
        fetchIndexedDb();
    }, []);


    useEffect(() => {
        const tags = [];
        if (cardInfo.cardLevel === '8') {
            tags.push({colorToken: 'purple_low', label: 'VIP', size: 'xSmall', style: 'solid', fill: 'solid'});
        }
        if (cardInfo.cardLevel === '7') {
            tags.push({colorToken: 'blue_low', label: '우수', size: 'xSmall', style: 'solid', fill: 'solid'});
        }
        if (cardInfo.cardLevel === '6') {
            tags.push({colorToken: 'primary_low', label: 'VVIP', size: 'xSmall', style: 'solid', fill: 'solid'});
        }

        if (cardInfo.longTermCode === 'NG1' || cardInfo.longTermCode === 'NG2' || cardInfo.longTermCode === 'NG3') {
            tags.push({
                colorToken: 'blue_low',
                label: cardInfo.longTermName,
                size: 'xSmall',
                style: 'solid',
                fill: 'solid'
            });
        }
        if (cardInfo.youthYn === 'Y') {
            tags.push({colorToken: 'green_low', label: '유쓰', size: 'xSmall', style: 'solid', fill: 'solid'});
        }

        setTagList(tags);
    }, [cardInfo]);

    const handleNameClick = () => {

        if (tabType === 'membership') {
            if (userInfo.memberStatus === '5') return;
            navigate('/my/benefit');
        }
        if (tabType === 'payment') {
            if (initialTab === 'payment') return;
            navigate('/mobile-pay');
        }
    };

    return (
        <>
            <div
                style={{
                    border: '1px solid #E7EBEE',
                    borderRadius: '12px',
                    width: '100%',
                    padding: userInfo.memberStatus === '5' ? '8px 0 8px' : '8px 0 20px',
                }}
            >
                <List
                    id='cardName'
                    fill='none'
                    items={[
                        {
                            leftSlot: [
                                <div style={{display: 'flex', gap: '6px'}}>
                                    <Text type='font_heading_4B' color='color_text_neutral_6'>
                                        {userInfo.name}님
                                    </Text>
                                    <Tag item={tagList}/>
                                </div>,
                            ],
                            linkIconOnlyId: userInfo.memberStatus === '5' || initialTab === 'payment' ? undefined : 'placeholder',
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': tabType === 'membership' ? '바코드|멤버십 - 링크 클릭' : '바코드|휴대폰 결제 - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 멤버십 더보기',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': tabType === 'membership' ? '바코드|멤버십' : '바코드|휴대폰 결제',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '멤버십 더보기',
                            },
                            onClick: handleNameClick,
                        },
                    ]}
                    type='view'
                />
                {userInfo.memberStatus !== '5' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 20px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                padding: '4px 0',
                            }}
                        >
                            <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                                {tabType == 'membership' ? '올해 받은 혜택' : '사용 가능 금액'}
                            </Text>
                            <Text typo='font_heading_5Sb' color='color_text_primary'>
                                {tabType == 'membership' && (cardInfo.totalDiscount === '0' ? '포인트 무제한' : formatCurrency(cardInfo.totalDiscount) + '원')}
                                {tabType == 'payment' && (userInfo.payExistYn === 'Y'
                                    ? (() => {
                                        // usableAmount 계산
                                        const usableAmount = Number(paymentLimit.monthUseAmnt) - Number(paymentLimit.useAmt);
                                        return formatCurrency(usableAmount.toString()) + '원';
                                    })()
                                    : '휴대폰결제 차단')}
                            </Text>
                        </div>
                    </div>)}

            </div>
        </>
    );
};

export default CardInfo;
