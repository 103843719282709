import {Accordion, Text} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {decodeHtmlEntities} from "../../utils/textUtils";

interface UsageAccordionProps {
    title: string;
    // usageNoticeList: UsageNotice[];
    content: string
}

const UsageAccordion: React.FC<UsageAccordionProps> = ({title, content}) => {

    return (
        <div>
            <Accordion
                fill='underline'
                divider={false}
                size='medium'
                weight='bolder'
                expanded={true}
                title={<Text>{title}</Text>}
            >
                <Accordion.Body>
                    <Text typo='font_body_2M'>
                        {decodeHtmlEntities(content)}
                    </Text>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default UsageAccordion;