import React, {useEffect} from 'react';
import {Badge, Image, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import useLandingPage from "../../hooks/useLandingPage";
import {useNavigate} from "react-router-dom";
import uptpDefault from "../../images/default/default_uplus_thumb.svg";
import {getItemByKey} from "../../utils/indexedDB";
import {useMessagePopup} from "../../provider/MessagePopupProvider";

interface EventItemProps {
    itemList: EventItem[];
    threshold?: number;
    onCheckLogin: () => boolean;
}

interface EventItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
    itemStartDate: string;
    itemEndDate: string;
    isNew: string;
    tagName: string;
    linkType: string;
    linkUrl: string;
}

const EventItem: React.FC<EventItemProps> = ({itemList, threshold, onCheckLogin}) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const {landingPage} = useLandingPage();
    const navigate = useNavigate();
    const [memberStatus, setMemberStatus] = React.useState<string>('');
    const {showMessagePopup} = useMessagePopup();

    useEffect(() => {
        const fetchMemberStatus = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchMemberStatus()
    }, []);

    const onEventClick = (event: EventItem) => () => {
        if (onCheckLogin()) {
            if (memberStatus === '3' || memberStatus === '4') {
                showMessagePopup('만 14세 이상 고객이 이용할 수 있어요.');
            } else {
                landingPage(event.linkType, event.linkUrl, navigate, event.tagName);
            }
        }
    }
    return (
        <div>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={itemsToRender.map(item => ({
                    onClick: onEventClick(item),
                    leftSlot: [
                        <Image
                            key={item.itemId}
                            ratio="1:1"
                            rounded="55"
                            src={item.itemImgUrl}
                            width="48"
                            srcFallback={uptpDefault}
                        />,
                        <div key={item.itemId + '_details'}>
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px"
                                            }}
                                        >
                                            <Text typo="font_body_1B">{item.itemName}</Text>
                                            {item.isNew === 'Y' && (
                                                <Badge
                                                    fill="circle"
                                                    text="N"
                                                />
                                            )}
                                        </div>
                                    ),
                                    description: (
                                        <Text typo="font_detail_2M">
                                            {item.itemStartDate.split(' ')[0].replace(/-/g, '.')} ~ {item.itemEndDate.split(' ')[0].replace(/-/g, '.')}
                                        </Text>
                                    )
                                }}
                            />
                        </div>
                    ],
                    linkIconOnlyId: "empty",
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': '이벤트 - 링크 클릭',
                        'data-gtm-event-label': `컨텐츠 : ${item.itemName}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': '이벤트',
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${item.itemName}`,
                    }
                }))}
            />
        </div>
    );
}

export default EventItem;