import React from 'react';
import {Box, Chip} from '@lguuxe/2024_designsystem_uni';

interface ChipButton {
    id: number;
    label: string;
    type?: string;
    gtmLocation: string;
}

interface ChipButtonGroupProps {
    chipButtons: ChipButton[];
    activeChipId: number;
    onChipClick: (id: number) => void;
}

const ChipButtonGroup: React.FC<ChipButtonGroupProps> = ({chipButtons, activeChipId, onChipClick}) => (
    <Box
        style={{
            display: 'flex',
            gap: '6px',
            padding: '16px 20px 12px',
            overflowX: "auto"
        }}
    >
        {chipButtons.map((chip) => (
            <Chip.Button
                key={chip.id}
                color="neutral"
                fill="outline"
                label={chip.label}
                onClick={() => onChipClick(chip.id)}
                size="medium"
                style={{
                    minWidth: 'auto',
                    backgroundColor: activeChipId === chip.id ? "#414762" : "transparent",
                    color: activeChipId === chip.id ? "#fff" : "inherit",
                }}
                data-gtm-event-name="all_click"
                data-gtm-event-category="클릭"
                data-gtm-event-action={`${chip.gtmLocation} - 링크 클릭`}
                data-gtm-event-label={`컨텐츠 : ${chip.label}`}
                data-gtm-click-url=""
                data-gtm-click-location={chip.gtmLocation}
                data-gtm-click-direction="내부"
                data-gtm-click-text={chip.label}
            />
        ))}
    </Box>
);

export default ChipButtonGroup;