import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, CTA, Icon, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import selfImg from '../../images/img/enter/authentication.svg';
import logoLGuplusImg from '../../images/main/benefit/logoLGuplus.svg';
import {sendEventReturn} from "../../api/nativeBridge";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import {gtmPageLoad} from "../../api/gtm";
import {ENVIROMENT} from "../../api/api";
import {getItemByKey} from "../../utils/indexedDB";

const STAGE_URL_IOS = 'https://dasteb.uplus.co.kr/dasweb/login/lgidLoginView.do?serviceCd=B09&returnUrl=das://callback';
const STAGE_URL_AOS = 'https://dasteb.uplus.co.kr/dasweb/ctnlogin/memLgidSignup.do?serviceCd=B09&returnUrl=das://callback';
const PROD_URL_AOS = 'https://das.lguplus.co.kr/dasweb/ctnlogin/memLgidSignup.do?serviceCd=B09&returnUrl=das://callback'
const PROD_URL_IOS = 'https://das.lguplus.co.kr/dasweb/login/lgidLoginView.do?serviceCd=B09&returnUrl=das://callback'
const IdentityVerification: React.FC = () => {
    const navigate = useNavigate();
    const [showMyLgErrorPopup, setShowMyLgErrorPopup] = useState(false);

    useEffect(() => {
        gtmPageLoad({
            url: '/identity-verification',
            contentGroup: '기타|본인 인증|인증 방법 선택',
            moduleName: '',
            pageTitle: '본인인증 후 U+멤버십만의 다양한 혜택을 즐겨보세요.'
        });
    }, []);

    const handlePhoneVerification = () => {
        navigate('/phone-certification?target=web');
    };

    const handleMyLGIDLogin = async () => {
        const platform = await getItemByKey('platform');
        const isProd = ENVIROMENT === 'prod'
        const params = {
            url: isProd
                ? platform === 'IOS'
                    ? PROD_URL_IOS
                    : PROD_URL_AOS
                : platform === 'IOS'
                    ? STAGE_URL_IOS
                    : STAGE_URL_AOS
        };
        sendEventReturn('CALL_LOGIN', params);
    }

    return (
        <>
            <CommonLeftIconHeader title={''}/>
            <Box
                type='1_trl'
                style={{
                    paddingTop: '0'
                }}
            >
                <Image src={logoLGuplusImg} alt='placeholder' width='78px' height='22px'/>
            </Box>
            <Box type='3_trbl'>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: (
                            <>
                                <span>
                                    본인인증 후 U+멤버십만의
                                    <br/>
                                    다양한 혜택을 즐겨보세요.
                                </span>
                            </>
                        ),
                    }}
                    weight='bolder'
                />
            </Box>
            <Box>
                <Image customClass='customClass' ratio='1.778:1' src={selfImg}/>
                <Box
                    style={{
                        backgroundColor: '#F9FAFB',
                        borderRadius: '12px',
                        // padding: '16px 20px',
                        // margin: '0 20px 4px',
                        marginTop: '20px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            padding: '0',
                        }}
                    >
                        <Icon color='#7f8a94' name='error_circle_filled' size='24px'/>
                        <TextSet
                            subComponent='Heading_6'
                            text={{
                                title: (
                                    <>
                                        <span style={{color: '#66707A'}}>꼭 확인하세요</span>
                                    </>
                                ),
                            }}
                            weight='bolder'
                        />
                    </Box>
                    <ul
                        style={{
                            paddingLeft: '26px',
                            margin: '8px 0 0 0',
                            color: '#66707A',
                        }}
                    >
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                LG유플러스 만 14세 미만 개인 또는 법인 고객은 휴대폰번호로 인증해 주세요.
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                다른 통신사 14세 미만 고객은 가입할 수 없어요.
                            </Text>
                        </li>
                    </ul>
                </Box>
            </Box>
            <Box
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    padding: '0 0 10px', // TODO: remove padding
                }}
            >

                <CTA layout='column'>
                    <Button color='primary' onClick={handlePhoneVerification}>
                        휴대폰번호로 인증하기
                    </Button>
                    <Button color='neutral' onClick={handleMyLGIDLogin}>MY LG ID로 인증하기</Button>
                </CTA>
            </Box>
            {/*{showMyLgErrorPopup && (*/}
            {/*    <CommonPopup description={'일시적으로 MY LG ID 인증을 할 수 없어요. \n휴대폰번호로 인증해 주세요.'} submitText={'휴대폰번호로 인증'}*/}
            {/*                 onSubmit={() => {*/}
            {/*                     navigate('/phone-certification')*/}
            {/*                 }} cancelText={'취소'} onClose={() => {*/}
            {/*        setShowMyLgErrorPopup(!showMyLgErrorPopup)*/}
            {/*    }}/>)}*/}
        </>
    );
};

export default IdentityVerification;
