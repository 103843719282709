import {Box, List, TextSet, Toggle} from "@lguuxe/2024_designsystem_uni";
import {sendEventReturn} from "../../api/nativeBridge";
import React, {CSSProperties, FC, useEffect, useState} from "react";
import {getItemByKey} from "../../utils/indexedDB";

interface LockSectionProps {
    appLock: boolean
    useBio: boolean
    setAppLock: (arg0: boolean) => void
    setUseBio: (arg0: boolean) => void
}

const LockSection: FC<LockSectionProps> = ({appLock, setAppLock, useBio, setUseBio}) => {
    let isCarrier = false
    const [memberStatus, setMemberStatus] = useState("0")
    useEffect(() => {
        const fetchIndexedDb = async () => {
            isCarrier = await getItemByKey("telcoCode") === "L"
            setMemberStatus(await getItemByKey("memberStatus"))
        }
        fetchIndexedDb()
    }, []);


    return (
        <>
            <div style={{paddingBottom: "12px"}}>
                <Box type="2_trbl">
                    <TextSet subComponent="Heading_4" text={{title: '잠금'}} weight="default"/>
                </Box>
                <List
                    fill="none"
                    items={[
                        {
                            linkIconOnlyId: 'empty',
                            text: {
                                title: '비밀번호 변경',
                                description: memberStatus === "1" || memberStatus === "2"
                                    ? "휴대폰결제 또는 앱/머니Me 잠금 해제에 사용되는 비밀번호를 변경할 수 있어요."
                                    : ""
                            },
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': `설정 - 링크 클릭\``,
                                'data-gtm-event-label': '컨텐츠 : 비밀번호 변경',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': `설정`,
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '비밀번호 변경',
                            }
                        }
                    ]}
                    type="view"
                    weight="bolder"
                    onClick={() => sendEventReturn("CHANGE_PASSWORD", {step: 1})}/>
            </div>
            {/* 앱 잠금 */}
            <div style={sectionStyle}>
                <TextSet
                    subComponent="Body_1"
                    text={{title: '앱 잠금'}}
                    weight="bolder"/>
                <Toggle
                    id="appLock"
                    checked={appLock}
                    onChange={() => {
                        console.log("appLock", !appLock)
                        sendEventReturn("SET_APP_LOCK", {enable: !appLock});
                        setAppLock(!appLock);
                    }}
                    size="medium"
                    trueValue="value"
                    value="value"
                    weight="default"
                    width="auto"/>
            </div>

            {/* 생체 인증 */}
            <div style={sectionStyle}>
                <TextSet
                    subComponent="Body_1"
                    text={{title: '생체 인증 사용'}}
                    weight="bolder"/>
                <Toggle
                    id="bio"
                    checked={useBio}
                    onChange={async () => {
                        const telcoCode = await getItemByKey("telcoCode")
                        const isCarrier = telcoCode === "L";
                        sendEventReturn("SET_BIO_AUTH", {enable: !useBio, isCarrier: isCarrier});
                        setUseBio(!useBio);
                    }}
                    size="medium"
                    trueValue="value"
                    value="value"
                    weight="default"
                    width="auto"/>
            </div>
        </>
    )
}
const sectionStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 20px",
};

export default LockSection
