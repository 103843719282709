import { Text } from "@lguuxe/2024_designsystem_uni";
import React, {useEffect, useState} from "react";
import {getItemByKey} from "../../../utils/indexedDB";

const EarlyMonth : React.FC = () => {
    const [userName, setUserName] = useState("")
    const getName = async () => {
        setUserName(await getItemByKey('customerName'))
    }
    useEffect(() => {
        getName()
    }, []);

    return (
                <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1"
                }}
            >
                <Text
                    typo="font_body_2Sb"
                    color="color_text_neutral_5"
                    style={{
                        display: "inline"
                    }}
                >
                    오늘부터 다시 선택할 수 있어요.<br/>
                    이번 달 <span style={{ display: "inline-block", color: "#E6007E" }}>VIP콕</span> 사용하러 가요.
                </Text>
            </div>
        </>
    )
}

export default EarlyMonth