import React, {FC} from 'react';
import {Box, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {callCheckPassword} from "../../utils/helper";

interface AppInfoListProps {
    currentVersion: string;
    latestVersion: string;
}

const AppInfoList: FC<AppInfoListProps> = ({currentVersion, latestVersion}) => {
    const navigate = useNavigate();

    const handleModifyInfo = () => {
        callCheckPassword("modifyInfo")
        // navigate('/setting/modify-user-info');
    }

    const handleAppVersion = () => {
        navigate('/setting/app-version');
    }

    const handleLicense = () => {
        navigate('/setting/license');
    }

    const isLatestVersion = currentVersion === latestVersion;

    return (
        <div>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '내 정보/앱 정보',
                    }}
                    weight="default"
                />
            </Box>
            <List
                fill="none"
                items={[
                    {
                        onClick: handleModifyInfo,
                        linkIconOnlyId: "id",
                        text: {
                            title: '내 정보 수정하기',
                        },
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `설정 - 링크 클릭`,
                            'data-gtm-event-label': '컨텐츠 : 내 정보 수정하기',
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `설정`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': '내 정보 수정하기',
                        }
                    },
                    {
                        onClick: handleAppVersion,
                        leftSlot: (
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: '앱 버전',
                                    description: isLatestVersion ? '최신 버전이에요' : '',
                                }}
                                weight="bolder"
                            />
                        ),
                        rightSlot: (
                            <Text typo='font_label_1Sb' color='color_text_neutral_3'>v {currentVersion}</Text>
                        ),
                        linkIconOnlyId: "id",
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `설정 - 링크 클릭`,
                            'data-gtm-event-label': '컨텐츠 : 앱 버전',
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `설정`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': '앱 버전',
                        }
                    },
                    {
                        onClick: handleLicense,
                        linkIconOnlyId: "id",
                        text: {
                            title: '오픈소스 라이센스',
                        },
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `설정 - 링크 클릭`,
                            'data-gtm-event-label': '컨텐츠 : 오픈소스 라이센스',
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `설정`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': '오픈소스 라이센스',
                        }
                    },
                ]}
                type="view"
                weight="bolder"
            />
        </div>
    );
};

export default AppInfoList;
