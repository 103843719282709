import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";

const RepresentativeTermDetail: React.FC = () => {
    return (
        <>
            <CommonLeftIconHeader title={'개인정보 수집 및 이용 동의'}/>

            <div style={{overflowX: 'auto', padding: '20px'}}>
                [필수] 개인정보 수집 및 이용 동의
                <div style={{padding: '5px'}}/>
                <table border={1} style={{borderCollapse: 'collapse', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{padding: '10px', minWidth: '150px'}}>수집 / 이용 목적</th>
                        <th style={{padding: '10px', minWidth: '200px'}}>수집항목</th>
                        <th style={{padding: '10px', minWidth: '200px'}}>보유 및 이용기간</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{padding: '10px'}}>가입정보관리</td>
                        <td style={{padding: '10px'}}>성명, 암호화된 동일인식별정보(CI), 성별, 생년월일, 휴대전화 번호, 내/외국인 구분정보, 통신사</td>
                        <td style={{padding: '10px'}} rowSpan={2}>
                            <u><b>서비스 가입기간 까지,
                                <br/>
                                단, 고객이 앱에 접한지 1년이 지난 경우 수집된개인정보는 파기됩니다.</b></u>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding: '10px'}}>VOC 관리 및 응대</td>
                        <td style={{padding: '10px'}}>서비스 이용 기록 및 이를 조합한 정보, UICCID</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                고객님은 위 동의를 거부할 권리가 있습니다. 동의를거부하실 경우 서비스 가입 및 이용에 제약이 있을 수 있습니다. 더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다.
            </div>
        </>
    )
}

export default RepresentativeTermDetail;