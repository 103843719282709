// ****************************************************
// [배포] 2.미션 > 메인 (UMembershipMission)
// ****************************************************
import React, {useState} from 'react';
import {Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import CommonLoginPopup from "./CommonLoginPopup";

interface TitleHeaderProps {
    title: string;
    isSearch?: boolean;
    isNotice?: boolean;
    isSetting?: boolean;
    isBackground?: boolean;
    memberStatus?: string;
}

const TitleHeader: React.FC<TitleHeaderProps> = ({
                                                     title,
                                                     isSearch = true,
                                                     isNotice = true,
                                                     isSetting = true,
                                                     isBackground = false,
                                                     memberStatus = "0"
                                                 }) => {
    const navigate = useNavigate();
    const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false)

    const headerStyle = isBackground ? {backgroundColor: '#262A3B'} : {};
    const textStyle = isBackground ? {color: '#fff'} : {};

    return (
        <>
            <Header align='left' style={headerStyle} divider={false}>
                <Header.CenterSlot>
                    <Text typo='font_heading_4Sb' color='color_text_neutral' style={textStyle}>
                        {title}
                    </Text>
                </Header.CenterSlot>
                <Header.RightSlot>
                    {isSearch && (
                        <IconButton
                            color='neutral'
                            fill='icon'
                            icon='search_line'
                            noStates
                            size='large'
                            onClick={() => {
                                navigate('/search');
                            }}
                            data-gtm-event-name={title === '전체' ? 'all_click' : undefined}
                            data-gtm-event-category={title === '전체' ? '클릭' : undefined}
                            data-gtm-event-action={title === '전체' ? 'LNB|전체 - 링크 클릭' : undefined}
                            data-gtm-event-label={title === '전체' ? '컨텐츠 : 검색' : undefined}
                            data-gtm-click-url={title === '전체' ? '' : undefined}
                            data-gtm-click-location={title === '전체' ? 'LNB|전체' : undefined}
                            data-gtm-click-direction={title === '전체' ? '내부' : undefined}
                            data-gtm-click-text={title === '전체' ? '검색' : undefined}
                        />
                    )}

                    {isNotice && (
                        <IconButton
                            color='neutral'
                            fill='icon'
                            icon='notification_line'
                            size='large'
                            noStates
                            onClick={() => {
                                navigate('/notice');
                            }}
                        />
                    )}

                    {isSetting && (
                        <IconButton
                            color='neutral'
                            fill='icon'
                            icon='setting_line'
                            size='large'
                            noStates
                            onClick={() => {
                                if (memberStatus !== "0") {
                                    navigate('/setting');
                                } else {
                                    setShowLoginPopup(true)
                                }
                            }}
                            data-gtm-event-name={title === '전체' ? 'all_click' : undefined}
                            data-gtm-event-category={title === '전체' ? '클릭' : undefined}
                            data-gtm-event-action={title === '전체' ? 'LNB|전체 - 링크 클릭' : undefined}
                            data-gtm-event-label={title === '전체' ? '컨텐츠 : 설정' : undefined}
                            data-gtm-click-url={title === '전체' ? '' : undefined}
                            data-gtm-click-location={title === '전체' ? 'LNB|전체' : undefined}
                            data-gtm-click-direction={title === '전체' ? '내부' : undefined}
                            data-gtm-click-text={title === '전체' ? '설정' : undefined}
                        />
                    )}
                </Header.RightSlot>
            </Header>
            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
        </>
    );
};

export default TitleHeader;
