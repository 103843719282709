import React from 'react';

interface CommonDividerVerticalProps {
    margin?: string;
}

const CommonDividerVertical: React.FC<CommonDividerVerticalProps> = ({margin}) => {
    return (
        <div
            style={{
                height: "8px",
                width: "1px",
                backgroundColor: "#E7EBEE",
                margin: margin ? `0 ${margin}px` : "0 8px",
            }}
        />
    );
};

export default CommonDividerVertical;
