import React, {useEffect, useState} from "react";
import {Box, Chip, Tab} from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import MembershipInfoTab from "../../components/BenefitGuide/Tab/MembershipInfoTab";
import MembershipUsageTab from "../../components/BenefitGuide/Tab/MembershipUsageTab";
import MobilePaymentTab from "../../components/BenefitGuide/Tab/MobilePatmentTab";
import MoneyMeTab from "../../components/BenefitGuide/Tab/MoneyMeTab";
import YouthTab from "../../components/BenefitGuide/Tab/YouthTab";
import LongTermCustomerTab from "../../components/BenefitGuide/Tab/LongTermCustomerTab";
import MembershipBenefitTab from "../../components/BenefitGuide/Tab/MemeberShipBenefitTab";
import MissionTab from "../../components/BenefitGuide/Tab/MissionTab";
import {useParams} from "react-router-dom";

const BenefitGuide: React.FC = () => {
    const {id} = useParams<{ id: string }>(); // id 가져오기
    const [mainTab, setMainTab] = useState<string>("1");
    const [subTab, setSubTab] = useState<string>("1");

    useEffect(() => {
        if (id) {
            const [main, sub] = id.split(""); // id 분할
            setMainTab(main);
            setSubTab(sub);
        }
    }, []);

    const handleMainTabChange = (id: string) => {
        setMainTab(id);
        setSubTab("1");
    };

    const handleSubTabChange = (id: string) => {
        setSubTab(id);
    };

    return (
        <Box type="1_b" style={{width: "100%"}}>
            <CommonLeftIconHeader title={"혜택 가이드"}/>

            <Box type="1_b">
                <Tab
                    items={[
                        {
                            id: "1", label: "U+멤버십 소개",
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'LNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : U+멤버십 소개',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'LNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': 'U+멤버십 소개',
                            }
                        },
                        {
                            id: "2", label: "이용 방법",
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'LNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 이용 방법',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'LNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '이용 방법',
                            }
                        },
                    ]}
                    layout="fullWidth"
                    selectedItemId={mainTab}
                    onItemSelected={handleMainTabChange}
                    style={{padding: "0 20px"}}
                />

                {mainTab === "1" && (
                    <Box type="4_tb" style={{
                        paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px", overflowX: "auto",  // 가로 스크롤 활성화
                        whiteSpace: "nowrap"
                    }}>
                        <div style={{padding: "0 20px", display: "flex", gap: '0.5rem'}}>
                            <Chip.Filter
                                isSelected={subTab === '1'}
                                color="neutral"
                                fill={subTab === '1' ? 'solid' : 'outline'}
                                label="등급 종류"
                                onClick={() => handleSubTabChange('1')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 등급 종류"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="등급 종류"
                            />
                            <Chip.Filter
                                isSelected={subTab === '2'}
                                color="neutral"
                                fill={subTab === '2' ? 'solid' : 'outline'}
                                label="U+멤버십 혜택"
                                onClick={() => handleSubTabChange('2')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : U+멤버십 혜택"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="U+멤버십 혜택"
                            />
                            <Chip.Filter
                                isSelected={subTab === '3'}
                                color="neutral"
                                fill={subTab === '3' ? 'solid' : 'outline'}
                                label="장기고객 혜택"
                                onClick={() => handleSubTabChange('3')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 장기고객 혜택"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="장기고객 혜택"
                            />
                            <Chip.Filter
                                isSelected={subTab === '4'}
                                color="neutral"
                                fill={subTab === '4' ? 'solid' : 'outline'}
                                label="유쓰"
                                onClick={() => handleSubTabChange('4')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 유쓰"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="유쓰"
                            />
                        </div>
                    </Box>
                )}

                {mainTab === "2" && (
                    <Box type="4_tb" style={{paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px"}}>

                        <div style={{padding: "0 20px", display: "flex", gap: '0.5rem'}}>
                            <Chip.Filter
                                isSelected={subTab === '1'}
                                color="neutral"
                                fill={subTab === '1' ? 'solid' : 'outline'}
                                label="U+멤버십"
                                onClick={() => handleSubTabChange('1')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : U+멤버십"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="U+멤버십"
                            />
                            <Chip.Filter
                                isSelected={subTab === '2'}
                                color="neutral"
                                fill={subTab === '2' ? 'solid' : 'outline'}
                                label="휴대폰결제"
                                onClick={() => handleSubTabChange('2')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 휴대폰결제"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="휴대폰결제"
                            />
                            <Chip.Filter
                                isSelected={subTab === '3'}
                                color="neutral"
                                fill={subTab === '3' ? 'solid' : 'outline'}
                                label="미션"
                                onClick={() => handleSubTabChange('3')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 미션"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="미션"
                            />
                            <Chip.Filter
                                isSelected={subTab === '4'}
                                color="neutral"
                                fill={subTab === '4' ? 'solid' : 'outline'}
                                label="머니Me"
                                onClick={() => handleSubTabChange('4')}
                                size="medium"
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action="카테고리 - 링크 클릭"
                                data-gtm-event-label="컨텐츠 : 머니Me"
                                data-gtm-click-url=""
                                data-gtm-click-location="카테고리"
                                data-gtm-click-direction="내부"
                                data-gtm-click-text="머니Me"
                            />
                        </div>
                    </Box>
                )}

                {mainTab === "1" && subTab === "1" && <MembershipInfoTab/>}
                {mainTab === "1" && subTab === "2" && <MembershipBenefitTab/>}
                {mainTab === "1" && subTab === "3" && <LongTermCustomerTab/>}
                {mainTab === "1" && subTab === "4" && <YouthTab/>}
                {mainTab === "2" && subTab === "1" && <MembershipUsageTab/>}
                {mainTab === "2" && subTab === "2" && <MobilePaymentTab/>}
                {mainTab === "2" && subTab === "3" && <MissionTab/>}
                {mainTab === "2" && subTab === "4" && <MoneyMeTab/>}
            </Box>
        </Box>
    );
};

export default BenefitGuide;
