import React from 'react';
import {Button, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import emptyContentImg from '../../images/img/empty/empty_result_120.svg';
import {useNavigate} from 'react-router-dom';
import {gtmDataLayer} from "../../api/gtm";
import {MonthUseHistory} from "../../pages/My/types";
import {formatCurrency} from "../../utils/currencyUtils";
import {formatDate} from "../../utils/dateUtiles";

interface BenefitUseListProps {
    monthUseHistory: MonthUseHistory;
}

const BenefitUseList: React.FC<BenefitUseListProps> = ({monthUseHistory}) => {
    const navigate = useNavigate();

    // TODO: list 20개만 보여주고 스크롤시 추가로 20개씩 더 보여주기
    const handlePartnersClick = () => {
        gtmDataLayer({
            clickLocation: '받은 혜택',
            clickText: '제휴처 보러 가기',
        });
        navigate('/partners');
    };

    const NoData = () => (
        <div
            style={{
                padding: '83px 0 71px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image src={emptyContentImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{paddingTop: '20px'}}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        받은 혜택이 없어요
                    </Text>
                </div>
            </div>
            <div
                style={{
                    paddingTop: '24px',
                }}
            >
                <Button color='neutral' rightIcon='chevron_right_small_line' onClick={handlePartnersClick}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'마이|내 혜택 이력 - 링크 클릭'}
                        data-gtm-event-label={`컨텐츠 : 제휴사 보러 가기`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'마이|내 혜택 이력'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`제휴사 보러 가기`}>
                    제휴사 보러 가기
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: '전체 내역',
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            {monthUseHistory.pointUseCnt !== '0' ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 20px 12px',
                    }}
                >
                    {monthUseHistory.pointUseList.map((use, index) => (
                        <div key={index} style={{padding: '12px 0'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    padding: '4px 0',
                                }}
                            >
                                <Text style={{flex: '1'}} typo='font_heading_5Sb' color='color_text_neutral_6'>
                                    {use.useSiteNm}
                                </Text>
                                <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                    {formatCurrency(use.usePt)}원
                                </Text>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}
                            >
                                <Text style={{flex: '1'}} typo='font_body_2M' color='color_text_neutral_4'>
                                    {formatDate(use.useDt)}
                                </Text>
                                <Text typo='font_body_2M'
                                      color={!use.usePt.includes('-') ? 'color_text_neutral_4' : 'color_status_error'}>
                                    {!use.usePt.includes('-') ? '사용' : '취소'}
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                NoData()
            )}
        </>
    );
};

export default BenefitUseList;