// 하위 10% 미만 그래프 혜택금 좌측 정렬
// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// ****************************************************

import React, {useEffect, useRef, useState} from 'react';
import {Icon, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {formatCurrency} from '../../utils/currencyUtils';
import BS_List from "../Common/BS_List";
import {BenefitSummery} from "../../pages/My/types";
import {getItemByKey} from "../../utils/indexedDB";
import {generateLast12Months} from "../../utils/helper";

interface BenefitGraphProps {
    benefitSummary: BenefitSummery;
    onDateChange: (yearMonth: string) => void;
}

const BenefitGraph: React.FC<BenefitGraphProps> = ({benefitSummary, onDateChange}) => {
    const root = document.documentElement;
    root.style.setProperty('--bar-percent', `${100 - parseFloat(benefitSummary.ranking)}%`);
    root.style.setProperty('--use-amount-left', `${100 - parseFloat(benefitSummary.ranking)}%`);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [isMonthBsOpen, setIsMonthBsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<{ id: string, label: string }[]>([]);
    const [name, setName] = useState<string>('');
    const isFirstRender = useRef(true);
    const [selectedDateId, setSelectedDateId] = useState<string>('0');

    useEffect(() => {
        // ranking 값을 파싱하고 조건에 맞는지 확인
        const useAmountElement = document.querySelector('.use_amount') as HTMLElement; // HTMLElement로 타입 캐스팅
        if (useAmountElement) {
            if (benefitSummary && (100 - parseFloat(benefitSummary.ranking)) > 85) {
                // 상위 14% 이상일 때 금액을 우측정렬
                useAmountElement.style.transform = 'translateX(-100%)'; // 조건 충족 시 transform 적용
                useAmountElement.style.textAlign = 'right'; // 텍스트 우측 정렬
                useAmountElement.style.display = 'block'; // width 100% 적용
            } else if (benefitSummary && (100 - parseFloat(benefitSummary.ranking)) <= 85 && (100 - parseFloat(benefitSummary.ranking)) >= 15) {
                // 85 ~ 15 사이일 때 금액을 중간에 배치
                useAmountElement.style.transform = 'translateX(-50%)';
                useAmountElement.style.textAlign = 'center';
                useAmountElement.style.display = 'block';
            } else {
                // 그 외에는 default css
                useAmountElement.style.transform = '';
                useAmountElement.style.textAlign = '';
                useAmountElement.style.display = '';
            }
        }


    }, [benefitSummary]);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            setName(await getItemByKey('customerName'));
        }

        fetchIndexedDB();
        setDataList(generateLast12Months(today.getFullYear(), today.getMonth() + 1));
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        onDateChange(`${year}${month < 10 ? '0' + month : month}`);
    }, [selectedDate]);

    const handleLeftMonth = () => {
        if (selectedDate.getMonth() === 0) {
            setSelectedDate(new Date(selectedDate.getFullYear() - 1, 11));
        } else {
            setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1));
        }
    };

    const handleRightMonth = () => {
        if (selectedDate.getMonth() === 11) {
            setSelectedDate(new Date(selectedDate.getFullYear() + 1, 0));
        } else {
            setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1));
        }
    };

    const handleDownMonth = () => {
        setIsMonthBsOpen(!isMonthBsOpen);
    };

    const handleSelectedMonth = (id: string) => {
        const selectedData = dataList.find(item => item.id === id);
        if (!selectedData) return;

        const match = selectedData.label.match(/(\d{4})년 (\d{1,2})월/);
        if (match) {
            const selectedYear = parseInt(match[1], 10);
            const selectedMonth = parseInt(match[2], 10);
            setSelectedDate(new Date(selectedYear, selectedMonth - 1));
            setSelectedDateId(id);
        }
    };

    return (
        <>
            <div className='benefit_graph'>
                <div className='header'>
                    <div className='left arrowborder'>
                        <IconButton
                            fill='ghost'
                            color='#7f8a94'
                            icon='chevron_left_small_line'
                            size='medium'
                            onClick={handleLeftMonth}
                        />
                    </div>
                    <div className='center'>
                        <Text typo='font_heading_5Sb'>
                            {selectedDate.getFullYear()}년 {selectedDate.getMonth() + 1}월
                        </Text>
                        <Icon color='#7f8a94' name='chevron_down_small_line' size='24px' onClick={handleDownMonth}/>
                    </div>
                    <div className='right arrowborder'>
                        <IconButton
                            fill='ghost'
                            color='#7f8a94'
                            icon='chevron_right_small_line'
                            size='medium'
                            onClick={handleRightMonth}
                            disabled={selectedDate.getFullYear() === today.getFullYear() && selectedDate.getMonth() === today.getMonth()}
                        />
                    </div>
                </div>

                <div className='body'>
                    <div className='text'>
                        {benefitSummary.thisMonthDiscount === '0' ? (
                            <>
                                <Text typo='font_heading_3Sb'>{name}님</Text>
                                <Text typo='font_heading_3Sb'>{selectedDate.getMonth() + 1}월에 받은 혜택이 없어요</Text>
                            </>) : (
                            <>
                                <Text typo='font_heading_3Sb'>{name}님 이번 달</Text>
                                <Text typo='font_heading_3Sb'>
                                    <Text typo='font_heading_3Sb' color='color_text_primary'>
                                        {formatCurrency(benefitSummary.thisMonthDiscount)}원
                                    </Text>
                                    &nbsp;혜택 받으셨어요!
                                </Text>
                            </>)}
                    </div>
                    <div className='graph_box'>
                        <Text typo='font_detail_1M' color='color_container_primary' className='use_amount'>
                            {formatCurrency(benefitSummary.thisMonthDiscount)}원
                        </Text>
                        <div className='bar_base'>
                            <div className='bar-fill color'></div>
                            <div className='label'></div>
                        </div>
                        <Text typo='font_detail_1M' color='color_text_neutral_3' className='base_amount'
                              style={{left: benefitSummary.userMonthAverageDiscount === '0' ? '0' : 'calc(50% - 43px)'}}>
                            평균 {formatCurrency(benefitSummary.userMonthAverageDiscount)}원
                        </Text>
                        <div className='bar_base'>
                            <div
                                className={`bar-fill gray ${benefitSummary.userMonthAverageDiscount === '0' ? 'left-aligned' : ''}`}>
                            </div>
                            <div className='label gray'></div>
                        </div>
                    </div>
                </div>
            </div>

            <BS_List
                title='월 선택'
                dataList={dataList}
                onClose={() => setIsMonthBsOpen(false)}
                onSelected={handleSelectedMonth}
                isOpen={isMonthBsOpen}
                checkedItem={selectedDateId}
            />
        </>
    );
};

export default BenefitGraph;
