export const getGradeTag = (cardLevel: string, size = 'xSmall') => {
    if (!cardLevel) {
        return {};
    }

    switch (cardLevel) {
        case '6':
            return {
                label: 'VVIP',
                size: size,
                style: 'solid',
                colorToken: 'primary_low',
            };
        case '7':
            return {
                label: '우수',
                size: size,
                style: 'solid',
                colorToken: 'blue_low',
            };
        case '8':
            return {
                label: 'VIP',
                size: size,
                style: 'solid',
                colorToken: 'purple_low',
            };
        default:
            return {
                label: '일반',
                size: size,
                style: 'solid',
                colorToken: 'default',
            };
    }
};

export const getYouthTag = () => {
    return {
        label: '유쓰',
        size: 'xSmall',
        style: 'solid',
        colorToken: 'green_low',
    };
};

export const getLongTermTag = (tagName: string, size = 'xSmall') => {
    return {
        label: tagName,
        size: 'xSmall',
        style: 'solid',
        colorToken: 'blue_low',
    };
};
