import React, {useEffect, useState} from "react";
import {Box, IconButton, Text, TextField, TextSet} from "@lguuxe/2024_designsystem_uni";

import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {eventApi} from "../../../api/gatewayApi/attendanceAndEvent";
import {useParams} from "react-router-dom";

interface WinnerInfo {
    ctn: string;
    userName: string;
    giftName: string;
}

const initWinnerInfo: WinnerInfo = {
    ctn: '',
    userName: '',
    giftName: ''
}

const EventResult: React.FC = () => {
    const [winnerInfo, setWinnerInfo] = useState<WinnerInfo>(initWinnerInfo);
    const [winnerState, setWinnerState] = useState<'first' | 'winner' | 'loser'>('first');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const {eventId} = useParams<{ eventId: string }>()

    useEffect(() => {
        if (!eventId) {
            console.error("id is undefined");
            // setIsLoading(false);
            return;
        }
    }, []);

    const fetchMyResult = async () => {
        try {
            if (!eventId) {
                console.error("id is undefined");
                // setIsLoading(false);
                return;
            }
            eventApi.getWinnerInfo(eventId, 'v1').then((res) => {
                console.log(res);
                if (res.header.resultCode === '0000') {
                    setWinnerInfo(res.body);
                    setWinnerState('winner');
                } else {
                    console.log("Error fetchData:", res);
                }
            });
        } catch (error) {
            console.log("Error fetchData:", error);
        }
    }

    const handleSearch = () => {
        fetchMyResult();
    }

    const handleNumberChange = (event: any) => {
        console.log(event.value);
        setPhoneNumber(event.value)
    }

    return (
        <>
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                {/* header */}
                <CommonLeftIconHeader title={'[당첨자 확인] 이벤트 명'}/>

                {/* 제목 */}
                <Box
                    type="2_trbl"
                    style={{
                        background: "#F9FAFB"
                    }}
                >
                    <TextSet
                        subComponent="Heading_2"
                        text={{
                            title: (
                                <>
                                    <span>
                                        내 휴대폰 번호로
                                        <br/>
                                        당첨을 확인하세요
                                    </span>
                                </>
                            )
                        }}
                        weight="bolder"
                    />
                </Box>

                {/* 휴대폰번호 검색 */}
                <Box
                    type="1_trbl"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                        background: "#F9FAFB"
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #1E1A34",
                            borderRadius: "5px",
                            width: "calc(100% - 20px)",
                            padding: "0 0 0 12px",
                            gap: "8px",
                            background: "#fff"
                        }}
                    >
                        <TextField
                            placeholder="11자리 숫자 입력"
                            onChange={handleNumberChange}
                            rightAddons={[<IconButton color="primary" fill="ghost" icon="search_line"
                                                      onClick={handleSearch} size="medium"/>]}
                        />
                    </Box>
                </Box>

                {/* 이벤트 결과 */}
                <Box
                    type="1_trbl"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px"
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #E7EBEE",
                            borderRadius: "8px",
                            width: "calc(100% - 100px)",
                            padding: "48px 50px",
                            gap: "8px"
                        }}
                    >


                        {winnerState === 'first' && (<Text typo="font_body_1M" color="color_text_neutral_4">
                            당첨 발표일로부터 2개월 동안 <br/>
                            당첨 여부를 확인할 수 있어요.
                        </Text>)}
                        {winnerState === 'winner' && (
                            <div>
                                <Text typo="font_heading_3B" color="color_text_primary" style={{paddingBottom: "16px"}}>
                                    당첨을 축하드립니다!
                                </Text>
                                <Text typo="font_heading_5B" color="color_text_neutral_6">
                                    {`${winnerInfo.userName[0]}*${winnerInfo.userName.slice(2)} [ ${winnerInfo.ctn} ]`}
                                </Text>
                                <Text typo="font_body_1M" color="color_text_neutral_4">
                                    [ 이벤트 명 대상자 ] <br/>
                                    선물 : {winnerInfo.giftName}
                                </Text>
                            </div>)
                        }
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default EventResult;
