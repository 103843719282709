import React, {useEffect} from "react";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import {mapApi} from "../../api/gatewayApi/other";
import {Box, Button, Image, Text} from "@lguuxe/2024_designsystem_uni";
import refeshIcon from '../../images/etc/refresh_24_line.svg'
import bluePin from '../../images/icon/inavi/bluePin.svg'
import purplePin from '../../images/icon/inavi/PurplePin.svg'
import herePin from '../../images/icon/inavi/herePin.svg'
import selectedBluePin from '../../images/icon/inavi/selectedBluePin.svg'
import selectedPurplePin from '../../images/icon/inavi/selectedPurplePin.svg'
import {useLocation, useNavigate} from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";

const stgUrl = 'https://openapi.testmap.uplus.co.kr:18000/mapinfra/api/map/jsVectorMapDownload?authKey=8bb5975c1d6f4f919a047765cd6dbd2f&svcId=90fc7981cb634638a06bd57351063cf3';

interface partner {
    ptnId: string;
    plcId: string;
    branchName: string;
    lat: string;
    lng: string;
    address: string;
    pinType: string;
}

const nonSelectedPartner: partner = {
    ptnId: '',
    plcId: '',
    branchName: '',
    lat: '',
    lng: '',
    address: '',
    pinType: '',
}

const TILE_BANNER_HEIGHT = 0.0005;

const PartnerMap: React.FC = () => {
    const [selectedPartner, setSelectedPartner] = React.useState<partner>(nonSelectedPartner);
    const [partnerList, setPartnerList] = React.useState<partner[]>([]);
    const [ptnId, setPtnId] = React.useState<string>('');
    const location = useLocation();
    const navigate = useNavigate();

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    useEffect(() => {
        // script 생성 및
        const script = document.createElement('script');
        script.type = 'text/javascript';
        // script.src = 'https://openapi.testmap.uplus.co.kr:18000/mapinfra/api/map/jsVectorMapDownload?authKey=8bb5975c1d6f4f919a047765cd6dbd2f&svcId=90fc7981cb634638a06bd57351063cf3&callback=initMap'
        script.src = 'https://openapi.testmap.uplus.co.kr:18000/Script/iNavi_v_Map.js';
        document.body.appendChild(script);

        const initMap = async () => {
            if (location.state && location.state.ptnId) {
                setPtnId(location.state.ptnId);
            }

            await sleep(500);
            let currentLocation = { // 한강대로 32
                lat: '37.523775',
                lng: '126.963579',
            }
            // 현재 위치를 가져올 수 있으면 가져온다
            const getCurrentLocation = (): any => {
                return new Promise((resolve) => {
                    if ((window as any).navigator.geolocation) {
                        (window as any).navigator.geolocation.getCurrentPosition(
                            (position: any) => {
                                console.log('geolocation : ', position);
                                currentLocation = {
                                    lat: String(position.coords.latitude.toFixed(10)),
                                    lng: String(position.coords.longitude.toFixed(10)),
                                };
                                initMyLocation(currentLocation);
                                resolve(currentLocation);
                            },
                            (error: any) => {
                                console.log('geolocation error : ', error);
                                initMyLocation(currentLocation);
                                resolve(currentLocation);
                            }
                        );
                    } else {
                        console.log('geolocation not supported');
                        initMyLocation(currentLocation);
                        resolve(currentLocation);
                    }
                });
            };
            // 비동기적으로 위치 정보를 가져오고, 이후에 fetchData를 실행
            currentLocation = await getCurrentLocation();
            fetchData(currentLocation);
        }

        initMap();
    }, []);

    useEffect(() => {
        createPartnersMarker();
    }, [partnerList]);

    const fetchData = async (location: any) => {
        try {
            let res;
            if (ptnId) {
                console.log('ptnId:', ptnId);
                res = await mapApi.getMapPartnersById(ptnId, {lat: location.lat, lng: location.lng}, 'v1');
            } else {
                res = await mapApi.getMapPartners({lat: location.lat, lng: location.lng}, 'v1');
            }
            if (res.header.resultCode === '0000' && res.body) {
                console.log(res.body.ptnList);
                setPartnerList(res.body.ptnList);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const initMyLocation = (location: any) => {
        const currentMarker = (current: any) => {
            // 마커
            const currentMarker = new (window as any).inavi.maps.Marker({
                map: (window as any).INAVI.map,
                position: current,
                icon: herePin
            });

            (window as any).INAVI.map.flyTo(current, {
                zoom: (window as any).INAVI.map.getMaxZoom(),
            });
        }
        currentMarker(location);
    }

    const createPartnersMarker = () => {

        partnerList.forEach((partner) => {
            const position = [Number(partner.lng), Number(partner.lat)];

            const marker = new (window as any).inavi.maps.Marker({
                position: position,
                map: (window as any).INAVI.map,
                icon: partner.pinType === '10' ? bluePin : purplePin,
            });
            marker.on('touchstart', () => {
                const selectedMarkerInfo = (window as any).INAVI.selectedMarkerInfo;
                console.log(selectedMarkerInfo);
                if (selectedMarkerInfo && selectedMarkerInfo.plcId !== partner.plcId) {
                    const icon = selectedMarkerInfo.iconType === '10' ? bluePin : purplePin;
                    (window as any).INAVI.selectedMarker.setIcon(icon);
                    // TODO: 현재 정보 창 닫기
                    console.log((window as any).INAVI.selectedInfoWindow);
                    (window as any).INAVI.selectedInfoWindow.setMap();
                }
                (window as any).INAVI.selectedMarker = marker;
                (window as any).INAVI.selectedMarkerInfo = {
                    ptnId: partner.ptnId,
                    iconType: partner.pinType,
                    plcId: partner.plcId
                };
                marker.setIcon(partner.pinType === '10' ? selectedBluePin : selectedPurplePin);
                setSelectedPartner(partner);

                //  정보 창 생성
                console.log(marker.getPosition());
                const infoWindow = new (window as any).inavi.maps.CustomInfoWindow({
                    map: (window as any).INAVI.map,
                    position: {lat: marker.getPosition().lat + TILE_BANNER_HEIGHT, lng: marker.getPosition().lng},

                    content: [
                        ' <div style="padding: 6px 12px 7px; top: 40px; border: 1px solid #DCE0E5; border-radius: 4px; display: flex; flex-direction: column; background-color: #FFFFFF;  opacity: 0.9;">',
                        ` <span style="font-family: Pretendard; color=#181A1B;">${partner.branchName}</span>`,
                        ' </div>',
                    ].join("")
                });
                (window as any).INAVI.selectedInfoWindow = infoWindow;
            });
        });
    }

    const removeMarkers = async () => {
        if ((window as any).INAVI.selectedInfoWindow) {
            (window as any).INAVI.selectedInfoWindow.setMap();
        }
        if ((window as any).INAVI.selectedMarker) {
            (window as any).INAVI.selectedMarker.setMap();
        }
        setSelectedPartner(nonSelectedPartner);

        (window as any).INAVI.selectedMarker = null;
        (window as any).INAVI.selectedMarkerInfo = null;
        (window as any).INAVI.selectedInfoWindow = null;
    }

    const handleClickRefresh = useDebounce(async () => {
        await removeMarkers();
        const center = (window as any).INAVI.map.getCenter();
        fetchData({lat: String(center.lat.toFixed(10)), lng: String(center.lng.toFixed(10))});
    })

    const handleClickBenefit = useDebounce(() => {
        navigate(`/partners/${selectedPartner.ptnId}`, {replace: true});
    });

    return (
        <>
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr', height: '100vh'}}>
                <CommonLeftIconHeader title={'내 주변 매장'}/>
                <Box
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        flexDrection: "column",
                        alignItems: "center",
                        position: "relative",
                        padding: "0"
                    }}
                >
                    <div id="map" style={{width: '100%', height: '100%'}}></div>
                    <Box
                        style={{
                            padding: "6px 12px 7px",
                            position: "absolute",
                            top: "20px",
                            border: "1px solid #DCE0E5",
                            borderRadius: "35px",
                            display: "flex",
                            gap: "4px",
                            backgroundColor: "#FFFFFF",
                            opacity: 0.9,
                        }}
                        onClick={handleClickRefresh}
                    >
                        <Image src={refeshIcon} alt="placeholder" width="20px" ratio="1:1"/>
                        <Text typo="font_label_2Sb" color="color_status_Info" style={{color: "#1662F8"}}>
                            현재 지도에서 검색
                        </Text>
                    </Box>

                    {selectedPartner.ptnId && (
                        <Box
                            style={{
                                position: "absolute",
                                bottom: "40px",
                                display: "flex",
                                border: "1px solid #DCE0E5",
                                borderRadius: "15px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "80%",  // 상자 전체 너비 사용
                                backgroundColor: "#FFFFFF",
                                // padding: "6px 12px 7px",
                                gap: "4px",
                                padding: "16px",

                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: "0",
                                    opacity: 0.9,
                                    borderRadius: "40px",  // 둥근 모서리 적용
                                }}
                            >
                                <Text typo="font_body_1M" color="color_text_neutral_6">
                                    {selectedPartner.branchName}
                                </Text>
                                <Text typo="font_detail_2M" color="color_text_neutral_3">
                                    {selectedPartner.address}
                                </Text>
                            </Box>

                            <Button
                                fill='solid'
                                color='neutral'
                                size='small'
                                onClick={handleClickBenefit}
                            >
                                혜택 확인
                            </Button>
                        </Box>
                    )}
                </Box>
            </div>
        </>
    )
}
export default PartnerMap;