// ****************************************************
// [배포] 4.마이 > 내 쿠폰 > VIP콕 상세 > 사용가능
// ****************************************************
import React, {useEffect, useState} from "react";
import {Box, Button, Image, List, Tab, Text, TextSet} from "@lguuxe/2024_designsystem_uni";

import CommonAllIconHeader from "../../components/Common/CommonAllIconHeader";
import "../../css/style.css"
import {couponApi} from "../../api/gatewayApi/other";
import {CouponItem, CouponSort, initialCouponSort, initialIssuedCoupon, IssuedCoupon} from "./types";
import emptyCouponImg from "../../images/img/empty/empty_result_120.svg";
import {formatDate} from "../../utils/dateUtiles";
import SortFilter from "../../components/Partner/SortFilter";
import {useLocation, useNavigate} from "react-router-dom";
import {gtmPageLoad} from "../../api/gtm";
import BarcodeLoading from "../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import defaultThumbnail from "../../images/default/default thumbnail.svg"

const TAG_LIST = [
    {
        id: "0",
        label: "전체"
    },
    {
        id: "1",
        label: "사용 완료"
    },
    {
        id: "2",
        label: "기간 만료"
    }
]


const MyCoupon: React.FC = () => {
    const [couponSort, setCouponSort] = useState<CouponSort>(initialCouponSort);
    const [coupons, setCoupons] = useState<IssuedCoupon>(initialIssuedCoupon);
    const [isSortBsOpen, setIsSortBsOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true); // 무한 스크롤에서 더 가져올 데이터가 있는지 여부
    const sortFilterList = [{id: '0', label: '최근 받은 순'}, {id: '1', label: '만료 임박순'}];

    const tabItems = [
        {
            id: "0",
            label: `사용 가능 ${coupons.useYcouponCnt}`,
            dataAttributes: {
                'data-gtm-event-name': 'all_click',
                'data-gtm-event-category': '클릭',
                'data-gtm-event-action': `LNB - 링크 클릭`,
                'data-gtm-event-label': `컨텐츠 : 사용 가능 ${coupons.useYcouponCnt}`,
                'data-gtm-click-url': '',
                'data-gtm-click-location': `LNB`,
                'data-gtm-click-direction': '내부',
                'data-gtm-click-text': `사용 가능 ${coupons.useYcouponCnt}`,
            }
        },
        {
            id: "1",
            label: `사용/기간 만료 ${coupons.useNcouponCnt}`,
            dataAttributes: {
                'data-gtm-event-name': 'all_click',
                'data-gtm-event-category': '클릭',
                'data-gtm-event-action': `LNB - 링크 클릭`,
                'data-gtm-event-label': `컨텐츠 : 사용/기간 만료 ${coupons.useNcouponCnt}`,
                'data-gtm-click-url': '',
                'data-gtm-click-location': `LNB`,
                'data-gtm-click-direction': '내부',
                'data-gtm-click-text': `사용/기간 만료 ${coupons.useNcouponCnt}`,
            }
        }
    ];

    useEffect(() => {
        // if (location.state && location.state.couponSort) {
        //     setCouponSort(location.state.couponSort);
        // }
        window.scrollTo(0, 0.1);
        gtmPageLoad({url: '/my/coupon', contentGroup: '마이|내 쿠폰', moduleName: '', pageTitle: '내 쿠폰'});
    }, []);

    useEffect(() => {
        fetchCoupon(1);
    }, [couponSort]);

    const fetchCoupon = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page
        try {
            const req = {
                ...couponSort,
                pageNo: currentPage.toString(),
            }
            couponApi.getCouponIssued(req, 'v1').then((res) => {
                if (res.header.resultCode === '0000') {
                    if (currentPage === 1) {
                        setCoupons(res.body);
                        setPage(2);
                    } else {
                        const newCouponList = coupons.couponList.concat(res.body.couponList);
                        setCoupons({...res.body, couponList: newCouponList});
                        setPage(currentPage + 1);
                    }
                }

                if (res.body.couponList.length === 0) {
                    setHasMore(false);
                }
            });
        } catch (error) {
            console.log('Failed to fetchCoupon :', error);
        }
    };

    const handleTabSelected = (id: string) => {
        if (id === '0') {
            setCouponSort({
                sortType: 'N',
                useYn: 'N'
            });
        }
        if (id === '1') setCouponSort(prevState => ({
            sortType: 'N',
            useYn: 'D'
        }));
    };

    const handleTagSelected = (id: string) => {
        if (id === '0') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'D'
        }));
        if (id === '1' && couponSort.sortType !== 'U') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'U'
        }));
        if (id === '2' && couponSort.sortType !== 'E') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'E'
        }));
    }

    const handleSortBsOpen = (selectedId: string) => {
        setCouponSort(prevState => ({
            ...prevState,
            sortType: selectedId === '0' ? 'N' : 'S'
        }));
    }

    const handleCouponClick = (coupon: CouponItem) => {
        navigate(`/my/coupon/${coupon.cpnId}`, {state: {couponSort: couponSort}, replace: true});
    }

    const renderCouponList = () => (
        <List
            id="couponList"
            type="view"
            fill="none"
            items={coupons.couponList.map(coupon => ({
                id: coupon.cpnId,
                leftSlot: [
                    <Image
                        src={coupon.ptnLogoImgUrl}
                        srcFallback={defaultThumbnail}
                        ratio="1:1"
                        width="48px"
                        rounded={"50%"}
                    />,
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem"
                        }}

                    >
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                eyebrow: (
                                    <>
                                        <span>{coupon.ptnName}</span>
                                        {/*<CommonDividerVertical/>*/}
                                        {/*<span>{coupon.cpnId}</span>*/}
                                    </>
                                ),
                                title: coupon.summary,
                                description: `${formatDate(coupon.expStartDate)} ~ ${formatDate(coupon.expEndDate)}`
                            }}
                        />
                    </div>
                ],
                rightSlot: [
                    couponSort.useYn === 'N' ? (<Button size="small" color="primary" state="default">
                        쿠폰 보기
                    </Button>) : <Button size="small" color="primary" state="default" disabled>
                        기간 만료
                    </Button>
                ],
                onClick: () => handleCouponClick(coupon),
                dataAttributes: {
                    'data-gtm-event-name': 'all_click',
                    'data-gtm-event-category': '클릭',
                    'data-gtm-event-action': `쿠폰 리스트 - 쿠폰 보기`,
                    'data-gtm-event-label': `컨텐츠 : ${coupon.summary}`,
                    'data-gtm-click-url': '',
                    'data-gtm-click-location': '쿠폰 리스트',
                    'data-gtm-click-direction': '내부',
                    'data-gtm-click-text': `${coupon.summary}`,
                }
            }))}
        />
    );
    const renderNoCoupon = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '172px 0',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image src={emptyCouponImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{paddingTop: '20px'}}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        {couponSort.useYn === 'N' ? '받은 쿠폰이 없어요' : couponSort.useYn === 'D' ? '사용 완료 / 기간 만료 쿠폰이 없어요' : couponSort.useYn === 'U' ? '사용 완료 쿠폰이 없어요' : '기간 만료 쿠폰이 없어요'}
                    </Text>
                </div>
            </div>
        </div>);

    return (

        <>
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                <InfiniteScroll next={fetchCoupon} hasMore={hasMore}
                                loader={<BarcodeLoading/>}
                                dataLength={Number(coupons.couponList.length)}
                                scrollableTarget={"window"}
                >
                    <CommonAllIconHeader title='내 쿠폰' divider={false}/>

                    <Box type="1_b">
                        {/*<CustomTab items={tabItems} selectedItemId={couponSort.useYn === 'N' ? '0' : '1'}*/}
                        {/*           onTabChange={handleTabSelected}/>*/}
                        <Tab
                            fill="underline"
                            items={tabItems}
                            layout="fullWidth"
                            selectedItemId={couponSort.useYn === 'N' ? '0' : '1'}
                            size="medium"
                            style={{
                                padding: "0 20px",
                            }}
                            onItemSelected={handleTabSelected}
                        />
                        {/* 태그 */}
                        {/*{(couponSort.useYn === 'D' || couponSort.useYn === 'U' || couponSort.useYn === 'E') && (*/}
                        {/*    <TagTab dataList={TAG_LIST} onSelected={handleTagSelected} selectedItem={'0'}/>)}*/}
                        {/* 정렬 */}
                        {couponSort.useYn === 'N' &&
                            <SortFilter totalCount={coupons.couponList.length.toString()} sortList={sortFilterList}
                                        onSortChange={handleSortBsOpen} isUsableCheckBox={false} isNumber={false}/>}
                        {/*--- 쿠폰 리스트 ---*/}
                        {coupons.couponList.length == 0 ? renderNoCoupon() : renderCouponList()}
                    </Box>
                </InfiniteScroll>
            </Box>
        </>
    )
};

export default MyCoupon;
