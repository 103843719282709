export interface AttendanceInfo {
    yearMonth: string; // 202408
    attendId: string; // 4
    attendName: string; // 추ㄹ석이벤트9월
    attendCheckDateList: { attendCheckDate: string }[]; // 
    specialDateList: { specialDate: string }[]; // 2024-08-01 00:00:00
    winnerEventLink: string;
    infoEventLink: string;
    benefitSummary: string;
    giftList: GiftItem[];
}

export interface GiftItem {
    giftType: string;
    giftName: string;
    attendCntLimit: number;
}

const today = new Date();
const yyyymmdd = today.getFullYear().toString() +
    (today.getMonth() + 1).toString().padStart(2, '0') +
    today.getDate().toString().padStart(2, '0');
export const initialAttendance: AttendanceInfo = {
    yearMonth: yyyymmdd,
    attendName: '',
    attendId: '',
    attendCheckDateList: [],
    specialDateList: [],
    winnerEventLink: '',
    infoEventLink: '',
    benefitSummary: '',
    giftList: []
};

export interface EventItem {
    evtImg: string;         // 이벤트 이미지
    evtThumbImg: string;    // 이벤트 썸네일 이미지
    evtTitle: string;       // 이벤트 타이틀
    evtNewYn: string;       // 신규 이벤트 여부 값 (예: 'Y' 또는 'N')
    evtStartDate: string;   // 이벤트 기간 시작일 (YYYYMMDD 형식)
    evtEndDate: string;     // 이벤트 기간 종료일 (YYYYMMDD 형식)
    evtId: string;          // 이벤트 ID
    evtTargetType: string;
    evtTargetLink: string;
}
