import React, {useEffect, useRef, useState} from 'react';
import {Box, TextField} from '@lguuxe/2024_designsystem_uni';
import {validateNameCharacter, validateNameLength} from '../../pages/IdentityVerification/validators';

interface NameInputProps {
    onChange: (value: string) => void;
    resetSignal: number;
}

const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

const NameInput: React.FC<NameInputProps> = ({resetSignal, onChange}) => {
    const [name, setName] = useState('');
    const [supportText, setSupportText] = useState<any[]>([]);
    const nameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (nameRef.current) {
            nameRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setName('');
        console.log('NameInput.useEffect resetSignal : ', name);
    }, [resetSignal]);

    useEffect(() => {
        onChange(name);
        if (name.length === 0) {
            setSupportText([]);
            return;
        }

        if (!validateNameLength(name)) {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '최소 2자 이상 입력해 주세요.',
                },
            ]);
            return;
        }

        if (!validateNameCharacter(name)) {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '문자만 입력할 수 있어요.',
                },
            ]);
            return;
        }

        const byteLength = new TextEncoder().encode(name).length;
        if (byteLength < 60) {
            setSupportText([]);
        }

    }, [name]);

    const handleChange = (event: any) => {
        setName(event.value || '');
    };

    return (
        <Box type='2_trbl'>
            <TextField
                ref={nameRef}
                fill='single'
                inputType='text'
                label='이름'
                maxLength={60}
                placeholder='홍길동'
                status='default'
                onChange={handleChange}
                value={name}
                supportText={supportText}
            />
        </Box>
    );
};

export default NameInput;
