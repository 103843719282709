import React, {FC} from 'react';
import {Box, Icon, Text} from "@lguuxe/2024_designsystem_uni";

const SectionInfoBox: FC = () => {
    return (
        <div
            style={{
                //     backgroundColor: "#F9FAFB",
                padding: "20px 0",
                //     borderRadius: "10px",
                //     display: "flex",
                //     alignItems: "flex-start",
                //     flexDirection: "column"
            }}
        >
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "row"*/}
            {/*    }}>*/}
            {/*    <Icon color='#7f8a94' name='error_circle_filled' size='20px' style={{ marginRight: "2px" }} />*/}
            {/*    <Text typo="font_body_2M" color="color_text_neutral_3" style={{ marginBottom: "8px" }}>*/}
            {/*        개명한 경우, 앱을 재설치하거나 초기화한 후 다시 실행하면 자동으로 변경된 이름이 반영됩니다.*/}
            {/*    </Text>*/}
            {/*</div>*/}


            <Box type="2_trbl" style={{
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                backgroundColor: '#F3F5F6',
                borderRadius: '12px',
                // display: 'flex',
                gap: '6px',
                padding: '16px 20px 20px 20px',
                border: '1px solid #E7EBEE',
                boxSizing: 'border-box',
                margin: '0 20px',
            }}>
                {/*<Text typo="font_heading_5B" color="color_text_neutral_6">등급 업그레이드 팁</Text>*/}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                }}>
                    <Icon color='#7f8a94' name='error_circle_filled' size='20px'/>
                    <Text typo='font_body_2M' color='color_text_neutral_3'>
                        개명한 경우, 앱을 재설치/초기화한 후 다시 실행하면 자동으로 변경된 이름으로 반영돼요.
                    </Text>
                </div>
                <ul style={{
                    padding: "5px 0 0 20px",
                    margin: "0",
                    fontSize: "14px",
                    color: "#66707A",
                    listStyleType: 'disc',

                }}>
                    <li style={{lineHeight: "21px",}}>
                        아이폰 : 앱을 삭제하고 다시 설치해 주세요.
                    </li>
                    <li style={{lineHeight: "21px",}}>
                        {'안드로이드 휴대폰 : 홈 화면에서 U+멤버십 앱을 1초 이상 누른 후 [애플리케이션 정보] > [저장 공간] > [데이터 삭제]를 누르세요.'}
                    </li>
                </ul>
            </Box>
        </div>
    );
}

export default SectionInfoBox;