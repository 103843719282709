import React from 'react';
import {Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import logoImg from '../../images/icon/5_full menu/uplus_logo.png';
import logo2Img from '../../images/icon/5_full menu/upluskok_logo.png';
import logo3Img from '../../images/icon/5_full menu/moneyme_logo.svg';
import udocImg from '../../images/icon/5_full menu/udoc_logo.svg';
import ixioImg from '../../images/icon/5_full menu/ixio_logo.svg';
import teumImg from '../../images/icon/5_full menu/teum_logo.svg';
import mobileManagerImg from '../../images/icon/5_full menu/mobileManager_logo.svg';
import musicBellingImg from '../../images/icon/5_full menu/musicBelling_logo.svg';
import {useNavigate} from "react-router-dom";
import {moneymeEnter} from "../../api/externalApi";
import {getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn} from "../../api/nativeBridge";
import otherApi from "../../api/gatewayApi/other";
import {ENVIROMENT} from "../../api/api";

const UPLUS_KOK_STG = 'https://dev.kok.uplus.co.kr'; // 검수, 개발
const UPLUS_KOK_PROD = 'https://kok.uplus.co.kr';

interface RecommendAppListProps {
    handlePopup: (type: string) => void;
}

interface AppItem {
    id: number;
    logo: string;
    title: string;
    link: string;
}

const ALL_APP_LIST: AppItem[] = [
    {
        id: 1,
        logo: logoImg,
        title: '당신의U+',
        link: 'https://app.lguplus.com/apcm/html-push?url=%2Fmain&utm_source=uplusmembership&utm_medium=recommend',
    },
    {
        id: 2,
        logo: logo3Img,
        title: '머니Me',
        link: 'moneyme',
    },
    {
        id: 3,
        logo: logo2Img,
        title: 'U+콕',
        link: 'upluskok',
    },
    {
        id: 4,
        logo: udocImg,
        title: '유독',
        link: 'https://m.lguplus.com/pogg/main?&utm_campaign=u24udok01pfm&utm_source=members&utm_medium=inapp_internal_conversion_cpa&utm_content=familyapp&utm_term=non',
    },
    {
        id: 5,
        logo: ixioImg,
        title: '익시오',
        link: 'https://ixi-o.com/link/recent',
    },
    {
        id: 6,
        logo: mobileManagerImg,
        title: '모바일매니저',
        link: 'https://lguplus-mobilemanager.onelink.me/wMFR/t55tmydk',
    },
    {
        id: 7,
        logo: teumImg,
        title: '틈',
        link: 'https://teum-app-push.onelink.me/5JH8/m3puftbo',
    },
    {
        id: 8,
        logo: musicBellingImg,
        title: '뮤직벨링',
        link: 'https://lgudcb.com/3WQEYqa|https://lgudcb.com/3AtI6kl', // IOS | Android
    },
];


const RecommendAppList: React.FC<RecommendAppListProps> = ({handlePopup}) => {
    const navigate = useNavigate();
    const [platform, setPlatform] = React.useState<string>('');
    const [appList, setAppList] = React.useState<AppItem[]>(ALL_APP_LIST);
    const [memberStatus, setMemberStatus] = React.useState('');

    React.useEffect(() => {
        const fetchPlatform = async () => {
            const platform = await getItemByKey('platform');
            setPlatform(platform);
            const filteredAppList = platform === 'IOS' ? appList.filter(app => app.id !== 6) : appList.filter(app => app.id !== 5);
            setAppList(filteredAppList);
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchPlatform();
    }, []);

    const handleClick = async (app: AppItem) => {
        if (app.link === 'moneyme') {
            if (!memberStatus || memberStatus === '0') {
                handlePopup('login');
                return;
            }
            moneymeEnter()
            return;
        }
        if (app.link === 'upluskok') {
            if (!memberStatus || memberStatus === '0') {
                handlePopup('login');
                return;
            }
            try {
                otherApi.getKokshop('v1').then((res) => {
                    if (res.header.resultCode === '0000' && res.body) {
                        const kokString = res.body.urlString;
                        const url = ENVIROMENT === 'prod' ? UPLUS_KOK_PROD : UPLUS_KOK_STG;
                        const params = {
                            url: url,
                            param: kokString,
                            liveYn: 'Y',
                            rid: 'AD_MBS2',
                            lrid: 'AD_MBS2',
                            mappver: 'Y',
                            appUpd: 'Y',
                            extraLinkUrl: '/'
                        }
                        sendEvent('OPEN_UPKOK', params);
                    } else {
                        handlePopup('error');
                    }
                });
            } catch (e) {
                console.log(e);
                handlePopup('error');
            }
            return;
        }
        if (app.id === 8) { // 뮤직벨링 처리
            const links = app.link.split('|');

            if (platform === 'IOS') {
                app.link = links[0];
            } else {
                app.link = links[1];
            }
        }

        if (app.link.startsWith('http')) {
            sendEventReturn('EXTERNAL_CALL', {type: '1', url: app.link});
        } else {
            navigate(app.link); // 내부 라우팅 처리
        }
    }

    return (
        <>
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>추천 서비스</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div style={{paddingBottom: '12px'}}>
                <List
                    fill="none"
                    name="list_name"
                    size="medium"
                    type="view"
                    weight="default"
                    items={appList.map((app) => ({
                        id: app.id.toString(),
                        leftSlot: [
                            <>
                                <Image ratio="1:1" src={app.logo} width="48"/>
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: (
                                            <>
                                                <Text typo="font_body_1Sb">{app.title}</Text>
                                            </>
                                        ),
                                    }}
                                />
                            </>,
                        ],
                        linkIconOnlyId: app.id.toString(),
                        onClick: () => handleClick(app),
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': 'LNB|U+서비스 - 링크 클릭',
                            'data-gtm-event-label': `컨텐츠 : ${app.title} 이동하기`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': 'LNB|U+서비',
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': `${app.title}`,
                        }
                    }))}
                />
            </div>
        </>
    );
};

export default RecommendAppList;