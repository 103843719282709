import React, {createContext, ReactNode, useContext, useState} from 'react';
import CommonPopup from "../components/Common/CommonPopup";

interface PopupContextType {
    showMessagePopup: (content: string) => void;
    hidePopup: () => void;
    isVisible: boolean;
    content: string;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const useMessagePopup = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
};

export const MessagePopupProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState<string>('');

    const showMessagePopup = (content: string) => {
        setContent(content);
        setIsVisible(true);
    };

    const hidePopup = () => {
        setIsVisible(false);
        setContent('');
    };

    return (
        <PopupContext.Provider value={{showMessagePopup, hidePopup, isVisible, content}}>
            {children}
            <CommonPopup submitText={'확인'} description={content} onSubmit={hidePopup} isOpen={isVisible}/>
        </PopupContext.Provider>
    );
};
