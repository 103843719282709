import React from 'react';
import {Tab} from "@lguuxe/2024_designsystem_uni";

interface SectionTabProps {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
}

const SectionTab: React.FC<SectionTabProps> = ({selectedTab, setSelectedTab}) => {
    return (
        <div>
            <Tab
                fill="underline"
                items={[
                    {
                        id: '1',
                        label: '배지함',
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `LNB - 링크 클릭`,
                            'data-gtm-event-label': '컨텐츠 : 배지함',
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `LNB`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': '배지함',
                        }
                    },
                    {
                        id: '2',
                        label: '전체 내역',
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `LNB - 링크 클릭`,
                            'data-gtm-event-label': '컨텐츠 : 전체 내역',
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `LNB`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': '전체 내역',
                        }
                    }
                ]}
                layout="fullWidth"
                selectedItemId={selectedTab}
                onItemSelected={(id) => setSelectedTab(id)}
                size="medium"
                style={{
                    padding: "0 20px"
                }}
            />
        </div>
    );
}

export default SectionTab;
