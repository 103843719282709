import React, {useEffect, useState} from 'react';
import {BottomSheet, Button, CTA, Header, IconButton, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonDividerThin from '../../components/Common/CommonDividerThin';
import AgreementsDetail from "../../pages/Agreements/AgreementsDetail";
import {useFullScreen} from "../../provider/FullScreenContext";

interface BS_EntryProps {
    setIsEntryOpen: (open: boolean) => void;
    info: any;
    handleApply: (infoCollectYn: string, infoHandlingYn: string) => void;
}

interface Term {
    title: string;
    onChange: () => void;
    link: () => void;
    onClick: () => void;
    isChecked: boolean;
    termKey: string;
}

const BS_Entry: React.FC<BS_EntryProps> = ({setIsEntryOpen, info, handleApply}) => {
    const [terms, setTerms] = useState<Term[]>([]);
    const [isNextButtonActive, setIsNextButtonActive] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null); // 선택된 약관 ID
    const [infoCollectAgreeYn, setInfoCollectAgreeYn] = useState(false);
    const [infoHandlingAgreeYn, setInfoHandlingAgreeYn] = useState(false);
    const [isAllAgreed, setIsAllAgreed] = useState(false); // 전체 동의 상태
    const {show, hide} = useFullScreen();

    useEffect(() => {
        const termsArray: Term[] = [];

        if (info.entPrizeInfo.infoCollectAgreeYn === "Y") {
            const title = "개인정보 수집 및 이용 동의";
            termsArray.push({
                title: title,
                link: () => handleDetailTerms("infoCollectYn", info.entPrizeInfo.infoCollectAgree),
                onClick: () => {
                },
                onChange: () => {
                },
                isChecked: infoCollectAgreeYn,
                termKey: "infoCollectYn"
            });
        }

        if (info.entPrizeInfo.infoHandlingAgreeYn === "Y") {
            const title = "개인정보 처리 및 위탁동의";
            termsArray.push({
                title: title,
                link: () => handleDetailTerms("infoHandlingYn", info.entPrizeInfo.infoHandlingAgree),
                onClick: () => {
                },
                onChange: () => {
                },
                isChecked: infoHandlingAgreeYn,
                termKey: "infoHandlingYn"
            });
        }
        console.log(termsArray)
        setTerms(termsArray);
    }, [info.entPrizeInfo, infoCollectAgreeYn, infoHandlingAgreeYn]);

    // 개별 항목들이 모두 체크되면 전체 동의도 체크되도록 설정
    useEffect(() => {
        const allChecked = terms.every((term) => term.isChecked);
        setIsAllAgreed(allChecked);
        setIsNextButtonActive(allChecked);
    }, [terms]);

    const handleAllAgree = () => {
        const newAllAgreeState = !isAllAgreed;
        setTerms(terms.map((term) => ({...term, isChecked: newAllAgreeState})));
    };

    const handleTermCheck = (index: string) => {
        const newTerms = terms.map((term, idx) =>
            Number(index) === idx ? {...term, isChecked: !term.isChecked} : term
        );
        setTerms(newTerms);
    };

    const handleDetailTerms = (termKey: string, content: string) => {
        show(<AgreementsDetail
            selected={termKey === 'infoCollectYn' ? infoCollectAgreeYn : infoHandlingAgreeYn}
            tempTermTitle={termKey === 'infoCollectYn' ? "개인정보 수집 및 이용 동의" : "개인정보 처리 및 위탁동의"}
            termId={"temp"}
            handleClose={() => hide()}
            type={termKey}
            tempTermContent={content}
        />)
    };

    const handleSubmit = () => {
        const infoCollectYn = terms.find(term => term.termKey === 'infoCollectYn')?.isChecked ? 'Y' : 'N';
        const infoHandlingYn = terms.find(term => term.termKey === 'infoHandlingYn')?.isChecked ? 'Y' : 'N';
        handleApply(infoCollectYn, infoHandlingYn);
    };

    return (
        <>
            <BottomSheet isOpen onClose={() => {
            }}>
                <BottomSheet.TopSlot>
                    <Header align="left" variant="heading" divider={false}>
                        <Header.CenterSlot>
                            <Text typo="font_heading_3B" color="color_text_neutral_6">
                                개인정보 수집 및 활용 동의
                            </Text>
                        </Header.CenterSlot>
                        <Header.RightSlot>
                            <IconButton
                                color="neutral"
                                fill="icon"
                                icon="close_line"
                                noStates
                                onClick={() => {
                                    setIsEntryOpen(false);
                                }}
                            />
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>
                <BottomSheet.CenterSlot>
                    <div>
                        <List
                            fill="none"
                            items={[
                                {
                                    checked: isAllAgreed,
                                    text: {
                                        title: '전체 동의',
                                    },
                                },
                            ]}
                            type="checkbox"
                            size="large"
                            weight="bolder"
                            onChange={handleAllAgree}
                        />
                    </div>

                    <div style={{padding: '0 20px'}}>
                        <CommonDividerThin/>
                    </div>

                    <div>
                        <List
                            fill="none"
                            size="medium"
                            type="checkbox"
                            items={terms.map((term, index) => ({
                                checked: term.isChecked,
                                id: index.toString(),
                                leftSlot: (
                                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                        <TextSet
                                            subComponent="Body_1"
                                            text={{
                                                title: (
                                                    <span style={{color: "#181A1B"}}>
                                                        {term.title}
                                                    </span>
                                                ),
                                            }}
                                        />
                                    </div>
                                ),
                                rightSlot: (
                                    <IconButton
                                        color="neutral"
                                        fill="icon"
                                        size="large"
                                        icon="chevron_right_small_line"
                                        onClick={term.link}
                                        noStates
                                    />
                                )
                            }))}
                            onChange={(e) => handleTermCheck(e.id)}
                        />
                    </div>

                </BottomSheet.CenterSlot>

                <BottomSheet.BottomSlot>
                    <CTA gradation layout="row">
                        <Button
                            color="primary"
                            size="large"
                            disabled={!isNextButtonActive}
                            onClick={handleSubmit}
                        >
                            동의 후 참여하기
                        </Button>
                    </CTA>
                </BottomSheet.BottomSlot>
            </BottomSheet>
        </>
    );
};

export default BS_Entry;
