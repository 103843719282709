import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

type AdditionalProp = {

    value: string;
    encrypt?: boolean;
};

const nftApi = {
    getMyBadge: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.NFT.MY_BADGE, {}, version),
    getEvent: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.EVENT, params, version),
    getPromotion: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.PROMOTION, params, version),
    getCheckWinning: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.CHECK_WINNING, params, version),
    getCategories: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.CATEGORIES, params, version),
    getCategoryById: (categoryId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.CATEGORY_BY_ID(categoryId), params, version),
    getTerms: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.TERMS, params, version),
    getTermsAgreements: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.TERMS_AGREEMENTS, params, version),
    postReceiptInfo: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.NFT.RECEIPT_INFO, body, version),
    getCheckSending: (version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.CHECK_SENDING, {}, version),
    getHistory: (params: ApiParams, version: string, mcpTrKey: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.HISTORY, params, version, mcpTrKey),
    getSend: (params: ApiParams, version: string, mcpTrKey: string, additionalProps?: {
        [key: string]: AdditionalProp
    }): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.SEND, params, version, mcpTrKey, additionalProps),
    getCheckReceiver: (params: ApiParams, version: string, mcpTrKey: string, additionalProps?: {
        [key: string]: AdditionalProp
    }): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.CHECK_RECEIVER, params, version, mcpTrKey, additionalProps),
    getBadgeById: (categoryId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.BADGE_BY_CategoryId(categoryId), params, version),
    getAvailableGiftList: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.NFT.AVAILABLE_GIFTLIST, params, version),
};

export default nftApi;
