import React from 'react';
import {Button, CTA} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";

interface SectionCTABtn2Props {
    status: string
    buttonDisable: boolean
    onChange: () => void
}

const SectionCTABtn2: React.FC<SectionCTABtn2Props> = ({status, buttonDisable, onChange}) => {
    const navigate = useNavigate()
    return (
        <div
            style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
            }}
        >
            <CTA layout="row">
                <Button color="neutral" onClick={() => navigate(-1)}>
                    취소
                </Button>
                <Button color="primary" disabled={buttonDisable} onClick={onChange}>
                    변경하기
                </Button>
            </CTA>
        </div>
    );
}

export default SectionCTABtn2;
