import React, {useEffect, useState} from 'react';
import {Box, TextField} from '@lguuxe/2024_designsystem_uni';
import {isValidRRNFront} from '../../pages/IdentityVerification/validators';

interface ResidentNumberInputProps {
    onChange: (frontNumber: string, backNumber: string) => void;
    resetSignal: number;
}

const ResidentNumberInput: React.FC<ResidentNumberInputProps> = ({onChange, resetSignal}) => {
    const [birth, setBirth] = useState<string>('');
    const [genderCode, setGenderCode] = useState<string>('');
    const [supportText, setSupportText] = useState<any[]>([]);

    useEffect(() => {
        setGenderCode('');
        setBirth('');
    }, [resetSignal]);

    useEffect(() => {
        onChange(birth, genderCode);
        if (birth.length === 0) {
            setSupportText([]);
            return;
        }

        if (!isValidRRNFront(birth)) {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '생년월일을 6자리로 입력해주세요.',
                },
            ]);
            return;
        }

        setSupportText([]);
    }, [birth, genderCode]);

    const handleResidentNumberChange = (event: any) => {
        const {index, value} = event;
        if (index === 0) {
            setBirth(value);
        } else {
            setGenderCode(value);
        }
    };

    return (
        <Box type='2_trbl'>
            <TextField
                fill='multi'
                isAutoTab
                label='주민등록번호'
                isRRNMode={true}
                placeholder={['생년월일 6자리', '*']}
                status='default'
                onChange={handleResidentNumberChange}
                supportText={supportText}
                value={[birth, genderCode]}
            ></TextField>
        </Box>
    );
};

export default ResidentNumberInput;
