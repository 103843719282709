import React, {useEffect, useRef, useState} from "react";
import {UptpData, UptpEvent} from "../../pages/Home/types";
import {Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {formatDateToYYYYMMDD} from "../../utils/helper";
import {sendEventReturn} from "../../api/nativeBridge";
import CommonLoginPopup from "../Common/CommonLoginPopup";
import {useSnackbar} from "../../provider/SnackbarProvider";
import useDebounce from "../../hooks/useDebounce";

interface EventAllProps {
    uptp: UptpData;
    userStatus: string;
}

const EventAll: React.FC<EventAllProps> = ({uptp, userStatus}) => {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<HTMLDivElement[]>([]);

    const [mainEvent, setMainEvent] = useState<UptpEvent>();
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
    const [containerMaxHeight, setContainerMaxHeight] = useState<number | undefined>(undefined);
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const mainImageRef = useRef<HTMLImageElement>(null);
    const {showSnackbar} = useSnackbar();

    const filteredEvents = uptp.uptpList
        ? uptp.uptpList.filter((event) => {
            const eventDateEndString = formatDateToYYYYMMDD(new Date(event.endDate));
            return eventDateEndString >= uptp.currentMonth
        })
        : [];

    useEffect(() => {
        if (filteredEvents.length > 0 && !mainEvent) {
            setMainEvent(filteredEvents[0]);
            setSelectedEventId(filteredEvents[0].evtId);
        }
    }, [mainEvent]);

    const updateContainerMaxHeight = () => {
        if (mainImageRef.current) {
            setContainerMaxHeight(mainImageRef.current.clientHeight);
        }
    };

    useEffect(() => {
        updateContainerMaxHeight();
        window.addEventListener('resize', updateContainerMaxHeight);

        return () => {
            window.removeEventListener('resize', updateContainerMaxHeight);
        };
    }, []);

    // 무제한 UPTP 용
    // // Extend the events list multiple times
    // const extendedEvents = [...filteredEvents, ...filteredEvents, ...filteredEvents];
    //
    // useEffect(() => {
    //     const container = containerRef.current;
    //     if (!container) return;
    //
    //     const handleScroll = () => {
    //         const scrollTop = container.scrollTop;
    //         const scrollHeight = container.scrollHeight;
    //         const clientHeight = container.clientHeight;
    //
    //         if (scrollTop <= 0) {
    //             // User scrolled to the top
    //             container.scrollTop = scrollTop + scrollHeight / 3;
    //         } else if (scrollTop + clientHeight >= scrollHeight) {
    //             // User scrolled to the bottom
    //             container.scrollTop = scrollTop - scrollHeight / 3;
    //         }
    //     };
    //
    //     container.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         container.removeEventListener('scroll', handleScroll);
    //     };
    // }, [extendedEvents]);

    const handleEventClick = useDebounce(() => {
        if (userStatus === "0") {
            setIsLogin(true);
        } else {
            if (mainEvent?.targetLink && !isNaN(Number(mainEvent.targetLink))) {
                sendEventReturn('WAITING_LINE_POPUP', {id: mainEvent?.targetLink, target: 'detail'});
            } else {
                showSnackbar('준비중이에요')
            }
        }
    });

    const getLabel = (startDate: string) => {
        const eventStartString = formatDateToYYYYMMDD(new Date(startDate));
        const currentMonthString = uptp.currentMonth;

        const eventStartDate = new Date(
            parseInt(eventStartString.slice(0, 4)), // 년도
            parseInt(eventStartString.slice(4, 6)) - 1, // 월 (0부터 시작)
            parseInt(eventStartString.slice(6, 8)) // 일
        );

        const currentMonthDate = new Date(
            parseInt(currentMonthString.slice(0, 4)),
            parseInt(currentMonthString.slice(4, 6)) - 1,
            parseInt(currentMonthString.slice(6, 8))
        );

        const diffInTime = eventStartDate.getTime() - currentMonthDate.getTime();
        if (diffInTime === 0) {
            return "Today";
        } else if (diffInTime > 0) {
            const diffDays = Math.floor(diffInTime / (1000 * 3600 * 24));
            return `D-${diffDays}`;
        } else { // 시작 날짜가 지난 경우에도 TODAY로 표시
            return "Today";
        }
    };

    const scrollToItem = (index: number) => {
        const container = containerRef.current;
        const item = itemRefs.current[index];

        if (container && item) {
            const containerHeight = container.clientHeight;
            const itemOffsetTop = item.offsetTop;
            const itemHeight = item.clientHeight;

            const scrollPosition = itemOffsetTop - containerHeight / 2 + itemHeight / 2;
            container.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div style={{padding: "0 20px"}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "20px 0 0 0",
                        gap: "8px",
                        justifyContent: "space-between",
                        maxHeight: containerMaxHeight ? `${containerMaxHeight}px` : "auto",
                        overflow: "hidden",
                    }}
                >
                    {/* Main large image */}
                    {filteredEvents.length > 0 && mainEvent && (
                        <div
                            className="event_big"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                width: "100%",
                                minWidth: "240px",
                                minHeight: "312px",
                            }}
                        >
                            <img
                                ref={mainImageRef}
                                src={mainEvent.mainImgUrl || ""}
                                style={{
                                    borderRadius: "16px",
                                    width: "100%",
                                }}
                                onLoad={updateContainerMaxHeight}
                                onClick={handleEventClick}
                                alt={filteredEvents[0]?.categoryName || "Event Image"}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'혜택 블록 - 링크 클릭'}
                                data-gtm-event-label={`컨텐츠 : 선택|${mainEvent?.targetLink}`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'혜택 블록'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`선택|${mainEvent?.targetLink}`}
                                data-gtm-mkt-event-id={mainEvent?.evtId}
                                data-gtm-mkt-event-name={''}
                                data-gtm-mkt-event-period={`${mainEvent?.startDate.slice(0, 10)} ~ ${mainEvent?.endDate.slice(0, 10)}`}
                                data-gtm-affiliate-id={''}
                                data-gtm-affiliate-name={''}
                            />
                        </div>
                    )}

                    {/* Small thumbnail images */}
                    <div
                        ref={containerRef}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                            gap: "3px",
                            position: "relative",
                            width: "24%",
                            minWidth: "72px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            height: containerMaxHeight ? `${containerMaxHeight}px` : "312px",
                        }}
                    >
                        {filteredEvents.map((event, index) => {
                            const label = getLabel(event.startDate);
                            const isSelected = event.evtId === selectedEventId;
                            return (
                                <div
                                    key={`${event.evtId}-${index}`}
                                    ref={(el) => {
                                        if (el) {
                                            itemRefs.current[index] = el;
                                        }
                                    }}
                                    className={`event_small hover_arrow ${isSelected ? 'selected' : ''}`}
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                    }}
                                    onClick={() => {
                                        setMainEvent(event);
                                        setSelectedEventId(event.evtId);
                                        scrollToItem(index);
                                    }}
                                    data-gtm-event-name={'all_click'}
                                    data-gtm-event-category={'클릭'}
                                    data-gtm-event-action={'혜택 블록 - 링크 클릭'}
                                    data-gtm-event-label={`컨텐츠 : 탐색|${event.evtId}`}
                                    data-gtm-click-url={''}
                                    data-gtm-click-location={'혜택 블록'}
                                    data-gtm-click-direction={'내부'}
                                    data-gtm-click-text={`탐색|${event.evtId}`}
                                    data-gtm-mkt-event-id={event.evtId}
                                    data-gtm-mkt-event-name={''}
                                    data-gtm-mkt-event-period={`${event.startDate.slice(0, 10)} ~ ${event.endDate.slice(0, 10)}`}
                                    data-gtm-affiliate-id={''}
                                    data-gtm-affiliate-name={''}
                                >
                                    {label && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                padding: "7px",
                                                borderRadius: "4px",
                                                color: "white",
                                            }}
                                        >
                                            <Text typo="font_body_2Sb">{label}</Text>
                                        </div>
                                    )}
                                    <img
                                        src={event.thumbImgUrl || ""}
                                        alt={event.categoryName || "Event Thumbnail"}
                                        style={{width: '100%'}}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <CommonLoginPopup isOpen={isLogin} onClose={() => setIsLogin(false)}/>
        </>
    );
};

export default EventAll;
