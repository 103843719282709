export type PaymentBlock = {
    payBlckYn: string;
    autoPayBlckYn: string;
};

export interface LimitInfo {
    maxLimit: string;
    monthUseAmnt: string;
    useAmt: string;
}

export const initLimit: LimitInfo = {
    maxLimit: "",
    monthUseAmnt: "",
    useAmt: ""
}
