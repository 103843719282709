import {Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";

const NotLogin: React.FC = () => {
    return (
        <div
            style={{
                display: "inline-flex",
                flexDirection: "column",
                flex: "1"
            }}
        >
            <Text
                typo="font_body_2Sb"
                color="color_text_neutral_5"
                style={{
                    display: "inline"
                }}
            >
                지금 <span style={{display: "inline-block", color: "#E6007E"}}>본인인증</span>하고 다양한 혜택을 <br/>이용해 보세요!
            </Text>
        </div>
    )
}

export default NotLogin