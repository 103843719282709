import React, {useEffect, useState} from 'react';
import SectionHeading from '../../components/Agreements/SectionHeading';
import SectionChkList from '../../components/Agreements/SectionChkList';
import TermUnitedInfoBoxCTA from '../../components/Agreements/TermUnitedInfoBoxCTA';
import {Agreement} from './types';
import {useLocation} from "react-router-dom";
import AgreementsDetail from "./AgreementsDetail";
import commonApi from "../../api/gatewayApi/common";
import {useFullScreen} from "../../provider/FullScreenContext";
import {sendEvent} from "../../api/nativeBridge";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {useMessagePopup} from "../../provider/MessagePopupProvider";

interface AdditionalAgreementsProps {
    sortedAgreementList: Agreement[]
    reAgreeContent: string
    addAgreeContent: string
}

const AdditionalAgreements: React.FC<AdditionalAgreementsProps> = ({sortedAgreementList, reAgreeContent, addAgreeContent}) => {
    const [agreementList, setAgreementList] = useState<Agreement[]>(sortedAgreementList);
    const [allCheck, setAllCheck] = useState(false);
    const [subType, setSubType] = useState("");  // 필수/선택 여부 설정
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);  // 버튼 활성화 상태
    const [isDetailOpen, setIsDetailOpen] = useState(false);  // Bottom Sheet의 상태 관리
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [showCheckbox, setShowCheckbox] = useState<boolean>(false)
    const {hide} = useFullScreen();
    const serviceId = "3";
    const location = useLocation();
    const info = location.state;
    const {showMessagePopup} = useMessagePopup()

    const handleTermClick = (termId: string) => {
        console.log(termId)
        setSelectedTermId(termId);
        setIsDetailOpen(true);  // Bottom Sheet 열기
    };

    const handleDetailClose = () => {
        setIsDetailOpen(false);  // Bottom Sheet 닫기
    };

    const handleCTAButtonClick = async (isChecked?: boolean) => {
        const termsList = agreementList.map(term => ({
            termId: term.termId,
            termYn: term.selected ? "Y" : "N"
        }));
        try {
            const response = await commonApi.terms.postAgreementsConfirm({termsList}, "v1");
            if (response.header.resultCode === '0000') {
                showMessagePopup("약관 동의가 완료되었어요.");

                // Y로 승인된 termId 제외하고 나머지 agreementList를 IndexedDB에 저장
                const approvedTermsIds = termsList
                    .filter(term => term.termYn === "Y")  // Y로 승인된 항목들만 필터링
                    .map(term => term.termId);  // termId만 추출
                const remainingAgreementList = agreementList.filter(
                    term => !approvedTermsIds.includes(term.termId)  // 승인된 termId 제외
                );
                const nonRequiredAgreements = remainingAgreementList.filter(term => !term.isRequired);
                await addItem("agreementList", nonRequiredAgreements);

                if (typeof isChecked !== "undefined") {
                    if (isChecked) {
                        const currentDay = await getItemByKey("currentDay");
                        await addItem("termsHideDuration", currentDay);
                    } else {
                        await addItem("termsHideDuration", null);
                    }
                }
            } else {
                showMessagePopup(response.header.message);
            }
        } catch (error) {
            showMessagePopup("약관 동의 전송에 실패했습니다.");
            console.error("약관 동의 전송 실패:", error);
        }
        hide();
    };

    const finishApp = () => {
        sendEvent('APP_FINISH', {})
    }

    const handleCancelClick = () => {
        const hasRequiredTerms = agreementList.some(term => term.isRequired);
        if (hasRequiredTerms) {
            finishApp();  // 필수 약관이 있을 경우 finishApp 호출
        } else {
            hide();  // 필수 약관이 없으면 팝업 닫기
        }
    };

    const onCheckbox = async () => {
        const currentDay = await getItemByKey("currentDay")
        await addItem("termsHideDuration", currentDay)

        const nonRequiredAgreements = agreementList.filter(agreement => !agreement.isRequired);
        await addItem("agreementList", nonRequiredAgreements);
    }

    useEffect(() => {
        const allChecked = agreementList.every(term => term.selected);
        const allRequiredChecked = agreementList.every(term => term.isRequired ? term.selected : true);
        const anyOptionalSelected = agreementList.some(term => !term.isRequired && term.selected);

        if (allCheck !== allChecked) {
            setAllCheck(allChecked);
        }

        // subType 설정 로직
        const hasRequired = agreementList.some(term => term.isRequired);
        if (hasRequired) {
            setIsButtonEnabled(allRequiredChecked);  // 필수 약관이 모두 체크되었는지 확인하여 버튼 상태 설정
        } else {
            setIsButtonEnabled(anyOptionalSelected);  // 필수 항목이 없으면 선택 항목 중 하나라도 선택되면 버튼 활성화
        }

        const hasOptional = agreementList.some(term => !term.isRequired);

        if (hasRequired && hasOptional) {
            setSubType("필수선택");
        } else if (hasRequired && !hasOptional) {
            setSubType("필수");
        } else if (!hasRequired && hasOptional) {
            setSubType("선택");
            setShowCheckbox(true)
        }
    }, [agreementList, allCheck]);

    const handleAgreementChange = (termId: string, isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term =>
                term.termId === termId ? {...term, selected: isChecked} : term
            )
        );
    };

    const handleAllAgreementsChange = (isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term => ({...term, selected: isChecked}))
        );
        setAllCheck(isChecked);
    };

    return (
        <>
            {!isDetailOpen &&
                <div style={{paddingTop: "56px"}}>
                    <SectionHeading mainType={"additional"} subType={subType} reAgreeContent={reAgreeContent} addAgreeContent={addAgreeContent}/>
                    <SectionChkList
                        subType={subType}
                        agreementList={agreementList}
                        allCheck={allCheck}
                        onAgreementChange={handleAgreementChange}
                        onAllAgreementsChange={handleAllAgreementsChange}
                        onTermClick={handleTermClick}
                    />
                    <TermUnitedInfoBoxCTA onCheckbox={onCheckbox} showCheckbox={showCheckbox} subType={subType}
                                          onClickCancel={handleCancelClick}
                                          onClickConfirm={handleCTAButtonClick} isButtonEnabled={isButtonEnabled}/>
                </div>
            }
            {isDetailOpen && <AgreementsDetail
                selected={agreementList.find(term => term.termId === selectedTermId)?.selected || false}
                type={"additional"} termId={selectedTermId || ""}
                handleClose={handleDetailClose}
            />}
        </>
    );
};

export default AdditionalAgreements;
