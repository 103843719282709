import React, {useEffect, useState} from "react";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import SectionChip from "../../components/Notice/SectionChip";
import SectionList from "../../components/Notice/SectionList";
import useLoading from "../../hooks/useLoading";
import {AlarmItem} from "./types";
import {notiApi} from "../../api/gatewayApi/notiAndQna";
import {getItemByKey} from "../../utils/indexedDB";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import CommonPopup from "../../components/Common/CommonPopup";
import {gtmPageLoad} from "../../api/gtm";

const UMembershipNotify: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alarmList, setAlarmList] = useState<AlarmItem[]>([]);
    const [filter, setFilter] = useState<string>('전체');
    const [memberStatus, setMemberStatus] = useState<string>('');
    const [showLoginPopup, setShowLoginPopup] = React.useState<boolean>(false);
    const [showVipPopup, setShowVipPopup] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchMemberStatus = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchMemberStatus();
        gtmPageLoad({url: '/notice', contentGroup: '알림', moduleName: '', pageTitle: '알림'});
    }, []);

    useEffect(() => {
        const fetchAlarm = async () => {
            try {
                const response = await notiApi.getAlarm({searchAlarmType: getSearchAlarmType(filter)}, 'v1');
                setAlarmList(response.body.alarmList);
            } catch (error) {
                console.error("Error fetchAlarm detail:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAlarm();
    }, [filter]);

    const getSearchAlarmType = (filter: string): string => {
        switch (filter) {
            case '전체':
                return 'ALL';
            case '혜택':
                return '160003|160004|160005|160008|160009|160010|160011|160012|160013';
            case '휴대폰결제':
                return '160006|160007';
            case '공지':
                return '160001';
            case '이벤트':
                return '160002';
            default:
                return 'ALL';
        }
    };

    const handleCheckState = (type: string): boolean => {
        if (type === 'login') {
            if (memberStatus === '0') {
                setShowLoginPopup(true);
                return false;
            }
        }

        if (type === 'vip') {
            if (memberStatus !== '1' && memberStatus !== '3') {
                setShowVipPopup(true);
                return false;
            }
        }

        return true;
    }

    return (
        <>
            {useLoading(isLoading)}
            {/* header */}
            <CommonLeftIconHeader title={"알림"}/>

            {/* chip 메뉴 */}
            <SectionChip setFilter={setFilter}/>

            {/* list 목록 */}
            <SectionList alarmList={alarmList} onCheckState={handleCheckState}/>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
            <CommonPopup submitText={'확인'} onSubmit={() => setShowVipPopup(false)} isOpen={showVipPopup}
                         description={'VIP 등급만 이용 가능해요'}/>
        </>
    );
};

export default UMembershipNotify;
