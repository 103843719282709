import {getItemByKey} from "../utils/indexedDB";

export const generateEssentialHeader = async (mcpTrKey?: string) => {
    mcpTrKey = mcpTrKey || generateMCP_TR_KEY();
    const memberId = await getItemByKey('memberId') || ''
    const ctn = await getItemByKey('ctn') || ''
    const birthday = await getItemByKey('birthday') || ''
    const encryptedBirthDay = await encrypt(mcpTrKey, birthday)
    const encryptedMemberId = await encrypt(mcpTrKey, memberId)
    const encryptedCtn = await encrypt(mcpTrKey, ctn)
    const customHeader: { [key: string]: string } = {
        userBirth: encryptedBirthDay,
        ctn: encryptedCtn,
        memberId: encryptedMemberId,
        os: (await getItemByKey('osVersion')) || '',
        osName: (await getItemByKey('platform')) || 'IOS',
        appVer: (await getItemByKey('appVersion')),
        carrier: (await getItemByKey('telcoCode')) === 'L' ? 'L' : 'O', // L: 자사, O: 타사(알뜰폰 포함)
        Authorization: `Bearer ${(await getItemByKey('sessionKey'))}`,
        MCP_TR_KEY: mcpTrKey,
    };

    return customHeader;
}

export const encrypt = async (mcpTrKey: string, value: string) => {
    const encryptKeyStr = getEncryptedKey(mcpTrKey);
    // convert to bytes
    const encryptKey = new TextEncoder().encode(encryptKeyStr);
    const plaintext = new TextEncoder().encode(value);
    const iv = crypto.getRandomValues(new Uint8Array(12));

    const decoder = new TextDecoder();
    const decodedEncryptKey = decoder.decode(encryptKey);
    const decodedPlaintext = decoder.decode(plaintext);
    const decodedIv = decoder.decode(iv);

    try {
        const key = await crypto.subtle.importKey(
            'raw',
            encryptKey,
            {name: 'AES-GCM'},
            false,
            ['encrypt']
        );
        const encryptedBuffer = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv,
            },
            key,
            plaintext
        );

        const combinedBuffer = new Uint8Array(iv.byteLength + encryptedBuffer.byteLength);
        combinedBuffer.set(new Uint8Array(iv), 0);
        combinedBuffer.set(new Uint8Array(encryptedBuffer), iv.byteLength);

        // encode to base64
        // Uint8Array를 문자열로 변환
        const binaryString = Array.from(combinedBuffer).map(byte => String.fromCharCode(byte)).join('');

        // encode to base64
        const encryptedStr = btoa(binaryString);
        return encryptedStr;
    } catch (error) {
        console.error('Error:', error);
    }
    return '';
}

export const decrypt = async (mcpTrKey: string, value: string) => {
    mcpTrKey = getEncryptedKey(mcpTrKey)
    const keyData = new TextEncoder().encode(mcpTrKey);
    const combinedBuffer = Uint8Array.from(atob(value), c => c.charCodeAt(0));
    const iv = combinedBuffer.slice(0, 12);
    const encryptedBuffer = combinedBuffer.slice(12);

    try {
        const key = await crypto.subtle.importKey(
            'raw',
            keyData,
            {name: 'AES-GCM'},
            false,
            ['decrypt']
        );

        const decryptedBuffer = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            encryptedBuffer
        );

        return new TextDecoder().decode(decryptedBuffer);
    } catch (e) {
        console.error(e);
        return null;
    }
}

const SERVER_HOST = 'frontService'
export const generateMCP_TR_KEY = () => {
    const serverHost = SERVER_HOST; // 실제 서버 호스트로 대체
    const yyyyMMddHHmmss = getDate();
    const randomString = generateRandomString();
    return `${serverHost}_${yyyyMMddHHmmss}_${randomString}`;
}

const getDate = () => {
    const now = new Date();
    const date = now
        .toISOString()
        .replace(/[^0-9]/g, '')
        .slice(0, 14);
    return date;
}

const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomStringLength = 14;

    let result = '';
    for (let i = 0; i < randomStringLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const getEncryptedKey = (MCP_TR_KEY: string) => {
    const match = MCP_TR_KEY.match(/_(\d{14})_/);
    const dateTime = match ? match[1] : '';
    let result = '';
    if (dateTime) {
        const lastTwo = dateTime.slice(-2);
        result = dateTime + lastTwo + dateTime + lastTwo;
    }
    return result;
}