import React from 'react';
import {Image, List, Tag, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {getColorTokenForLabel} from "../../utils/helper";
import uptpDefault from "../../images/default/default_uplus_thumb.svg";

interface UPTPItemProps {
    itemList: UPTPItem[];
    threshold?: number;
    onCheckLogin: () => boolean;
}

interface UPTPItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
    tagName: string;
}

const UPTPItem: React.FC<UPTPItemProps> = ({itemList, threshold, onCheckLogin}) => {
    const navigate = useNavigate()
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);

    const onClickUPTP = (item: UPTPItem) => {
        if (onCheckLogin()) {
            navigate(`/uptp-detail/${item.itemId}`)
        }
    }

    return (
        <div>
            <List
                type="view"
                fill="none"
                items={itemsToRender.map(item => ({
                    onClick: () => onClickUPTP(item),
                    leftSlot: [
                        <div
                            key={item.itemId}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                            }}>
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    eyebrow: (
                                        <div
                                            style={{
                                                paddingBottom: "2px"
                                            }}
                                        >
                                            <span>{item.itemSummary.split('/')[0]}</span>
                                            <span className="bar"></span>
                                            <span>{item.itemSummary.split('/')[1]}</span>
                                        </div>
                                    ),
                                    title: (
                                        <Text color="color_text_neutral_6">
                                            {decodeURIComponent(item.itemName)}
                                        </Text>
                                    )
                                }}
                            />
                            <Tag
                                item={item.tagName.split('|').map((tag, index) => ({
                                    label: tag.trim(), // 공백을 제거한 태그 이름
                                    size: "xSmall",
                                    style: "solid",
                                    colorToken: getColorTokenForLabel(tag),
                                }))}
                            />
                        </div>
                    ],
                    rightSlot: [
                        <Image
                            key={item.itemId + '_image'}
                            src={item.itemImgUrl}
                            ratio="1:1"
                            width="72px"
                            srcFallback={uptpDefault}
                        />
                    ],
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': '유플투쁠 혜택 - 링크 클릭',
                        'data-gtm-event-label': `컨텐츠 : ${item.itemName}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': '유플투쁠 혜택',
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${item.itemName}`,
                    }
                }))}
            />
        </div>
    );
}

export default UPTPItem;