import React, {useEffect, useState} from 'react';
import {BottomSheet, Box, Button, CTA, Header, IconButton, List} from '@lguuxe/2024_designsystem_uni';
import {sendEventReturn} from "../../api/nativeBridge";
import CommonDividerThin from "../Common/CommonDividerThin";

interface BS_TermsProps {
    onCloseClick: () => void;
    onAgreeClick: () => void;
    carrier: string;
    isOpen: boolean;
}

interface Term {
    title: string;
    link: string;
}

const BS_Terms: React.FC<BS_TermsProps> = ({onCloseClick, onAgreeClick, carrier, isOpen}) => {
    const [allAgree, setAllAgree] = useState(false);
    const [agreeList, setAgreeList] = useState<boolean[]>([false, false, false, false]);
    const [isNextButtonActive, setIsNextButtonActive] = useState(false);

    const normalizedCarrier = carrier === 'L' || carrier === 'LM' ? 'LGU'
        : carrier === 'S' || carrier === 'SM' ? 'SKT'
            : carrier === 'K' || carrier === 'KM' ? 'KT'
                : carrier;
    const TERMS: Term[] = [
        {
            title: '서비스 이용 약관동의',
            link: 'https://www.kmcert.com/kmcis/mobile_v2/layer/kmcisHpUse_popUpBox.html',
        },
        {
            title: '고유식별 정보 처리 동의',
            link: `https://www.kmcert.com/kmcis/mobile_v2/layer/kmcisHpDiscrAgree_${normalizedCarrier}_popUpBox.html`,
        },
        {
            title: '개인정보 수집 및 이용동의',
            link: `https://www.kmcert.com/kmcis/mobile_v2/layer/kmcisHpAgree_${normalizedCarrier}_popUpBox.html`,
        },
        {
            title: '통신사 이용 약관 동의',
            link: `https://www.kmcert.com/kmcis/mobile_v2/layer/kmcisHpUse_${normalizedCarrier}_popUpBox.html`,
        },
    ];

    useEffect(() => {
        allAgree ? setAgreeList([true, true, true, true]) : setAgreeList([false, false, false, false]);
        console.log('allAgree', allAgree);
    }, [allAgree]);

    useEffect(() => {
        const isAllAgree = agreeList.every((agree) => agree);
        const isAllDisagree = agreeList.every((agree) => !agree);
        setIsNextButtonActive(isAllAgree);
        if (isAllAgree) {
            setAllAgree(true);
        }
        if (isAllDisagree) {
            setAllAgree(false);
        }
    }, [agreeList]);

    const checkTerm = (index: number) => {
        return agreeList[index];
    };

    const handleAllAgree = () => {
        setAllAgree(!allAgree);
    };

    const handleTermCheck = (e: any) => {
        const checkedState = e.checkedState;
        const newAgreeList = TERMS.map((_, index) => checkedState[`terms-${index}`]);
        setAgreeList(newAgreeList);
    };

    const handleCloseButton = () => {
        onCloseClick();
    };

    const handleNextButton = () => {
        onCloseClick();
        onAgreeClick();
    };

    const handleLinkClick = (e: any) => {
        const targetId = e.target.id;
        if (targetId.startsWith('link-')) {
            const index = parseInt(targetId.split('-')[1]);
            const param = {
                type: '1',
                url: TERMS[index].link,
            }
            console.log('EXTERNAL_CALLL param : ', param);
            sendEventReturn('EXTERNAL_CALL', param);
        }
    }

    return (
        <BottomSheet isOpen={isOpen} bottomSpace="0rem" onClose={onCloseClick}>
            <BottomSheet.TopSlot>
                <div
                    style={{
                        marginTop: '8px',
                    }}
                >
                    <Header align='left' variant='heading'>
                        휴대폰 본인 확인 약관 동의
                        <Header.RightSlot>
                            <IconButton
                                color='neutral'
                                fill='icon'
                                icon='close_line'
                                noStates
                                onClick={handleCloseButton}
                            />
                        </Header.RightSlot>
                    </Header>
                </div>
            </BottomSheet.TopSlot>

            <BottomSheet.CenterSlot>
                <List
                    fill='none'
                    items={[
                        {
                            text: {
                                title: '전체 동의',
                            },
                            checked: allAgree,
                        },
                    ]}
                    type='checkbox'
                    size='large'
                    weight='bolder'
                    onChange={handleAllAgree}
                />

                <Box
                    style={{
                        padding: '0 20px',
                    }}
                >
                    <CommonDividerThin/>
                </Box>

                <List
                    id='terms'
                    fill='none'
                    items={agreeList.map((isAgree, index) => ({
                        text: {
                            title: TERMS[index].title,
                        },
                        linkIconOnlyId: 'link-' + index.toString(),
                        // link: TERMS[index].link,
                        checked: isAgree,
                    }))}
                    type='checkbox'
                    weight='default'
                    onChange={handleTermCheck}
                    onClick={handleLinkClick}
                />
            </BottomSheet.CenterSlot>

            <BottomSheet.BottomSlot>
                <CTA layout='row'>
                    <Button color='primary' disabled={!isNextButtonActive} onClick={handleNextButton}>
                        다음
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    );
};

export default BS_Terms;
