import React, {FC} from 'react';
import {Accordion, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {decodeHtmlEntities} from "../../utils/textUtils";

interface MustCheckAccordionProps {
    items: string[];
}

const MustCheckAccordion: FC<MustCheckAccordionProps> = ({items}) => {
    return (
        <div>
            <Accordion
                fill="underline"
                size="large"
                weight="bolder"
                divider={false}
                expanded={true}
                title={
                    <TextSet
                        subComponent="Body_1"
                        text={{
                            title: '꼭 확인하세요',
                        }}
                        weight="bolder"
                    />
                }
            >
                <Accordion.Body>
                    <div>
                        <ul style={{paddingLeft: "26px", margin: "0"}}>
                            {items.map((text) => (
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    {decodeHtmlEntities(text)}
                                </Text>
                            ))}
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default MustCheckAccordion;
