import React from "react";
import {Button, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";


const NoLogin: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                padding: "6px 20px",
                marginTop: "8px"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "12px 20px 28px",
                    gap: "8px"
                }}
            >
                <div
                    style={{
                        padding: "20px 0",
                        backgroundColor: "#F3F5F6",
                        borderRadius: "12px",
                        width: "100%",
                        gap: "12px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "12px",
                            padding: "4.5px 22px",
                        }}
                    >
                        <Text typo="font_body_1M" color="color_text_neutral_3" textAlign="center">
                            U+멤버십 할인/휴대폰결제를 이용하려면 본인인증이 필요해요.
                        </Text>
                        <Button
                            as="button"
                            color="primary"
                            fill="solid"
                            size="medium"
                            state="default"
                            onClick={() => {
                                navigate('/identity-verification');
                            }}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'바코드|멤버십 - 링크 클릭'}
                            data-gtm-event-label={'컨텐츠 : 멤버십 혜택 더보기'}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'바코드|멤버십'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={'본인인증하기'}
                        >
                            본인인증하기
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NoLogin;