import { Text } from "@lguuxe/2024_designsystem_uni";
import React from "react";

const EndMonth : React.FC = () => {
    return (
        <div
            style={{
                display: "inline-flex",
                flexDirection: "column",
                flex: "1"
            }}
        >
            <Text
                typo="font_body_2Sb"
                color="color_text_neutral_5"
                style={{
                    display: "inline"
                }}
            >
                안쓰면 곧 사라져요~<br/>
                이번 달 <span style={{ display: "inline-block", color: "#E6007E" }}>VIP콕</span> 사용해 보세요.
            </Text>
        </div>
    )
}

export default EndMonth