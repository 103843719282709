import React from 'react';
import {Accordion, Text, TextSet} from '@lguuxe/2024_designsystem_uni';

const VerificationInfo: React.FC = () => {
    return (
        <Accordion
            fill='underline'
            size='medium'
            weight='default'
            title={
                <TextSet
                    subComponent='Body_1'
                    text={{
                        title: '인증 문자가 오지 않나요?',
                    }}
                    weight='bolder'
                />
            }
        >
            <Accordion.Body>
                <Text typo='font_body_2M' color='color_text_neutral_3'>
                    <div>
                        <ol style={{margin: '0', paddingLeft: '22px'}}>
                            <li>스팸 설정 여부를 확인해주세요.</li>
                        </ol>
                        <ul style={{margin: '0', paddingLeft: '32px'}}>

                            안드로이드폰: 휴대폰의 ‘문자메시지’ 선택 → 우측 상단 선택 → <br/>
                            설정 → 메시지 차단 → ‘수신 차단’ 에서 02-2033-8500 번호가 <br/>
                            있을 경우 삭제


                            아이폰: 휴대폰의 ‘설정’ 선택 → 메시지 → ‘차단 목록’에서 <br/>
                            02-2033-8500 번호가 있을 경우 삭제
                        </ul>
                        <ol start={2} style={{margin: '0', paddingLeft: '22px'}}>
                            <li>
                                착신전환 부가서비스, 휴대폰번호도용방지 부가서비스에 가입 <br/>
                                하셨다면 해지 후 다시 시도해 주세요.
                            </li>
                            <li>‘인증번호 받기’ 버튼을 정확히 눌렀는지 확인해 주세요.</li>
                            <li>
                                스팸 문자메시지 차단 앱이 설치되어 있다면 삭제 후 다시 시도해 주세요.
                            </li>
                        </ol>
                    </div>
                </Text>
            </Accordion.Body>
        </Accordion>
    );
};

export default VerificationInfo;
