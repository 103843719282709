import React from "react";
import {Box, Text} from "@lguuxe/2024_designsystem_uni";
import {decodeHtmlEntities} from "../../utils/textUtils";

interface BoxHeaderInfoProps {
    title: string;
    content?: string;
}

const BoxHeaderInfo: React.FC<BoxHeaderInfoProps> = ({title, content}) => {
    return (
        <Box
            type="2_trbl"
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
            }}
        >
            <Text typo="font_heading_5B" color="color_text_neutral_6">
                {title}
            </Text>
            <Text typo="font_body_2M" color="color_text_neutral_3">
                {content && decodeHtmlEntities(content)}
            </Text>
        </Box>
    );
};

export default BoxHeaderInfo;
