// SnackbarProvider.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import Snackbar from '../components/Common/Snackbar'; // 기존 Snackbar 컴포넌트를 불러옴

interface SnackbarContextType {
    showSnackbar: (message: string, buttonText?: string, buttonLinkUrl?: () => void) => void;
}

interface SnackbarProviderProps {
    children: ReactNode;  // ReactNode로 children 선언
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackbar, setSnackbar] = useState<{ message: string, buttonText?: string, buttonLinkUrl?: ()=>void } | null>(null);

    const showSnackbar = (message: string, buttonText?: string, buttonLinkUrl?: ()=>void) => {
        setSnackbar({ message, buttonText, buttonLinkUrl });
        setTimeout(() => {
            setSnackbar(null);
        }, 3000); // 3초 뒤에 사라지도록 설정
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {snackbar && (
                <Snackbar
                    text={snackbar.message}
                    buttonText={snackbar.buttonText}
                    buttonLinkUrl={snackbar.buttonLinkUrl}
                />
            )}
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};
