// ****************************************************
// Nodata 공통   padding값만 각 페이지에서 조정 , 하단 내용은 페이지별 상이함.
// ****************************************************
import React from 'react'
import {Image, Text} from "@lguuxe/2024_designsystem_uni";
import emptyFailImg from '../../images/img/empty/empty_fail.svg'

interface PaymentResultNodataProps {
    message: string;
}

const PaymentResultNodata: React.FC<PaymentResultNodataProps> = ({message}) => {

    const formattedMessage = (message: string) => {
        const lines = message.split('\\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br/>} {/* 마지막 줄에 <br> 생략 */}
            </React.Fragment>
        ));
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <Image
                src={emptyFailImg}
                alt="placeholder"
                width="120px"
                ratio="1:1"
            />
            <div style={{paddingTop: "20px"}}>
                <Text typo="font_detail_1M" color="color_text_neutral_3" textAlign="center">
                    결제를 하지 못했습니다.
                </Text>
                <br/>
                <Text typo="font_detail_1M" color="color_text_neutral_3" textAlign="center">
                    (사유 : {formattedMessage(message)})
                </Text>
            </div>
        </div>
    )
}

export default PaymentResultNodata
