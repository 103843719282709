import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

const otherApi = {
    getMissionReward: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MISSION.REWARD_POINT, {}, version),
    getMoneyBenefit: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MONEYME, {}, version),
    getMoneyMeEnter: (version: string): Promise<ApiResponse> => api.post(ApiEndpoints.EXTERNAL_SERVICE.MONEYME, {}, version),
    getDas: (params: ApiParams, version: string, mcpTrKey: string): Promise<ApiResponse> => api.get(ApiEndpoints.DAS, params, version, mcpTrKey),
    postIpinRedirect: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.IPIN.REDIRECT, body, version),
    getKokshop: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.EXTERNAL_SERVICE.KOKSHOP, {}, version),

};

export const couponApi = {
    getCouponIssued: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COUPON.ISSUED.INDEX, params, version),
    getCouponIssuedById: (cpnId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COUPON.ISSUED.BY_ID(cpnId), {}, version),
    getCouponById: (cpnId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.COUPON.BY_ID(cpnId), {}, version),
    postCoupon: (cpnId: string, body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.COUPON.BY_ID(cpnId), body, version),
    deleteCouponById: (cpnId: string, version: string): Promise<ApiResponse> => api.delete(ApiEndpoints.COUPON.ISSUED.BY_ID(cpnId), {}, version),
    putCouponCrewConfirm: (cpnKey: string, body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.COUPON.ISSUED.CREW_CONFIRM(cpnKey), body, version),
    postCouponVipkokById: (ptnId: string, body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.COUPON.VIPKOK.By_ID(ptnId), body, version),
}

export const mapApi = {
    getMapPartners: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MAP.PARTNERS, params, version),
    getMapPartnersById: (ptnId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MAP.PARTNERS_BY_ID(ptnId), params, version),
}


export const searchApi = {
    getSearch: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SEARCH.INDEX, params, version),
    getSearchRecomm: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SEARCH.RECOMM, {}, version),
};

export default otherApi;
