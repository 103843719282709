import {BottomSheet, Box, Button, Header, IconButton, Text, TextField} from "@lguuxe/2024_designsystem_uni";
import {useState} from "react";
import {couponApi} from "../../../api/gatewayApi/other";

interface BS_CouponEmployeeCheckProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    cpnKey: string;
}

const BS_CouponEmployeeCheck: React.FC<BS_CouponEmployeeCheckProps> = ({isOpen, onClose, onSuccess, cpnKey}) => {
    const [pinNo, setPinNo] = useState("");
    const [supportText, setSupportText] = useState<any[]>([]);

    const handleCheck = async () => {

        if (pinNo.length === 0) {
            setSupportText([
                {
                    icon: "",
                    status: "error",
                    text: "비밀번호를 입력해 주세요."
                }
            ])
            return;
        }

        fetchCheck();
    }

    const fetchCheck = async () => {
        try {
            const res = await couponApi.putCouponCrewConfirm(cpnKey, {pinNo: pinNo}, 'v1');
            switch (res.header.resultCode) {
                case '0000':
                    onSuccess();
                    onClose();
                    break;
                default:
                    setSupportText([
                        {
                            icon: "",
                            status: "error",
                            text: "비밀번호를 잘못 입력하셨습니다. 확인 후 다시 입력해 주세요."
                        }
                    ])
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleChange = (event: any) => {
        setPinNo(event.value);
        if (event.value.length !== 0) {
            setSupportText([]);
        }
    }

    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align="left" variant="heading" style={{borderBottom: "1px solid #fff"}}>
                        {/* <Header.LeftSlot>
              <IconButton color="neutral" fill="icon" icon="chevron_left_line" noStates />
            </Header.LeftSlot> */}
                        직원 확인
                        <Header.RightSlot>
                            <IconButton color="neutral" fill="icon" icon="close_line" noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>

                <BottomSheet.CenterSlot>
                    <Box style={{padding: "0", height: "auto"}}>
                        {/* 직원 확인 정보 */}
                        <div style={{padding: "0 0 0"}}>
                            <Text typo="font_body_1M" color="color_text_neutral_3" style={{paddingBottom: "20px"}}>
                                제휴사 직원이신가요? <br/>
                                비밀번호를 입력해 주세요.
                            </Text>
                            <TextField
                                rightAddons={[
                                    <Button color="neutral" size="small" onClick={handleCheck}>
                                        확인
                                    </Button>
                                ]}
                                placeholder="4자리"
                                onChange={handleChange}
                                supportText={supportText}
                            />
                        </div>
                    </Box>
                </BottomSheet.CenterSlot>
            </BottomSheet>
        </>
    )
}

export default BS_CouponEmployeeCheck;