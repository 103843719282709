import React from "react";

interface CommonBsBackgroundProps {
    isOpen: boolean;
    onClose: () => void;
}

const CommonBsBackground: React.FC<CommonBsBackgroundProps> = ({isOpen, onClose}) => {
    return (
        <>
            {isOpen && <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // 검은색 배경, 투명도 조절 가능
                zIndex: 999, // BottomSheet보다 낮은 zIndex를 설정
            }} onClick={onClose} // 배경을 클릭하면 BottomSheet 닫기
            />}
        </>

    );
}

export default CommonBsBackground;