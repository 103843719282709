import React, { useRef, useEffect } from 'react';
import { Text } from "@lguuxe/2024_designsystem_uni";
import '../../css/style.css';
import { MonthEvent } from "../../pages/UPTP/types";
import { CLICK_LOCATION, EVENT_ACTION } from "../../utils/const";

interface SectionCalendarProps {
    monthEvent: MonthEvent;
    currentDay: string;
    selectedDay: string;
    setSelectedDay: (day: string) => void;
}

const SectionCalendar: React.FC<SectionCalendarProps> = ({ monthEvent, currentDay, selectedDay, setSelectedDay }) => {
    const year = parseInt(currentDay.slice(0, 4));
    const month = parseInt(currentDay.slice(4, 6));

    const getDaysInMonth = (year: number, month: number) => {
        return new Array(new Date(year, month, 0).getDate()).fill(null).map((_, i) => i + 1);
    };

    const daysInMonth = getDaysInMonth(year, month);

    // 스크롤 이동을 위한 ref 생성
    const selectedDayRef = useRef<HTMLDivElement | null>(null);
    const calendarRef = useRef<HTMLDivElement | null>(null); // 스크롤 컨테이너에 대한 ref 추가

    const handleItemClick = (day: string) => {
        console.log("selectedDay", day);
        setSelectedDay(day);
    };

    useEffect(() => {
        if (selectedDayRef.current && calendarRef.current) {
            const selectedDayLeft = selectedDayRef.current.offsetLeft;
            const itemWidth = selectedDayRef.current.offsetWidth;

            // 스크롤 위치를 선택된 아이템의 offsetLeft에서 아이템의 너비를 뺀 값으로 설정
            const scrollPosition = Math.max(selectedDayLeft - itemWidth, 0);

            calendarRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [selectedDay]);

    return (
        <div
            className="calendar"
            style={{ display: 'flex', overflowX: 'auto' }}
            ref={calendarRef} // 스크롤 컨테이너에 ref 추가
        >
            {daysInMonth.map((day) => {
                const formattedDay = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
                const dDay = day - parseInt(currentDay.slice(6, 8));

                let className = 'data_box';
                if (formattedDay === selectedDay) {
                    className = 'data_box event';
                } else if (formattedDay < currentDay) {
                    className = 'data_box end';
                }

                return (
                    <div
                        key={day}
                        className={className}
                        onClick={() => handleItemClick(formattedDay)}
                        ref={formattedDay === selectedDay ? selectedDayRef : null}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.UPTP_DATE} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : ${formattedDay}`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.UPTP_DATE}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`${formattedDay}`}

                    >
                        <Text typo="font_heading_4B" className="data">
                            {day.toString()}
                        </Text>
                        <Text typo="font_body_3M">
                            {dDay === 0 ? "Today" : dDay < 0 ? `D+${-dDay}` : `D-${dDay}`}
                        </Text>
                    </div>
                );
            })}
        </div>
    );
}

export default SectionCalendar;
