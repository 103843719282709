import React, {useState} from 'react';
import {Badge, Image, TextSet} from "@lguuxe/2024_designsystem_uni";
import badgeTripOneImg from "../../images/badge/trip_one.svg";
import badgeTripSeveralImg from "../../images/badge/trip_several.svg";
import badgeTripZeroImg from "../../images/badge/trip_zero.svg";
import badgeShoppingOneImg from "../../images/badge/shopping_one.svg";
import badgeShoppingSeveralImg from "../../images/badge/shopping_several.svg";
import badgeShoppingZeroImg from "../../images/badge/shopping_zero.svg";
import badgeStudyOneImg from "../../images/badge/study_one.svg";
import badgeStudySeveralImg from "../../images/badge/study_several.svg";
import badgeStudyZeroImg from "../../images/badge/study_zero.svg";
import badgeBeautyOneImg from "../../images/badge/beauty_one.svg";
import badgeBeautySeveralImg from "../../images/badge/beauty_several.svg";
import badgeBeautyZeroImg from "../../images/badge/beauty_zero.svg";
import badgeContentOneImg from "../../images/badge/content_one.svg";
import badgeContentSeveralImg from "../../images/badge/content_several.svg";
import badgeContentZeroImg from "../../images/badge/content_zero.svg";
import badgeDailyOneImg from "../../images/badge/daily_one.svg";
import badgeDailySeveralImg from "../../images/badge/daily_several.svg";
import badgeDailyZeroImg from "../../images/badge/daily_zero.svg";
import badgeFoodOneImg from "../../images/badge/food_one.svg";
import badgeFoodSeveralImg from "../../images/badge/food_several.svg";
import badgeFoodZeroImg from "../../images/badge/food_zero.svg"; // 전체 import 경로는 상황에 맞게 조정하세요
import BS_Badge from './BS_Badge';
import {Category, initialNftInfo, nftInfo} from "../../pages/Badge/types";
import nftApi from "../../api/gatewayApi/nft";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import {gtmOpenPopup} from "../../api/gtm";

interface BadgeComponentProps {
    badge: Category
    isNew?: boolean;
}

const imageMap: { [key: string]: { one: string, several: string, zero: string } } = {
    '나들이킹': {
        one: badgeTripOneImg,
        several: badgeTripSeveralImg,
        zero: badgeTripZeroImg
    },
    '쇼핑킹': {
        one: badgeShoppingOneImg,
        several: badgeShoppingSeveralImg,
        zero: badgeShoppingZeroImg
    },
    '스터디킹': {
        one: badgeStudyOneImg,
        several: badgeStudySeveralImg,
        zero: badgeStudyZeroImg
    },
    '뷰티킹': {
        one: badgeBeautyOneImg,
        several: badgeBeautySeveralImg,
        zero: badgeBeautyZeroImg
    },
    '콘텐츠킹': {
        one: badgeContentOneImg,
        several: badgeContentSeveralImg,
        zero: badgeContentZeroImg
    },
    '일상킹': {
        one: badgeDailyOneImg,
        several: badgeDailySeveralImg,
        zero: badgeDailyZeroImg
    },
    '푸드킹': {
        one: badgeFoodOneImg,
        several: badgeFoodSeveralImg,
        zero: badgeFoodZeroImg
    },
};

const categoryNameToIds: { [key: string]: string } = {
    "나들이킹": "0",
    "쇼핑킹": "1",
    "푸드킹": "2",
    "콘텐츠킹": "3",
    "스터디킹": "4",
    "일상킹": "5",
    "뷰티킹": "6"
};

const BadgeComponent: React.FC<BadgeComponentProps> = ({badge, isNew}) => {

    const [showBSBadge, setShowBSBadge] = useState(false);
    const [selectedBadge, setSelectedBadge] = useState('');
    const [nftInfoList, setNftInfoList] = useState<nftInfo[]>([]);
    const badgeImageSet = imageMap[badge.categoryName] || imageMap['나들이킹'];
    const count = parseInt(badge.count)
    const badgeImage = count === 0 ? badgeImageSet.zero : count === 1 ? badgeImageSet.one : badgeImageSet.several;
    const onClickBadge = async () => {
        try {
            const response = await nftApi.getBadgeById(categoryNameToIds[badge.categoryName], {}, 'v1')
            setNftInfoList(response.body.nftInfoList || [initialNftInfo])
        } catch (error) {
            console.log(error)
        }
    }
    const handleClick = () => {
        onClickBadge()
        if (count === 0) {
            setSelectedBadge(badgeImageSet.zero)
        } else if (count === 1) {
            setSelectedBadge(badgeImageSet.one)
        } else {
            setSelectedBadge(badgeImageSet.several)
        }
        setShowBSBadge(true);
        gtmOpenPopup('배지 상세 팝업');
    };

    const handleClose = () => {
        setShowBSBadge(false);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onClick={handleClick}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={`${CLICK_LOCATION.BADGE_LIST} - ${EVENT_ACTION.LINK_CLICK}`}
                data-gtm-event-label={`컨텐츠 : ${badge.categoryName}`}
                data-gtm-click-url={''}
                data-gtm-click-location={`${CLICK_LOCATION.BADGE_LIST}`}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`${badge.categoryName}`}
            >
                {isNew ? (
                    <Badge text="N" style={{marginRight: "8px"}}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={badgeImage}
                            width="100"
                        />
                    </Badge>
                ) : (
                    <Image
                        customClass="customClass"
                        ratio="1:1"
                        src={badgeImage}
                        width="100"
                    />
                )}
                <TextSet
                    blockSpacing={{
                        bottom: '4px',
                        top: '4px'
                    }}
                    subComponent="Heading_5"
                    text={{
                        title: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <span>{badge.categoryName}</span>
                                <span style={{color: "#E6007E"}}>{count}개</span>
                            </div>
                        )
                    }}
                    weight="default"
                />
            </div>
            {showBSBadge && (
                <BS_Badge nftInfoList={nftInfoList || [initialNftInfo]} imageSrc={selectedBadge}
                          name={badge.categoryName} count={count} onClose={handleClose}/>
            )}
        </>
    );
};

export default BadgeComponent;
