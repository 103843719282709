import React from "react";
import {Image, List, Text} from "@lguuxe/2024_designsystem_uni";

import ExImg from "../../images/default/default thumbnail.svg";
import {Movie} from "../../pages/Home/types";

interface CommonCarouselProps {
    movies: Movie[];
    handleMovieClick: () => void;
}

const CommonCarousel: React.FC<CommonCarouselProps> = ({movies, handleMovieClick}) => {
    // 현재 날짜를 YYYY-MM-DD 형식으로 가져옵니다.
    const today = new Date();
    const modifyPosterUrl = (url?: string) => {
        if (url && url.startsWith("http://")) {
            return url.replace("http://", "https://");
        }
        return url;
    };

    // 날짜 차이를 계산하는 함수
    const getDaysRemaining = (openDateStr?: string) => {
        if (!openDateStr) return ""; // openDate가 없으면 빈 문자열 반환

        // YYYYMMDD 형식의 문자열을 YYYY-MM-DD 형식으로 변환
        const year = parseInt(openDateStr.slice(0, 4), 10);
        const month = parseInt(openDateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
        const day = parseInt(openDateStr.slice(6, 8), 10);

        const openDateObj = new Date(year, month, day);
        const timeDiff = openDateObj.getTime() - today.getTime();
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // 밀리초를 일 단위로 변환

        // 날짜가 현재 날짜보다 전이면 빈 문자열 반환
        return dayDiff > 0 ? ` ・ D-${dayDiff}` : "";
    };

    return (
        <>
            <List
                horizontalItemWidth='132px'
                style={{
                    padding: "0 1.25rem 1rem 1.25rem",
                    width: "calc(100% - 2.5rem)",
                    minHeight: "264px",
                }}
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                customItemPadding={"12 8 0 0"}
                isleftPadding={false}
                items={movies.map((movie) => ({
                    id: movie.movieCode,
                    leftSlot: (
                        <div
                            key={movie.movieCode}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "6px",
                                minWidth: "104px",
                            }}
                        >
                            <Image
                                src={modifyPosterUrl(movie.posterImgUrl) || ExImg}
                                alt={movie.movieNm || "영화 포스터"}
                                width="132px"
                                rounded={12}
                                height="auto"
                            />
                            <div
                                style={{
                                    gap: "2px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Text typo="font_body_1Sb" color="color_text_neutral_6"
                                      style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1, // 최대 2줄로 설정
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "normal",
                                      }}
                                >
                                    {movie.movieNm || "영화 제목"}
                                </Text>
                                <Text typo="font_detail_2M" color="color_text_neutral_3">
                                    {"예매율 " + movie.rate + "%"}
                                    {getDaysRemaining(movie.openDate)}
                                </Text>
                            </div>
                        </div>
                    ),
                    onClick: () => handleMovieClick()
                }))}
            />
        </>
    );
};

export default CommonCarousel;
