// ****************************************************
// [배포] 4.마이 > VIP콕 > VIP콕 > VIP 회원 VIP콕 (UMembershipVip)
// ****************************************************
// InfoBox 다른 유형
import React from "react";
import {Box, Icon, Text} from "@lguuxe/2024_designsystem_uni";

type InfoBoxProps = {
    text: string;
    name?: string;
    rank?: string;
};

const InfoBox: React.FC<InfoBoxProps> = ({text, name, rank}) => {
    return (
        <Box
            style={{
                alignItems: "flex-start",
                alignSelf: "stretch",
                backgroundColor: "#F9FAFB",
                borderRadius: "12px",
                display: "flex",
                gap: "6px",
                padding: "16px",
                width: "100%",
            }}
        >
            <Icon color="#7f8a94" name="error_circle_filled" size="20px"/>
            <Text typo="font_body_2M" style={{color: '#66707A'}}>
                {text}
                {name && rank && (
                    <>
                        <br/>
                        {`${name} 님은 ${rank} 등급이에요.`}
                    </>
                )}
            </Text>
        </Box>
    );
};

export default InfoBox;
