import React, {createContext, ReactNode, useContext, useState} from 'react';
import ReactDOM from 'react-dom';

// FullScreenContext 생성
interface FullScreenContextType {
    show: (content: ReactNode) => void;
    hide: () => void;
}

const FullScreenContext = createContext<FullScreenContextType | undefined>(undefined);

// Provider 컴포넌트
export const FullScreenProvider = ({children}: { children: ReactNode }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState<ReactNode>(null);

    const show = (newContent: ReactNode) => {
        setContent(newContent);
        setIsVisible(true);
    };

    const hide = () => {
        setIsVisible(false);
        setContent(null);
    };

    const Modal = ({content, style}: { content: ReactNode; style: React.CSSProperties }) => {
        return ReactDOM.createPortal(
            <div style={overlayStyle}>
                <div style={style}>{content}</div>
            </div>,
            document.body
        );
    };

    return (
        <FullScreenContext.Provider value={{show, hide}}>
            {children}
            {isVisible && <Modal content={content} style={modalStyle}/>}
        </FullScreenContext.Provider>
    );
};

export const useFullScreen = () => {
    const context = useContext(FullScreenContext);
    if (!context) {
        throw new Error('useFullScreen must be used within a FullScreenProvider');
    }
    return context;
};

// 스타일 정의
const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,  // 기존 화면을 덮을 수 있도록 z-index를 높게 설정
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const modalStyle: React.CSSProperties = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    height: '100%',
    width: '100%',
};