import React, {useEffect, useState} from 'react';
import {Box, Image, Text} from '@lguuxe/2024_designsystem_uni';
import to19Coin from '../../images/json/Coin/benefit_0.json';
import to39Coin from '../../images/json/Coin/benefit_20.json';
import to59Coin from '../../images/json/Coin/benefit_40.json';
import to79Coin from '../../images/json/Coin/benefit_60.json';
import to99Coin from '../../images/json/Coin/benefit_80.json';
// import zeroCoin from '../../images/json/Coin/Coin_Gold_06.json';
import zeroCoin from '../../images/icon/4_my/benefit_none.svg';
import sliverCoin from '../../images/json/Coin/Coin_Silver.json';

import loveIcon from '../../images/icon/11_emoji/emoji_01.svg';
import happyIcon from '../../images/icon/11_emoji/emoji_02.svg';
import sosoIcon from '../../images/icon/11_emoji/emoji_03.svg';
import sadIcon from '../../images/icon/11_emoji/emoji_04.svg';
import {BenefitSummery} from '../../pages/My/types';
import {formatCurrency} from '../../utils/currencyUtils';
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";

interface DiscountSectionProps {
    summary: BenefitSummery;
    date: string;
}

const DiscountSection: React.FC<DiscountSectionProps> = ({summary, date}) => {
    const [name, setName] = useState('');
    const [ranking, setRanking] = useState<string>('');
    const [faceIcon, setFaceIcon] = useState('');
    const [coinAnimation, setCoinAnimation] = useState<any>('');
    const [isZero, setIsZero] = useState(true);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            const name = await getItemByKey('customerName');
            const ranking = await getItemByKey('ranking');
            if (ranking) {
                setCoinAnimation(getCoinAnimation(ranking));
                setFaceIcon(getIcon(ranking));
                if (ranking === '100') {
                    setIsZero(true);
                } else {
                    setIsZero(false);
                }
            }
            setName(name);
            setRanking(ranking);
        }
        fetchIndexedDB();
    }, []);

    const getCoinAnimation = (ranking: string): any => {
        const numberRanking = parseInt(ranking);
        if (numberRanking >= 0 && numberRanking <= 19) return to19Coin;
        if (numberRanking >= 20 && numberRanking <= 39) return to39Coin;
        if (numberRanking >= 40 && numberRanking <= 59) return to59Coin;
        if (numberRanking >= 60 && numberRanking <= 79) return to79Coin;
        if (numberRanking >= 80 && numberRanking <= 99) return to99Coin;
        return zeroCoin;
    };

    const getIcon = (ranking: string): string => {
        const numberRanking = parseInt(ranking);
        if (numberRanking >= 0 && numberRanking <= 39) return loveIcon;
        if (numberRanking >= 40 && numberRanking <= 59) return happyIcon;
        if (numberRanking >= 60 && numberRanking <= 99) return sosoIcon;
        return sadIcon;
    };

    useEffect(() => {
        if (!summary.ranking) return;
        if (summary.ranking === ranking) return;

        const faceIcon = getIcon(summary.ranking);
        setFaceIcon(faceIcon);

        const coinAnimation = getCoinAnimation(summary.ranking);
        const fetchIndexedDB = async () => {
            console.log('addItem : ranking', ranking);
            await addItem('ranking', summary.ranking);
        }
        fetchIndexedDB();
        setCoinAnimation(coinAnimation);
        if (coinAnimation === zeroCoin) {
            setIsZero(true);
        } else {
            setIsZero(false);
        }

        setRanking(summary.ranking);

    }, [summary]);


    return (
        <Box style={{backgroundColor: '#262A3B', padding: '0'}}>
            <Box style={{padding: '0 20px 0'}}>
                <Text typo='font_heading_3B' color='color_text_neutral' style={{color: '#fff'}}>
                    {Number(date.substring(4, 6))}월에 쓴 혜택은&nbsp;
                </Text>
                <br/>
                <Text typo='font_heading_3B' color='color_text_neutral' style={{color: '#fff'}}>
                    <span style={{color: '#E6007E'}}>상위 {ranking}%</span>&nbsp;네요
                    <div style={{marginLeft: '8px'}}>
                        {faceIcon !== '' && <Image src={faceIcon} alt='placeholder' width='22' ratio='1:1'/>}
                    </div>
                </Text>
            </Box>
            <Box style={{padding: '0 20px 20px', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: '100%',}}>
                    {/* <Image
            customClass="customClass"
            ratio="1:1"
            src={my1Icon}
            width= {160}
          /> */}

                    <DotLottieReact loop={false} autoplay data={sliverCoin} style={{height: '80%'}}/>
                    <div
                        style={{
                            padding: '12px 0 0 0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Text typo='font_label_4Sb' color='#fff'>
                            {Number(date.substring(4, 6))}월 전체 평균
                        </Text>
                        <Text typo='font_body_1Sb' color='#fff'>
                            {formatCurrency(summary.userMonthAverageDiscount)}원
                        </Text>
                    </div>
                </div>
                <div style={{width: '100%',}}>
                    {isZero && <Image customClass='customClass' src={zeroCoin}/>}
                    {!isZero && coinAnimation !== '' &&
                        <DotLottieReact loop={false} autoplay data={coinAnimation} style={{height: '80%'}}/>}

                    <div
                        style={{
                            padding: '12px 0 0 0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Text typo='font_label_4Sb' color='#FFBC27'>
                            {name}
                        </Text>
                        <Text typo='font_body_1Sb' color='#FFBC27'>
                            {formatCurrency(summary.thisMonthDiscount)}원
                        </Text>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default DiscountSection;
