import React, {useEffect, useState} from "react";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionContentHeaderBenefit from "../../components/Vipkok/SectionContentHeaderBenefit";
import BoxHeaderInfo from "../../components/Vipkok/BoxHeaderInfo";
import CallCenter from "../../components/Vipkok/CallCenter";
import {useParams, useSearchParams} from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import {PartnerDetailData, PartnerType, UserGrade} from "../Partner/types";
import {getItemByKey} from "../../utils/indexedDB";
import {CouponItem} from "../My/types";

const VipkokDetail: React.FC = () => {
    const [partnerDetail, setPartnerDetail] = useState<PartnerDetailData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [useVipkokYn, setUseVipkokYn] = useState<string>("N");
    const [partnerType, setPartnerType] = useState<PartnerType>("normal");
    const [userGrade, setUserGrade] = useState<UserGrade>("미로그인");
    const {ptnId} = useParams<{ ptnId: string }>();
    const [searchParams] = useSearchParams();
    const idType = searchParams.get('idType');
    const [couponList, setCouponList] = useState<CouponItem[]>([]);

    useEffect(() => {
        if (!ptnId) return;
        const fetchPartnerDetail = async () => {
            const memberStatus = await getItemByKey("memberStatus")
            setUserGrade(
                memberStatus === "0" ? "미로그인" :
                    memberStatus === "1" || memberStatus === "3" ? "VIP/VVIP" :
                        memberStatus === "2" || memberStatus === "4" ? "우수" :
                            memberStatus === "5" ? "일반" : "미로그인"
            );
            setUseVipkokYn(await getItemByKey("useVipkokYn"))
            if (!ptnId) {
                console.error("ptnId is undefined");
                setIsLoading(false);
                return;
            }
            try {
                let response;
                if (idType && idType === 'vipkok') { // ptnId가 아닌 vipkokId로 조회
                    response = await partnerApi.getPartnersUmem(ptnId, 'v1');
                } else {
                    response = await partnerApi.getPartnerById(ptnId, 'v1');
                }
                if (response.header.resultCode === '0000' && response.body) {
                    const data = response.body
                    switch (data.vipPartnerType) {
                        case '165001': // 일반형
                            setPartnerType('normal')
                            break;
                        case '165002': // 영화형
                            setPartnerType('movie')
                            break;
                        case '165003': // 쿠폰형
                            setPartnerType('coupon')
                            try {
                                const res = await partnerApi.getCoupon(data.ptnId, {}, 'v1');
                                if (res.header.resultCode === '0000' && res.body) {
                                    setCouponList(res.body.couponList);
                                }
                            } catch (error) {
                                console.log("Error fetching coupon list:", error);
                            }
                            break;
                        default:
                            setPartnerType('normal')
                            break;
                    }
                    setPartnerDetail(response.body);
                }
                // TODO: api 에러 처리
            } catch (error) {
                console.error("Error fetching partner detail:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPartnerDetail();
    }, [ptnId]);

    const handleChangeState = (state: string) => {
        setUseVipkokYn(state);
    }

    return (
        <>
            {useLoading(isLoading)}
            <div style={{position: 'fixed', width: '100%', zIndex: 300}}>
                <CommonLeftIconHeader title={'VIP콕'}/>
            </div>

            {partnerDetail && (
                <div style={{marginTop: '3.5rem'}}>
                    <SectionContentHeaderBenefit partnerDetail={partnerDetail} partnerType={partnerType}
                                                 useVipkokYn={useVipkokYn}
                                                 userGrade={userGrade} couponList={couponList}
                                                 onChangeState={handleChangeState}/>
                    <CommonDivider/>

                    {/* VIP콕 혜택 정보 */}
                    <BoxHeaderInfo title="혜택" description={partnerDetail.vipBnf}/>
                    <BoxHeaderInfo title="혜택한도" description={partnerDetail.vipBnfLimit}/>
                    <BoxHeaderInfo title="꼭 확인하세요" description={partnerDetail.vipNotice}/>
                    <BoxHeaderInfo title="이용방법" description={partnerDetail.vipGuide}/>
                    <CommonDivider/>

                    {/* 대표문의 */}
                    <CallCenter phone={partnerDetail.phone || ""} homepage={partnerDetail.homepage || ""}
                                name={partnerDetail.ptnName}/>
                </div>
            )}
        </>
    );
};

export default VipkokDetail;
