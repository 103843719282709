import React from "react";
import {Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {History, initialHistory} from "../../pages/Vipkok/types";
import ExImg from "../../images/default/default thumbnail.svg";
import {formatDate} from "../../utils/dateUtiles";

type HeadingCardProps = {
    history: History;
};

const subscriptionIds = ["123", "789"]; // 구독 혜택 제휴처 ID 목록

const HeadingCard: React.FC<HeadingCardProps> = ({history = initialHistory}) => {
    const formatDateDifference = (endDate: string) => {
        const end = new Date(endDate);
        const current = new Date();
        const diffTime = end.getTime() - current.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 0 ? `D-${diffDays}` : "사용만료";
    };

    const recentItem = history.pointUseList
        ?.filter(item => item.useKokYn === "Y") // 콕혜택 사용 여부가 "Y"인 항목만 필터링
        .sort((a, b) => new Date(b.useDt).getTime() - new Date(a.useDt).getTime())[0];
    console.log(recentItem);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "0 20px"
            }}
        >
            <TextSet
                subComponent="Heading_3"
                text={{
                    title: (
                        <>
                            <Text typo="font_heading_3B">최근 사용</Text>
                        </>
                    )
                }}
                blockSpacing={{top: "24px"}}
            />
            {!recentItem ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "24px 20px",
                        border: "1px solid #E7EBEE",
                        borderRadius: "16px",
                    }}
                >
                    <div style={{paddingTop: "0px"}}>
                        <Text typo="font_body_1M" color="color_text_neutral_3" textAlign="center">
                            올해 VIP콕 사용내역이 없습니다.
                        </Text>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        width: "100%",
                        borderRadius: "16px",
                        backgroundColor: "#fff",
                        border: "1px solid #E7EBEE"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            padding: "12px 0"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "12px 20px",
                            }}
                        >
                            <Image
                                srcFallback={ExImg}
                                src={recentItem.ptnLogoImgUrl}
                                ratio="1:1"
                                width="48px"
                                rounded={"50%"}
                                key={`image-${recentItem.coopCode}`}
                            />
                            <div
                                style={{
                                    gap: "2px",
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "12px"
                                }}
                                key={`text-${recentItem.coopCode}`}
                            >
                                <Text typo="font_body_1M" color="color_text_neutral_5">
                                    {recentItem.useSiteNm}
                                </Text>
                                <Text typo="font_detail_2M" color="color_text_neutral_3">
                                    {formatDate(recentItem.useDt)}
                                </Text>
                            </div>
                            {/*{subscriptionIds.includes(item.ptnId) && (*/}
                            {/*    <div*/}
                            {/*        style={{*/}
                            {/*            display: "flex",*/}
                            {/*            flexDirection: "column",*/}
                            {/*            alignItems: "flex-end",*/}
                            {/*            marginLeft: "auto"*/}
                            {/*        }}*/}
                            {/*        key={`right-${item.ptnId}`}*/}
                            {/*    >*/}
                            {/*        <Text typo="font_label_4Sb" color="color_text_neutral_2">*/}
                            {/*            {new Date(item.useEndDate) > new Date() ? formatDateDifference(item.useEndDate) : ""}*/}
                            {/*        </Text>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeadingCard;
