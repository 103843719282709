
import React from "react";
import { Box, IconButton, List, Text } from "@lguuxe/2024_designsystem_uni";
import BoxHeaderInfo from "./BoxHeaderInfo";
import { sendEventReturn } from "../../api/nativeBridge";

type CallCenterProps = {
    phone: string;
    homepage: string;
    name: string;
};

type SlotItemProps = {
    label: string;
    content?: string;
    isLink?: boolean;
    onClick: () => void;
};

const SlotItem: React.FC<SlotItemProps> = ({ label, content, isLink, onClick }) => (
    <List
        type="view"
        fill="none"
        items={[
            {
                onClick,
                leftSlot: (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text typo="font_body_2M" color="color_text_neutral_6">
                            {label}
                        </Text>
                    </div>
                ),
                rightSlot: (
                    <>
                        <IconButton
                            color="neutral"
                            fill="icon"
                            icon="chevron_right_small_line"
                            noStates
                        />
                        {content && (
                            <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                {content}
                            </Text>
                        )}
                    </>
                )
            }
        ]}
    />
);

const CallCenter: React.FC<CallCenterProps> = ({ phone, homepage, name }) => {
    return (
        <div>
            <BoxHeaderInfo title="문의" />
            <Box style={{ padding: "0 20px 48px" }} type="1_rbl">
                <div style={{ padding: "0", background: "#F3F5F6", borderRadius: "12px" }}>
                    {/* 제휴사 홈페이지 */}
                    <SlotItem
                        label="제휴사 홈페이지"
                        isLink
                        onClick={() => sendEventReturn('EXTERNAL_CALL', { url: homepage, type: 1 })}
                    />

                    {/* 제휴사 대표전화 */}
                    <SlotItem
                        label="제휴사 대표전화"
                        content={phone}
                        onClick={() => { window.location.href = `tel:+82${phone}` }}
                    />
                </div>
            </Box>
        </div>
    );
};

export default CallCenter;