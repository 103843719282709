export type Company = {
    ptnId: string
    ptnName: string;
    ptnLogoImgUrl: string;
    benefitSummary: string;
};

export type Category = {
    count: string,
    categoryId: string,
    categoryName: string
}

export type MyBadgeData = {
    badgeCount: string,
    sendableCount: string,
    categoryList: Category[]
}

export const initialMyBadge: MyBadgeData = {
    badgeCount: '0',
    sendableCount: '0',
    categoryList: []
}

export interface nftInfo {
    serialNumber: string
    updateTime: string
}

export const initialNftInfo: nftInfo = {
    serialNumber: '',
    updateTime: ''
}