export const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

export const formatBarcode = (barcode: string) => {
    return barcode.match(/.{1,4}/g) || [];
};

export const getBarcodeWidth = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 350) {
        return 1.5;  // 작은 화면에서는 좁은 width
    } else if (windowWidth >= 350 && windowWidth < 400) {
        return 1.7;  // 작은 화면에서는 좁은 width
    } else if (windowWidth >= 400 && windowWidth < 500) {
        return 2;  // 중간 크기의 화면에서는 기본 width
    } else if (windowWidth >= 500 && windowWidth < 600) {
        return 2.5;  // 큰 화면에서는 넓은 width
    } else {
        return 3;  // 가장 큰 화면에서는 가장 넓은 width
    }
}