import React from 'react';
import {Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';

interface CommonAllIconHeaderProps {
    title: string;
    isSearch?: boolean;
    isShare?: boolean;
    divider?: boolean;
}

const CommonAllIconHeader: React.FC<CommonAllIconHeaderProps> = ({
                                                                     title,
                                                                     isSearch = true,
                                                                     isShare = false,
                                                                     divider
                                                                 }) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleSearchClick = () => {
        navigate('/search');
    }

    const handleShareClick = () => {
        console.log('share');
    }

    const gtmAttributes = title === '내 쿠폰' ? {
        'data-gtm-event-name': 'all_click',
        'data-gtm-event-category': '클릭',
        'data-gtm-event-action': '마이|내 쿠폰 - 링크 클릭',
        'data-gtm-event-label': '컨텐츠 : 검색',
        'data-gtm-click-url': '',
        'data-gtm-click-location': '마이|내 쿠폰',
        'data-gtm-click-direction': '내부',
        'data-gtm-click-text': '검색',
    } : {};

    return (
        <Header align='center' variant='heading' divider={divider ?? true}>
            <Header.LeftSlot>
                <IconButton color='neutral' fill='icon' icon='chevron_left_line' onClick={handleBackClick} noStates/>
            </Header.LeftSlot>
            <Header.CenterSlot>
                <Text typo='font_heading_4Sb'>{title}</Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                {isSearch &&
                    <IconButton color='neutral' fill='icon' icon='search_line' onClick={handleSearchClick} noStates
                                {...gtmAttributes}
                    />}
                {isShare &&
                    <IconButton color='neutral' fill='icon' icon='share_line' onClick={handleShareClick} noStates/>}
            </Header.RightSlot>
        </Header>
    );
};

export default CommonAllIconHeader;
