import React from 'react';
import {List, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../utils/dateUtiles";
import {decodeHtmlEntities} from "../../utils/textUtils";

interface NoticeItemProps {
    itemList: NoticeItem[]
    threshold?: number;
}

interface NoticeItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
    itemStartDate: string;
}

const NoticeItem: React.FC<NoticeItemProps> = ({itemList, threshold}) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const navigate = useNavigate()
    const onNoticeClick = (item: NoticeItem) => {
        navigate(`/help/announcement/${item.itemId}`)
    }

    return (
        <div>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={itemsToRender.map(item => ({
                    linkIconOnlyId: "empty",
                    onClick: () => onNoticeClick(item),
                    leftSlot: [
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                title: decodeHtmlEntities(item.itemName),
                                description: formatDate(item.itemStartDate)
                            }}
                        />
                    ],
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': '공지사항 - 링크 클릭',
                        'data-gtm-event-label': `컨텐츠 : ${item.itemName}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': '공지사항',
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${item.itemName}`,
                    }
                }))}
            />
        </div>
    );
}

export default NoticeItem;