import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';

// Props 인터페이스 정의
interface CommonLeftIconHeaderProps {
    title: string;
}

const CommonLeftIconHeader: React.FC<CommonLeftIconHeaderProps> = ({title}) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        window.scrollTo(0, 0); // Scroll to top
        navigate(-1); // Navigate back
    };
    return (
        <Header align="center" variant="heading" divider={false}>
            <Header.LeftSlot>
                <IconButton color='neutral' fill='icon' icon='chevron_left_line' noStates onClick={handleBackClick}/>
            </Header.LeftSlot>
            <Header.CenterSlot>
                <div style={{width: 'calc(100vw - 110px)'}}>
                    <Text typo="font_heading_4Sb" style={{
                        display: "inline-block",
                        // WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1, // 최대 2줄로 설정
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                    }}>
                        {title}
                    </Text>
                </div>
            </Header.CenterSlot>
        </Header>
    );
};
export default CommonLeftIconHeader;
