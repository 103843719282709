import {Button, Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";
import {useNavigate} from "react-router-dom";

const BarcodeOther = () => {
    const navigate = useNavigate();

    return (

        <div
            style={{
                padding: "20px 0",
                backgroundColor: "#F3F5F6",
                borderRadius: "12px",
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "12px",
                    padding: "4.5px 22px",
                }}
            >
                <Text typo="font_body_1M" color="color_text_neutral_3" textAlign="center">
                    U+모바일 가입 고객만<br/>
                    U+멤버십 카드를 발급받을 수 있어요.
                </Text>
                <Button
                    as="button"
                    color="primary"
                    fill="solid"
                    size="medium"
                    state="default"
                    onClick={() => {
                        navigate('/partners');
                    }}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'바코드|멤버십 - 링크 클릭'}
                    data-gtm-event-label={'컨텐츠 : 멤버십 혜택 더보기'}
                    data-gtm-click-url={''}
                    data-gtm-click-location={'바코드|멤버십'}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={'제휴사 혜택 보기'}
                >
                    제휴사 혜택 보기
                </Button>
            </div>
        </div>
    )
}

export default BarcodeOther;