import React, {useEffect, useState} from 'react';
import BadgeComponent from "./BadgeComponent";
import {Box} from '@lguuxe/2024_designsystem_uni';
import {addItem, getItemByKey} from '../../utils/indexedDB';
import {Category} from "../../pages/Badge/types";


interface SectionBadgeProps {
    badgeCategories: Category[];
}

const SectionBadge: React.FC<SectionBadgeProps> = ({badgeCategories}) => {
    const [newBadges, setNewBadges] = useState<string[]>([]);
    // 배지 카테고리의 기본 순서 유지
    const badgeOrder = ["나들이킹", "쇼핑킹", "스터디킹", "뷰티킹", "콘텐츠킹", "일상킹", "푸드킹"];

    useEffect(() => {
        const checkAndStoreBadgeData = async () => {
            const newBadgeNames: string[] = [];

            for (const badge of badgeCategories) {
                const storedBadge = await getItemByKey(badge.categoryName);
                if (storedBadge) {
                    if (parseInt(badge.count) !== parseInt(storedBadge.count)) {
                        newBadgeNames.push(badge.categoryName);
                    }
                }
                await addItem(badge.categoryName, badge);
            }

            setNewBadges(newBadgeNames);
        };

        checkAndStoreBadgeData();
    }, [badgeCategories]);

    // 배지 카테고리를 순서에 맞게 정렬
    const sortedCategories = badgeOrder.map(order =>
            badgeCategories.find(category => category.categoryName === order) || {
                count: '0',
                categoryId: '0',
                categoryName: order
            }
    );

    return (
        <>
            <Box
                style={{
                    padding: "24px 20px",
                    display: "grid",
                    gap: "12px 0",
                    gridTemplateColumns: "1fr 1fr 1fr"
                }}
            >
                {sortedCategories.map((badge: Category) => (
                    <BadgeComponent
                        badge={badge}
                        isNew={newBadges.includes(badge.categoryName)}
                    />
                ))}
            </Box>
        </>
    );
};

export default SectionBadge;
