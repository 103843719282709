import React, {useState} from 'react';
import {Box, Button, Checkbox, CTA} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from "react-router-dom";
import {gtmOpenPopup} from "../../api/gtm";

interface SectionChkCTAProps {
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedReason: string | null
    hasReasonText: boolean
}

const SectionChkCTA: React.FC<SectionChkCTAProps> = ({setOpenPopup, selectedReason, hasReasonText}) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const navigate = useNavigate()
    const handleCheckboxChange = (e: any) => {
        if (e.value === '선택되었습니다') {
            setIsCheckboxChecked(true);
        } else {
            setIsCheckboxChecked(false);
        }
    };

    const handleClickQuit = () => {
        gtmOpenPopup('탈퇴 확인 팝업')
        setOpenPopup(true);
    }

    return (
        <Box
            style={{
                position: "fixed",
                bottom: "0",
                width: "100%",
                padding: "0"
            }}
        >
            <CTA
                layout="row"
            >
                <Button color="neutral" onClick={() => navigate(-1)}>
                    취소
                </Button>
                <Button
                    color="primary"
                    disabled={!(isCheckboxChecked && selectedReason && hasReasonText)}
                    onClick={handleClickQuit}
                >
                    회원탈퇴
                </Button>
                <Checkbox
                    label="안내사항을 모두 확인하였으며, 이에 동의합니다."
                    size="medium"
                    width="auto"
                    weight="default"
                    checked={isCheckboxChecked}
                    disabled={selectedReason === '' || selectedReason === null}
                    style={{backgroundColor: "#fff"}}
                    onChange={(e) => handleCheckboxChange(e)}
                />
            </CTA>
        </Box>
    );
}

export default SectionChkCTA;
