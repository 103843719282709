import React from 'react';
import {Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import box2Img from '../../images/icon/4_my/point.svg';
import box1Img from '../../images/icon/4_my/dcmembership.svg';
import {MissionReward} from "../../pages/My/types";
import {formatCurrency} from "../../utils/currencyUtils";
import {appTech} from "../../api/externalApi";
import useDebounce from "../../hooks/useDebounce";

interface RewordBlockProps {
    missionReward: MissionReward;
}

const RewordBlock: React.FC<RewordBlockProps> = ({missionReward}) => {
    const handleBlock1Click = useDebounce(() => {
        appTech('target=MAIN');
    });
    const handleBlock2Click = useDebounce(() => {
        appTech('target=COUPON_MALL');
    });
    return (
        <div style={{paddingBottom: '0'}}>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>내 리워드 </span>&nbsp;
                            <span style={{color: '#FF6E00'}}> {formatCurrency(missionReward.rewardPoint)}P</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    padding: '0 20px 24px',
                }}
            >
                <div
                    onClick={handleBlock1Click}
                    style={{
                        backgroundColor: '#EFE7FE',
                        borderRadius: '12px',
                        width: 'calc(50% - 4px)',
                        boxSizing: 'border-box',
                        height: '168px',
                        position: 'relative',
                    }}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`리워드 - 링크클릭`}
                    data-gtm-event-label={`컨텐츠 : 미션 참여하고 리워드 받자`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`리워드`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`미션 참여하고 리워드 받자`}
                >
                    <div style={{padding: '20px'}}>

                        <TextSet
                            subComponent='Body_1'
                            text={{
                                eyebrow: '매일 1 ~ 9,000P',
                                title: (
                                    <>
                                        <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                            미션 참여하고
                                            <br/>
                                            리워드 받자
                                        </Text>

                                    </>
                                ),
                            }}
                            weight='bolder'
                        />
                        <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                            <Image customClass='customClass' ratio='1:1' src={box2Img} width='64'/>
                        </div>
                    </div>
                </div>
                <div
                    onClick={handleBlock2Click}
                    style={{
                        backgroundColor: '#FFE7D6',
                        borderRadius: '12px',
                        width: 'calc(50% - 4px)',
                        boxSizing: 'border-box',
                        height: '168px',
                        position: 'relative',
                    }}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'리워드 - 링크클릭'}
                    data-gtm-event-label={`컨텐츠 : U+통신비 할인 받기`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`리워드`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`U+통신비 할인 받기`}
                >
                    <div style={{padding: '20px'}}>
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                eyebrow: '리워드 사용해서',
                                title: (
                                    <>
                                        <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                            U+통신비
                                            <br/>
                                            할인 받기
                                        </Text>
                                    </>
                                ),
                            }}
                            weight='bolder'
                        />
                        <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                            <Image customClass='customClass' ratio='1:1' src={box1Img} width='64'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewordBlock;
