import React, {useEffect, useState} from "react";
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SectionContentBenfit from "../../components/MobilePayment/SectionContentBenefit";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionInfo from "../../components/MobilePayment/SectionInfo";
import {BannerItem} from "../../components/UPTP/SectionBannerInfo";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import {notiApi} from "../../api/gatewayApi/notiAndQna";
import PaymentResultNodata from "../../components/MobilePayment/PaymentResultNodata";
import {useMessagePopup} from "../../provider/MessagePopupProvider";


const bannerList: BannerItem[] = [
    {
        bannerId: '1',
        bannerImgUrl: 'https://via.placeholder.com/375x150',
        bannerTitle: '배송비 무료 이벤트',
        targetInfo: [
            {
                targetType: 'LINK',
                targetLink: '/search'
            }
        ]
    }
]

export type PaymentInfo = {
    agreeYn?: string;
    payType: string;
    payPrice: string;
    discountPrice1: string;
    discountPrice2: string;
    agreeDate?: string;
    useplaceName?: string;
    productName?: string;
    discountType?: string;
};

const initPaymentInfo: PaymentInfo = {
    payType: '',
    payPrice: '',
    discountPrice1: '',
    discountPrice2: '',
    agreeYn: '',
    agreeDate: '',
    useplaceName: '',
    productName: '',
    discountType: ''
};

const MobilePaymentResult = () => {
    const navigate = useNavigate();
    const {status, id} = useParams();
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(initPaymentInfo);
    const {showMessagePopup} = useMessagePopup();
    const location = useLocation();
    const [message, setMessage] = useState<string>('');

    const pageTitle = status === 'success' ? '결제 완료' :
        status === 'fail' ? '결제 실패' :
            status === 'cancel' ? '결제 취소' : '';

    useEffect(() => {
        if (location.state && location.state.message) {
            setMessage(location.state.message);
        }
        if (status === 'fail' || status === 'cancel') {
            return;
        }
        if (!id) {
            showMessagePopup('결제 정보가 없습니다.');
            return;
        }
        const fetchPaymentInfo = async () => {
            try {
                const res = await notiApi.getPayResult({otpId: id}, 'v1');
                if (res.header.resultCode === '0000') {
                    let paymentInfo = res.body;
                    paymentInfo = {
                        ...paymentInfo,
                        payPrice: paymentInfo.payPrice ?? '0',
                        discountPrice1: paymentInfo.discountPrice1 ?? '0',
                        discountPrice2: paymentInfo.discountPrice2 ?? '0'
                    }
                    setPaymentInfo(paymentInfo);
                } else {
                    showMessagePopup('결제 정보가 없습니다.');
                }
            } catch (e) {
                console.log(e);
                showMessagePopup('결제 정보가 없습니다.');
            }
        }
        fetchPaymentInfo();
    }, []);

    return (
        <>
            <CommonRightIconHeader icon={'close_line'} onClick={() => {
                navigate('/');
            }} title={pageTitle}/>


            {(status === 'success') && <>
                {/* -- 제휴사 --  */}
                <SectionContentBenfit paymentInfo={paymentInfo}/>

                <div style={{marginBottom: "8px"}}>
                    <CommonDivider/>
                </div>
                {/* -- 결제 내역 --*/}
                <SectionInfo paymentInfo={paymentInfo}/>
            </>}
            {(status === 'fail' || status === 'cancel') && <div
                style={{
                    marginTop: "227px"
                }}
            >
                <PaymentResultNodata message={message}/>
            </div>}

            <div style={{
                position: "fixed",
                bottom: "0",
                width: "100%"
            }}>
                {/*<SectionBannerInfo bannerList={bannerList}/>*/}
                {status === 'success' && <CommonCTAColumn buttonText={'확인'} handleSummit={() => navigate('/')}/>}
                {(status === 'fail' || status === 'cancel') &&
                    <CommonCTAColumn buttonText={'확인'} cancelText={'고객센터 전화 연결'} onClose={() => {
                        window.location.href = `tel:+82114`;
                    }} handleSummit={() => navigate('/')}/>}
            </div>
        </>
    )
}

export default MobilePaymentResult
