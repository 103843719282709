import React, {useEffect, useState} from 'react';
import {Image, Text} from '@lguuxe/2024_designsystem_uni';

import HomeHeader from '../../components/Home/HomeHeader';
import UserMessage from '../../components/Home/UserMessage';
import EventAll from '../../components/Home/EventAll';
import BannerInfo from '../../components/Home/BannerInfo';
import QuickMenu from '../../components/Home/QuickMenu';
import BenefitInfo from '../../components/Home/BenefitInfo';
import SectionChip from '../../components/Home/SectionChip';
import TextInfo from '../../components/Home/TextInfo';
import CommonCarousel1 from '../../components/Home/CommonCarousel1';
import CommonCarousel2 from '../../components/Home/CommonCarousel2';

import Bugapan from '../../images/icon/11_emoji/emoji_01.svg';
import chevron_right_line from '../../images/main/benefit/chevron_right_line.svg';
import ixi from '../../images/main/by ixi.svg'

import '../../css/style.css';
import benefitApi from '../../api/gatewayApi/benefit';
import {Benefit, Event, Movie, Msg, RecentUsedBenefit, SSPResponse, Tag, UptpData} from './types';

import EventInfo from '../../components/Home/EventInfo';
import {useNavigate, useSearchParams} from "react-router-dom";

import {ENVIROMENT, handlePromiseResult} from "../../api/api";
import {extractMonth, getAgeCode, getCurrentYYYYMM} from "../../utils/helper";
import HeadingCard from "../../components/Home/NotLoginCard";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {goMovie, moneymeEnter} from "../../api/externalApi";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import CommonPopup from "../../components/Common/CommonPopup";
import useLoading from "../../hooks/useLoading";
import commonApi from "../../api/gatewayApi/common";
import {sendEvent} from "../../api/nativeBridge";
import {gtmPageLoad, gtmUserInfo} from "../../api/gtm";
import {brazeAttributeApi, brazeEventApi} from "../../api/brazeBridge";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import useDebounce from "../../hooks/useDebounce";

interface HomeBenefitProps {
    userStatus: string
    useVipYn: boolean
}

const HomeBenefit: React.FC<HomeBenefitProps> = ({userStatus, useVipYn}) => {
    //0.미로그인 / 1.14이상 VIP / 2.14이상 우수 / 3.14미만 vip / 4.14미만 우수 / 5.타사
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [prsMsg, setPrsMsg] = useState<Msg[]>([]);
    const [uptp, setUptp] = useState<UptpData>({currentMonth: '', uptpList: []}) // 초기값 설정);
    const [recentUsedBenefit, setRecentUsedBenefit] = useState<RecentUsedBenefit[]>([]);
    const [benefitTag, setBenefitTag] = useState<Tag[]>([]);
    const [selectedBenefit, setSelectedBenefit] = useState<Tag>({tagId: "", tagName: "", tagType: ""});
    const [movieRanking, setMovieRanking] = useState<Movie[]>([]);
    const [eventTag, setEventTag] = useState<Tag[]>([]);
    const [selectedEventTag, setSelectedEventTag] = useState<string>("");
    const [eventList, setEventList] = useState<Event[]>([]);
    const [benefitList, setBenefitList] = useState<Benefit[]>([]);
    const [userName, setUserName] = useState<string>("");
    const [sspData, setSspData] = useState<SSPResponse>({creatives: [], exist: false});
    const [showUsevipPopup, setShowUsevipPopup] = useState<boolean>()
    const [showNotVipPopup, setShowNotVipPopup] = useState<boolean>()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFirstData = async () => {
            const YYYYMM = getCurrentYYYYMM()
            setUserName(await getItemByKey("customerName"))

            try {
                const memberId = await getItemByKey('memberId');
                if (!memberId) {
                    const [uptp] = await Promise.allSettled([
                        benefitApi.getUptp({month: YYYYMM}, 'v1'),
                    ]);
                    const uptpData = handlePromiseResult(uptp, {currentMonth: '', uptpList: []});
                    addItem("currentDay", uptpData.currentMonth)
                    setUptp(uptpData);
                } else {
                    const [uptp, prsMsg, benefitTag,] = await Promise.allSettled([
                        benefitApi.getUptp({month: YYYYMM}, 'v1'),
                        benefitApi.getPrsMsg({}, 'v1'),
                        benefitApi.getBenefitTag({}, 'v1'),
                    ]);

                    const uptpData = handlePromiseResult(uptp, {currentMonth: '', uptpList: []});
                    addItem("currentDay", uptpData.currentMonth)
                    setUptp(uptpData);
                    setPrsMsg(handlePromiseResult(prsMsg)?.msgList || []);
                    const benefitTagList = handlePromiseResult(benefitTag, {tagList: []}).tagList;
                    setBenefitTag(benefitTagList);
                    if (benefitTagList.length > 0) {
                        setSelectedBenefit(benefitTagList[0])
                    }
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false)
                window.scrollTo(0, 0.1);
                fetchOtherData();
            }
        }

        const fetchOtherData = async () => {
            const ageCode = await getAgeCode()
            let genderCode = await getItemByKey('gender') || 'A'
            if (!genderCode) {
                genderCode = 'A'
            }

            try {
                const slashInfoRes = await commonApi.getSlashInfo({}, 'v1');
                if (slashInfoRes.header.resultCode === '0000' && slashInfoRes.body.splashImgUrl) {
                    sendEvent("SET_SPLASH_IMAGE", {url: slashInfoRes.body.splashImgUrl})
                }

                const memberId = await getItemByKey('memberId')
                if (!memberId) return; // 미로그인일때는 아래 api를 호출하지 않는다

                const [movieRanking, eventTag] =
                    await Promise.allSettled([
                        benefitApi.getMovieRanking({theaterCode: 'CGV'}, 'v1'),
                        benefitApi.getEventTag({}, 'v1'),
                    ]);
                const movieRankingList = handlePromiseResult(movieRanking, {movieList: []})?.movieList
                setMovieRanking(movieRankingList);
                setEventTag(handlePromiseResult(eventTag, {eventTagList: []})?.eventTagList);
                const benefitTagId = selectedBenefit.tagId
                const eventTagId = handlePromiseResult(eventTag, null)?.eventTagList[0]?.tagId || null;
                if (benefitTagId) {
                    const benefitTagDetailsResult = await benefitApi.getBenefitTagById(benefitTagId, {}, 'v1');
                    setBenefitList(benefitTagDetailsResult.body.benefitList);
                }
                if (eventTagId) {
                    const eventTagDetailsResult = await benefitApi.getEventTagById(eventTagId, {
                        targetAge: ageCode,
                        targetGender: genderCode
                    }, 'v1');
                    setEventList(eventTagDetailsResult.body.eventList);
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                brazeEventApi.homemain_enter();
                if (searchParams.get('state') === 'first') brazeAttributeApi.firstCollection();
                gtmPageLoad({
                    url: '/',
                    contentGroup: '홈 메인',
                    moduleName: '프로필 블록|혜택 블록|바로가기 블록|머니Me 블록|추천 제휴사 블록|최근 사용 혜택 블록|영화 추천 블록|이벤트 블록',
                    pageTitle: '홈'
                });
                gtmUserInfo();
            }
        };
        fetchFirstData();

    }, []);

    useEffect(() => {
        const fetchRecentUsedBenefit = async () => {
            try {
                if (userStatus && userStatus !== '0') { // 미로그인일때는 최근사용이력 api 호출하지 않음
                    const recentUsedBenefitResponse = await benefitApi.getRecentUsedBenefit({}, 'v1');
                    if (recentUsedBenefitResponse.header.resultCode === '0000') {
                        setRecentUsedBenefit(recentUsedBenefitResponse.body.recentUsedList);
                    }
                }
            } catch (error) {
                console.log('An unexpected error occurred:', error);
            }
        }
        fetchRecentUsedBenefit();
    }, [userStatus]);


    useEffect(() => {
        if (selectedBenefit.tagId === '') {
            return;
        }
        const fetchBenefitList = async () => {
            const location = await getItemByKey("interestLocation");
            const categoryId = await getItemByKey("interestLocationId");
            const ids = categoryId ? categoryId.split("|") : [];
            try {
                if (selectedBenefit.tagType === '158002' && location) {
                    const requestParam = {
                        cateDepth1: ENVIROMENT === 'prod' ? '10' : '180',
                        cateDepth2: ids[0] ?? '',
                        cateDepth3: ids[1] ?? '',
                        sort: 'L',
                        pageNo: '1'
                    }
                    partnerApi.getPartners(requestParam, 'v1').then((res) => {
                        if (res.header.resultCode === '0000' && res.body) {
                            setBenefitList(res.body.partnerList.slice(0, 5));
                        }
                    });
                    // } else if (tagName === '지역별' && !location) {
                } else {
                    const benefitTagDetailsResult = await benefitApi.getBenefitTagById(selectedBenefit.tagId, {}, 'v1');
                    setBenefitList(benefitTagDetailsResult.body.benefitList);
                }
            } catch (error) {
                console.error('Failed to fetch :', error);
            }
        }

        fetchBenefitList();
    }, [selectedBenefit]);

    const handleBenefitChipClick = async (tagId: string, tagName: string) => {
        const selectedTag = benefitTag.find(tag => tag.tagId === tagId);
        // 해당 태그가 있으면 상태 업데이트
        if (selectedTag) {
            setSelectedBenefit(selectedTag);
        }
    }

    const handleLocationClick = useDebounce(async (depth2: string, depth3: string) => {
        try {
            const requestParam = {
                cateDepth1: ENVIROMENT === 'prod' ? '10' : '180',
                cateDepth2: depth2,
                cateDepth3: depth3,
                sort: 'L',
                pageNo: '1'
            }
            partnerApi.getPartners(requestParam, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    setBenefitList(res.body.partnerList.slice(0, 5));
                }
            });
        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    });

    const handleEventChipClick = useDebounce(async (tagId: string) => {
        const ageCode = await getAgeCode()
        const genderCode = await getItemByKey('gender') || 'A'
        setSelectedEventTag(tagId)
        const eventTagDetailsResult = await benefitApi.getEventTagById(tagId, {
            targetAge: ageCode,
            targetGender: genderCode
        }, 'v1');
        setEventList(eventTagDetailsResult.body.eventList);
    });

    // useEffect(() => {
    //     if (userStatus === "1" || userStatus === "2" || userStatus === "5") {
    //         console.log("call Add!!");
    //         const callAdd = async () => {
    //             try {
    //                 setSspData(await adSSP('식별'));
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         };
    //         callAdd();
    //     }
    // }, [userStatus]);

    const handleUPTP = () => {
        if (!userStatus || userStatus === "0") {
            setShowLoginPopup(true)
        } else {
            navigate('/UPTP');
        }
    };

    const handleMoneyMe = useDebounce(async () => {
        if (!userStatus || userStatus === "0") {
            setShowLoginPopup(true)
        } else {
            await moneymeEnter()
        }
    });

    const handleMovieClick = useDebounce(async () => {
        if (userStatus === "2" || userStatus === "4") {
            setShowNotVipPopup(true)
        } else if (useVipYn) {
            setShowUsevipPopup(true)
        } else {
            goMovie("CGV")
        }
    });

    return (
        <>
            {useLoading(isLoading)}
            <div style={styles.container} className='bg_gra'>
                <HomeHeader/>

                {/*--------------------------------개인화 메세지-------------------------------------*/}

                {(userStatus !== "0") && userStatus &&
                    <>
                        <div style={styles.sectionHeader}>
                            <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                {userName}님을 위한 맞춤 메시지
                            </Text>
                            <Image src={Bugapan} alt='placeholder' width='22' ratio='1:1'/>
                        </div>
                    </>
                }
                <UserMessage useVipYn={useVipYn} prsMsg={prsMsg} memberStatus={userStatus}/>

                {/*--------------------------------유플투쁠------------------------------------*/}
                {uptp?.uptpList?.length > 0 &&
                    <>
                        <div style={styles.UPUPSubHeader}>
                            <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                {extractMonth(uptp.currentMonth)}월의 유플투쁠 혜택
                            </Text>
                            <Image src={chevron_right_line} onClick={handleUPTP} alt='placeholder' width='10'
                                   ratio='1:2'
                                   data-gtm-event-name={'all_click'}
                                   data-gtm-event-category={'클릭'}
                                   data-gtm-event-action={'혜택 블록 - 링크 클릭'}
                                   data-gtm-event-label={'컨텐츠 : 혜택 더보기'}
                                   data-gtm-click-url={''}
                                   data-gtm-click-location={'혜택 블록'}
                                   data-gtm-click-direction={'내부'}
                                   data-gtm-click-text={`${extractMonth(uptp.currentMonth)}월의 유플투쁠`}
                            />
                        </div>
                        <EventAll uptp={uptp || {}} userStatus={userStatus}/>
                    </>
                }

                {/*--------------------------------퀵메뉴----------------------------------------*/}
                <div style={styles.sectionBody}>
                    {(userStatus !== "0") &&
                        <QuickMenu userStatus={userStatus}/>
                    }
                    {/*--------------------------------머니ME 배너------------------------------------*/}
                    {(userStatus !== "3" && userStatus !== "4") &&
                        <BannerInfo onClick={handleMoneyMe}/>
                    }
                </div>

                {/*--------------------------------AI 추천 혜택------------------------------------*/}
                <>
                    {userStatus === "0" ? (
                        <>
                            <div style={styles.sectionSubHeader}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Text typo='font_heading_4B' color='color_text_natural_6' style={{ color: '#181A1B' }}>
                                        AI 추천 혜택
                                    </Text>
                                    <div style={{ paddingLeft: "5px" }}>
                                        <Image src={ixi} width={40} height={23} />
                                    </div>
                                </div>
                            </div>
                            <div style={styles.sectionBody}>
                                <HeadingCard />
                            </div>
                        </>
                    ) : (
                        benefitTag.length > 0 && (
                            <>
                                <div style={styles.sectionSubHeader}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Text typo='font_heading_4B' color='color_text_natural_6' style={{ color: '#181A1B' }}>
                                            AI 추천 혜택
                                        </Text>
                                        <div style={{ paddingLeft: "5px" }}>
                                            <Image src={ixi} width={40} height={23} />
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.sectionBody}>
                                    <div style={{ margin: "0 -20px 0 0" }}>
                                        <SectionChip Tags={benefitTag} handleChipClick={handleBenefitChipClick} gtmType={'추천 제휴사 블록'} />
                                    </div>
                                    <BenefitInfo benefits={benefitList} selectedBenefitTag={selectedBenefit} onLocationClick={handleLocationClick} />
                                </div>
                            </>
                        )
                    )}
                </>
                {/*--------------------------------최근 사용 혜택------------------------------------*/}
                {((userStatus !== "0" && userStatus !== "5")) && (
                    <>
                        {/* 최근 사용한 혜택 */}
                        {recentUsedBenefit.length > 0 && (
                            <>
                                <div style={styles.sectionSubHeader}>
                                    <Text typo='font_heading_4B' color='color_text_natural_6'
                                          style={{color: '#181A1B'}}>
                                        최근 사용한 혜택
                                    </Text>
                                </div>

                                <div style={styles.sectionBody}>
                                    <CommonCarousel1 benefits={recentUsedBenefit}/>
                                </div>
                            </>
                        )}

                        {/* 영화 예매 */}
                        {movieRanking.length > 0 && (
                            <>
                                <div style={styles.sectionSubHeader}>
                                    <Text typo='font_heading_4B' color='color_text_natural_6'
                                          style={{color: '#181A1B'}}>
                                        {userStatus === "1" || userStatus === "3" ? "VIP콕 무료로 영화 예매하세요" : "VIP가 되면 영화도 무료로 볼 수 있어요"}
                                    </Text>
                                    <Image src={chevron_right_line} onClick={async () => handleMovieClick()}
                                           alt='placeholder' width='10' ratio='1:2'
                                           data-gtm-event-name={'all_click'}
                                           data-gtm-event-category={'클릭'}
                                           data-gtm-event-action={'영화 추천 블록 - 링크 클릭'}
                                           data-gtm-event-label={'컨텐츠 : 영화 더보기'}
                                           data-gtm-click-url={''}
                                           data-gtm-click-location={'영화 추천 블록'}
                                           data-gtm-click-direction={'내부'}
                                           data-gtm-click-text={'영화 더보기'}/>
                                </div>
                                <CommonCarousel2 movies={movieRanking} handleMovieClick={handleMovieClick}/>
                            </>
                        )}
                    </>
                )}
                {/*--------------------------------이벤트 추천------------------------------------*/}
                {(userStatus !== "0" && userStatus !== "3" && userStatus !== "4" && eventTag.length > 0) &&
                    <>
                        <div style={styles.sectionSubHeader}>
                            <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                지금 이벤트에 참여하세요
                            </Text>
                        </div>

                        <div style={styles.sectionBody}>
                            <div style={{margin: "0 -20px 0 0",}}>
                                <SectionChip Tags={eventTag} handleChipClick={handleEventChipClick}
                                             gtmType={'이벤트 블록'}/>
                            </div>
                            <EventInfo events={eventList}
                                       sspData={eventTag[0].tagId === selectedEventTag ? sspData : undefined}/>
                        </div>
                    </>
                }
                <div style={{
                    padding: '20px 20px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}>
                    <TextInfo/>
                </div>

                {showNotVipPopup && <CommonPopup
                    description={"VIP/VVIP 전용 혜택이에요.\n내 정보를 확인해 보시겠어요?"}
                    cancelText={"취소"}
                    onClose={() => setShowNotVipPopup(false)}
                    submitText={"내 정보보기"}
                    onSubmit={() => navigate('/my/grade')}
                />}
                {showUsevipPopup && <CommonPopup
                    description={"이번 달 콕을 사용했어요.\n영화 예매는 다음 달에 이용해 주세요.\n\n 예매 내역을 보시겠어요?"}
                    cancelText={"취소"}
                    onClose={() => setShowUsevipPopup(false)}
                    submitText={"MY예매 보기"}
                    onSubmit={() => {
                        setShowUsevipPopup(false)
                        goMovie()
                    }}
                />}
                <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
            </div>
        </>
    );
};

// 공통 스타일 객체 정의
const styles = {
    container: {
        width: '100%',
        height: '100%',
        padding: '0 0 70px 0',
        background: 'linear-gradient(-180deg, #ffffff 5px, #F3F5F6, #F3F5F6, #F3F5F6, #F3F5F6)',
    },
    sectionHeader: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        gap: '8px',
        padding: '12px 20px',
    },
    UPUPSubHeader: {
        padding: '16px 20px 0 20px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        gap: '8px',
        justifyContent: 'space-between',
    },
    sectionSubHeader: {
        padding: '24px 20px 0 20px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        gap: '8px',
        justifyContent: 'space-between',
    },
    sectionBody: {
        padding: '20px 20px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '12px',
    }
};

export default HomeBenefit;
