import React from 'react';
import {Header, IconButton, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";

const CommonAllIconHeader: React.FC = () => {
    const navigate = useNavigate()

    const back = () => {
        navigate(-1)
    }
    const goPaymentSetting = () => {
        navigate('/mobile-pay/setting')
    }

    return (
        <Header
            align="center"
            variant="heading"
            divider={false}
        >
            <Header.LeftSlot>
                <IconButton
                    color="neutral"
                    fill="icon"
                    icon="chevron_left_line"
                    onClick={back}
                    noStates
                />
            </Header.LeftSlot>
            <Header.CenterSlot>
                <Text typo="font_heading_4Sb">
                    휴대폰결제
                </Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                <IconButton
                    color="neutral"
                    fill="icon"
                    icon="setting_filled"
                    noStates
                    onClick={goPaymentSetting}
                    data-gtm-event-name="all_click"
                    data-gtm-event-category="클릭"
                    data-gtm-event-action="마이|휴대폰 결제 - 링크 클릭"
                    data-gtm-event-label="컨텐츠 : 설정"
                    data-gtm-click-url=""
                    data-gtm-click-location="마이|휴대폰 결제"
                    data-gtm-click-direction="내부"
                    data-gtm-click-text="설정"
                />
            </Header.RightSlot>
        </Header>
    );
}

export default CommonAllIconHeader;
