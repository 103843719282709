import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionQuitList from "../../components/Setting/SectionQuitList";
import SectionRadioList from "../../components/Setting/SectionRadioList";
import MustCheckAccordion from '../../components/Setting/SectionAccordion';
import SectionChkCTA from '../../components/Setting/SectionChkCTA';
import CommonPopup from "../../components/Common/CommonPopup";
import commonApi from "../../api/gatewayApi/common";
import {getItemByKey, removeItemByKey} from "../../utils/indexedDB";
import {sendEventReturn, sendEventToAndroid, sendEventToIOS} from "../../api/nativeBridge";
import {gtmSecede} from "../../api/gtm";

export interface QuitHandles {
    handleResetLoginInfo: () => void;
}


const UMembershipQuit = forwardRef<QuitHandles>((props, ref) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [openLastPopup, setOpenLastPopup] = useState(false);
    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [hasReasonText, setHasReasonText] = useState<boolean>(true);

    useEffect(() => {
        window.scrollTo(0, 0.1);
    }, []);

    const handleRadioChange = (reason: string) => {
        setSelectedReason(reason);
    };

    useImperativeHandle(ref, () => ({
        handleResetLoginInfo: async () => {
            await removeItemByKey('memberId');
            await removeItemByKey('memberStatus');
            await removeItemByKey('customerName');
            await removeItemByKey('isVIP');
            await removeItemByKey('userStatus');
            sendEventToAndroid("APP_FINISH", {});
            sendEventToIOS("APP_FINISH", {});
            setOpenPopup(false);
        }
    }))

    const handleConfirm = async () => {
        try {
            const subNo = await getItemByKey('subNo')
            let response
            if (subNo) {
                response = await commonApi.withdrawal({subNo: subNo, dropDetail: selectedReason}, 'v1');
            } else {
                response = await commonApi.withdrawal({dropDetail: selectedReason}, 'v1');
            }
            console.log(response)
        } catch (error) {
            console.error("Error fetchAlarm detail:", error);
        }
        setOpenPopup(false);
        setOpenLastPopup(true);
    };

    const handleLastConfirm = async () => {
        setOpenLastPopup(false);
        gtmSecede(selectedReason || '');
        sendEventReturn('RESET_LOGIN_INFO', {});
    };

    const text = [
        "탈퇴하면 내 정보는 즉시 삭제되지만, 휴대폰결제 관련 정보는 전자금융거래법에 따라 5년간 보관돼요.",
        "내 리워드도 모두 삭제 돼요. 리워드 관련 제휴사 포인트 등은 각 제휴사 고객센터에 문의해 주세요.",
        "LG페이 멤버십을 이용 중인 U+멤버스 회원은 회원 탈퇴 시 LG페이 이용이 제한될 수 있습니다.",
        "U+멤버십 앱만 탈퇴되며, U+멤버십 서비스 탈퇴는 휴대폰에서 LG유플러스 고객센터 114(무료)로 문의해 주세요.",
    ]
    return (
        <>
            <CommonLeftIconHeader title={"회원탈퇴"}/>

            {/* ----------  탈퇴 전 혜택 확인 ----------- */}
            <div style={{
                overflowY: "auto", // 세로 스크롤 허용
                paddingBottom: "110px", // 하단 CTA 버튼 고정
            }}>
                <SectionQuitList/>

                <div style={{marginBottom: "8px"}}>
                    <CommonDivider/>
                </div>

                {/* ----------  탈퇴 사유 체크 ----------- */}
                <div style={{marginBottom: "12px"}}>
                    <SectionRadioList setHasReasonText={setHasReasonText} onRadioChange={handleRadioChange}/>
                </div>

                <CommonDivider/>
                <MustCheckAccordion items={text}/>
            </div>
            <SectionChkCTA hasReasonText={hasReasonText} setOpenPopup={setOpenPopup} selectedReason={selectedReason}/>

            {openPopup && (
                <CommonPopup
                    description="정말로 탈퇴하시겠어요?"
                    submitText="탈퇴하기"
                    cancelText="취소"
                    onSubmit={handleConfirm}
                    onClose={() => setOpenPopup(false)}
                />
            )}

            {openLastPopup && (
                <CommonPopup
                    description={"U+멤버십 앱을 탈퇴했어요.\n그동안 U+멤버십 앱을 이용해 주셔서\n감사합니다."}
                    submitText="확인"
                    onSubmit={handleLastConfirm}
                />
            )}
        </>
    );
})

export default UMembershipQuit;