export const joinRequestParam = (termList: Term[]) => {
    return {
        serviceId: '129001',
        termsList: termList.map((term) => ({
            termId: term.termId,
            termYn: term.isAgree ? 'Y' : 'N',
            termType: term.termType,
        })),
    }
}

export interface Term {
    termId: string;
    termName: string;
    termType: string;
    isAgree: boolean;
}

export interface UserInfo {
    address1: string;
    address2: string;
    email: string;
    zipCode: string;
}

export const initialUserInfo: UserInfo = {
    address1: '',
    address2: '',
    email: '',
    zipCode: ''
}

export const getStringBeforeAt = (email: string) => {
    const atIndex = email.indexOf('@');

    if (atIndex === -1) {
        return email;  // @가 없으면 전체 문자열 반환
    }

    return email.slice(0, atIndex);  // @ 앞부분만 반환
};
