import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import SectionHeader from '../../components/Common/CommonHeader';
import SectionHeading from '../../components/Agreements/SectionHeading';
import SectionChkList from '../../components/Agreements/SectionChkList';
import TermUnitedInfoBoxCTA from '../../components/Agreements/TermUnitedInfoBoxCTA';
import {Agreement, JoinRequestParams} from './types';
import useLoading from "../../hooks/useLoading";
import {termsApi} from "../../api/gatewayApi/settingAndTerms";
import commonApi from "../../api/gatewayApi/common";
import {affiliateServiceApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn} from "../../api/nativeBridge";
import CommonPopup from "../../components/Common/CommonPopup";
import AgreementsDetail from "./AgreementsDetail";
import {gtmPageLoad, gtmSignUp} from "../../api/gtm";
import {checkUnder14} from "../IdentityVerification/validators";
import {decrypt, encrypt, generateMCP_TR_KEY} from "../../api/authFunctions";
import {useMessagePopup} from "../../provider/MessagePopupProvider";

export interface AgreementHandles {
    triggerHandleJoin: (fcmToken: string) => void;
    triggerKeypressBack: () => void;
}

const Agreements = forwardRef<AgreementHandles>((props, ref) => {
    const navigate = useNavigate();
    const {type} = useParams<{ type: string }>();
    const [agreementList, setAgreementList] = useState<Agreement[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allCheck, setAllCheck] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);  // 버튼 활성화 상태
    const [isDetailOpen, setIsDetailOpen] = useState(false);  // Bottom Sheet의 상태 관리
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [selectedTermDisabled, setSelectedTermDisabled] = useState<boolean>(false); // 후에 리팩토링 필요
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [fcmToken, setFcmToken] = useState<string | null>(null);  // fcmToken 상태 추가
    const serviceId = "3";
    const location = useLocation();
    const {showMessagePopup} = useMessagePopup();

    const [info, setInfo] = useState(location.state); // location.state를 상태로 설정
    const [showBackPopup, setShowBackPopup] = useState(false);

    useEffect(() => {
        const fetchInfoFromDB = async () => {
            if (!location.state) {
                const storedInfo = await getItemByKey('join');
                if (storedInfo) {
                    setInfo(storedInfo);
                } else {
                    console.error("정보를 찾을 수 없습니다. 다시 시도해 주세요.");
                    navigate('/'); // 정보가 없을 경우 메인 페이지로 리다이렉트
                }
            } else {
                // state가 존재하면, 이를 IndexedDB에 저장
                await addItem('join', location.state);
            }
        };

        fetchInfoFromDB();
    }, [location.state, navigate]);

    useEffect(() => {
        gtmPageLoad({
            url: '/agreements',
            contentGroup: '기타|약관동의',
            moduleName: '',
            pageTitle: '서비스 이용 약관 및 개인정보 동의서에 동의해 주세요'
        });
    }, []);

    useImperativeHandle(ref, () => ({
        async triggerHandleJoin(fcmToken: string) {
            console.log("handleJoin")
            await handleJoin(fcmToken);
        },
        async triggerKeypressBack() {
            if (isDetailOpen) {
                setIsDetailOpen(false);
            } else {
                setShowBackPopup(true);
            }
        }
    }));

    const handleJoin = useCallback(async (fcmToken: string) => {
        if (fcmToken === '') {
            await addItem('pushRegId', fcmToken)
            await addItem('sendTokenYn', 'N')
        }
        setIsLoading(true);
        const MCP_TR_KEY = generateMCP_TR_KEY();
        const params: JoinRequestParams = {
            name: await encrypt(MCP_TR_KEY, info.name),
            phoneNo: info.phoneNo,
            birthday: info.birthday,
            gender: info.gender,
            nation: info.nation,
            telcoCode: info.telcoCode,
            pushRegId: fcmToken,
            modelName: await getItemByKey('model'),
            termsList: agreementList.map((agreement) => ({
                termId: agreement.termId,
                termYn: agreement.selected ? 'Y' : 'N',
                termsType: agreement.termsType,
                optTermCode: agreement.optTermCode || '',
            })),
            ...(info.certNum ? {certNum: info.certNum} : {}),
            ...(info.dasKey ? {dasKey: info.dasKey} : {}),
            ...(info.dasSsoKey ? {dasSsoKey: info.dasSsoKey} : {}),
            ...(info.ipinAuthId ? {ipinAuthId: info.ipinAuthId} : {}),
        };

        try {
            const response = await commonApi.join(params, 'v1', MCP_TR_KEY);
            const data = response.body;
            if (data === undefined || data === null) {
                setIsLoading(false);
                setOpenErrorPopup(true);
            } else {
                const memberId = await decrypt(MCP_TR_KEY, data.memberId);
                await sendEvent('SET_MEMBER_ID', {memberId});
                await addItem('memberId', memberId);

                const name = await decrypt(MCP_TR_KEY, data.customerName);
                await sendEvent('SET_USER_NAME', {userName: name});
                await addItem('customerName', name);

                const ctn = await decrypt(MCP_TR_KEY, data.ctn);
                await sendEvent('SET_CTN_INFO', {ctn_info: ctn});
                await addItem('ctn', ctn);

                const birthday = data.birthday;
                await sendEvent('SET_USER_BIRTH', {userBirth: birthday});
                await addItem('birthday', birthday);

                const gender = data.gender;
                await sendEvent('SET_USER_GENDER', {gender});
                await addItem('gender', gender);

                const telcoCode = data.telcoCode;
                await sendEvent('SET_CARRIER', {carrier: telcoCode});
                console.log("data.telcoCode", telcoCode)
                await addItem('telcoCode', telcoCode);

                await addItem('userStatus', data.memberStatus);

                const subNo = data.subNo;  // TODO 가번 추후 native 저장
                await addItem('subNo', subNo);

                const subCustNo = data.subCustNo;  // TODO 고객번호 추후 native 저장

                await addItem('subCustNo', subCustNo);

                const isCarrier = telcoCode === "L"
                await sendEventReturn('SET_PASSWORD', {isCarrier: isCarrier});

                const adAgreement = agreementList.filter(agreement => agreement.termsType === '125003');
                if (adAgreement.length > 0) {
                    sendEvent('SET_AD_AGREE', {agree: adAgreement[0].selected});
                    addItem('adAgreeYn', adAgreement[0].selected ? 'Y' : 'N');
                }
                const geoAgreement = agreementList.filter(agreement => agreement.termsType === '125004');
                if (geoAgreement.length > 0) {
                    addItem('geoAgreeYn', geoAgreement[0].selected ? 'Y' : 'N');
                }
                await addItem('pushRegId', fcmToken)
                await addItem('sendTokenYn', 'Y')
                gtmSignUp();
                navigate('/?state=first', {replace: true});
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
        } finally {
            setIsLoading(false);
        }
    }, [agreementList, info, navigate]);

    const handleCTAButtonClick = useCallback(() => {
        if (type === 'join') {
            // handleJoin('');
            sendEventReturn("FCM_TOKEN", {})
        } else if (type === 'partnership') {
            console.log("파트너십 약관 동의 및 진행");
        } else if (type === 'existing') {
            console.log("기존 약관 동의 및 진행");
        }
    }, [type, agreementList, info]);

    const goMain = () => {
        navigate('/');
    };

    const handleTermClick = (termId: string, disable: boolean) => {
        setSelectedTermId(termId);
        setSelectedTermDisabled(disable)
        setIsDetailOpen(true);  // Bottom Sheet 열기
    };

    const handleDetailClose = () => {
        setIsDetailOpen(false);  // Bottom Sheet 닫기
    };

    const handleSubmit = (termId: string) => {
        // termId에 해당하는 약관의 체크 상태를 반대로 변경
        setAgreementList(prevList =>
            prevList.map(term =>
                term.termId === termId ? {...term, selected: !term.selected} : term
            )
        );
        setIsDetailOpen(false); // 세부 항목 창 닫기
    };

    useEffect(() => {
        const fetchAgreements = async () => {
            try {
                let response;
                switch (type) {
                    case 'join':
                        response = await termsApi.getJoinAgreements({
                            birthday: info.birthday,
                            phoneNo: info.phoneNo
                        }, 'v1');
                        const reqTermsList = response.body.reqTermsList.map((term: any) => ({
                            termId: term.termId,
                            termName: term.termName,
                            termsType: term.termsType,
                            optTermCode: term.optTermCode,
                            selected: false,
                            isRequired: true,  // 필수 약관
                        }));

                        const optTermsList = response.body.optTermsList.map((term: any) => ({
                            termId: term.termId,
                            termName: term.termName,
                            termsType: term.termsType,
                            optTermCode: term.optTermCode,
                            selected: false,
                            isRequired: false,  // 선택 약관
                        }));

                        if (checkUnder14(info.birthday)) {
                            setAgreementList([...reqTermsList]);
                        } else {
                            setAgreementList([...reqTermsList, ...optTermsList]);
                        }

                        break;
                    case 'partnership':
                        response = await affiliateServiceApi.getAgreements({serviceId}, 'v1');
                        break;
                    case 'existing':
                        response = await termsApi.getAgreements({}, 'v1');
                        break;
                    default:
                        throw new Error("Invalid agreement type");
                }
            } catch (error) {
                console.error("Error fetching agreements:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgreements();
    }, [type]);

    useEffect(() => {
        const allChecked = agreementList.every(term => term.selected);
        const allRequiredChecked = agreementList.every(term => term.isRequired ? term.selected : true);

        if (allCheck !== allChecked) {
            setAllCheck(allChecked);
        }
        setIsButtonEnabled(allRequiredChecked);  // 필수 약관이 모두 체크되었는지 확인하여 버튼 상태 설정
    }, [agreementList]);

    const handleAgreementChange = (termId: string, isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term =>
                term.termId === termId ? {...term, selected: isChecked} : term
            )
        );
    };

    const handleAllAgreementsChange = (isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term => ({...term, selected: isChecked}))
        );
        setAllCheck(isChecked);
    };

    return (
        <>
            {useLoading(isLoading)}
            {!isDetailOpen &&
                <div style={{paddingBottom: '80px'}}>
                    <SectionHeader align={"left"} icon={"chevron_left_line"}/>
                    <SectionHeading mainType={type}
                                    subType={checkUnder14(info.birthday) ? '14세 미만' : info.telcoCode === 'L' ? "자사" : "타사"}/>
                    <SectionChkList
                        agreementList={agreementList}
                        allCheck={allCheck}
                        onAgreementChange={handleAgreementChange}
                        onAllAgreementsChange={handleAllAgreementsChange}
                        onTermClick={handleTermClick}
                    />
                    <TermUnitedInfoBoxCTA onClickConfirm={handleCTAButtonClick} isButtonEnabled={isButtonEnabled}/>
                </div>
            }

            {openErrorPopup && (
                <CommonPopup description={"일시적인 서비스 오류로 가입이 어려워요.\n다음에 이용부탁드려요."} submitText={"확인"} onSubmit={goMain}/>
            )}
            {isDetailOpen && <AgreementsDetail
                selected={agreementList.find(term => term.termId === selectedTermId)?.selected || false}
                type={type} termId={selectedTermId || ""}
                handleSubmit={() => handleSubmit(selectedTermId || '')}
                handleClose={() => setIsDetailOpen(false)}
                disabled={selectedTermDisabled}
            />}

            <CommonPopup isOpen={showBackPopup} submitText={'확인'} onSubmit={() => navigate(-1)}
                         description={'뒤로가면 휴대폰 인증을 다시 받아야 해요. 정말 뒤로 가시겠어요?'} cancelText={'취소'}
                         onClose={() => setShowBackPopup(false)}/>
        </>
    )
})

export default Agreements;
