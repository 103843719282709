import React from 'react';
import {Button, CTA} from "@lguuxe/2024_designsystem_uni";

interface CommonCTAColumnProps {
    handleSummit?: () => void;
    buttonText?: string;
    cancelText?: string;
    onClose?: () => void;
    gtmType?: string;
    isActive?: boolean;
    gradation?: boolean;
}

const CommonCTAColumn: React.FC<CommonCTAColumnProps> = ({
                                                             buttonText,
                                                             handleSummit,
                                                             cancelText,
                                                             onClose,
                                                             gtmType,
                                                             isActive= true,
                                                             gradation,
                                                         }) => {

    return (
        <CTA layout="row" gradation={gradation}>
            {cancelText && (
                <Button color="neutral" size="large" onClick={onClose}>
                    {cancelText}
                </Button>
            )}
            {buttonText &&
                <Button color="primary" size="large" onClick={handleSummit} disabled={!isActive}
                >
                    {buttonText}
                </Button>}
        </CTA>
    );
}

export default CommonCTAColumn;
