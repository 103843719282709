import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Icon, Tag, Text, Toggle} from '@lguuxe/2024_designsystem_uni';
import CommonDividerVertical from '../Common/CommonDividerVertical';
import BarcodeFailed from './BarcodeFailed';
import BarcodeTimeOut from './BarcodeTimeOut';
import BarcodeBlock from './BarcodeBlock';
import {BarcodeStatus, PaymentBarcode} from './types';
import Barcode from 'react-barcode';
import useDebounce from "../../hooks/useDebounce";
import {useSnackbar} from "../../provider/SnackbarProvider";
import BarcodeLoading from "./BarcodeLoading";
import {callCheckPassword, preparePaymentApi} from "../../utils/helper";
import paymentApi from "../../api/gatewayApi/payment";
import {PAYMENT_BARCODE_TIMER} from "./BS_Barcode";
import {addItem, getItemByKey} from "../../utils/indexedDB";

const BARCODE_TIMER = 60; // 15분 = 900초

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
};

export interface BarcodeHandlers {
    handleCheckPassword: (result: string, auth: string) => void;
}

interface PaymentBarcodeSectionProps {
    barcode: PaymentBarcode;
    onChange: (status: BarcodeStatus, password?: string) => void;
    onTermBsOpen: () => void;
    onToggle: (isOpen: boolean) => void;
}

const PaymentBarcodeSection: React.FC<PaymentBarcodeSectionProps> = ({barcode, onChange, onTermBsOpen, onToggle}) => {
    const [time, setTime] = useState(PAYMENT_BARCODE_TIMER / 1000);
    const [isMembershipPopupVisible, setIsMembershipPopupVisible] = useState(false);
    const [isMembershipDiscount, setIsMembershipDiscount] = useState<boolean>(false);
    const barcodeContainerRef = useRef<HTMLDivElement>(null);
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const {showSnackbar} = useSnackbar();

    // TODO: 보안 프로그램 설치 팝업

    useEffect(() => {
        const handleCheckPasswordEvent = (event: CustomEvent) => {
            const password = event.detail.password;
            console.log('handleCheckPasswordEvent : password ', password);
            onChange('ready', password);
        };

        const handleFailPasswordEvent = (event: CustomEvent) => {
            onChange('init'); // timeout?
        }

        const handleCheckPasswordTermEvent = (event: CustomEvent) => {
            const password = event.detail.password;
            const fetch = async () => {
                const res = await preparePaymentApi('15', password, {}, paymentApi.postServiceUseTerm);
                if (res && res.header.resultCode === '0000') {
                    addItem('useTermYn', 'Y');
                    onChange('ready', password);
                } else {
                    onChange('fail');
                }
            }
            fetch();
        }

        window.addEventListener('passwordBarcode', handleCheckPasswordEvent as EventListener);
        window.addEventListener('failPassword', handleFailPasswordEvent as EventListener);
        window.addEventListener('passwordBarcodeTerm', handleCheckPasswordTermEvent as EventListener);

        return () => {
            window.removeEventListener('passwordBarcode', handleCheckPasswordEvent as EventListener);
            window.removeEventListener('failPassword', handleFailPasswordEvent as EventListener);
            window.removeEventListener('passwordBarcodeTerm', handleCheckPasswordTermEvent as EventListener);
        };
    }, []);

    useEffect(() => {
        console.log('paymentBarcode', barcode);
        if (barcode.barcode !== '' && barcode.status === 'ready') {
            // onchange('running');
            getWithMembershipDiscount();

            const nowTime = new Date(barcode.barcodeTime - new Date().getTime());
            setTime(nowTime.getMinutes() * 60 + nowTime.getSeconds());
            const timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 1) {
                        return prevTime - 1;
                    } else {
                        clearInterval(timer);
                        onChange('init');
                        return 0;
                    }
                });
            }, 1000);

            const updateBarcodeWidth = () => {
                if (barcodeContainerRef.current) {
                    const containerWidth = barcodeContainerRef.current.offsetWidth;
                    setBarcodeWidth(containerWidth / 120); // 바코드에 맞는 적절한 비율로 너비 계산 (숫자는 비율에 맞게 조정)
                }
            };
            updateBarcodeWidth();
            window.addEventListener('resize', updateBarcodeWidth);

            return () => {
                clearInterval(timer)
                window.removeEventListener('resize', updateBarcodeWidth);
            }
        }
    }, [barcode]);

    const formatBarcode = (barcode: string) => {
        return barcode.match(/.{1,4}/g) || [];
    };

    const handleGenerateBarcode = useDebounce(async () => {
        // TODO: 1.  보안프로그램 설치 여부
        let useTermYn = await getItemByKey('useTermYn');
        if (!useTermYn) {
            try {
                const memberId = await getItemByKey('memberId')
                if (memberId) {
                    const termRes = await paymentApi.getServiceUseTerm('v1');
                    if (termRes.header.resultCode === '0000') {
                        addItem('useTermYn', termRes.body.useTermYn)
                        useTermYn = termRes.body.useTermYn;
                    }
                }
            } catch (error) {
                console.log('Failed to fetch useTermYn :', error);
            }
        }


        if (useTermYn === 'Y') {
            await callCheckPassword('barcode')
            onChange('loading');

            //TODO: remove
            // onClose();
            // onTermBsOpen();
        } else if (useTermYn === 'N') {
            onToggle(false);
            onTermBsOpen();
        } else {
            onChange('fail');
        }
    });

    const handleMembershipDiscount = useDebounce((event: any) => {
        console.log('handleMembershipDiscount', event);
        if (event.value == 'value') {
            setIsMembershipDiscount(!isMembershipDiscount);
            putWithMembershipDiscount(!isMembershipDiscount);
        } else {
            setIsMembershipPopupVisible(true);
            putWithMembershipDiscount(!isMembershipDiscount);
        }
    });

    const getWithMembershipDiscount = async () => {
        try {
            const res = await paymentApi.getMembershipDiscount({reqType: 'G'}, 'v1');
            if (res.header.resultCode === '0000' && res.body) {
                res.body.discountSetStus === 'Y' ? setIsMembershipDiscount(true) : setIsMembershipDiscount(false);
            } else {
                console.log('getMembershipDiscount fail');
            }
        } catch (error) {
            console.error('getMembershipDiscount error:', error);
        }
    }

    const putWithMembershipDiscount = async (isMembershipDiscount: boolean) => {
        const discountOnOff = isMembershipDiscount ? '10' : '20';
        try {
            const res = await paymentApi.putMembershipDiscount({discountOnOff: discountOnOff}, 'v1');
            if (res.header.resultCode === '0000') {
                isMembershipDiscount ? showSnackbar('제휴사에서 휴대폰결제 할 때 U+멤버십 할인이\n 자동 적용돼요.') : showSnackbar('휴대폰결제할 때 U+멤버십 할인이 적용되지않아요.')
            } else {
                setIsMembershipDiscount(!isMembershipDiscount);
                showSnackbar('일시적인 오류가 발생했어요')
            }
        } catch (error) {
            setIsMembershipDiscount(!isMembershipDiscount);
            showSnackbar('일시적인 오류가 발생했어요')
        }
    }

    if (barcode.payBlckYn === 'Y') {
        return <BarcodeBlock/>;
    }
    if (barcode.status === 'fail') {
        return <BarcodeFailed onGenerateBarcode={handleGenerateBarcode}/>;
    }
    if (barcode.status === 'init') {
        return <BarcodeTimeOut onGenerateBarcode={handleGenerateBarcode} tabType='payment'/>;
    }

    return (
        <>
            <div
                style={{
                    border: '2px solid transparent',
                    borderRadius: '12px',
                    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)',
                    borderImageSlice: '1',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                    width: '100%',
                    height: '155px',
                    boxSizing: 'border-box',
                }}
            >
                <div
                    style={{
                        padding: '16px 20px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tag
                            item={[
                                {
                                    colorToken: 'gradient_secondary',
                                    label: '결제 바코드',
                                    size: 'small',
                                    style: 'solid',
                                },
                            ]}
                        />
                        <Toggle size='small' onChange={handleMembershipDiscount} checked={isMembershipDiscount}>
                            멤버십 할인 함께 받기
                        </Toggle>
                    </div>
                    <div
                        ref={barcodeContainerRef}
                        style={{
                            width: 'auto',
                            height: '56px',
                            display: "flex",
                            margin: '12px auto 0',
                            justifyContent: 'center',
                        }}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'바코드|휴대폰 결제 - 링크 클릭'}
                        data-gtm-event-label={'컨텐츠 : 소액결제 바코드'}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'바코드|휴대폰 결제'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={'소액결제 바코드'}
                    >
                        {barcode.status === 'loading' ?
                            <Box type="4_trbl">
                                <BarcodeLoading/>
                            </Box> :
                            <Barcode value={barcode.barcode}
                                     displayValue={false}
                                     height={56}
                                     width={barcodeWidth}
                                     margin={0}/>}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            {formatBarcode(barcode.barcode).map((chunk, index) => (
                                <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                    {chunk}
                                </Text>
                            ))}
                            {/*<IconButton icon='copy_line' size='small' fill='icon' onClick={handleCopy}/>*/}
                        </div>

                        <CommonDividerVertical/>
                        <Button color='primary' fill='text' onClick={handleGenerateBarcode}>
                            {formatTime(time)}
                            <Icon
                                name='refresh_line'
                                size='16px'
                                color='#E6007E'
                                style={{
                                    marginLeft: '2px',
                                }}
                            />
                        </Button>

                        {/* {isMembershipPopupVisible && (  )} */}
                        {/* TODO: 팝업 띄우기... 팝업 공용화. 차단 헤제하기 누르면 휴대폰결제 설정 화면으로 이동     */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentBarcodeSection;
