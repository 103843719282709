import {useCallback} from 'react';
import {debounce} from 'lodash';

// useDebounce: debounce 처리를 일반화한 커스텀 훅
const useDebounce = (callback: (...args: any[]) => void, delay?: number) => {
    if (!delay) {
        delay = 500;
    }
    return useCallback(debounce(callback, delay), [callback, delay]);
};

export default useDebounce;