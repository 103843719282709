import React from 'react';
import {Box, Image, List, Tag, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonDividerVertical from "../../components/Common/CommonDividerVertical";
import {MonthEventByDate} from "../../pages/UPTP/types"; // 타입을 import
import ExImg from "../../images/uptp/Upltwopl_default_1_1.svg";
import {useNavigate} from "react-router-dom";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import {getColorTokenForLabel} from "../../utils/helper";
import {sendEventReturn} from "../../api/nativeBridge";
import {decodeHtmlEntities} from "../../utils/textUtils";

interface SectionHeadingListNoBtn3Props {
    monthEventData: MonthEventByDate;
    currentDay: string
}

const SectionHeadingListNoBtn3: React.FC<SectionHeadingListNoBtn3Props> = ({monthEventData, currentDay}) => {
    const navigate = useNavigate();
    const getEventStatus = (startDate: string, endDate: string) => {
        const today = currentDay
        const getDayFromDate = (dateString: string) => {
            return parseInt(dateString.slice(8, 10)); // '일' 부분만 추출 (YYYY-MM-DD에서 DD만)
        };

        const todayDay = parseInt(today.slice(6, 8)); // 오늘의 일(Day)
        const startDay = getDayFromDate(startDate); // 시작 날짜의 일(Day)
        const endDay = getDayFromDate(endDate); // 종료 날짜의 일(Day)

        if (todayDay < startDay) {
            const daysUntilStart = startDay - todayDay; // 다가올 이벤트까지 남은 일 수
            return `D-${daysUntilStart}`;
        }

        if (todayDay > endDay) return '종료'; // 종료된 이벤트
        if (todayDay === endDay) return ''; // 오늘이 종료일인 경우 D-Day

        return '';
    };

    const handleItemClick = (eventId: string) => {
        sendEventReturn('WAITING_LINE_POPUP', {id: eventId, target: 'detail'});
        // navigate(`/uptp-detail/${eventId}`);
    }

    // eventList가 undefined면 아무것도 렌더링하지 않음
    if (!monthEventData.eventList) {
        return null;
    }

    return (
        <List
            type="view"
            fill="none"
            isSlotClickable={true}
            items={monthEventData.eventList.map((event, index) => {
                const eventStatus = getEventStatus(event.startDate, event.endDate);
                return {
                    onClick: () => handleItemClick(event.targetUrl),
                    leftSlot: [
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                            }}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={`${CLICK_LOCATION.UPTP_DAY_BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                            data-gtm-event-label={`컨텐츠 : ${event.evtSummary}`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={`${CLICK_LOCATION.UPTP_DAY_BENEFIT}`}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`${event.evtSummary}`}
                        >
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    eyebrow: (
                                        <div
                                            style={{
                                                paddingBottom: "2px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text typo="font_detail_3Sb"
                                                  color="color_text_neutral_2">{decodeHtmlEntities(event.ptnName)}</Text>
                                            {event.categoryName &&
                                                <>
                                                    <CommonDividerVertical/>
                                                    <Text typo="font_detail_3M"
                                                          color="color_text_neutral_2">{decodeHtmlEntities(event.categoryName)}</Text>
                                                </>
                                            }
                                        </div>
                                    ),
                                    title: (
                                        <Text color="color_text_neutral_6">{decodeHtmlEntities(event.evtSummary)}</Text>
                                    )
                                }}
                            />
                            {event.tagName && (
                                <Tag
                                    item={event.tagName.split('|').map((tag, index) => ({
                                        label: tag.trim(), // 공백을 제거한 태그 이름
                                        size: "xSmall",
                                        style: "solid",
                                        colorToken: getColorTokenForLabel(tag),
                                    }))}
                                />
                            )}
                        </div>,
                    ],
                    rightSlot: [
                        <Box
                            style={{
                                position: "relative",
                                padding: "0"
                            }}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={`${CLICK_LOCATION.UPTP_DAY_BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                            data-gtm-event-label={`컨텐츠 : ${event.evtSummary}`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={`${CLICK_LOCATION.UPTP_DAY_BENEFIT}`}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`${event.evtSummary}`}
                        >
                            <Image
                                src={event.evtTumbImgUrl || ExImg}
                                ratio="1:1"
                                width="72px"
                                rounded="8"
                                alt={event.evtSummary}
                            />
                            {eventStatus && (
                                <Box
                                    style={{
                                        position: "absolute",
                                        right: "0",
                                        top: "0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "rgba(0, 0, 0, 0.42)",
                                        borderRadius: "8px",
                                        width: "100%",
                                        height: "100%",
                                        color: "#fff",
                                        padding: "0"
                                    }}
                                >
                                    {eventStatus}
                                </Box>
                            )}
                        </Box>
                    ],
                };
            })}
        />
    )
}

export default SectionHeadingListNoBtn3;
