import TagManager from 'react-gtm-module'
import {getItemByKey} from "../utils/indexedDB";
import {BASE_URL} from "./api";
import {getGradeName} from "../pages/My/types";
import {Badge} from '../pages/Badge/GiftBadge';

interface GtmArgs {
    clickLocation: string,
    clickText: string,
    isInner?: boolean,
    url?: string,
    eventId?: string,
    eventName?: string,
    eventPeriod?: string,
    ptnId?: string,
    ptnName?: string,
    ptnSector?: string,
}

// 가져가서 태그 안에 넣어주면 됨
// data-gtm-event-name={'all_click'}
// data-gtm-event-category={'클릭'}
// data-gtm-event-action={'LNB|챗봇 - 링크 클릭'}
// data-gtm-event-label={`컨텐츠 : 챗봇`}
// data-gtm-click-url={''}
// data-gtm-click-location={'LNB|챗봇'}
// data-gtm-click-direction={'내부'}
// data-gtm-click-text={`${title}`}
// data-gtm-mkt-event-id={''}
// data-gtm-mkt-event-name={''}
// data-gtm-mkt-event-period={''}
// data-gtm-affiliate-id={''}
// data-gtm-affiliate-name={''}


export const gtmDataLayer = ({
                                 clickLocation,
                                 clickText,
                                 isInner = true,
                                 url,
                                 eventId,
                                 eventName,
                                 eventPeriod,
                                 ptnId,
                                 ptnName,
                                 ptnSector
                             }: GtmArgs) => {
    const tagManagerArgs = {
        dataLayer: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': clickLocation + ' - 링크 클릭',
            'data-gtm-event-label': '컨텐츠 : ' + clickText,
            'data-gtm-click-url': url,
            'data-gtm-click-location': clickLocation,
            'data-gtm-module-name': clickLocation,
            'data-gtm-click-direction': isInner ? '내부' : '외부',
            'data-gtm-click-text': clickText,
            'data-gtm-mkt-event-id': eventId,
            'data-gtm-mkt-event-name': eventName,
            'data-gtm-mkt_event_period': eventPeriod,
            'data-gtm-affiliate-id': ptnId,
            'data-gtm-affiliate-name': ptnName,
            'data-gtm-affiliate-sector': ptnSector,
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}


export const gtmPageLoad = async ({url, contentGroup, moduleName, pageTitle}: any) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'spaPageLoaded',
            pageUrl: BASE_URL + url,
            user_page_type: await getMemberPageType(),
            content_group: contentGroup,
            module_name: moduleName,
            page_title: pageTitle,

        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

const getMemberPageType = async () => {
    const memberStatus = await getItemByKey('memberStatus')
    switch (memberStatus) {
        case '0':
            return '미로그인';
        case '1':
            return '14세이상_VIP';
        case '2':
            return '14세이상_우수';
        case '3':
            return '14세미만_VIP';
        case '4':
            return '14세미만_우수';
        case '5':
            return '타사';
    }
}


export const gtmUserInfo = async () => {
    const getAgeGroup = (birthday: string) => {
        if (!birthday) return ''
        const currentYear = new Date().getFullYear()
        const birthYear = Number(birthday.slice(0, 4))
        const age = currentYear - birthYear

        if (age < 10) return 'Under 10';
        if (age >= 10 && age < 20) return '10s';
        if (age >= 20 && age < 30) return '20s';
        if (age >= 30 && age < 40) return '30s';
        if (age >= 40 && age < 50) return '40s';
        if (age >= 50 && age < 60) return '50s';
        if (age >= 60 && age < 70) return '60s';
        if (age >= 70 && age < 80) return '70s';
        if (age >= 80 && age < 90) return '80s';
        if (age >= 90 && age < 100) return '90s';
        return 'Over 100';
    }

    const longTermCode = await getItemByKey('longTermCode')
    const loyalty = longTermCode === 'NG1' ? '2년 이상' : longTermCode === 'NG2' ? '5년 이상' : longTermCode === 'NG3' ? '10년 이상' : '';

    (window as any).digitalData.userInfo = {
        user_id: await getItemByKey('memberId'),
        member_type: getGradeName(await getItemByKey('cardLevel')),
        gender: await getItemByKey('gender'),
        age: getAgeGroup(await getItemByKey('birthday') || ''),
        user_address: '',
        marketing_agreement: await getItemByKey('maketingInfoYn'),
        login_type: '',
        own_third_type: await getItemByKey('telcoCode') === 'L' ? '자사' : '타사',
        geo_service_agreement: await getItemByKey('geoAgreeYn'),
        ad_agreement_app: await getItemByKey('adAgreeYn'),
        user_mykok_type: '', // 구독/라이프/영화...
        entry_type: '', // 잠금화면/APPPush/앱테크
        member_loyalty: loyalty,
        member_uth: await getItemByKey('youthYn'),
    }
}

export const gtmSignUp = () => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'sign_up',
            register_channel: '멤버'
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmCommonComp = (name: string, yn?: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'compEvt',
            comp_evt_name: name,
            comp_evt_content: yn ? yn === 'Y' ? 'N > Y' : 'N > Y' : ''
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmEventSubmit = ({eventName, eventPeriod, eventType, eventId}: any) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'event_submit',
            mkt_event_name: eventName,
            mkt_event_period: eventPeriod,
            mkt_event_type: eventType,
            mkt_event_id: eventId,
        }
    }
}

export const gtmDailyCheckComp = () => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'daily_check_comp',
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmBadgeSendComp = (badgeList: Badge[]) => {
    const totalCount = badgeList.reduce((acc, badge) => acc + badge.count, 0);
    const categoryNames = badgeList.map(badge => badge.categoryName).join('|');

    const tagManagerArgs = {
        dataLayer: {
            event: 'badge_send_comp',
            badge_quantity: totalCount,
            badge_name: categoryNames,
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmOpenPopup = (popupName: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'open_popup',
            pupup_name: popupName,
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmDeleteComp = (contentStatus: string, contentName: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'delete_comp',
            delete_type: contentStatus,
            deleted_contents_name: contentName
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmUseCouponComp = (couponName: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'use_coupon_comp',
            coupon_name: couponName
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmLogin = () => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'login',
            login_channel: ''
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmFavoriteComp = (contentName: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'favorite_comp',
            favorite_type: '제휴사',
            favorite_contents: contentName
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

export const gtmViewSearchResults = (searchFilter: string) => {
    // 1. 연속된 공백을 하나의 공백으로 치환
    const normalizedFilter = searchFilter.replace(/\s+/g, ' ');
    const formattedFilter = normalizedFilter.split(' ').join('|');

    const tagManagerArgs = {
        dataLayer: {
            event: 'view_search_results',
            search_result_pattern: formattedFilter,
            search_result_qty: ''
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

export const gtmAffiliateCouponDownloadComp = (couponName: string, ptnId: string, ptnName: string, ptnCategory: string, period: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'affiliate_coupon_download_comp',
            coupon_name: couponName,
            mkt_event_id: '',
            mkt_event_name: '',
            mkt_event_period: period,
            affiliate_id: ptnId,
            affiliate_name: ptnName,
            affiliate_sector: ptnCategory,
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmLogout = () => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'logout',
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const gtmSecede = (reason: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'secede',
            secede_reason: reason,
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

export const gtmInquiryComplete = (category: string) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'inquiry_complete',
            service_categoty: category,
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}