import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CommonSearch from '../../components/Common/CommonSearch';
import CommonHeading from '../../components/Common/CommonHeading';
import RecentSearch from '../../components/Search/RecentSearch';
import RecommandSearch from '../../components/Search/RecommandSearch';
import Section4List from '../../components/Search/ChatbotItem';
import {addItem, getItemByKey} from '../../utils/indexedDB';
import {api, ApiEndpoints} from '../../api/api';
import CommonNodata from "../../components/Common/CommonNodata";
import CommonDivider from "../../components/Common/CommonDivider";
import useLoading from "../../hooks/useLoading";
import useDebounce from "../../hooks/useDebounce";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {gtmPageLoad} from "../../api/gtm";
import {SearchResults} from "./ResultDetail";

export interface ListItem {
    leftSlot: React.ReactNode[];
    linkIconOnlyId: string;
    onClick: () => void;
}

const Search: React.FC = () => {
    const [showSearchingList, setShowSearchingList] = useState<boolean>(false);
    const [nodata, setNodata] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSearchEntry, setShowSearchEntry] = useState<boolean>(false);

    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();
    useEffect(() => {
        const initializeState = async () => {
            const storedInputValue = await getItemByKey('inputValue');

            // if (storedInputValue) {
            //     setInputValue(storedInputValue);
            //     setShowSearchEntry(false);
            //     setShowSearchingList(true);
            // } else {
            //     setShowSearchEntry(true);
            //     setShowSearchingList(false);
            // }

            // const recentSearch = await getItemByKey('recentSearch');
            // setStoredSearchQueries(recentSearch || []);

            // if (inputRef.current) {
            //     setTimeout(() => {
            //         inputRef.current?.focus();
            //     }, 100);
            //     // inputRef.current.focus();
            // }
        };

        initializeState();
        gtmPageLoad({url: '/', contentGroup: '검색|검색 실행', moduleName: '', pageTitle: '검색'});
    }, []);

    const addRecent = async (term: string) => {
        let recentSearch = await getItemByKey("recentSearch");

        if (recentSearch) {
            recentSearch = [term, ...recentSearch.filter((t: string) => t !== term)];
        } else {
            recentSearch = [term];
        }

        await addItem("recentSearch", recentSearch);
    };


    const handleSearchResult = (result: SearchResults, search: string) => {
        // setShowSearchingList(true); // 검색 후에는 검색 결과 화면을 표시
        navigate(`/result-detail?search=${search}`, {
            state: {
                searchResults: result
            }
        });
        // if (result.sectionCount === "0") {
        //     setNodata(true);
        // } else {
        //     navigate(`/result-detail?search=${search}`, {
        //         state: {
        //             searchResults: result
        //         }
        //     });
        // }
        setIsLoading(false)
    }

    const handleSearch = useDebounce(async (inputValue: string) => {
        if (inputValue.trim() === '') {
            showSnackbar('검색어를 입력해 주세요')
        } else {
            setIsLoading(true)
            setShowSearchingList(true); // 검색 후에는 검색 결과 화면을 표시
            await addRecent(inputValue);

            await addItem('searchQuery', inputValue);
            try {
                const response = await api.get(ApiEndpoints.SEARCH.INDEX, {
                    keyword: inputValue,
                });
                const searchResultList = response.body.searchResultList;
                console.log(searchResultList)
                if (searchResultList.sectionCount === "0") {
                    setNodata(true);
                } else {
                    navigate(`/result-detail?search=${inputValue}`, {
                        state: {
                            searchResults: searchResultList
                        }
                    });
                }
            } catch (error) {
                console.error('Failed to fetch search results:', error);
            } finally {
                setIsLoading(false)
            }
        }
    });

    return (
        <>
            {useLoading(isLoading)}

            <CommonSearch
                onSearch={handleSearchResult}
            />
            <div style={{paddingTop: "3.6rem"}}>
                {nodata ?
                    <>
                        <div
                            style={{
                                padding: '24px 0 24px 0'
                            }}
                        >
                            <CommonNodata type={"search"} text={"검색 결과가 없어요"}/>
                        </div>
                        <CommonDivider/>
                        <RecommandSearch onSearch={handleSearch}/>
                    </> : <>
                        <RecentSearch onSearch={handleSearch}/>
                        <RecommandSearch onSearch={handleSearch}/>
                        <CommonHeading title={'챗봇 검색'}/>
                        <Section4List/>
                    </>}
            </div>

        </>
    );
};

export default Search;
