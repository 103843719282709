import React, {useEffect, useState} from 'react';
import {Button, Image, Tab, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {CategoryItem, MainRegion, Region, SelectedPartnerCategory} from '../../pages/Partner/types';
import arrowFlowIcon from "../../images/etc/arrow_flow.svg";
import BS_MainRegionBS from "./BS_MainRegionBS";
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "../Common/CommonPopup";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import BS_SubRegionBS from "./BS_SubRegionBS";
import useDebounce from "../../hooks/useDebounce";
import CommonLocation from "./CommonLocation";
import {ENVIROMENT} from "../../api/api";

interface CategoryTabsProps {
    title: string;
    itemList: CategoryItem[];
    onItemChange: (category: SelectedPartnerCategory) => void;
    selectedItemId?: string;
}

const initMainRegion: MainRegion = {
    categoryId: ENVIROMENT === 'prod' ? '11' : '1022',
    categoryName: '서울',
    categoryType: '',
    subRegionList: [],
};

const initRegion: Region = {
    categoryId: '',
    categoryName: '전체',
    categoryType: '',
};

const CategoryTabs: React.FC<CategoryTabsProps> = ({
                                                       title,
                                                       itemList,
                                                       onItemChange,
                                                       selectedItemId,
                                                   }) => {
    const [selectedCategory, setSelectedCategory] = useState<CategoryItem>(initRegion);
    const [selectedMainRegion, setSelectedMainRegion] = useState<MainRegion>(initMainRegion);
    const [selectedSubRegion, setSelectedSubRegion] = useState<Region>(initRegion);
    const [subCategoryList, setSubCategoryList] = useState<MainRegion[]>([]);

    const [showMainRegionBS, setShowMainRegionBS] = useState(false);
    const [showSubRegionBS, setShowSubRegionBS] = useState(false);

    const [openLocationComponent, setOpenLocationComponent] = useState(false);

    const [platform, setPlatform] = useState('');
    const [show14BlockPopup, setShow14BlockPopup] = useState(false);


    useEffect(() => {
        const fetchIndexedDB = async () => {
            setPlatform(await getItemByKey('platform'));
        }
        fetchIndexedDB();

    }, []);

    const fetchSubCategory = async (categoryId: string) => {
        try {
            const res = await partnerApi.getCategoriesSub(categoryId, {cateDepth: '2'}, 'v1');
            if (res.header.resultCode !== '0000') {
                console.log('fetchSubCategory error');
            }

            const mainRegionList = res.body.categoryList;
            mainRegionList.map((item: MainRegion) => {
                item.subRegionList = [];
                item.subRegionList.push({categoryId: '', categoryName: '전체', categoryType: ''});
                try {
                    partnerApi.getCategoriesSub(item.categoryId, {cateDepth: '3'}, 'v1').then((res) => {
                        if (res.header.resultCode !== '0000') {
                            console.log('fetchSubCategory error');
                        }
                        item.subRegionList = item.subRegionList.concat(res.body.categoryList);
                    });
                } catch (e) {
                    console.log('fetchSubCategory error');
                }
            });
            setSubCategoryList(mainRegionList);

        } catch (e) {
            console.log('fetchSubCategory error');
        }
    }

    const handleCategoryChange = (id: string) => {
        const selectedItem = itemList.find(item => item.categoryId === id);
        if (selectedItem) {
            setSelectedCategory(selectedItem);
            const categoryForFetch = {
                categoryName: selectedItem.categoryName,
                depth1: selectedItem.categoryId,
                depth2: '',
                depth3: ''
            }
            onItemChange(categoryForFetch);
            if (selectedItem.categoryName === '지역') {
                fetchSubCategory(id);
            }
        }
    };

    const handleMainRegionSelect = (mainRegion: MainRegion) => {
        setSelectedMainRegion(mainRegion);
        // setSelectedSubRegion(initRegion);
        if (mainRegion.subRegionList.length > 1) {
            setShowSubRegionBS(true);
        } else {
            const categoryForFetch = {
                categoryName: selectedCategory.categoryName,
                depth1: selectedCategory.categoryId,
                depth2: mainRegion.categoryId,
                depth3: ''
            }
            onItemChange(categoryForFetch);
        }
    }

    const handleSubRegionSelect = (subRegion: Region) => {
        setSelectedSubRegion(subRegion);
        const categoryForFetch = {
            categoryName: selectedCategory.categoryName,
            depth1: selectedCategory.categoryId,
            depth2: selectedMainRegion.categoryId,
            depth3: subRegion.categoryId
        }
        onItemChange(categoryForFetch);
    }

    const handleMapClick = useDebounce(() => {
        // if (memberStatus === '3' || memberStatus === '4') {
        //     setShow14BlockPopup(true);
        // }
        // fetchLocationAgree();
        setOpenLocationComponent(true);
    });

    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{title: <span>{title}</span>}}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />
            {itemList && itemList.length > 0 && (
                <Tab
                    items={itemList.map((item) => ({
                        id: item.categoryId,
                        label: item.categoryName,
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `카테고리 - 링크 클릭`,
                            'data-gtm-event-label': `컨텐츠 : ${item.categoryName}`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `카테고리`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': item.categoryName,
                        }
                    }))}
                    layout="fitContent"
                    size="small"
                    selectedItemId={selectedItemId ? selectedItemId : itemList[0].categoryId}
                    onItemSelected={handleCategoryChange}
                />
            )}
            {selectedCategory?.categoryName == '지역' && (<div
                style={{
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "20px",
                    paddingRight: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#F3F5F6"
                }}
            >
                <Text as="span" textAlign="center" typo="font_heading_6B" color="color_text_neutral_3"
                      style={{display: "flex", gap: "6px"}}>
                    {selectedMainRegion.categoryName &&
                        <>
                            {selectedMainRegion.categoryName} {selectedSubRegion.categoryName &&
                            <>
                                <Image customClass="customClass" ratio="1:1" src={arrowFlowIcon}
                                       width="8"/> {selectedSubRegion.categoryName}
                            </>}
                        </>}
                </Text>
                <div
                    style={{
                        padding: "0px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}
                >
                    <Button fill="ghost" size="small" color="secondary" onClick={() => {
                        setShowMainRegionBS(!showMainRegionBS)
                    }}>
                        <Text as="span" textAlign="center" typo="font_label_3Sb" color="color_text_neutral_3">
                            지역 선택
                        </Text>
                    </Button>
                    <span className="bar2" style={{
                        width: "1px",
                        height: "1rem",
                        backgroundColor: "#E7EBEE",
                        padding: "0",
                        margin: "0 6px"
                    }}></span>
                    <Button fill="ghost" size="small" color="secondary" onClick={handleMapClick}
                            data-gtm-event-name="all_click"
                            data-gtm-event-category="클릭"
                            data-gtm-event-action={`${selectedMainRegion.categoryName}${selectedSubRegion.categoryName ? ` > ${selectedSubRegion.categoryName}` : ''}|리스트 - 링크 클릭`}
                            data-gtm-event-label="컨텐츠 : 내 주변 매장 보기"
                            data-gtm-click-url=""
                            data-gtm-click-location={`${selectedMainRegion.categoryName}${selectedSubRegion.categoryName ? ` > ${selectedSubRegion.categoryName}` : ''}|리스트`}
                            data-gtm-click-direction="내부"
                            data-gtm-click-text="내 주변 매장 보기">
                        <Text as="span" textAlign="center" typo="font_label_3Sb" color="color_text_neutral_3">
                            지도 보기
                        </Text>
                    </Button>
                </div>
            </div>)}

            <BS_MainRegionBS
                regionList={subCategoryList}
                selectedRegionId={selectedMainRegion.categoryId}
                isOpen={showMainRegionBS}
                onClose={() => {
                    setShowMainRegionBS(false)
                }}
                onSelect={handleMainRegionSelect}/>
            <BS_SubRegionBS
                mainRegion={selectedMainRegion}
                selectedRegion={selectedSubRegion}
                isOpen={showSubRegionBS}
                onClose={() => setShowSubRegionBS(false)}
                onSelect={handleSubRegionSelect} onBack={() => {
                setShowSubRegionBS(false);
                setShowMainRegionBS(true);
            }}/>

            <CommonPopup submitText={'만 14세 이상 고객이 이용할 수 있어요.'} onSubmit={() => {
                setShow14BlockPopup(false)
            }} isOpen={show14BlockPopup}/>

            <CommonLocation isOpen={openLocationComponent} onClose={() => setOpenLocationComponent(false)}/>
        </>
    );
};

export default CategoryTabs;
