import React from 'react';
import {Icon, Image, Text} from '@lguuxe/2024_designsystem_uni';


interface GuideProps {
    title: string;
    icon: string;
    onClick: () => void;
}

const GuideSection: React.FC<GuideProps> = ({title, icon, onClick}) => {
    const getGaLocation = (title: string) => {
        switch (title) {
            case '궁금한 내용 챗봇에게 물어보기':
                return 'LNB|챗봇';
            case 'U+멤버십 혜택 이용 GUIDE':
                return 'LNB|중간배너';
        }
    }
    return (
        <div style={{padding: '20px'}}>
            <div
                style={{
                    backgroundColor: '#FDEDF6',
                    border: '#FBDAED',
                    borderRadius: '12px',
                    padding: '12px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={onClick}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={`${getGaLocation(title)} - 링크 클릭`}
                data-gtm-event-label={`컨텐츠 : ${title}`}
                data-gtm-click-url={''}
                data-gtm-click-location={`${getGaLocation(title)}`}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`${title === '궁금한 내용 챗봇에게 물어보기' ? '챗봇' : `${title}`}`}
            >
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Image ratio='1:1' src={icon} width='28'/>
                    <Text typo='font_body_1Sb' color='#DF0C65'>
                        {title}
                    </Text>
                </div>
                <Icon name='chevron_right_small_line' size='24px' color='#E6007E'/>
            </div>
        </div>
    );
};

export default GuideSection
;

