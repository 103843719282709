import {openDB} from 'idb';

const DB_NAME = 'GenericDB';
const DB_VERSION = 1;
const STORE_NAME = 'items';

export const initDB = async () => {
    return await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        }
    });
};

export const addItem = async (key: string, value: any) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put(value, key);
    await tx.done;
};

export const getItems = async () => {
    const db = await initDB();
    return db.getAll(STORE_NAME);
};

export const getItemByKey = async (key: string) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const item = await store.get(key);
    await tx.done;
    return item;
};

export const clearItems = async () => {
    const db = await initDB();
    return db.clear(STORE_NAME);
};

export const removeItemByKey = async (key: string) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(key);
    await tx.done;
};

export const clearAllDatabases = async (): Promise<boolean> => {
    const deleteDatabase = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const request: IDBOpenDBRequest = window.indexedDB.deleteDatabase(DB_NAME);

            request.onsuccess = (ev) => {
                console.log(`Database ${DB_NAME} deleted successfully.`);
                console.log(ev);
                resolve(true);
            };

            request.onerror = (ev) => {
                console.error(`Failed to delete database ${DB_NAME}.`);
                console.error(ev);
                reject(false);
            };

            request.onblocked = (ev) => {
                console.error(`Failed to delete database ${DB_NAME} due to the operation being blocked.`);
                console.error(ev);
                reject(false);
            }

        });
    };

    try {
        // 첫 번째 시도
        return await deleteDatabase();
    } catch (error) {
        console.log("Retrying to delete the database...");
        // 에러 발생 시 한 번 더 시도
        try {
            return await deleteDatabase();
        } catch (secondError) {
            console.error("Failed again to delete the database.");
            return false;
        }
    }
};