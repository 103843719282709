import React from 'react'
import {Box} from '@lguuxe/2024_designsystem_uni'

interface SectionBodyTextProps {
    text: string;
}

const SectionBodyText: React.FC<SectionBodyTextProps> = ({text}) => {
    return (
        <Box>
            <div style={{
                overflowX: 'auto',  // 가로 스크롤 활성화
                paddingBottom: '70px'
            }}
                 dangerouslySetInnerHTML={{__html: text}}/>
        </Box>
    )
}

export default SectionBodyText
