import {appTech, chatbot, goMoveData, moneymeEnter} from "../api/externalApi";
import {sendEvent, sendEventReturn} from "../api/nativeBridge";
import {eventApi} from "../api/gatewayApi/attendanceAndEvent";
import useDebounce from "./useDebounce";
import {getItemByKey} from "../utils/indexedDB";
import {ENVIROMENT} from "../api/api";
import otherApi from "../api/gatewayApi/other";
import {useMessagePopup} from "../provider/MessagePopupProvider";
import {brazeEventApi} from "../api/brazeBridge";

const MOVIE_PROD = 'https://membership.lguplus.co.kr:30001/movie/mobile/intro.lgum?isMembers=Y'
const MOVIE_STG_DEV = 'http://ex-membership.lguplus.co.kr:30001/movie/mobile/intro.lgum?isMembers=Y'

const UPLUS_KOK_STG = 'https://dev.kok.uplus.co.kr'; // 검수, 개발
const UPLUS_KOK_PROD = 'https://kok.uplus.co.kr';

const useLandingPage = () => {
    const {showMessagePopup} = useMessagePopup();

    const noEnterUnder14PageList = ['mission', 'my/badge', 'pop', 'mobile-pay', 'event'];
    const checkBlockUnder14 = async (url: string) => {
        if (noEnterUnder14PageList.some((page) => url.startsWith(page))) {
            const memberStatus = await getItemByKey('memberStatus');
            if (memberStatus === '3' || memberStatus === '4') {
                showMessagePopup('만 14세 이상 고객이 이용할 수 있어요.')
                return true;
            }
        }
        return false;
    }

    const urlHandler = async (url: string, value: string, navigate: any) => {
        switch (url) {
            case 'chat':
                chatbot();
                break;
            case 'promotion/partners':
                navigate('/promotion?tab=2');
                break;
            case '/uptp-detail':
                sendEventReturn('WAITING_LINE_POPUP', {id: value, target: 'detail'});
                break;
            case 'mobile-pay/cancle':
            case 'mobile-pay/cancel':
                navigate('/mobile-pay/cancel', {state: {message: value}});
                break;
            case 'mobile-pay/fail':
                navigate('/mobile-pay/fail', {state: {message: value}});
                break;
            case 'barcode':
                navigate('/?state=barcode');
                break;
            case 'data':
                if (value === 'gift') {
                    goMoveData('0')
                } else if (value === 'list') {
                    goMoveData('1')
                } else {
                    navigate(`/${url}${value ? '/' + value : ''}`);
                }
                break;
            default:
                navigate(`/${url}${value ? '/' + value : ''}`);
                break;
        }
    }

    const landingPage = useDebounce(async (targetType: string, targetUrl: string, navigate: any, title?: string, pushParam?: string,) => {

        console.log('landingPage', targetType, targetUrl);
        let [url, value] = targetUrl ? targetUrl.split('|') : ['', ''];
        if (value === undefined || value === null) value = '';

        const memberId = await getItemByKey('memberId')
        if (!memberId && targetType !== '117001') {
            showMessagePopup('본인 인증이 필요해요')
            navigate('/')
            return;
        }

        switch (targetType) {
            case '117001': // 외부 페이지 이동
                sendEventReturn('EXTERNAL_CALL', {type: '1', url: url});
                break;
            case '117002': // 내부 페이지 이동
                if (await checkBlockUnder14(url)) {
                    break;
                }
                urlHandler(url, value, navigate);
                break;
            case '117003': // 이벤트 상세페이지
                try {
                    eventApi.getEventById(value, 'v1').then((res) => {
                        if (res.header.resultCode === '0000') {
                            const param = {
                                url: res.body.redirectUrl + (pushParam ? '&' + pushParam : ''),
                                title: title ? title : '',
                                showTitlebar: title !== undefined,
                                evtId: value,
                            }
                            brazeEventApi.event_detail_page_enter(title || '', value);
                            sendEvent('CALL_EVENT_DETAIL', param);
                        } else {
                            // TODO: 이벤트 api 콜 실패 처리
                        }
                    });
                } catch (error) {
                    console.log("Error getEventById:", error);
                }
                break;
            case '117004':
                sendEventReturn('WAITING_LINE_POPUP', {id: value, target: 'detail'});
                break;
            case '117005':
                const uri = ENVIROMENT === 'prod' ? MOVIE_PROD : MOVIE_STG_DEV;
                const param = {
                    url: uri + (value ? `&agent=${value}` : ''),
                    agent: value ? value : '',
                    userName: await getItemByKey('customerName'),
                    token: await getItemByKey('token'),
                    encKey: await getItemByKey('encKey'),
                    clv: await getItemByKey('cardLevel'),
                    ci: await getItemByKey('ci'),
                }
                sendEvent('OPEN_MOVIE_RESERVATION', param);

                break;
            case '117006': // 머니Me 메인
                moneymeEnter(url);
                break;
            case '117007': // 앱테크
                let urlParam = '';
                if (url) urlParam += url;
                if (pushParam) urlParam += `?${pushParam}`
                if (urlParam.startsWith('?')) {
                    urlParam = urlParam.replace('?', '');
                }

                appTech(urlParam);
                break;
            case '117008': // U+ 콕
                // if (memberStatus === '0') {
                //     handlePopup('login');
                //     return;
                // }
                try {
                    const res = await otherApi.getKokshop('v1');
                    if (res.header.resultCode === '0000') {
                        const kokString = res.body.urlString;
                        const baseUrl = ENVIROMENT === 'prod' ? UPLUS_KOK_PROD : UPLUS_KOK_STG;
                        const params = {
                            url: `${baseUrl}?${url}`,
                            param: kokString,
                            liveYn: 'Y',
                            rid: 'AD_MBS2',
                            lrid: 'AD_MBS2',
                            mappver: 'Y',
                            appUpd: 'Y',
                            extraLinkUrl: '/'
                        }
                        sendEvent('OPEN_UPKOK', params);
                    } else {
                        showMessagePopup(res.header.message);
                    }
                } catch (e) {
                    console.log(e);
                    showMessagePopup('일시적인 오류에요');
                }
                break;
            case '117009': // 휴대폰 결제 승인화면
                navigate(`/${url}${value ? '/' + value : ''}`);
                break;
            default:
                console.log('targetType is not matched');
                break;
        }
    });

    return {landingPage};
};

export default useLandingPage;