import {Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";

// msgData 타입 정의
interface MsgData {
    msgId: string;
    memberId?: string;
    msgTypeCode: string;
    msgTitle?: string;
    msgDetail?: string;
    linkType?: string;
    linkUrl?: string;
}

// 컴포넌트 정의
const Notification: React.FC<{ msgData: MsgData | null | undefined }> = ({msgData}) => {
    const title = msgData?.msgTitle || '';
    const detail = msgData?.msgDetail || '';

    return (
        <div>
            <Text
                typo="font_body_2Sb"
                color="color_text_neutral_5"
                style={{
                    display: "inline"
                }}
            >
                {msgData?.msgTitle || ''}
                <span style={{display: "inline-block", color: "#E6007E"}}>
                    {msgData?.msgDetail || ''}

                </span>
            </Text>
        </div>
    );
};

export default Notification;
