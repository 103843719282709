// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// ****************************************************
import React from 'react';
import {Button, CTA, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import tripBadge from '../../images/badge/trip_one.svg';
import attendanceBadge from '../../images/badge/trip_one.svg';
import shoppingBadge from '../../images/badge/shopping_one.svg';
import contentBadge from '../../images/badge/content_one.svg';
import dailyBadge from '../../images/badge/daily_one.svg';
import beautyBadge from '../../images/badge/beauty_one.svg';
import foodBadge from '../../images/badge/food_one.svg';
import steadyBadge from '../../images/badge/steady_one.svg';
import studyBadge from '../../images/badge/study_one.svg';
import {MyBadge} from '../../pages/My/types';
import {useNavigate} from 'react-router-dom';
import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";

interface MyBadgesProps {
    myBadge: MyBadge;
}

const getBadgeImage = (badgeName: string) => {
    switch (badgeName) {
        case '나들이킹':
            return tripBadge;
        case '쇼핑킹':
            return shoppingBadge;
        case '콘텐츠킹':
            return contentBadge;
        case '일상킹':
            return dailyBadge;
        case '푸드킹':
            return foodBadge;
        case '뷰티킹':
            return beautyBadge;
        case '스터디킹':
            return studyBadge;
        case '개근킹':
            return steadyBadge;
        case '출석킹':
            return attendanceBadge;
        default:
            return '';
    }
};

const MyBadges: React.FC<MyBadgesProps> = ({myBadge}) => {
    const navigate = useNavigate();

    const handleMore = () => {
        navigate('/my/badge');
    };

    const handleBadgeMission = () => {
        navigate('/promotion');
    }

    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <span style={{color: '#181A1B'}}>내 배지
                            <Text typo='font_heading_4B' color='color_text_primary'>
                                &nbsp;{myBadge.badgeCount}
                            </Text>
                        </span>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div
                style={{
                    overflow: 'auto',
                    display: 'flex',
                    width: 'calc(100vw - 40px)',
                    padding: '0 20px',
                    gap: '6px',
                }}
            >
                {myBadge.categoryList.map((badge, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '6px',
                            minWidth: '96px',
                            padding: '12px',
                        }}
                    >
                        <Image src={getBadgeImage(badge.categoryName)} alt='제휴이미지' width='96' ratio='1:1'/>
                        <div style={{gap: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Text typo='font_heading_5Sb' color='color_text_neutral_5'>
                                {badge.categoryName} {badge.count}개
                            </Text>
                        </div>
                    </div>
                ))}
            </div>

            {myBadge.categoryList.length === 0 && (
                <div style={{padding: '24px  0 4px'}}>
                    <CTA layout='column'>
                        <Button color='secondary' onClick={handleBadgeMission}
                            // data-gtm-event-name={'all_click'}
                            // data-gtm-event-category={'클릭'}
                            // data-gtm-event-action={`${CLICK_LOCATION.VIPKOK_BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                            // data-gtm-event-label={`컨텐츠 : 이번 달 VIP콕 사용하기`}
                            // data-gtm-click-url={''}
                            // data-gtm-click-location={`${CLICK_LOCATION.VIPKOK_BENEFIT}`}
                            // data-gtm-click-direction={'내부'}
                            // data-gtm-click-text={`이번 달 VIP콕 사용하기`}
                        >
                            킹배지 미션 보기
                        </Button>
                    </CTA>
                </div>
            )}

            <div
                style={{
                    textAlign: 'center',
                    borderTop: '1px solid #E7EBEE',
                    // marginTop: '12px',
                }}
            >
                <Button
                    color='neutral'
                    rightIcon='chevron_right_small_line'
                    size='large'
                    fill='text'
                    style={{
                        padding: '14px 0',
                        width: '100%',
                    }}
                    onClick={handleMore}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.MY_BADGES} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.MY_BADGES_MORE}`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`${CLICK_LOCATION.MY_BADGES}`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`${CLICK_TEXT.MY_BADGES_MORE}`}
                >
                    <Text typo='font_label_2Sb' color='color_text_neutral_3'>
                        더보기
                    </Text>
                </Button>
            </div>
        </>
    );
};

export default MyBadges;
