import React from 'react';
import {Box, Button, CTA, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {formatCurrency} from "../../utils/currencyUtils";

interface SectionPaymentInfo3Props {
    limit: {
        maxLimit: string;
        monthUseAmnt: string;
    } | null;
}

const SectionPaymentInfo3: React.FC<SectionPaymentInfo3Props> = ({limit}) => {
    const navigate = useNavigate();

    const goLimitChange = () => {
        navigate('/mobile-pay/limit');
    };

    return (
        <div>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_1"
                    text={{
                        eyebrow: '월 결제 한도',
                        title: `${formatCurrency(limit?.monthUseAmnt)}원`, // 월 사용 가능금액 표시
                    }}
                    weight="bolder"
                />
            </Box>
            <div
                style={{
                    padding: "16px 20px 0",
                }}
            >
                <Text
                    style={{flex: "1"}}
                    typo="font_body_1M"
                    color="color_text_neutral_4"
                >
                    {formatCurrency(limit?.maxLimit)}원까지 설정 가능
                </Text>
            </div>
            <div
                style={{
                    paddingTop: "12px",
                }}
            >
                <CTA layout="column">
                    <Button color="secondary" onClick={goLimitChange}
                            data-gtm-event-name="all_click"
                            data-gtm-event-category="클릭"
                            data-gtm-event-action="마이|휴대폰 결제|휴대폰 결제 설정 - 링크 클릭"
                            data-gtm-event-label="컨텐츠 : 한도 변경"
                            data-gtm-click-url=""
                            data-gtm-click-location="마이|휴대폰 결제|휴대폰 결제 설정"
                            data-gtm-click-direction="내부"
                            data-gtm-click-text="한도 변경"
                    >한도 변경</Button>
                </CTA>
            </div>
        </div>
    );
};

export default SectionPaymentInfo3;
