// ****************************************************
// [배포] 4.마이 - 휴대폰결제 - 결제 케이스 [결제완료] (UmembershipPaymentCompleted)
// [배포] 4.마이 - 휴대폰결제 - 결제 케이스 [결제 취소] (UmembershipPaymentCompleted_3)
// ****************************************************
import React from 'react'
import {Box, Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {PaymentInfo} from "../../pages/MobilePayment/MobilePaymentResult";
import dayjs from "dayjs";
import defaultThumbnail from '../../images/default/default thumbnail.svg'
import partnerThumbnail from '../../images/img/mobile-pay/partner.svg'

interface SectionContentBenfitProps {
    paymentInfo: PaymentInfo;
}

const SectionContentBenfit: React.FC<SectionContentBenfitProps> = ({paymentInfo}) => {

    return (
        <Box
            type="1_trbl"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px"
            }}
        >
            <Image
                customClass="customClass"
                ratio="1:1"
                rounded="999"
                src={partnerThumbnail}
                srcFallback={defaultThumbnail}
                width="72"
            />
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <TextSet
                    subComponent="Heading_3"
                    text={{
                        title: paymentInfo.useplaceName
                    }}
                    weight="bolder"
                />
                <Text typo="font_detail_2M" color="color_text_neutral_3"
                      style={{
                          paddingTop: "8px",
                          display: "flex",
                          justifyContent: "center"
                      }}>{dayjs(paymentInfo.agreeDate).format('YYYY년 MM월 DD일 HH시 mm분')}</Text>
            </div>
        </Box>
    )
}

export default SectionContentBenfit
