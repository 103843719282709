import React from 'react';
import {Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import defaultThumbnail from "../../images/default/default thumbnail.svg";

interface StoreItemProps {
    itemList: StoreItem[]
    threshold?: number;
    onCheckLogin: () => boolean;
}

interface StoreItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
}

const StoreItem: React.FC<StoreItemProps> = ({itemList, threshold, onCheckLogin}) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const navigate = useNavigate()

    const handleClickStore = (store: StoreItem) => () => {
        if (onCheckLogin()) {
            navigate(`/partners/${store.itemId}`)
        }
    }

    return (
        <div style={{padding: "10px 0 12px "}}>
            <List
                horizontalItemWidth='104px'
                style={{
                    gap: '0.75rem',
                    padding: '1rem 1.25rem 1rem 1.25rem',
                    width: 'calc(100% - 2.5rem)',

                }}
                fill='none'
                name='list_name'
                size='large'
                type='view'
                weight='default'
                isSlotClickable={true}
                customItemPadding='0px'
                items={itemsToRender.map((item, index) => ({
                    if: item.itemId,
                    leftSlot: (
                        <div
                            key={index}
                            style={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '8px',
                                minWidth: '104px',
                            }}
                            onClick={handleClickStore(item)}
                        >
                            <Image
                                src={item.itemImgUrl}
                                alt='파트너 로고'
                                width='56'
                                ratio='1:1'
                                rounded={54.86}
                                srcFallback={defaultThumbnail}
                            />
                            <div
                                style={{
                                    gap: '2px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '104px',  // width 설정
                                }}
                            >
                                <Text
                                    typo='font_body_1B'
                                    color='color_text_neutral_6'
                                    style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 1, // 최대 2줄로 설정
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {item.itemName}
                                </Text>
                            </div>
                        </div>
                    ),
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': '추천 제휴사 - 링크 클릭',
                        'data-gtm-event-label': `컨텐츠 : ${item.itemName}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': '추천 제휴사',
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${item.itemName}`,
                    }
                }))}
            />
        </div>
    );
}

export default StoreItem;